import {SET_USERS} from "../../actions/users/setUsers";

export default (state = null, {type, users = null }) => {
	Object.freeze(state);
	switch (type) {
		case SET_USERS:
			return users;
		default:
			return state;
	}
};
