/** @format */

import React from 'react';
import { connect } from 'react-redux';
import { setPurchasedCourse } from '../../actions/course-details/setPurchasedCourse';
import CourseCard from '../global/cards/CourseCard';

const ProfileCourseList = (props) => {
	let pastCourses = props.data.filter((f) => f.status === 'completed');
	let currentCourses = props.data.filter((f) => f.status === 'current');
	let allCourses = props.data;

	// console.log(props.purchasedCourse);
	return (
		<section>
			{props.session.role === 'instructor' ? (
				<>
					<h2>Courses</h2>
					<ul className='course-list'>
						{allCourses &&
							allCourses.length > 0 &&
							allCourses.map((course, i) => {
								return (
									<li key={"allcourses"+i}>
										<CourseCard
											courseId={course.id}
											key={`cc${i}`}
										/>
									</li>
								);
							})}
					</ul>
				</>
			) : props.purchasedCourse ? (
				<>
					<h2>Current courses</h2>

					<ul className='course-list'>
						<li>
							<CourseCard courseId={props.purchasedCourse.id} />
						</li>
					</ul>
				</>
			) : (
				<></>
			)}

			{pastCourses && pastCourses.length > 0 ? (
				<>
					<h2>Completed courses</h2>
					<ul className='course-list'>
						{pastCourses && pastCourses.length > 0 ? (
							pastCourses.map((course, index) => {
								return (
									<li key={'pastCourse' + index}>
										<CourseCard courseId={course.id} />
									</li>
								);
							})
						) : (
							<></>
						)}
					</ul>
				</>
			) : (
				<></>
			)}
		</section>
	);
};
const mapStateToProps = ({ session, purchasedCourse }) => ({
	session,
	purchasedCourse,
});

const mapDispatchToProps = (dispatch) => ({
	setPurchasedCourse: (data) => dispatch(setPurchasedCourse(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCourseList);
