/** @format */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import PageWrapper from '../global/PageWrapper';
import { getCourseDetails } from '../../util/getCourseDetails';
import { setCourseInfo } from '../../actions/course-details/setCourseInfo';
import RelatedLearning from './RelatedLearning';
import GoBack from '../global/GoBack';
import Modal from '../global/Modal';
import CourseCountdown from './CourseCountdown';

function CourseDetails(props) {
	const params = useParams();
	const { courseId } = params;
	const [purchased, setPurchased] = useState(false);
	const [modalopen, setmodalopen] = useState(false);
	const [modalcontent, setmodalcontent] = useState('Content');
	const [modalid, setmodalid] = useState('id');

	useEffect(() => {
		if (props.purchasedCourse && courseId === props.purchasedCourse.courseId) {
			setPurchased(true);
		}
		getCourseDetails(courseId).then((data) => {
			props.setCourseInfo(data);
		});
	}, [courseId, purchased, setPurchased]);

	const openModal = (e) => {
		e.preventDefault();
		const id = e.target.getAttribute('id');
		const badge = props.course;
		const isCongrats = e.target.textContent == 'Congratulate' ? true : false;

		// the idea here is that you can get this content from wherever, and populate during render
		var ModalContent = (
			<>
				<div className='recommend innerContent'>
					<div className='col-1'>
						<img src={badge.image} />
					</div>
					<div className='col2'>
						<h2>{badge.name}</h2>
						{props.course.slug ? (
							<p className=''>
								Issued by:{' '}
								<Link
									to={
										process.env.PUBLIC_URL +
										/issuer/ +
										props.course.slug.toLowerCase().replace(/\s/g, '-')
									}
									className=''
								>
									{badge.issuer}
								</Link>
							</p>
						) : (
							<p>
								Issued by: <a>{badge.issuer}</a>
							</p>
						)}
						<div>
							<button
								className='button button-primary'
								onClick={closeModal}
							>
								{isCongrats ? 'Congratulate' : 'Recommend'}
							</button>
						</div>
					</div>
				</div>
				<div className='buttonBar'>
					<div className='form-input'>
						<label htmlFor='testInput'>Add a custom message</label>
						<input
							type='text'
							id='testInput'
							className='gr-input'
							placeholder='Add your custom message here'
						/>
					</div>
				</div>
			</>
		);

		setmodalid(e.target.getAttribute('id'));
		setmodalcontent(ModalContent);
		setmodalopen(true);
	};
	const closeModal = (e) => {
		e.preventDefault();
		setmodalopen(false);
	};

	if (props.course) {
		return (
			<>
				<PageWrapper
					pageType='dashboard'
					activePage='coursedetails'
					hideNav
				>
					<div className='pillar-page learning'>
						<section className='intro dark-bg'>
							<GoBack />
							<div className='section-row'>
								<div className='page-info'>
									<div className='badge'>
										<img
											src={props.course.image}
											alt=''
										/>
										<span className='chip-highlight plum'>+5 Fit Score</span>
									</div>
									<div>
										<h1 className='page-title'>{props.course.name}</h1>
										<p className='page-desc'>{props.course.benefits}</p>
										<p className=''>
											Issued by:{' '}
											<Link
												className=''
												to={'/issuer/' + props.course.slug}
											>
												{props.course.issuer}
											</Link>
										</p>
									</div>
								</div>
								{props.session.role === 'owner' ? (
									<button
										className='button button-primary'
										onClick={openModal}
										id={props.course.id}
									>
										Recommend
									</button>
								) : purchased === true ? (
									<Link
										className='button button-primary'
										to={`/channels`}
									>
										Continue learning
									</Link>
								) : (
									<Link
										className='button button-primary'
										to={`/courses/${props.course.id}/enroll`}
									>
										Enroll in this course
									</Link>
								)}
							</div>
							
							<div className='card-list'>
								<div className='card overview'>
									<button className='icon-btn info-btn'>
										<span className='info-icon'>
											<svg
												focusable='false'
												className='icon-18'
												aria-hidden='true'
											>
												<use
													xmlnsXlink='http://www.w3.org/1999/xlink'
													xlinkHref='#info-18'
												></use>
											</svg>
										</span>
									</button>
									<span className='label'>Cost</span>
									<span className='number'>${props.course.cost}</span>
									<span className='footnote'>Exam included</span>
									{props.session.role === 'owner' && (
										<button className='button-link'>View</button>
									)}
								</div>
								<div className='card overview'>
									<button className='icon-btn info-btn'>
										<span className='info-icon'>
											<svg
												focusable='false'
												className='icon-18'
												aria-hidden='true'
											>
												<use
													xmlnsXlink='http://www.w3.org/1999/xlink'
													xlinkHref='#info-18'
												></use>
											</svg>
										</span>
									</button>
									<span className='label'>Est. time</span>
									<span className='number'>
										{props.course.time} <small>{props.course.timeUnit}</small>
									</span>
									{props.session.role === 'owner' && (
										<button className='button-link'>View</button>
									)}
								</div>
								<div className='card overview'>
									<button className='icon-btn info-btn'>
										<span className='info-icon'>
											<svg
												focusable='false'
												className='icon-18'
												aria-hidden='true'
											>
												<use
													xmlnsXlink='http://www.w3.org/1999/xlink'
													xlinkHref='#info-18'
												></use>
											</svg>
										</span>
									</button>
									<span className='label'>Skills</span>
									<span className='number'>
										{props.course.skillNames.length}
									</span>
									{props.session.role === 'owner' && (
										<button className='button-link'>View</button>
									)}
								</div>
								<div className='card overview'>
									<button className='icon-btn info-btn'>
										<span className='info-icon'>
											<svg
												focusable='false'
												className='icon-18'
												aria-hidden='true'
											>
												<use
													xmlnsXlink='http://www.w3.org/1999/xlink'
													xlinkHref='#info-18'
												></use>
											</svg>
										</span>
									</button>
									<span className='label'>Employees</span>
									<span className='number'>{props.course.totalEmployees}</span>
									{props.session.role === 'owner' && (
										<button className='button-link'>View</button>
									)}
								</div>
							</div>
						</section>
						<section className='overview'>
							<h2>Overview</h2>
							<p>{props.course.description}</p>
							<h3>Earning criteria</h3>
							<p>{props.course.earningCriteria}</p>
						</section>
						<section>
							<h2>Skills</h2>
							<ul className='chip-list'>
								{props.course.skillNames.map((skill, i) => {
									return (
										<li key={`skill${i}`}>
											<Link
												className='chip-btn'
												to={`/skill/${props.course.relatedSkills[i]}`}
											>
												{skill}
											</Link>
										</li>
									);
								})}
							</ul>
						</section>
						<section className='dark-bg discover'>
							<h2>Discover</h2>
							<div className='card-list'>
								<div className='card '>
									<div className='card-header'>
										<h3 className='card-title'>Related skills</h3>
										<a>View all</a>
									</div>
									<div className='card-body'>
										<ul className='chip-list'>
											{props.course.skillNames.map((skill, i) => {
												return (
													<li key={`skill${i}`}>
														<Link
															className='chip-btn'
															href={`/skill/${props.course.relatedSkills[i]}`}
														>
															{skill}
														</Link>
													</li>
												);
											})}
										</ul>
									</div>
								</div>
								<div className='card '>
									<div className='card-header'>
										<h3 className='card-title'>Related learning</h3>
										<a>View all</a>
									</div>
									<div className='card-body'>
										<ul className='learning-list'>
											{props.course.relatedCBNames.map((relatedCbName, i) => {
												return (
													<li key={`related${i}`}>
														<RelatedLearning name={relatedCbName} />
													</li>
												);
											})}
										</ul>
									</div>
								</div>
							</div>
						</section>
					</div>
				</PageWrapper>
				<Modal
					open={modalopen}
					setModalOpen={setmodalopen}
					targetid={modalid}
				>
					{modalcontent}
				</Modal>
			</>
		);
	} else {
		return (
			<div className='authorized'>
				<div className='pageWrapper employee pillar'>
					<div className='skeleton course-details'>
						<br />
						<div className='img'></div>
						<h2></h2>

						<div className='table-row'></div>
						<div className='table-row'></div>
						<div className='table-row'></div>
						<div className='table-row'></div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ session, course, purchasedCourse }) => ({
	session,
	course,
	purchasedCourse,
});

const mapDispatchToProps = (dispatch) => ({
	setCourseInfo: (data) => dispatch(setCourseInfo(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetails);
