/** @format */

import React, { useEffect } from 'react';
import { getStudentPathways } from '../../util/getStudentPathways';
import PathwayCard from './PathwayCard';
import { setPathways } from '../../actions/pathways/setPathways';
import { connect } from 'react-redux';

const PathwayList = (props) => {
	useEffect(() => {
		if (!props.pathways) {
			getStudentPathways(props.session.userId).then((data) => {
				props.setPathways(data);
							});
		}
	}, []);

	const filterList = (query) => {
		let filtered = props.pathways.filter((f) => f.type === query);

		return filtered;
	};

	if (props.pathways) {
		return (
			<section style={{padding:"20px 0"}}>
				<div className='pathway-list'>
					<h2>Recommended pathway</h2>
					{filterList('recommended').map((pathway, index) => {
						return (
							<PathwayCard
								type='recommended'
								openSheet={props.openSheet}
								data={pathway}
								key={'rec' + index}
							/>
						);
					})}
					{/* // <PathwayCard type="recommended"/> */}
				</div>
				<div className='pathway-list'>
					<h2>Alternate pathways</h2>
					{filterList('alternate').map((pathway, index) => {
						return (
							<PathwayCard
								type='alternate'
								data={pathway}
								key={'alt' + index}
							/>
						);
					})}
				</div>
				<div className='pathway-list'>
					<h2>Current role</h2>
					{filterList('current').map((pathway, index) => {
						return (
							<PathwayCard
								type='current'
								data={pathway}
								key={'current' + index}
							/>
						);
					})}
				</div>
			</section>
		);
	} else {
		return <></>;
	}
};

const mapStateToProps = ({ session, pathways }) => ({
	session,
	pathways,
});

const mapDispatchToProps = (dispatch) => ({
	setPathways: (data) => dispatch(setPathways(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PathwayList);
