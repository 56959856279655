import React from "react";
import { Link } from "react-router-dom";

function getAvatarImage (props) {
	//console.log('what are these props?', props)
    if(!props.data.avatar){
        if (props.data.gender === 'Female') {
            return `https://xsgames.co/randomusers/assets/avatars/female/${props.index}.jpg`
        } else {
            return `https://xsgames.co/randomusers/assets/avatars/male/${props.index}.jpg`
        }    
    } else {
        return props.data.avatar
    }
}
const Avatar = (props) => {
    return (
        <div className="avatar">
            <Link to={`/people/${props.data.id}`}>
                {
                    props.data.avatar ?
                    <img src={getAvatarImage(props)} /> :
                    <span className="initials">{props.firstName.substr(0,1)}{props.lastName.substr(0,1)}</span>
                }
            </Link>
        </div>
    )
}

export default Avatar
