/** @format */

import Icon from '../global/Icon';
import Card from '../global/cards/Card';

const StudentSummary = () => {
	return (
		<div className='card-list'>
			<Card modifier='overview'>
				<button className='icon-btn info-btn'>
					<span className='info-icon'>
						<Icon
							icon='info'
							size='18'
						/>
					</span>
				</button>
				<span className='label'>Total classes</span>
				<span className='number'>218</span>
				<div className='graph-container trend-up'>
					<div>
						<span className='chip-highlight'>
							+13{' '}
							<Icon
								icon='trending-up'
								size='18'
							/>
						</span>
					</div>
					<svg
						width='162'
						height='23'
						viewBox='0 0 162 23'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M 0,22.953634881555555 C 1.44,22.867416434668943 6.12,22.570568709444228 9,22.41476958851423 C 11.879999999999999,22.25897046758423 15.12,22.32530622561432 18,21.979890375743057 C 20.88,21.634474525871795 24.12,20.592053716661436 27,20.25592052681883 C 29.88,19.919787336976224 33.12,19.863414477416303 36,19.87905793922677 C 38.88,19.894701401037235 42.12,20.437639476757962 45,20.353692163134248 C 47.88,20.269744849510534 51.12,19.815141686327994 54,19.354387229078565 C 56.88,18.893632771829136 60.12,17.66559239565024 63,17.473976805325314 C 65.88,17.28236121500039 69.12,18.560183458132055 72,18.15678978954778 C 74.88,17.753396120963505 78.12,15.192348354869317 81,14.952766376673583 C 83.88,14.713184398477848 87.12,16.458363177303717 90,16.65940242582444 C 92.88,16.86044167434516 96.12,16.53906663977765 99,16.209261679928105 C 101.88,15.87945672007856 105.12,15.2223111773069 108,14.59812142676478 C 110.88,13.973931676222664 114.12,13.038249563647398 117,12.308075739039873 C 119.88,11.577901914432351 123.12,9.926139511057505 126,10.034535022967763 C 128.88,10.14293053487802 132.12,13.378232220710878 135,12.985547688478984 C 137.88,12.592863156247093 141.12,7.569822287586233 144,7.580256696518436 C 146.88,7.590691105450639 150.12,12.546016190637038 153,13.050762744305253 C 155.88,13.555509297973467 159.12,11.183725365466257 162,10.73492265694478 C 164.88,10.286119948423305 169.56,10.324014110589829 171,10.245745816046028'
							fill='none'
							stroke='#6D0176'
							strokeWidth='1.5px'
						/>
					</svg>
				</div>
			</Card>
			<Card modifier='overview'>
				<button className='icon-btn info-btn'>
					<span className='info-icon'>
						<Icon
							icon='info'
							size='18'
						/>
					</span>
				</button>
				<span className='label'>Active students</span>
				<span className='number'>61</span>
				<div className='graph-container trend-up'>
					<div>
						<span className='chip-highlight'>
							+22{' '}
							<Icon
								icon='trending-up'
								size='18'
							/>
						</span>
					</div>
					<svg
						width='162'
						height='23'
						viewBox='0 0 162 23'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M 0,22.809634126191185 C 1.4400000000000002,22.68433760951778 6.12,22.23666496762181 9,22.026530896982404 C 11.879999999999999,21.816396826343 15.120000000000001,21.627676981985715 18,21.496296184694906 C 20.88,21.364915387404096 24.12,21.471057900918257 27,21.20540091391485 C 29.88,20.939743926911444 33.12,20.302886870628146 36,19.83594001592361 C 38.88,19.368993161219077 42.12,18.701208657222264 45,18.28698307201151 C 47.88,17.872757486800754 51.12,17.083407365274933 54,17.24703010835639 C 56.88,17.41065285143785 60.12,19.596410751744592 63,19.30962521627063 C 65.88,19.022839680796665 69.12,16.261267053536365 72,15.454620511644112 C 74.88,14.647973969751861 78.12,14.560023952419225 81,14.268084329444056 C 83.88,13.976144706468885 87.12,13.941578699501925 90,13.629997868049298 C 92.88,13.31841703659667 96.12,11.959357978016724 99,12.320704132865142 C 101.88,12.68205028771356 105.12,15.953892347864171 108,15.888411335851911 C 110.88,15.822930323839653 114.12,12.208628971504947 117,11.911447807788527 C 119.88,11.614266644072107 123.12,14.350543486047743 126,14.031029062624285 C 128.88,13.711514639200828 132.12,10.960148749781105 135,9.91448266139192 C 137.88,8.868816573002738 141.12,7.896349538991154 144,7.495616010191888 C 146.88,7.094882481392623 150.12,7.737342841092592 153,7.409898106396512 C 155.88,7.082453371700432 159.12,5.499410786427137 162,5.449086418341391 C 164.88,5.398762050255646 169.56,6.831965303857534 171,7.095370805860608'
							fill='none'
							stroke='#6D0176'
							strokeWidth='1.5px'
						/>
					</svg>
				</div>
			</Card>
			<Card modifier='overview'>
				<button className='icon-btn info-btn'>
					<span className='info-icon'>
						<Icon
							icon='info'
							size='18'
						/>
					</span>
				</button>
				<span className='label'>Learning completed</span>
				<span className='number'>34</span>
				<div className='graph-container trend-up'>
					<div>
						<span className='chip-highlight'>
							+2{' '}
							<Icon
								icon='trending-up'
								size='18'
							/>
						</span>
					</div>
					<svg
						width='162'
						height='23'
						viewBox='0 0 162 23'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M 0,22.991036054791365 C 1.4400000000000002,22.884062939796074 6.119999999999999,22.52663867203701 9,22.322454086070792 C 11.88,22.118269500104574 15.120000000000001,21.984635995636975 18,21.714882392502503 C 20.88,21.44512878936803 24.12,20.803236660939117 27,20.63649406648034 C 29.88,20.46975147202156 33.12,20.84690105826753 36,20.67274117713513 C 38.88,20.49858129600273 42.12,19.749638974717232 45,19.54799480940284 C 47.88,19.34635064408845 51.12,19.768952074038204 54,19.412465143920176 C 56.88,19.05597821380215 60.12,17.958863984826333 63,17.31995149616516 C 65.88,16.681039007503987 69.12,15.805099006032806 72,15.419262089787834 C 74.88,15.033425173542861 78.12,15.001217131732657 81,14.90847076963408 C 83.88,14.815724407535503 87.12,15.215011465739437 90,14.839597326671733 C 92.88,14.464183187604029 96.12,12.685224210722822 99,12.562132400460928 C 101.88,12.439040590199035 105.12,14.282416513769679 108,14.070273512534902 C 110.88,13.858130511300127 114.12,11.636735116414904 117,11.236238642743581 C 119.88,10.835742169072258 123.12,11.272733183177314 126,11.56717055208913 C 128.88,11.861607921000948 132.12,13.68512906369249 135,13.076472198442435 C 137.88,12.46781533319238 141.12,8.35875499913659 144,7.763065144276294 C 146.88,7.167375289416 150.12,9.436413167807288 153,9.3534106055656 C 155.88,9.270408043323911 159.12,7.318518733429895 162,7.244299130265739 C 164.88,7.170079527101585 169.56,8.626299852905811 171,8.889538085789633'
							fill='none'
							stroke='#6D0176'
							strokeWidth='1.5px'
						/>
					</svg>
				</div>
			</Card>
			<Card modifier='overview'>
				<button className='icon-btn info-btn'>
					<span className='info-icon'>
						<Icon
							icon='info'
							size='18'
						/>
					</span>
				</button>
				<span className='label'>Certifications acquired</span>
				<span className='number'>178</span>
				<div className='graph-container trend-up'>
					<div>
						<span className='chip-highlight'>
							+8{' '}
							<Icon
								icon='trending-up'
								size='18'
							/>
						</span>
					</div>
					<svg
						width='162'
						height='23'
						viewBox='0 0 162 23'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M 0,22.9973500707612 C 1.4400000000000002,22.85407464077052 6.119999999999999,22.328410115320807 9,22.10187863331946 C 11.88,21.87534715131811 15.120000000000001,21.787575897971173 18,21.58152830825276 C 20.88,21.375480718534348 24.12,21.002332193860386 27,20.814081197579387 C 29.88,20.62583020129839 33.12,20.502811558161554 36,20.40495958149652 C 38.88,20.307107604831486 42.12,20.46599472780945 45,20.202506343422936 C 47.88,19.939017959036427 51.12,19.15547698663038 54,18.758157179080833 C 56.88,18.360837371531286 60.12,17.88668789141708 63,17.719257546238264 C 65.88,17.55182720105945 69.12,17.77038199091041 72,17.711717521713236 C 74.88,17.653053052516068 78.12,17.52605236696253 81,17.352604613755954 C 83.88,17.179156860549377 87.12,17.475007973672614 90,16.627669064172125 C 92.88,15.780330154671635 96.12,12.467008379918996 99,12.056736429377892 C 101.88,11.64646447883679 105.12,13.782797196043044 108,14.063469373290236 C 110.88,14.344141550537428 114.12,14.106414464839718 117,13.810937537172844 C 119.88,13.51546060950597 123.12,12.392959104821799 126,12.21673857537227 C 128.88,12.040518045922743 132.12,12.916786045142562 135,12.709559228113301 C 137.88,12.50233241108404 141.12,10.635738169679568 144,10.921570968939392 C 146.88,11.207403768199216 150.12,15.076687283293419 153,14.496014223487203 C 155.88,13.915341163680989 159.12,8.58918434311931 162,7.292364345150548 C 164.88,5.995544347181786 169.56,6.535125253617339 171,6.390889236182443'
							fill='none'
							stroke='#6D0176'
							strokeWidth='1.5px'
						/>
					</svg>
				</div>
			</Card>
		</div>
	);
};
export default StudentSummary;
