import React, {useEffect, useState} from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";
import Icon from '../global/Icon';
import {connect} from "react-redux";
import * as d3 from "d3";

const FitScoreDonut = (props) => {
	const scores = props.scores[0];
    const data = [
        {
            "name": "avg",
            "value": 75,
            "avg": 70,
            "exp": scores.experience,
            "edu": scores.education,
            "abil": scores.ability,
            "fill":"url(#ringGradientFF)"
          }
      ]
      const stacked = [
        {
            name: "Score",
            exp: scores.experience,
            edu: scores.education,
            abil: scores.ability
        },
        {
          "name": "score",
          "value": scores.score,
          "avg": 70,
          "fill":"#0CC0AD"
        }
      ]
      const donut = [
        {
            "name":"experience",
            "value":scores.experience,
            "fill":"#9901A5"
        },
        {
            "name":"education",
            "value":scores.education,
            "fill":"#6D0176"
        },
        {
            "name":"ability",
            "value":scores.ability,
            "fill":"#57015E"
        },
        
        {
            "name":"total",
            "value": 100 - scores.score,
            "fill":"var(--color-light-200)"
        }
        
      ]
      const average = [
        {
            "name": "avg",
            "value": 75,
            "fill":"url(#ringGradientFF)"
        },{
            "name":"total",
            "value":25,
            "fill":"var(--color-light-200)"
        }
      ]
      const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

      const drawChart = () => {
        const width = 295;
        const height = 260;
        const radius = 148;
        const donutWidth = 111;
        const svg = d3.select("#chart")
                    .append('svg')
                    .attr("height",height)
                    .attr("width",width)
                    .append('g')
                    .attr('transform', 'translate(' + (width / 2) + ',' + '150)');
        
        var arc = d3.arc()
                .innerRadius(111)
                .outerRadius(148)
                .padAngle(0)
                .padRadius(-1*2*Math.PI)
                .cornerRadius(20);
        var pie = d3.pie()
            .startAngle(-135 * (Math.PI / 180))
            .endAngle(135 * (Math.PI / 180))
            .value(function (d) {
                return d.value
            })
            .sort(null);
        var path = svg.selectAll('path')
            .data(pie(donut))
            .enter()
            .append('path')
            .attr('d', arc)
            .attr('stroke-width', '10')
            .attr('stroke', 'white')
            .attr('fill', function (d, i) {
                 return donut[i].fill;
            })
            .attr('cx',20)
            .attr('transform', 'translate(0, 0)');

        path.transition().duration(500).attr("d", arc); // redrawing the path with a smooth transition
      }
    //   innerRadius={111}
    //   outerRadius={148}
    //   cornerRadius={20}
    //   fill="#8884d8"

    useEffect(() => {
        drawChart()
    },[])
    return (
        <div className='infographic fit-score'>
            <div className='infographic-text'>
                {props.increase && <span className='chip-highlight'>+5 <Icon icon="trending-up" size="18"/></span>}
                {/* <span style={{fontSize:"14px"}}>Looking great</span> */}
                <span className='current-value'>
                    {props.data.score}
                </span>
                <span className='avg-value'>
                    avg 75
                </span>
            </div>
           <div style={{textAlign:"center"}}>
                <div id="chart"/>

            
            </div>

        </div>
    )
}
const mapStateToProps = ({session,scores}) => ({
	session, scores
});

export default connect(
	mapStateToProps
)(FitScoreDonut);


