/** @format */

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import RecommendationsCard from '../global/cards/RecommendationsCard';
import { getRecommendations } from '../../util/getRecommendations';
import { setRecommendations } from '../../actions/dashboard/setRecomendations';

function ManagerRecommendations(props) {
	let recommendationCount = 5;
	if (props.purchasedCourse) {
		recommendationCount = 6;
	}
	useEffect(() => {
		if (!props.recommendations) {
			getRecommendations(recommendationCount).then((data) => {
				data.length = recommendationCount;
				props.setRecommendations(data);
			});
		}
	}, [recommendationCount]);

	if (props.recommendations) {
		return (
			<section>
				<h2>{props.title}</h2>
				<div className='card-carousel firstSlide'>
					<button
						className='icon-btn circle-btn prev-btn'
						aria-label='Previous'
						disabled=''
					>
						<svg
							focusable='false'
							className='icon-24'
							aria-hidden='true'
						>
							<use
								xmlnsXlink='http://www.w3.org/1999/xlink'
								xlinkHref='#chevron-left-24'
							/>
						</svg>
					</button>
					<div className='card-container'>
						<ul>
							{props.recommendations.map((reco, i) => {
								return props.purchasedCourse ? (
									reco.id !== props.purchasedCourse.courseId && (
										<li
											style={{ width: 310 }}
											key={`recom${i}`}
										>
											<RecommendationsCard data={reco} />
										</li>
									)
								) : (
									<li
										style={{ width: 310 }}
										key={`recom${i}`}
									>
										<RecommendationsCard data={reco} />
									</li>
								);
							})}
						</ul>
					</div>
					<button
						className='icon-btn circle-btn next-btn'
						aria-label='Next'
					>
						<svg
							focusable='false'
							className='icon-24'
							aria-hidden='true'
						>
							<use
								xmlnsXlink='http://www.w3.org/1999/xlink'
								xlinkHref='#chevron-right-24'
							/>
						</svg>
					</button>
				</div>
			</section>
		);
	} else {
		return (
			<section className='skeleton'>
				<h2></h2>
				<div className='card-carousel'>
					<div className='card-container'>
						<ul>
							<li>
								<div className='card learning'>
									<div className='skeleton table-row'></div>
									<div className='skeleton table-row'></div>
									<div className='skeleton table-row'></div>
								</div>
							</li>
							<li>
								<div className='card learning'>
									<div className='skeleton table-row'></div>
									<div className='skeleton table-row'></div>
									<div className='skeleton table-row'></div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</section>
		);
	}
}

const mapStateToProps = ({ session, recommendations, purchasedCourse }) => ({
	session,
	recommendations,
	purchasedCourse,
});

const mapDispatchToProps = (dispatch) => ({
	setRecommendations: (data) => dispatch(setRecommendations(data)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ManagerRecommendations);
