/** @format */

import { useRef, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Card from '../global/cards/Card';
import Icon from '../global/Icon';
import SelectableTable from '../global/tables/SelectableTable';
import Tabs from '../global/tabs/Tabs';
import TabPanel from '../global/tabs/TabPanel';
import GoBack from '../global/GoBack';
import ChartTimeSwitcher from '../global/infographics/ChartTimeSwitcher';
import ChartSwitcher from '../global/infographics/ChartSwitcher';
import Carousel from '../global/Carousel';
import useOnScreen from '../occupations/OnScreenHook';
import ProgressRing from '../global/ProgressRing';
import { getSkillTags } from '../../util/getSkillTags';
import { setSkillTags } from '../../actions/dashboard/setSkillTags';
import { setOccupations } from '../../actions/occupations/setOccupations';
import { setUsersWithSkill } from '../../actions/skills/setUsersWithSkill';
import { getUsersWithSkill } from '../../util/getUsersWithSkill';
import Industry from '../careers/Industry';

const Pillar = (props) => {
	const auth = props.session;
	const badges = useSelector((state) => state.badges);
	const skills = props.skillTags ? props.skillTags : []; // TODO - fill in if it's not loaded already
	const info = { ...props.data }; // shallow clone to allow modification
	const graph = useRef();

	let newSkills = props.skillTags
		? [
				skills[23].name,
				skills[17].name,
				skills[21].name,
				skills[41].name,
				skills[15].name,
				skills[41].name,
		  ]
		: [];
	const isVisible = useOnScreen(graph);

	// clean up the data a bit here
	if (
		!info.relatedCourses ||
		(info.relatedCourses && info.relatedCourses.length === 0)
	) {
		// no related courses... put some in!
		info.relatedCourses = badges ? [badges[0], badges[6], badges[3]] : [];
	}
	if (
		!info.relatedSkills ||
		(info.relatedSkills && info.relatedSkills.length === 0)
	) {
		// no related skills.json... put some in!
		info.relatedSkills = props.skillTags
			? [
					skills[4].name,
					skills[6].name,
					skills[8].name,
					skills[10].name,
					skills[5].name,
					skills[7].name,
			  ]
			: [];
		// console.log(info.relatedSkills)
	}
	// remap relatedSkills to be the full objects
	if (
		info.relatedSkills &&
		info.relatedSkills.length > 0 &&
		!info.relatedSkills[0].id
	) {
		let relatedSkills = [];
		// console.log(info.relatedSkills)
		// console.log(skills.json)
		info.relatedSkills.forEach((name) => {
			// let newSkill = skills.json.find(skill => {
			//     if(name.indexOf(skill.name) > -1) return skill
			// })
			let newSkill = skills.find((f) => f.name === name);
			if (newSkill === undefined) {
				newSkill = {
					name: name,
					id: 'SK-' + name.replace(/\s/g, '').toUpperCase().substr(0, 3),
				};
			}
			// console.log(newSkill)
			relatedSkills.push(newSkill);
		});
		info.relatedSkills = relatedSkills;
		// console.log(relatedSkills)
	}
	// remap newSkills to be the full objects
	if (newSkills && newSkills.length > 0 && !newSkills[0].id) {
		let updatedSkills = [];
		// console.log(info.relatedSkills)
		// console.log(skills.json)
		newSkills.forEach((name) => {
			// let newSkill = skills.json.find(skill => {
			//     if(name.indexOf(skill.name) > -1) return skill
			// })
			let newSkill = skills.find((f) => f.name === name);
			if (newSkill === undefined) {
				newSkill = {
					name: name,
					id: 'SK-' + name.replace(/\s/g, '').toUpperCase().substr(0, 3),
				};
			}
			// console.log(newSkill)
			updatedSkills.push(newSkill);
		});
		newSkills = updatedSkills;
		// console.log(relatedSkills)
	}
	// remap relatedCourses too, because they don't want to be left out...
	if (
		info.relatedCourses &&
		info.relatedCourses.length > 0 &&
		!info.relatedCourses[0].id
	) {
		let relatedCourses = [];
		info.relatedCourses.forEach((name) => {
			name = name.replace(/\s/g, '');
			let newCourse = badges.find((badge) => {
				if (name === badge.id) return badge;
			});
			relatedCourses.push(newCourse);
		});
		info.relatedCourses = relatedCourses;
	}

	const formatName = () => {
		if (info.name) {
			return info.name;
		} else {
			if (info.jobTitle.toLowerCase() === 'manager') {
				return info.jobTitle + ', ' + info.orgLevel;
			} else {
				return info.jobTitle;
			}
		}
	};

	useEffect(() => {
		if (!props.skillTags) {
			getSkillTags().then((data) => {
				props.setSkillTags(data);
			});
		}
		if (!props.usersWithSkill) {
			getUsersWithSkill(props.data.id).then((data) => {
				// console.log(data);
				props.setUsersWithSkill(data);
			});
		}
	});

	return (
		<div className={`pillar-page ${props.type}`}>
			<section className={'intro dark-bg'}>
				{/* Check for breakpoint */}
				<GoBack />

				<div className='section-row'>
					<div className='page-info'>
						{info.image && (
							<div className='badge'>
								<img
									src={info.image}
									alt=''
								/>
							</div>
						)}
						<div>
							<h1 className='page-title'>
								{formatName()}{' '}
								{info.type && <span className='chip-label'>{info.type}</span>}
							</h1>
							<p className='page-desc'>{info.description}</p>
						</div>
					</div>
					{auth.role !== 'student' ? (
						<button className='button-primary'>
							{props.type === 'learning' ? 'Recommend' : 'Prioritize'}
						</button>
					) : (
						<></>
					)}
				</div>
				{props.type !== 'pathway' ? (
					<div className='card-list'>
						<Card modifier='overview'>
							<button className='icon-btn info-btn'>
								<span className='info-icon'>
									<Icon
										icon='info'
										size='18'
									/>
								</span>
							</button>
							<span className='label'>Employees</span>
							<span className='number'>{info.total ? info.total : '12'}</span>
							{auth.role !== 'student' && (
								<button className='button-link'>View</button>
							)}
						</Card>
						{props.type === 'occupation' ? (
							<>
								<Card modifier='overview'>
									<button className='icon-btn info-btn'>
										<span className='info-icon'>
											<Icon
												icon='info'
												size='18'
											/>
										</span>
									</button>
									<span className='label'>Skills</span>
									<span className='number'>42</span>
									{auth.role !== 'student' && (
										<button className='button-link'>View</button>
									)}
								</Card>
								{auth.role !== 'student' && (
									<>
										<Card modifier='overview'>
											<button className='icon-btn info-btn'>
												<span className='info-icon'>
													<Icon
														icon='info'
														size='18'
													/>
												</span>
											</button>
											<span className='label'>Automatable</span>
											<span className='number'>6.3%</span>
											{auth.role !== 'student' && (
												<button className='button-link'>View</button>
											)}
										</Card>
										<Card modifier='overview'>
											<button className='icon-btn info-btn'>
												<span className='info-icon'>
													<Icon
														icon='info'
														size='18'
													/>
												</span>
											</button>
											<span className='label'>Augmentable</span>
											<span className='number'>13.1%</span>
											{auth.role !== 'student' && (
												<button className='button-link'>View</button>
											)}
										</Card>
									</>
								)}
							</>
						) : (
							<>
								<Card modifier='overview'>
									<button className='icon-btn info-btn'>
										<span className='info-icon'>
											<Icon
												icon='info'
												size='18'
											/>
										</span>
									</button>
									<span className='label'>Occupations used</span>
									<span className='number'>4</span>
									{auth.role !== 'student' && (
										<button className='button-link'>View</button>
									)}
								</Card>
								<Card modifier='overview'>
									<button className='icon-btn info-btn'>
										<span className='info-icon'>
											<Icon
												icon='info'
												size='18'
											/>
										</span>
									</button>
									<span className='label'>Credentials</span>
									<span className='number'>48</span>
									{auth.role !== 'student' && (
										<button className='button-link'>View</button>
									)}
								</Card>
								<Card modifier='overview'>
									<button className='icon-btn info-btn'>
										<span className='info-icon'>
											<Icon
												icon='info'
												size='18'
											/>
										</span>
									</button>
									<span className='label'>Frequency</span>
									<span className='number'>8%</span>
									{auth.role !== 'student' && (
										<button className='button-link'>View</button>
									)}
								</Card>
							</>
						)}
					</div>
				) : (
					<></>
				)}
			</section>
			{props.type === 'occupation' ? (
				<section>
					<Industry />
				</section>
			) : (
				<></>
			)}
			<section>
				{props.type !== 'pathway' ? (
					<div
						className='infographics'
						ref={graph}
					>
						<ChartSwitcher
							selected={2}
							visible={isVisible}
						>
							<TabPanel
								label={
									'<span><strong>Market demand</strong> for &lsquo;' +
									formatName() +
									'&rsquo;</span><span class="number">2,867 <svg focusable="false" class="icon-24" aria-hidden="true"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#trending-up-24"></use></svg></span>'
								}
								id='chartTabOne'
							>
								<ChartTimeSwitcher
									title='Market demand within your organization'
									lastValue='2,867'
								/>
							</TabPanel>
							<TabPanel
								label={
									'<span><strong>Supply</strong> for &lsquo;' +
									formatName() +
									'&rsquo;</span><span class="number">4,245 <svg focusable="false" class="icon-24" aria-hidden="true"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#trending-up-24"></use></svg></span>'
								}
								id='chartTabOne'
							>
								<ChartTimeSwitcher
									title='Supply within your organization'
									lastValue='4,245'
								/>
							</TabPanel>
							<TabPanel
								label={
									'<span><strong>Org acquisition</strong> for &lsquo;' +
									formatName() +
									'&rsquo;</span><span class="number">3 <svg focusable="false" class="icon-24" aria-hidden="true"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#trending-up-24"></use></svg></span>'
								}
								id='chartTabOne'
							>
								<ChartTimeSwitcher
									title='Aquisition within your organization'
									lastValue='3'
								/>
							</TabPanel>
						</ChartSwitcher>
					</div>
				) : (
					<div className='infographics skills'>
						<Card modifier='skills'>
							<div className='card-header'>
								<h3>Overlapping skills</h3>
								<button className='icon-btn info-btn'>
									<span className='info-icon'>
										<Icon
											icon='info'
											size='18'
										/>
									</span>
								</button>
							</div>
							<div className='card-body'>
								<div className='card-row'>
									<div className='progress-ring-container'>
										<ProgressRing
											size='64'
											stroke='6'
											value='90'
											percent={true}
											fill={1}
										/>
										Application Programming
									</div>
									<div className='progress-ring-container'>
										<ProgressRing
											size='64'
											stroke='6'
											value='70'
											percent={true}
											fill={2}
										/>
										Cyber Security
									</div>
									<div className='progress-ring-container'>
										<ProgressRing
											size='64'
											stroke='6'
											value='50'
											percent={true}
											fill={3}
										/>
										Data Analysis
									</div>
								</div>
							</div>
						</Card>

						<section>
							<div
								className='section-header'
								style={{ marginBottom: '8px' }}
							>
								<h3>New needed skills</h3>
								<button className='icon-btn info-btn'>
									<span className='info-icon'>
										<Icon
											icon='info'
											size='18'
										/>
									</span>
								</button>
								{/* <a className='button-link'>View all</a> */}
							</div>
							<div className='section-body'>
								{newSkills && newSkills.length > 0 ? (
									<ul className='chip-list'>
										{newSkills.map((chip, index) => {
											return (
												<li key={'chip-' + index}>
													<Link
														to={process.env.PUBLIC_URL + '/skill/' + chip.id}
														className='chip-btn'
													>
														{chip.name}
													</Link>
												</li>
											);
										})}
									</ul>
								) : (
									<p>No related skills available</p>
								)}
							</div>
						</section>
					</div>
				)}
			</section>

			{auth.role !== 'student' ? (
				<section>
					<Tabs>
						<TabPanel
							label='Employees'
							id='tabOnePanel'
						>
							{props.usersWithSkill ? (
								<SelectableTable
									columns={[
										'Employee',
										'Credentials',
										'Skills',
										'Status',
										'Mapping',
										'Last activity',
									]}
									options={true}
									data={props.usersWithSkill}
								/>
							) : (
								<p>Loading...</p>
							)}
						</TabPanel>
						{props.type === 'skill' ? (
							<TabPanel
								label='Occupations'
								id='tabThreePanel'
							>
								<h3>Occupations tab</h3>
							</TabPanel>
						) : (
							<>
								<TabPanel
									label='Skills'
									id='tabTwoPanel'
								>
									<h3>Skills table here</h3>
								</TabPanel>
								<TabPanel
									label='Tasks'
									id='tabFourPanel'
								>
									<h3>Tasks table here</h3>
								</TabPanel>
							</>
						)}
					</Tabs>
				</section>
			) : (
				<></>
			)}

			{auth.role === 'employee' && props.type !== 'pathway' ? (
				<>
					<section>
						<div className='section-header'>
							<h2>Related learning</h2>
							<a className='button-link'>View all</a>
						</div>
						<div className='section-body'>
							{info.relatedCourses && info.relatedCourses.length > 0 ? (
								<Carousel size='310'>
									{info.relatedCourses.map((badge, index) => {
										return (
											<Card
												modifier='learning'
												key={'card-' + index}
											>
												<Link
													className='learning-link'
													to={process.env.PUBLIC_URL + '/courses/' + badge.id}
												>
													<span className='program-img'>
														<img
															src={badge.image}
															alt={badge.name}
														/>
													</span>
													<h3 className='card-title'>{badge.name}</h3>
												</Link>
												<p className='card-text'>
													<Link
														to={
															process.env.PUBLIC_URL +
															'/issuer/' +
															badge.issuer.toLowerCase().replace(/\s/g, '-')
														}
														className='card-link'
													>
														{badge.issuer}
													</Link>
												</p>
												<ul className='feature-list'>
													<li className='card-text'>
														<span className='circle-icon'>
															<Icon
																icon='dollar-sign'
																size='24'
															/>
														</span>
														${badge.cost}
													</li>
													<li className='card-text'>
														<span className='circle-icon'>
															<Icon
																icon='layer-group'
																size='24'
															/>
														</span>
														{badge.level}
													</li>
													<li className='card-text'>
														<span className='circle-icon'>
															<Icon
																icon='timer'
																size='24'
															/>
														</span>
														{badge.time} {badge.timeUnit}
													</li>
													<li className='card-text'>
														<span className='circle-icon'>
															<Icon
																icon='award'
																size='24'
															/>
														</span>
														{badge.type}
													</li>
												</ul>
											</Card>
										);
									})}
								</Carousel>
							) : (
								<p>No related learning available</p>
							)}
						</div>
					</section>

					<section>
						<div className='section-header'>
							<h2>Related skills</h2>
							<a className='button-link'>View all</a>
						</div>
						<div className='section-body'>
							{info.relatedSkills && info.relatedSkills.length > 0 ? (
								<ul className='chip-list'>
									{info.relatedSkills.map((chip, index) => {
										return (
											<li key={'chip-' + index}>
												<Link
													to={process.env.PUBLIC_URL + '/skill/' + chip.id}
													className='chip-btn'
												>
													{chip.name}
												</Link>
											</li>
										);
									})}
								</ul>
							) : (
								<p>No related skills available</p>
							)}
						</div>
					</section>
				</>
			) : props.type !== 'pathway' ? (
				<section className='dark-bg discover'>
					<h2>Discover</h2>
					<div className='card-list'>
						<Card variant='discover'>
							<div className='card-header'>
								<h3 className='card-title'>Related skills</h3>
								<a className='button-link'>View all</a>
							</div>
							<div className='card-body'>
								{info.relatedSkills && info.relatedSkills.length > 0 ? (
									<ul className='chip-list'>
										{info.relatedSkills.map((chip, index) => {
											return (
												<li key={'chip-' + index}>
													<Link
														to={process.env.PUBLIC_URL + '/skill/' + chip.id}
														className='chip-btn'
													>
														{chip.name}
													</Link>
												</li>
											);
										})}
									</ul>
								) : (
									<p>No related skills available</p>
								)}
							</div>
						</Card>
						<Card variant='discover'>
							<div className='card-header'>
								<h3 className='card-title'>Related learning</h3>
								<a className='button-link'>View all</a>
							</div>
							<div className='card-body'>
								{info.relatedCourses && info.relatedCourses.length > 0 ? (
									<ul className='learning-list'>
										{info.relatedCourses.map((badge, index) => {
											return (
												<li key={"badge"+index}>
													<div className='go-to-learning'>
														<div className='badge'>
															<Link
																to={
																	process.env.PUBLIC_URL +
																	'/courses/' +
																	badge.id
																}
															>
																<img
																	src={badge.image}
																	alt=''
																/>
															</Link>
														</div>

														<div className='learning-info'>
															<h4 className='text-body'>
																{badge.name}{' '}
																<span className='icon-24 trending-up'>
																	<Icon
																		icon='trending-up'
																		size='24'
																	/>
																</span>
															</h4>
															{badge.slug ? (
																<p className='card-text'>
																	<Link
																		to={
																			process.env.PUBLIC_URL +
																			'/issuer/' +
																			badge.slug
																				.toLowerCase()
																				.replace(/\s/g, '-')
																		}
																		className='card-link'
																	>
																		{badge.issuer}
																	</Link>
																</p>
															) : (
																<p className='card-link'>
																	<a>{badge.issuer}</a>
																</p>
															)}
														</div>
														<Link
															to={
																process.env.PUBLIC_URL + '/courses/' + badge.id
															}
															className='button icon-btn'
															aria-label='Open {title}'
														>
															<Icon
																icon='chevron-right'
																size='24'
															/>
														</Link>
													</div>
												</li>
											);
										})}
									</ul>
								) : (
									<p>No related learning available</p>
								)}
							</div>
						</Card>
					</div>
				</section>
			) : (
				<section>
					<div className='section-header'>
						<h2>Get started</h2>
						<a className='button-link'>View all</a>
					</div>
					<div className='section-body'>
						{info.relatedCourses && info.relatedCourses.length > 0 ? (
							<Carousel size='310'>
								{info.relatedCourses.map((badge, index) => {
									return (
										<Card
											modifier='learning'
											key={'card-' + index}
										>
											<Link
												className='learning-link'
												to={process.env.PUBLIC_URL + '/courses/' + badge.id}
											>
												<span className='program-img'>
													<img
														src={badge.image}
														alt={badge.name}
													/>
												</span>
												<h3 className='card-title'>{badge.name}</h3>
											</Link>
											<p className='card-text'>
												<Link
													to={
														process.env.PUBLIC_URL +
														'/issuer/' +
														badge.issuer.toLowerCase().replace(/\s/g, '-')
													}
													className='card-link'
												>
													{badge.issuer}
												</Link>
											</p>
											<ul className='feature-list'>
												<li className='card-text'>
													<span className='circle-icon'>
														<Icon
															icon='dollar-sign'
															size='24'
														/>
													</span>
													${badge.cost}
												</li>
												<li className='card-text'>
													<span className='circle-icon'>
														<Icon
															icon='layer-group'
															size='24'
														/>
													</span>
													{badge.level}
												</li>
												<li className='card-text'>
													<span className='circle-icon'>
														<Icon
															icon='timer'
															size='24'
														/>
													</span>
													{badge.time} {badge.timeUnit}
												</li>
												<li className='card-text'>
													<span className='circle-icon'>
														<Icon
															icon='award'
															size='24'
														/>
													</span>
													{badge.type}
												</li>
											</ul>
										</Card>
									);
								})}
							</Carousel>
						) : (
							<p>No related learning available</p>
						)}
					</div>
				</section>
			)}
		</div>
	);
};

const mapStateToProps = ({
	session,
	occupations,
	skillTags,
	usersWithSkill,
}) => ({
	session,
	occupations,
	skillTags,
	usersWithSkill,
});

const mapDispatchToProps = (dispatch) => ({
	setSkillTags: (skillTags) => dispatch(setSkillTags(skillTags)),
	setUsersWithSkill: (usersWithSkill) =>
		dispatch(setUsersWithSkill(usersWithSkill)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Pillar);
