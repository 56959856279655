import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Icon from "../global/Icon";
const MatchingSkills = (props) => {
    const skillsSample = [
        {
            "id": "SK-TM-COL",
            "name": "Collaboration",
            "role": "Project Manager/all",
            "area": "Teams",
            "description": "Collaboration is the act of working together with others to achieve a common goal. It involves effective communication, coordination, and cooperation among individuals to solve problems, make decisions, and achieve results. ",
            "verified": true,
            "inferred": true
        },
        {
            "id": "SK-CNT-PRC",
            "name": "Continuous Process Improvement",
            "role": "Project Manager",
            "area": "Management",
            "description": "Continuous Process Improvement is a systematic approach to enhance processes, services and products by regularly identifying and implementing changes. It is a key aspect of Total Quality Management and focuses on incremental and ongoing improvement through data analysis and stakeholder feedback.",
            "verified": true,
            "inferred": true
        },
        {
            id: "SK-AWS-CERT",
            relatedSkills: [],
            relatedCourses: [],
            name: "AWS Certification",
            role: "Cloud Solutions Architect",
            area: "Information Technology",
            description: "AWS Certification is a globally recognized certification program that validates an individual's expertise in designing, deploying, and operating applications and infrastructure on the AWS platform. There are several levels of certifications available, including Associate, Professional, and Specialty, each demonstrating a progressively higher level of skill and knowledge in AWS technologies",
            type: ""
          },
          {
            id: "SK-AWS-CLD",
            relatedSkills: [],
            relatedCourses: [],
            name: "AWS Cloud",
            role: "cloud specialist",
            area: "Information Technology",
            description: "AWS Cloud is the cloud computing platform, which provides scalable, flexible and cost-effective solutions for businesses and organizations to host their applications and data in the cloud. It includes a wide range of services and tools for computing, storage, databases, analytics, mobile, Internet of Things, security, and more.",
            type: ""
          },
          {
            id: "SK-AWS-EC2",
            relatedSkills: [],
            relatedCourses: [],
            name: "EC2",
            role: "Cloud Solutions Architect",
            area: "Information Technology",
            description: "Amazon Elastic Compute Cloud (EC2) is a scalable cloud computing service that provides resizable virtual machines (instances) for deploying and running applications. EC2 allows users to launch and configure instances with a variety of operating systems and pre-configured applications, and provides a range of options for storage, network connectivity, and security.",
            type: ""
          },        
    ]

    return (
        <div className="matchingSkills card">
            <h3><strong>Your matching skills</strong></h3>
            <ul className="list">
                {
                    skillsSample.map((skill, i) => {
                        return (
                            <li key={"matchSkill"+i}>
                                <Link className="chip-btn" to={`/skills/${skill.id}`}>
                                    {skill.verified && <span className="icon teal"><Icon icon="verified" size="24"/></span>}
							        {skill.name}
						        </Link>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

const mapStateToProps = ({ session }) => ({
	session,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MatchingSkills);