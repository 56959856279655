/** @format */

import React, { useEffect } from 'react';
import { useState } from 'react';
import JobsListing from './JobsListing';
import JobDetail from './JobDetail';
import JobListing from './JobListing';
import Icon from '../global/Icon';
import Modal from '../global/Modal';
import { getJobs } from '../../util/getJobs';
import { setJobs } from '../../actions/companies/setJobs';
import { connect } from 'react-redux';
import JobFilter from './JobFilter';

const JobPanel = (props) => {
	const [filterOpen, setFilterOpen] = useState(false);
	const [filterBy, setFilterBy] = useState({view:['internal']});
	// load jobs
	useEffect(() => {
		console.log(props);

		if (!props.jobs) {
			console.log("trigger")
			getJobs().then((data) => {
				if (props.type && props.type == 'saved') {
					let saved = [data[2], data[5], data[7], data[8], data[11]];
					setSavedJobs(saved);
					props.setJobs(data);
					console.log("saved jobs")
				} else {
					console.log("2")
					props.setJobs(data);
				}
			});
		} else {
			console.log(props.jobs)
			let saved = [props.jobs[2], props.jobs[5], props.jobs[7], props.jobs[8], props.jobs[11]];
			setSavedJobs(saved);

		}
		
		
	},[]);

	useEffect(() => {
		if (props.jobs) {
			if (props.session.type === 'enterprise') {
				setFilterBy({view:['internal']})
				filterApply({view:['internal']})
			} else {
				setFilterBy({view:['internal','external']})
				filterApply({view:['internal','external']})
			}
		}
		
	},[props.jobs])

	const [savedJobs, setSavedJobs] = useState([]);
	const [filteredJobs, setFilteredJobs] = useState([])
	const [activeJob, setActiveJob] = useState({});
	const [sheetopen, setsheetopen] = useState(false);
	const [sheetType, setSheetType] = useState('job');
	const [sheetid, setsheetid] = useState('id2');

	const filterApply = (filters) => {
		//on apply
		let newArr;

		setFilterBy(filters)

		//a.every(item => b.includes(item)) && b.every(item => a.includes(item))

		if (['internal'].every(item => filters.view.includes(item)) && filters.view.length === 1) {
			//filter by company name only
			newArr = props.jobs.filter(f => f.company_name === 'Quad Bank')
		} else if (['external'].every(item => filters.view.includes(item)) && filters.view.length === 1) {
			//exclude company name
			newArr = props.jobs.filter(f => f.company_name !== 'Quad Bank')
			
		} else {
			newArr = props.jobs
		}
		
		setFilteredJobs(newArr)
	}

	const clickJob = (e) => {
		e.preventDefault();
		var element = e.target;
		var parent = element.parentElement;
		while (parent.tagName.toLowerCase() !== 'div') {
			element = parent;
			parent = element.parentElement;
		}
		const id = parent.getAttribute('jobid');
		var active = {};
		props.jobs.forEach((job) => {
			if (id == job._id) {
				setSheetType('job')
				setActiveJob(job);
				setsheetid(id);
				setsheetopen(true);
			}
		});
	};

	const openFilters = () => {
		setSheetType('filter')
		// setActiveJob(job);
		setsheetid('filterBtn');
		setsheetopen(true);
	}

	return (
		<>
			{filteredJobs ? (
				<>
					<div className='jobPanel'>
						<div className='meta' style={{ display: 'flex', alignItems: 'center', justifyContent:"flex-end",gap: '10px' }}>
						<span className='results'>
								{props.type && props.type == 'saved'
									? savedJobs.length
									: filteredJobs.length}{' '}
								results
							</span>
							<div className='filter settings filter-menu'>
								<button className='button-unstyled' id="filterBtn" onClick={() => openFilters()}>
								<Icon
									icon='filter-alternative'
									size='18'
								/>
								Filter
							</button>
							
							</div>
						</div>
						
						<JobsListing>
							{props.type && props.type == 'saved'
								? savedJobs.map((job, i) => {
										return (
											<JobListing
												active={activeJob.id == job.id ? true : false}
												key={i + '-job'}
												data={job}
												clickHandler={clickJob}
												saveIcon={true}
												saved={true}
											/>
										);
								  })
								: filteredJobs.map((job, i) => {
										return (
											<JobListing
												active={activeJob.id == job.id ? true : false}
												key={i + '-job'}
												data={job}
												clickHandler={clickJob}
												saveIcon={true}
												saved={i === 2 || i === 5 || i === 7 || i === 8 || i === 11 ? true : false}
											/>
										);
								  })}
						</JobsListing>
					</div>
					<Modal
						open={sheetopen}
						setModalOpen={setsheetopen}
						targetid={sheetid}
						sheet={true}
					>
						{sheetType === 'job' ?
						<JobDetail data={activeJob} />
						: <JobFilter type={props.session.type} filters={filterBy} apply={(f) => {filterApply(f)}} close={() => setsheetopen(false)}/> }
					</Modal>
				</>
			) : (
				<div>loading jobs...</div>
			)}
		</>
	);
};

const mapStateToProps = ({ session, jobs }) => ({
	session,
	jobs,
});

const mapDispatchToProps = (dispatch) => ({
	setJobs: (data) => dispatch(setJobs(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobPanel);
