/** @format */

import React from 'react';
import { useNavigate } from 'react-router-dom';
import Icon from './Icon';

const GoBack = (props) => {
	const navigate = useNavigate();
	const goBack = () => {
		if (props.path) {
			navigate('/' + props.path);
		} else {
			navigate(-1);
		}
	};

	return (
		<div
			className='go-back'
			style={{ padding: '0 0 20px 0', zIndex: 1, position: 'relative' }}
		>
			<button
				className='back-btn'
				onClick={goBack}
			>
				<Icon
					icon='back'
					size='24'
				/>
				  Back
			</button>
		</div>
	);
};
export default GoBack;
