import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Icon from "../../global/Icon";

const OrdersTable = (props) => {
    const [sorted, setSorted] = useState({column:0,dir:"descending"});

    const navigate = useNavigate();

    const sortColumn = (index) => {
        //need to bring in data and then transform it still
        if (sorted.column === index) {
            if (sorted.dir === "descending") {
                setSorted({...sorted, dir:"ascending"})
            } else {
                setSorted({...sorted, dir:"descending"})
            }
        } else {
            setSorted({column:index, dir:"descending"})
        }
    }

    return (
        <div className="table-container">
                <table className="sortable-table" aria-label="Orders">
                    <caption><h3>Orders</h3></caption>
                    <thead>
                        <tr>
                            <th scope="col" aria-sort={sorted.column === 0 ? sorted.dir : "none"}>
                                <button className="sort-btn button-unstyled" onClick={() => sortColumn(0)}>
                                    Order reference 
                                    <span className="icon">
                                        {sorted.column === 0 ? <Icon icon={sorted.dir} size="18"/> : <Icon icon="sortable" size="18"/>}
                                    </span>
                                </button>
                            </th>
                            <th scope="col" aria-sort={sorted.column === 1 ? sorted.dir : "none"}>
                                <button className="button-unstyled sort-btn" onClick={() => sortColumn(1)}>
                                    Purchased seats 
                                    <span className="icon">
                                        {sorted.column === 1 ? <Icon icon={sorted.dir} size="18"/> : <Icon icon="sortable" size="18"/>}
                                    </span>
                                </button>
                            </th>
                            <th scope="col" aria-sort={sorted.column === 3 ? sorted.dir : "none"}>
                                <button className="button-unstyled sort-btn" onClick={() => sortColumn(3)}>
                                    Status
                                    <span className="icon">
                                        {sorted.column === 3 ? <Icon icon={sorted.dir} size="18"/> : <Icon icon="sortable" size="18"/>}
                                    </span>
                                </button>
                            </th>
                            <th scope="col" aria-sort={sorted.column === 4 ? sorted.dir : "none"}>
                                <button className="button-unstyled sort-btn" onClick={() => sortColumn(4)}>
                                    Actions
                                    <span className="icon">
                                        {sorted.column === 4 ? <Icon icon={sorted.dir} size="18"/> : <Icon icon="sortable" size="18"/>}
                                    </span>
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.data.map((item, index) => {
                            
                            if (item.id.length > 0) return (
                                <tr key={`order-${index}`} >
                                     <th scope="row">
                                        {item.id}           
                                    </th>
                                    <td>
                                     {item.purchasedSeats ? item.purchasedSeats : "69"}
                                    </td>
                                     <td>
                                        {item.status ? item.status : "active"}
                                    </td>
                                    <td className="actions">
                                        <button className="icon-btn">
                                            <Icon icon="edit" size="24"/>
                                        </button>
                                    </td>
                                   
                                </tr>)
                            else return null
                            
                        })} 
                    
                    </tbody>
                </table>
        </div>
    )
}
export default OrdersTable