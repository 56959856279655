/** @format */

import React, { useState } from 'react';
import { connect } from 'react-redux';
import Navigation from '../global/Navigation';
import Header from '../global/Header';
import { Link } from 'react-router-dom';
import Logo from '../../assets/icons/Logo_reverse.svg';
import Icon from './Icon';
import Modal from './Modal';
import AccountMenu from './AccountMenu';
import SearchPage from '../extraPages/Search';
import Notifications from '../extraPages/Notifications';
import EmployerNavigation from '../global/EmployerNavigation';
import InstructorNavigation from '../global/InstructorNavigation';

function PageWrapper(props) {
	console.log(props.activePage);
	const [modalopen, setmodalopen] = useState(false);
	const modalid = 'searchModal';

	const [modalopen2, setmodalopen2] = useState(false);
	const modalid2 = 'notificationsModal';

	const mainClass =
		props.session.role == 'student'
			? 'pageWrapper employee ' + props.pageType
			: 'pageWrapper employer ' + props.pageType;

	const CompanyName =
		props.session.role !== 'employee' ? (
			<div className='company-name'>
				<div className='company-logo dark'>
					<img
						src={process.env.PUBLIC_URL + '/images/logo.png'}
						alt=''
					/>
				</div>
				National Able Network
			</div>
		) : (
			<div className='company-name'>National Able Network</div>
		);

	const openSearch = (e) => {
		e.preventDefault();
		setmodalopen(true);
	};
	const openNotifications = (e) => {
		e.preventDefault();
		setmodalopen2(true);
	};

	return (
		<div className={'authorized'}>
			<div className={mainClass}>
				{props.session.role === 'owner' ? (
					<EmployerNavigation activePage={props.pageType} />
				) : props.session.role === 'instructor' ? (
					<InstructorNavigation activePage={props.pageType} />
				) : props.pageType !== 'channels' &&
				  props.activePage !== 'coursedetails' ? (
					<Navigation selected={props.pageType} />
				) : (
					<></>
				)}
				<main>
					{props.session.role === 'owner' ||
					props.session.role === 'instructor' ||
					props.activePage !== 'coursedetails' ? (
						<header>
							{CompanyName}
							<div className='reverse-logo'>
								<Link to={process.env.PUBLIC_URL + '/'}>
									<img
										src={Logo}
										alt='Pearson'
									/>
								</Link>
							</div>
							{props.session.role !== 'student' ? (
								<div className='actions'>
									<AccountMenu />
								</div>
							) : (
								<div className='actions'>
									{props.pageType === 'profile' ? (
										<>
											<Link
												to='/settings'
												className='icon-btn'
											>
												<Icon
													icon='settings-outline'
													size='24'
												/>
											</Link>
											<AccountMenu />
										</>
									) : (
										<>
											<div>
												<a
													href='/search'
													className='icon-btn mobile-only'
													aria-label='search'
													onClick={openSearch}
													id={modalid}
												>
													<svg
														width='25'
														height='26'
														viewBox='0 0 25 26'
														fill='none'
														xmlns='http://www.w3.org/2000/svg'
													>
														<path
															fillRule='evenodd'
															clipRule='evenodd'
															d='M18.6561 17.631L23.6426 22.6175C24.0637 23.0386 24.0637 23.7214 23.6426 24.1425C23.2215 24.5636 22.5387 24.5636 22.1176 24.1425L17.1311 19.156C15.4356 20.5182 13.2818 21.3333 10.9376 21.3333C5.47226 21.3333 1.04175 16.9028 1.04175 11.4375C1.04175 5.97219 5.47226 1.54167 10.9376 1.54167C16.4029 1.54167 20.8334 5.97219 20.8334 11.4375C20.8334 13.7817 20.0183 15.9355 18.6561 17.631ZM10.9376 19.1458C15.1948 19.1458 18.6459 15.6947 18.6459 11.4375C18.6459 7.18031 15.1948 3.72917 10.9376 3.72917C6.68039 3.72917 3.22925 7.18031 3.22925 11.4375C3.22925 15.6947 6.68039 19.1458 10.9376 19.1458Z'
															fill='currentColor'
														/>
													</svg>
												</a>
												<Link
													to='/search'
													className='icon-btn desktop-only'
												>
													<svg
														width='25'
														height='26'
														viewBox='0 0 25 26'
														fill='none'
														xmlns='http://www.w3.org/2000/svg'
													>
														<path
															fillRule='evenodd'
															clipRule='evenodd'
															d='M18.6561 17.631L23.6426 22.6175C24.0637 23.0386 24.0637 23.7214 23.6426 24.1425C23.2215 24.5636 22.5387 24.5636 22.1176 24.1425L17.1311 19.156C15.4356 20.5182 13.2818 21.3333 10.9376 21.3333C5.47226 21.3333 1.04175 16.9028 1.04175 11.4375C1.04175 5.97219 5.47226 1.54167 10.9376 1.54167C16.4029 1.54167 20.8334 5.97219 20.8334 11.4375C20.8334 13.7817 20.0183 15.9355 18.6561 17.631ZM10.9376 19.1458C15.1948 19.1458 18.6459 15.6947 18.6459 11.4375C18.6459 7.18031 15.1948 3.72917 10.9376 3.72917C6.68039 3.72917 3.22925 7.18031 3.22925 11.4375C3.22925 15.6947 6.68039 19.1458 10.9376 19.1458Z'
															fill='currentColor'
														/>
													</svg>
												</Link>
											</div>
											<div className='notification-icon'>
												<span className='status new'></span>
												<a
													href='/notification'
													className='icon-btn mobile-only'
													aria-label='notifications'
													onClick={openNotifications}
													id={modalid2}
												>
													<Icon
														icon='notification'
														size='24'
													/>
												</a>
												<Link
													to={process.env.PUBLIC_URL + '/notifications'}
													className='icon-btn desktop-only'
													aria-label='notifications'
												>
													<Icon
														icon='notification'
														size='24'
													/>
												</Link>
											</div>
										</>
									)}
								</div>
							)}
						</header>
					) : (
						<></>
					)}
					{props.children}
				</main>
				<Modal
					open={modalopen}
					setModalOpen={setmodalopen}
					targetid={modalid}
					overlay
				>
					<SearchPage />
				</Modal>
				<Modal
					open={modalopen2}
					setModalOpen={setmodalopen2}
					targetid={modalid2}
					overlay
				>
					<Notifications />
				</Modal>
			</div>
		</div>
	);
}

const mapStateToProps = ({ session }) => ({
	session,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PageWrapper);
