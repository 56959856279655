import {SET_PURCHASED_COURSE} from "../../actions/course-details/setPurchasedCourse";

export default (state = null, {type, purchasedCourse = null }) => {
	Object.freeze(state);
	switch (type) {
		case SET_PURCHASED_COURSE:
			return purchasedCourse;
		default:
			return state;
	}
};
