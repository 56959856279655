import React from 'react'
function ActivitySnapshot(props) {
	return (
		<section className="teal-gradient-bg activity-snapshot">
			<h2>{props.title}</h2>
			<div className="card-list">
				<div className="card snapshot">
					<span className="label">Credentials</span>
					<span className="number">11</span>
				</div>
				<div className="card snapshot">
					<span className="label">Skills</span>
					<span className="number">60</span>
				</div>
				<div className="card snapshot">
					<span className="label">Shares</span>
					<span className="number">6</span>
				</div>
				<div className="card snapshot">
					<span className="label">Views</span>
					<span className="number">49</span>
				</div>
			</div>
		</section>
	)
}
export default ActivitySnapshot
