/** @format */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Icon from '../../global/Icon';
import Tippy from '@tippyjs/react';
import { getUsers } from '../../../util/getUsers';
import { setUsers } from '../../../actions/users/setUsers';
import Avatar from '../../global/Avatar';
const InstructorsTable = (props) => {
	const [sorted, setSorted] = useState({ column: 0, dir: 'descending' });
	const [tableData, setTableData] = useState([])

	const navigate = useNavigate();

	useEffect(() => {
		let instructorData;
		if (!props.users) {
			getUsers().then((data) => {
                // console.log(data.language)
                props.setUsers(data);
				instructorData = data.filter(f => f.role === 'instructor')
			});
		} else {
			instructorData = props.users.filter(f => f.role === "instructor")
		}

		setTableData(instructorData)
        return () => {
            if(props.users){
                props.setUsers(null)
            }
			setTableData([])

        }
	}, [props]);

	const sortColumn = (index) => {
		//need to bring in data and then transform it still
		if (sorted.column === index) {
			if (sorted.dir === 'descending') {
				setSorted({ ...sorted, dir: 'ascending' });
			} else {
				setSorted({ ...sorted, dir: 'descending' });
			}
		} else {
			setSorted({ column: index, dir: 'descending' });
		}
	};
	// console.log(tableData)
	return (
		<>
			<div className='table-actions'>
				{props.session.role === 'owner' ? (
					<div
						className='form-input'
						style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
					>
						<label>View</label>
						<div className='gr-select-container'>
							<select
								className='gr-select'
								defaultValue={0}
							>
								<option value='0'>All centers</option>
								<option value='1'>Center 1</option>
								<option value='2'>Center 2</option>
								<option value='3'>Center 3</option>
							</select>
							<Icon
								icon='drop-down'
								size='18'
							/>
						</div>
					</div>
				) : (
					<span></span>
				)}
				<div className='filter'>
					<button className='button-unstyled'>
						<Icon
							icon='filter-alternative'
							size='18'
						/>
						Filter
					</button>
				</div>
			</div>
			<div className='table-container'>
				<table
					className='sortable-table'
					aria-label='Instructors'
				>
					{/* <caption>Employees</caption> */}
					<thead>
						<tr>
							<th
								scope='col'
								aria-sort={sorted.column === 0 ? sorted.dir : 'none'}
							>
								<button
									className='sort-btn button-unstyled'
									onClick={() => sortColumn(0)}
								>
									Instructor
									<span className='icon'>
										{sorted.column === 0 ? (
											<Icon
												icon={sorted.dir}
												size='18'
											/>
										) : (
											<Icon
												icon='sortable'
												size='18'
											/>
										)}
									</span>
								</button>
							</th>
							<th
								scope='col'
								aria-sort={sorted.column === 1 ? sorted.dir : 'none'}
							>
								<button
									className='button-unstyled sort-btn'
									onClick={() => sortColumn(1)}
								>
									Credentials
									<span className='icon'>
										{sorted.column === 1 ? (
											<Icon
												icon={sorted.dir}
												size='18'
											/>
										) : (
											<Icon
												icon='sortable'
												size='18'
											/>
										)}
									</span>
								</button>
							</th>
							<th
								scope='col'
								aria-sort={sorted.column === 2 ? sorted.dir : 'none'}
							>
								<button
									className='button-unstyled sort-btn'
									onClick={() => sortColumn(2)}
								>
									Verified skills
									<span className='icon'>
										{sorted.column === 2 ? (
											<Icon
												icon={sorted.dir}
												size='18'
											/>
										) : (
											<Icon
												icon='sortable'
												size='18'
											/>
										)}
									</span>
								</button>
							</th>
							<th
								scope='col'
								aria-sort={sorted.column === 3 ? sorted.dir : 'none'}
							>
								<button
									className='button-unstyled sort-btn'
									onClick={() => sortColumn(3)}
								>
									Availability
									<span className='icon'>
										{sorted.column === 3 ? (
											<Icon
												icon={sorted.dir}
												size='18'
											/>
										) : (
											<Icon
												icon='sortable'
												size='18'
											/>
										)}
									</span>
								</button>
							</th>
							<th
								scope='col'
								aria-sort={sorted.column === 4 ? sorted.dir : 'none'}
							>
								<button
									className='button-unstyled sort-btn'
									onClick={() => sortColumn(4)}
								>
									Location
									<span className='icon'>
										{sorted.column === 4 ? (
											<Icon
												icon={sorted.dir}
												size='18'
											/>
										) : (
											<Icon
												icon='sortable'
												size='18'
											/>
										)}
									</span>
								</button>
							</th>
							<th
								scope='col'
								aria-sort={sorted.column === 5 ? sorted.dir : 'none'}
							>
								<button
									className='button-unstyled sort-btn'
									onClick={() => sortColumn(5)}
								>
									Language
									<span className='icon'>
										{sorted.column === 5 ? (
											<Icon
												icon={sorted.dir}
												size='18'
											/>
										) : (
											<Icon
												icon='sortable'
												size='18'
											/>
										)}
									</span>
								</button>
							</th>
						</tr>
					</thead>
					<tbody>
						{tableData ? <>
						{tableData.map((item, index) => {
							const { firstName, lastName, city, state, country } = item;
							// const credentials = getCredential(item.)

							if (firstName.length > 0)
								return (
									<tr
										key={`instructor-${index}`}
										onClick={() => {
											navigate(
												`${process.env.PUBLIC_URL}/instructor/${item.id}`,
												{ replace: false }
											);
											window.scrollTo(0, 0);
										}}
									>
										<th scope='row'>
											<div className='employee-data'>
												{item.avatar ? (
													<Avatar data={item} index={index} />
												) : (
													<></>
												)}
												<div className='employee-info'>
													{/* <Link to={`${process.env.PUBLIC_URL}/employee/${item.id}`}><span className="employee-name"> {firstName} {lastName}</span></Link> */}
													<span className='employee-name'>
														{' '}
														{firstName} {lastName}
													</span>
												</div>
											</div>
										</th>
										<td>
											{item.badges && item.badges.length > 0 ? (
												<ul className='credential-list'>
													{' '}
													{item.badges.map((credential, ci) => {
														// const credential = getCredential(course.id);
														return (
															<li key={'badge' + index + '-' + ci}>
																<Tippy content={credential.name}>
																	<span className='badge'>
																		<img
																			src={credential.image}
																			alt={credential.name}
																		/>
																	</span>
																</Tippy>
															</li>
														);
													})}
												</ul>
											) : (
												<></>
											)}
										</td>
										<td>
											{item.skills && item.skills.length > 0 ? (
												<ul className='skills-list'>
													{' '}
													{item.skills.map((skill, si) => {
														return (
															<li key={'skill' + index + '-' + si}>
																{skill.name}
															</li>
														);

													})}
												</ul>
											) : (
												<></>
											)}
										</td>
										<td>
											<span className='icon'>
												<Icon
													icon='date'
													size='18'
												/>
											</span>{' '}
											{item.availability}
										</td>
										<td>
											<span className='item-location'>
												{city}, {state}, {country}
											</span>
										</td>
										<td>
											{item.language && item.language.length > 0 ? (
												<ul>
													{item.language.map((lang, ii) => {
														return <li key={ii+"lang"}>{lang}</li>;
													})}
												</ul>
											) : (
												<></>
											)}
										</td>
									</tr>
								);
							else return null;
						})} </> : <tr><td colSpan={6}>Loading...</td></tr>}
					</tbody>
				</table>
			</div>
		</>
	);
};
const mapStateToProps = ({ session,users }) => ({
	session,
	users
});

const mapDispatchToProps = (dispatch) => ({
	setUsers: (data) => dispatch(setUsers(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(InstructorsTable);
