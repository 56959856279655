/** @format */

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from '../util/routes/protected';
import { AuthRoute } from '../util/routes/auth';
import SignIn from './auth/SignIn';
import Dashboard from '../components/dashboard/Dashboard';
import Careers from './careers/Careers';
import Pathways from './pathways/Pathways';
import Profile from './profile/Profile';
import CourseDetails from './coursedetails/CourseDetails';
import CourseDetailsEnroll from './coursedetails/CourseDetailsEnroll';
import CourseLocations from './coursedetails/CourseLocations';
import Insights from './insights/Insights';
import Company from './companies/Company';
import CourseCheckout from './coursedetails/CourseCheckout';
import Placeholder from './global/Placeholder';
import Communities from './communities/Communities';
import Badges from './global/Badges';
import Recruiter from './users/Recruiter';
import ChannelsVideo from './channels/ChannelsVideo';
import Channels from './channels/Channels';
import PillarWrapper from './occupations/PillarWrapper';
import SkillWrapper from './skills/Skill';
import IssuerDetail from './issuer/IssuerDetail';
import { useDataFetch } from '../util/hooks/useDataFetch';
import { useLocation } from 'react-router-dom';
import SignOut from './auth/SignOut';
import WorkStyleQuiz from './workstyle/WorkStyleQuiz';
import ListPage from './listPages/ListPage';
import Analytics from './Analytics/Analytics';
import DetailPage from './detailPages/DetailPage';
import Surveys from './surveys/Surveys';
import ExamSignUp from './extraPages/ExamSignUp';

// Dave can change this later
import '../assets/styles/styles.css';
import CourseHome from './courses/CourseHome';


function App(props) {
	/*const [badges, setBadges] = useDataFetch();
	const [skills, setSkills] = useDataFetch(); */
	const location = useLocation();

	// location change listener
	useEffect(() => {
		// strip off stray mobile-open classes on body
		document.body.className = document.body.className.replace(
			'modal-window-open',
			''
		);
		// scroll to top
		window.scrollTo(0, 0);
	}, [location]);

	return (
		<div className={`app theme-${props.session.theme}`}>
			<Routes>
				{/*auth routes*/}
				{AuthRoute('/sign-in', SignIn, props.session.userId)}

				{/*confirmation pages*/}

				{/*main*/}

				<Route
					exact
					path='/'
					element={<Navigate to='/dashboard' />}
				/>
				<Route
					path='*'
					element={<Placeholder page='Page not found' />}
				/>
				<Route
					path='/support' exact
					element={<Placeholder page='Support' />}
				/>
				{ProtectedRoute('/dashboard', Dashboard, props.session.userId)}
				{ProtectedRoute('/pathways', Pathways, props.session.userId)}
				{ProtectedRoute('/careers', Careers, props.session.userId)}
				{ProtectedRoute('/profile', Profile, props.session.userId)}
				{ProtectedRoute('/insights', Insights, props.session.userId)}
				{ProtectedRoute('/sign-out', SignOut, props.session.userId)}
				{ProtectedRoute(
					'/courses/:courseId',
					CourseDetails,
					props.session.userId
				)}
				{ProtectedRoute(
					'/courses/:courseId/enroll',
					CourseDetailsEnroll,
					props.session.userId
				)}
				{ProtectedRoute(
					'/courses/:courseId/locations',
					CourseLocations,
					props.session.userId
				)}
				{ProtectedRoute(
					'/courses/:courseId/:locationId/checkout',
					CourseCheckout,
					props.session.userId
				)}
				{ProtectedRoute('/workstylequiz', WorkStyleQuiz, props.session.userId)}
				{ProtectedRoute(
					'/courses/:courseId/enroll',
					CourseDetails,
					props.session.userId
				)}
				{ProtectedRoute(
					'/courses/:courseId/locations',
					CourseLocations,
					props.session.userId
				)}
				{ProtectedRoute(
					'/courses/:courseId/:locationId/checkout',
					CourseCheckout,
					props.session.userId
				)}

				{ProtectedRoute('/companies/:id', Company, props.session.userId)}

				{ProtectedRoute('/badges', Badges, props.session.userId)}
				{ProtectedRoute('/people/:id', Recruiter, props.session.userId)}
				{ProtectedRoute('/channels/:id', CourseHome, props.session.userId)}

				{ProtectedRoute('/channels', CourseHome, props.session.userId)}
				{ProtectedRoute('/channels/video', ChannelsVideo, props.session.userId)}

				{ProtectedRoute(
					'/occupation/:pillarId',
					PillarWrapper,
					props.session.userId,
					{ type: 'occupation' }
				)}
				{ProtectedRoute(
					'/skill/:pillarId',
					SkillWrapper,
					props.session.userId,
					{ type: 'skill' }
				)}

				{ProtectedRoute(
					'/occupation/:pillarId',
					PillarWrapper,
					props.session.userId,
					{ type: 'occupation' }
				)}
				{ProtectedRoute(
					'/skill/:pillarId',
					SkillWrapper,
					props.session.userId,
					{ type: 'skill' }
				)}
				{ProtectedRoute(
					'/skills/:pillarId',
					SkillWrapper,
					props.session.userId,
					{ type: 'skill' }
				)}
				{ProtectedRoute(
					'/pathway/:pillarId',
					PillarWrapper,
					props.session.userId,
					{ type: 'pathway' }
				)}

				{ProtectedRoute('/communities', Communities, props.session.userId)}

				{ProtectedRoute('/settings', Placeholder, props.session.userId, {
					page: 'Settings',
				})}

				{ProtectedRoute(
					'/issuer/:issuerId',
					IssuerDetail,
					props.session.userId,
					{ page: 'Issuer' }
				)}
				{ProtectedRoute('/instructors', ListPage, props.session.userId, {pageType: 'instructors'})}
				{ProtectedRoute(
					'/instructor/:id',
					DetailPage,
					props.session.userId,
					{ pageType: 'instructors' }
				)}
				{ProtectedRoute('/students', ListPage, props.session.userId, {pageType: 'students'})}
				{ProtectedRoute(
					'/student/:id',
					DetailPage,
					props.session.userId,
					{ pageType: 'students' }
				)}
				{ProtectedRoute('/licenses', ListPage, props.session.userId, {pageType: 'licenses'})}
				{ProtectedRoute(
					'/license/:id',
					DetailPage,
					props.session.userId,
					{ pageType: 'licenses' }
				)}
				{ProtectedRoute('/classes', ListPage, props.session.userId, {pageType: 'classes'})}
				{ProtectedRoute(
					'/class/:id',
					DetailPage,
					props.session.userId,
					{ pageType: 'classes' }
				)}

				{ProtectedRoute('/analytics', Analytics, props.session.userId, {
					pageType: 'analytics',
				})}
				{ProtectedRoute('/surveys', Surveys, props.session.userId, {
					pageType: 'surveys',
				})}

				{ProtectedRoute('/exam/:courseId', ExamSignUp, props.session.userId, {
					pageType: 'exam',
				})}

			</Routes>
		</div>
	);
}

const mapStateToProps = ({ session }) => ({
	session,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(App);
