import {SET_EXAM_SCHEDULED} from "../../actions/channels/setExamScheduled";

export default (state = null, {type, examScheduled = false }) => {
	Object.freeze(state);
	switch (type) {
		case SET_EXAM_SCHEDULED:
			return examScheduled;
		default:
			return state;
	}
};
