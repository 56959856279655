import React from "react";
import { Link } from "react-router-dom";

const PathwayCard = (props) => {
    const type = props.type

    return (
        <div className={`card pathway-role ${type}`}>
            <h3>
                <span><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.2" d="M5.89142 9.5562C5.89142 8.42861 6.22579 7.32634 6.85225 6.38877C7.47871 5.45121 8.36912 4.72047 9.41088 4.28896C10.4526 3.85745 11.599 3.74455 12.7049 3.96453C13.8108 4.18451 14.8267 4.7275 15.624 5.52483C16.4213 6.32216 16.9643 7.33802 17.1843 8.44395C17.4043 9.54988 17.2914 10.6962 16.8599 11.738C16.4284 12.7797 15.6976 13.6701 14.7601 14.2966C13.8225 14.9231 12.7202 15.2574 11.5926 15.2574H5.3213C4.26286 15.2574 3.24777 14.837 2.49934 14.0885C1.75091 13.3401 1.33044 12.325 1.33044 11.2666C1.33044 10.2081 1.75091 9.19304 2.49934 8.44461C3.24777 7.69618 4.26286 7.27571 5.3213 7.27571C5.6552 7.27559 5.98786 7.31627 6.31188 7.39687" fill="#FEFEFE"></path><path d="M6.27149 9.93609C6.27149 8.80849 6.60586 7.70622 7.23231 6.76866C7.85877 5.8311 8.74918 5.10036 9.79094 4.66885C10.8327 4.23733 11.979 4.12443 13.085 4.34441C14.1909 4.5644 15.2067 5.10738 16.0041 5.90471C16.8014 6.70204 17.3444 7.7179 17.5644 8.82383C17.7844 9.92976 17.6715 11.0761 17.2399 12.1178C16.8084 13.1596 16.0777 14.05 15.1401 14.6765C14.2026 15.3029 13.1003 15.6373 11.9727 15.6373H5.70136C4.64292 15.6373 3.62783 15.2168 2.8794 14.4684C2.13097 13.72 1.71051 12.7049 1.71051 11.6465C1.71051 10.588 2.13097 9.57292 2.8794 8.82449C3.62783 8.07606 4.64292 7.6556 5.70136 7.6556C6.03526 7.65547 6.36792 7.69616 6.69195 7.77675" stroke="#FEFEFE" strokeWidth="1.36829" strokeLinecap="round" strokeLinejoin="round"></path></svg></span>
                {props.data.title ? props.data.title : "Title title "}
                {/* Title title */}
            </h3>
            <div className="pathway-desc">
                {props.data.longevity && <p><strong>Longevity:</strong> <span className="number">{props.data.longevity}%</span></p> }
                {props.data.salary && <p><strong>Salary projection:</strong> <span>{props.data.salary}</span></p>}
            </div>
            {type === "recommended" && <>
            <Link to={"/pathway/IT-CAA"} className="button-primary desktop-only">Learn more</Link>
                <a href="IT-CAA" type="pathway" className="button-primary mobile-only" onClick={(e) => props.openSheet(e)}>Learn more</a>
            </>}
                           
        </div>
    )
}
export default PathwayCard