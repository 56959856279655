import React, {useEffect,useState} from "react";
import { getUserInfo } from '../../util/getUserInfo';
import { setUser } from '../../actions/users/setUser';
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { getJobsByCompany } from '../../util/getJobsByCompany';
import { setJobsByCompany } from '../../actions/companies/setJobsByCompany';
import GoBack from "../global/GoBack";
import JobsListing from "../careers/JobsListing";
import JobListing from "../careers/JobListing";
import Modal from "../global/Modal";
import JobDetail from "../careers/JobDetail";
import Icon from "../global/Icon";

const Recruiter = (props) => {
	const params = useParams();
	const { id } = params;

    const [activeJob, setActiveJob] = useState({})
    const [sheetopen, setsheetopen] = useState(false)
    const [sheetid, setsheetid] = useState("id2")

    const posts = [
        {   
            id: "12345",   
            company: "disney",  
            postedDate: "2023-03-23",   
            title: "The Walt Disney Company to participate in the JP Morgan council on film",
            content: "Lorem ipsum dolor set amet. Lorem ipsum dolor set amet. Lorem ipsum dolor set amet. Lorem ipsum dolor set amet. Lorem ipsum dolor set amet.",
            preview: "Lorem ipsum dolor set amet.",   
            image: "https://lumiere-a.akamaihd.net/v1/images/6047825fea89c20001bc7f2a-image_c06b80fa.jpeg?region=0,0,1536,864",
            commentCount: 98,   
            comments: []     
        },
        {   
            id: "12346",   
            company: "disney",  
            postedDate: "2023-03-21",   
            title: "Disney in collaboration with Lucas Film and the philanthropic institute",
            content: "Lorem ipsum dolor set amet. Lorem ipsum dolor set amet. Lorem ipsum dolor set amet. Lorem ipsum dolor set amet. Lorem ipsum dolor set amet.",
            preview: "Lorem ipsum dolor set amet.",   
            image: "https://cdn.segmentnextimages.com/wp-content/uploads/2022/03/Jedi-Fallen-Order-Duel.jpg",
            commentCount: 98,   
            comments: []     
        },
        {   
            id: "12347",   
            company: "disney",  
            postedDate: "2023-03-19",   
            title: "The next generation of storytellers",
            content: "Lorem ipsum dolor set amet. Lorem ipsum dolor set amet. Lorem ipsum dolor set amet. Lorem ipsum dolor set amet. Lorem ipsum dolor set amet.",
            preview: "Lorem ipsum dolor set amet.",   
            image: "https://images.creativemarket.com/0.1.0/ps/15090451/3485/2323/m1/fpnw/wm1/sstk_2145228761_9516428.jpg-.jpg?1&s=7231e87944f17ab958c40e9dd8a1b848",
            commentCount: 98,   
            comments: []     
        }
    ]

    const communities = [
        {    
            id: "34567",    
            company: "disney",
            name: "Tips for applying",    
            description: "Lorem ipsum",    
            memberCount: 432,    
            postCount: 1176,
            image: "https://i0.wp.com/ooa.hunter.cuny.edu/wp-content/uploads/2022/01/dn.jpeg?fit=700%2C700&ssl=1&w=640"  
        }  
    ]

    useEffect(() => {
		if (!props.user) {
			getUserInfo(id).then((data) => {
                console.log(data)
                props.setUser(data);
			});
		}

        return () => {
            if(props.setUser){
                props.setUser(null)
            }
        }
	}, [id]);

    useEffect(() => {
		if (!props.jobsByCompany && props.user) {
            getJobsByCompany(props.user.company.name).then((data) => {
                console.log("got data")
                props.setJobsByCompany(data);
            })
		} else if (props.jobsByCompany && props.user){
            props.setJobsByCompany(props.jobsByCompany);
        }

        return () => {
            if(props.jobsByCompany){
                props.setJobsByCompany(null)
            }
        }
	}, [props.user]);

    const clickJob = (e) => {
        e.preventDefault()
        var element = e.target
        var parent = element.parentElement
        while(parent.tagName.toLowerCase() != "div"){
            element = parent
            parent = element.parentElement
        }
        const id = parent.getAttribute("jobid")
        var active = {}
        console.log(id)
        props.jobsByCompany.forEach(job => {
            if(id == job._id) {
                setActiveJob(job)
                setsheetid(id)
                setsheetopen(true)
            }
        })
    }

    return (
        <>
            {
                props.user ? 
                <div className={'authorized'}>
                    <div className="pageWrapper course recruiter">
                        <main>
                            <GoBack />
                            <section className="intro">
                                <div className="picture">
                                    <img src={ props.user.user.avatar } />
                                </div>
                                <h1>{props.user.user.firstName} {props.user.user.lastName}</h1>
                                <p className="title">Recruiter at {props.user.user.organization}</p>
                                { props.user.user.bio ? <div dangerouslySetInnerHTML={{__html: props.user.user.bio}} /> : <></> }
                                <div className="affiliations">
                                    <p className="title">Affiliations</p>
                                    <div className="company"><img src={props.user.company.photo.url} /></div>
                                </div>
                            </section>
                            <section>
                            <h2>Careers & internships</h2>
                            {
                                props.jobsByCompany ? 
                                <JobsListing>
                                    {
                                        props.jobsByCompany.map((job, i) => {
                                            return ( <JobListing key={`${i}-job`} data={job} clickHandler={clickJob} /> )
                                        })
                                    }
                                </JobsListing> :
                                <div>loading...</div>
                            }
                        </section>
                        <section>
                            <h2>{props.user.user.firstName}'s Posts</h2>
                            <ul className="postList">
                                {
                                    posts.map((post, i) => {
                                        return (
                                            <li key={`${i}-post`} className="card post">
                                                <div className="title">
                                                    <div className="imageWrapper">
                                                        <img src={post.image} />
                                                    </div>
                                                    {post.title}
                                                </div>
                                                <div className="preview">{post.preview}...</div>
                                                <div className="meta">
                                                    <Icon icon="chat" size="24" />
                                                    {post.commentCount}
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </section>
                        <section>
                            <h2>Communities</h2>
                            <ul className="communityList">
                                {
                                    communities.map((community, i) => {
                                        return (
                                            <li key={`${i}-post`} className="card community">
                                                <div className="title">
                                                    <div className="imageWrapper">
                                                        <img src={community.image} />
                                                    </div>
                                                    {community.name}
                                                </div>
                                                <div className="meta">
                                                    {community.memberCount}k members<br />
                                                    {community.postCount} posts
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </section>
                        </main>
                    </div>
                    <Modal open={sheetopen} setModalOpen={setsheetopen} targetid={sheetid} sheet={true}>
                        <JobDetail data={activeJob} />
                    </Modal>
                </div> :
                <div className={'authorized'}>
                    <div className="pageWrapper course recruiter">
                        <main>
                            <GoBack />
                            <section className="intro">
                                <h1>loading...</h1>
                            </section>
                        </main>
                    </div>
                </div>
            
    
            }
        </>
    )
}

const mapStateToProps = ({ session, user, jobsByCompany }) => ({
	session,
    user,
    jobsByCompany,
});

const mapDispatchToProps = (dispatch) => ({
	setUser: (data) => dispatch(setUser(data)),
    setJobsByCompany: (data) => dispatch(setJobsByCompany(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Recruiter);