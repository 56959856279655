// Combine all the reducers
import {combineReducers} from 'redux';
import signIn from './signIn'
import setScores from "./dashboard/setScores";
import setJobTags from "./dashboard/setJobTags";
import setRecommendations from "./dashboard/setRecommendations";
import setSkillTags from "./dashboard/setSkillTags";
import setSnapshots from "./dashboard/setSnapshots";
import setCourseInfo from "./course-details/setCourseInfo";
import setCourseLocations from "./course-details/setCourseLocations";
import setAllCourses from './course-details/setAllCourses';
import setCredentials from "./pathways/setCredentials";
import setInsights from "./pathways/setInsights";
import setPathways from "./pathways/setPathways";
import setProfile from "./pathways/setProfile";
import setResume from "./pathways/setResume";
import setCompanies from "./companies/setCompanies";
import setCompanyInfo from "./companies/setCompanyInfo";
import setJobs from "./companies/setJobs";
import setPurchasedCourse from "./course-details/setPurchasedCourse";
import setJobsByCompany from "./companies/setJobsByCompany";
import setBadges from "./badges/setBadges";
import setUser from './users/setUser';
import setOccupations from "./occupations/setOccupations";
import setSkills from './skills/setSkills';
import setCourseComplete from "./channels/setCourseComplete";
import setLicenses from "./licenses/setLicenses";
import setLicenseDetail from './licenses/setLicenseDetail';
import setUsers from './users/setUsers';
import setExamScheduled from './channels/setExamScheduled';

export default combineReducers({
    session: signIn,
	scores:setScores,
	jobTags:setJobTags,
	recommendations:setRecommendations,
	skillTags:setSkillTags,
	snapshots:setSnapshots,
	allCourses: setAllCourses,
	course:setCourseInfo,
	courseLocations:setCourseLocations,
	credentials:setCredentials,
	insights:setInsights,
	pathways:setPathways,
	profile:setProfile,
	resume:setResume,
	company:setCompanyInfo,
	companies:setCompanies,
	jobs:setJobs,
	purchasedCourse: setPurchasedCourse,
	jobsByCompany:setJobsByCompany,
	badges:setBadges,
	user:setUser,
	users:setUsers,
	occupations:setOccupations,
	skills:setSkills,
	courseComplete:setCourseComplete,
	licenses: setLicenses,
	licenseDetail: setLicenseDetail,
	examScheduled: setExamScheduled,
});
