import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer } from "recharts";



const SalaryChart = props => {

    const data1 = [
        {
            salary: 60550,
            percent: 10,
        },
        {
            salary: 79840,
            percent: 90,
        },
        {
            salary: 133320,
            percent: 10,
        },
    ]
    const renderColorfulLegendText = (value, entry) => {


        return <div style={{ display: "inline-block", color: "var(--color-text-muted)", fontWeight: "400", marginTop: "16px" }}>{value} skills</div>;
    };


    return (
        <ResponsiveContainer width="100%" height={220}>
            <AreaChart
                width={500}
                height={400}
                data={data1}
                margin={{
                    top: 10,
                    right: 10,
                    left: 0,
                    bottom: 0,
                }}
            >
                <defs>
                    <linearGradient id="colorGG" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="0%" stopColor="var(--color-dark-blue)" stopOpacity={0.8} />
                        <stop offset="100%" stopColor="var(--color-dark-blue)" stopOpacity={0} />
                    </linearGradient>
                </defs>
                <CartesianGrid vertical={false} stroke="#DDE3EE" strokeWidth={1} />
                <XAxis dataKey="salary" interval="preserveStartEnd" tickLine={false} axisLine={false} overflow={"allow"} ticks={[60550, 79840, 133320]} dy={5} />
                {/* <Tooltip /> */}
                <Area type="monotone" dataKey="percent" strokeWidth={2} strokeLinecap="round" stroke="var(--color-dark-blue)" fill="url(#colorGG)" minTickGap={10} />
            </AreaChart>
        </ResponsiveContainer>
    );

}
export default SalaryChart
