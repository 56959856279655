/** @format */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Icon from '../../global/Icon';
import { getLicenses } from '../../../util/getLicenses';
import {setLicenses} from "../../../actions/licenses/setLicenses";


const LicensesTable = (props) => {
	const [sorted, setSorted] = useState({ column: 0, dir: 'descending' });

	const navigate = useNavigate();

    useEffect(() => {
		if (!props.licenses) {
            getLicenses().then((data) => {
                props.setLicenses(data)
                console.log(data)
            })
		}
	}, []);

	const sortColumn = (index) => {
		//need to bring in data and then transform it still
		if (sorted.column === index) {
			if (sorted.dir === 'descending') {
				setSorted({ ...sorted, dir: 'ascending' });
			} else {
				setSorted({ ...sorted, dir: 'descending' });
			}
		} else {
			setSorted({ column: index, dir: 'descending' });
		}
	};

	return (
		<>
			<div className='table-actions filter-only'>
				<div className='filter'>
					<button className='button-unstyled'>
						<Icon
							icon='filter-alternative'
							size='18'
						/>
						Filter
					</button>
				</div>
			</div>

            {props.licenses ? 
            
			<div className='table-container'>
				<table
					className='sortable-table'
					aria-label='Licenses'
				>
					<thead>
						<tr>
							<th
								scope='col'
								aria-sort={sorted.column === 0 ? sorted.dir : 'none'}
							>
								<button
									className='sort-btn button-unstyled'
									onClick={() => sortColumn(0)}
								>
									License
									<span className='icon'>
										{sorted.column === 0 ? (
											<Icon
												icon={sorted.dir}
												size='18'
											/>
										) : (
											<Icon
												icon='sortable'
												size='18'
											/>
										)}
									</span>
								</button>
							</th>
							<th
								scope='col'
								aria-sort={sorted.column === 1 ? sorted.dir : 'none'}
							>
								<button
									className='button-unstyled sort-btn'
									onClick={() => sortColumn(1)}
								>
									Purchased seats
									<span className='icon'>
										{sorted.column === 1 ? (
											<Icon
												icon={sorted.dir}
												size='18'
											/>
										) : (
											<Icon
												icon='sortable'
												size='18'
											/>
										)}
									</span>
								</button>
							</th>
							<th
								scope='col'
								aria-sort={sorted.column === 2 ? sorted.dir : 'none'}
							>
								<button
									className='button-unstyled sort-btn'
									onClick={() => sortColumn(2)}
								>
									Course access duration
									<span className='icon'>
										{sorted.column === 2 ? (
											<Icon
												icon={sorted.dir}
												size='18'
											/>
										) : (
											<Icon
												icon='sortable'
												size='18'
											/>
										)}
									</span>
								</button>
							</th>
							<th
								scope='col'
								aria-sort={sorted.column === 3 ? sorted.dir : 'none'}
							>
								<button
									className='button-unstyled sort-btn'
									onClick={() => sortColumn(3)}
								>
									Status
									<span className='icon'>
										{sorted.column === 3 ? (
											<Icon
												icon={sorted.dir}
												size='18'
											/>
										) : (
											<Icon
												icon='sortable'
												size='18'
											/>
										)}
									</span>
								</button>
							</th>
							<th
								scope='col'
								aria-sort={sorted.column === 4 ? sorted.dir : 'none'}
							>
								<button
									className='button-unstyled sort-btn'
									onClick={() => sortColumn(4)}
								>
									Actions
									<span className='icon'>
										{sorted.column === 4 ? (
											<Icon
												icon={sorted.dir}
												size='18'
											/>
										) : (
											<Icon
												icon='sortable'
												size='18'
											/>
										)}
									</span>
								</button>
							</th>
						</tr>
					</thead>
					<tbody>
						{props.licenses.map((item, index) => {
							if (item.name.length > 0)
								return (
									<tr
										key={`license-${index}`}
										onClick={() => {
											navigate(`${process.env.PUBLIC_URL}/license/${item.id}`, {
												replace: false,
											});
											window.scrollTo(0, 0);
										}}
									>
										<th scope='row'>
											<div className='employee-data'>
												<div className='employee-info'>
													<span className='employee-name'> {item.name}</span>
												</div>
											</div>
										</th>
										<td>{item.purchasedSeats ? item.purchasedSeats : '69'}</td>
										<td>{item.duration ? item.duration : '180'}</td>

										<td>{item.status ? item.status : 'active'}</td>
										<td>
											<button className='icon-btn'>
												<Icon
													icon='edit'
													size='24'
												/>
											</button>
										</td>
									</tr>
								);
							else return null;
						})}
					</tbody>
				</table>
			</div> : <div className='skeleton card'></div> }
		</>
	);
};
const mapStateToProps = ({ session, licenses }) => ({
	session, licenses
});

const mapDispatchToProps = (dispatch) => ({
    setLicenses: (data) => dispatch(setLicenses(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(LicensesTable);
