const truncateStr = (str, strSize) => {
    let newStr = "";
    // console.log("length:", str.length)
    if (str.length < strSize) {
      newStr = str;
          } else if (str.length >= strSize && !str.includes(" ")) {
      newStr = str.slice(0, strSize) + "...";
          } else if (str.includes(" ")) {
      const strArray = str.split(" ");

        strArray.forEach((item, index) => {
          if (item.length >= strSize && strArray.length !== index) {
            item = item.slice(0, strSize) + "...";
            newStr = newStr + item + " ";
          } else if (strArray.length !== index) {
            newStr = newStr + item + " ";
          } else newStr = newStr + item;
                  });
    }
    return newStr;
  };

  export default truncateStr;
