import React, { useState } from "react";
import { useSelector } from "react-redux";
import Icon from "../../global/Icon";
import { useNavigate } from "react-router-dom";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional


const EmployeeSkillsTable = (props) => {
    const navigate = useNavigate()
    const [sorted, setSorted] = useState({column:0,dir:"descending"});
    const sortColumn = (index) => {
        //need to bring in data and then transform it still
        if (sorted.column === index) {
            if (sorted.dir === "descending") {
                setSorted({...sorted, dir:"ascending"})
            } else {
                setSorted({...sorted, dir:"descending"})
            }
        } else {
            setSorted({column:index, dir:"descending"})
        }
    }

      const getRandomDate = () => {
        const max = Date.now();
        const min = +new Date("02/10/2022")
        const timestamp = Math.floor(Math.random() * (max - min + 1) + min)
        return new Date(timestamp);
    }

    return (
        <div className="table-container">
                <table className="sortable-table" aria-label="Skills">
                    {/* <caption>Skills</caption> */}
                    <thead>
                        <tr>
                            <th scope="col" aria-sort={sorted.column === 0 ? sorted.dir : "none"}>
                                <button className="sort-btn button-unstyled" onClick={() => sortColumn(0)}>
                                    Skill 
                                    <span className="icon">
                                        {sorted.column === 0 ? <Icon icon={sorted.dir} size="18"/> : <Icon icon="sortable" size="18"/>}
                                    </span>
                                </button>
                            </th>
                            <th scope="col" aria-sort={sorted.column === 1 ? sorted.dir : "none"}>
                                <button className="button-unstyled sort-btn" onClick={() => sortColumn(1)}>
                                   Skill area
                                    <span className="icon">
                                        {sorted.column === 1 ? <Icon icon={sorted.dir} size="18"/> : <Icon icon="sortable" size="18"/>}
                                    </span>
                                </button>
                            </th>
                            <th scope="col" aria-sort={sorted.column === 2 ? sorted.dir : "none"}>
                                <button className="button-unstyled sort-btn" onClick={() => sortColumn(2)}>
                                    Issued 
                                    <span className="icon">
                                        {sorted.column === 2 ? <Icon icon={sorted.dir} size="18"/> : <Icon icon="sortable" size="18"/>}
                                    </span>
                                </button>
                            </th>
                            <th scope="col" aria-sort={sorted.column === 3 ? sorted.dir : "none"}>
                                <button className="button-unstyled sort-btn" onClick={() => sortColumn(3)}>
                                    Inferred
                                    <span className="icon">
                                        {sorted.column === 3 ? <Icon icon={sorted.dir} size="18"/> : <Icon icon="sortable" size="18"/>}
                                    </span>
                                </button>
                            </th>
                            <th scope="col" aria-sort={sorted.column === 4 ? sorted.dir : "none"}>
                                <button className="button-unstyled sort-btn" onClick={() => sortColumn(4)}>
                                    Verified
                                    <span className="icon">
                                        {sorted.column === 4 ? <Icon icon={sorted.dir} size="18"/> : <Icon icon="sortable" size="18"/>}
                                    </span>
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.data && props.data.length > 0 ? props.data.map((item, index) => {     
                            // console.log("PROPS", props)
                            const itemData = item                                                
                                return (
                                <tr key={`skills-${index}`} onClick={() => {navigate(`${process.env.PUBLIC_URL}/skill/${item.id}`,{replace:false});window.scrollTo(0,0)}}>
                                     <th scope="row">
                                     <Tippy className={"pq-tooltip"} placement={"left"} content={<div className="tooltip-content"><strong>{itemData ? itemData.name : item.id}</strong><span>{itemData ? itemData.description : "No description available"}</span></div>}>
                                        <span className="employee-name">{itemData ? itemData.name : item.id}</span>
                                        </Tippy>
                                        {/* <div className="badge">
                                            <img src={item.image} alt="badge"/>
                                        </div>
                                        <div className="employee-info">
                                            <span className="employee-name"> {item.name}</span>
                                            <span className="employee-location">{item.issuer}</span>
                                        </div>                    */}
                                    </th>
                                    <td>
                                        {itemData ? itemData.area : "area"}
                                    </td>
                                   
                                    <td>
                                        {/* {console.log("ITEM:", item)} */}
                                        {item.date ? item.date : "date"}
                                    </td>
                                    <td>{item.inferred ? <span className="icon"><Icon icon="correct" size="24"/></span> : "false"}</td>
                                    <td>{item.verified ? <span className="icon"><Icon icon="verified" size="24"/></span> : "false"}</td>
                                  
                                   
                                </tr>)
                            
                        }) : 
                        <tr>
                        <td colSpan={5}>No skills </td>
                    </tr>}
                    
                    </tbody>
                </table>
        </div>
    )
}
export default EmployeeSkillsTable