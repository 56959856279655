import React from "react";
import { connect } from "react-redux";
import JobLinks from "./JobLinks";
import JobLink from "./JobLink";
import BestCompanies from "./BestCompanies";
import IndustryList from "./IndustryList";

function OccupationsTab(props) {

	return (
        <>
            <h2>The information and tools you need to find your next job</h2>
            {/* <p>We believe that every person should have access to the best information available to evaluate and choose a job. Other sites list jobs, but they don't care how it fits you and your longer term career. Pearson helps you find and pursue the job that's right for your career.</p> */}
            <p>Other sites list jobs, but they don't care how it fits you and your longer term career. Pearson helps you find and pursue the job that's right for your career.</p>
            <JobLinks>
                <JobLink type="personalizedJob" title="Personalized Jobs" content="Get recommendations tailored just for you." />
                <JobLink type="companyInsights" title="Company Insights" content="See if the company culture matches you." />
                <JobLink type="jobInsights" title="Job Insights" content="Find new career options based on your skills." />
                <JobLink type="careerPaths" title="Career Paths" content="Discover new career paths after college." />
            </JobLinks>
            <BestCompanies id={props.session.id} />
            <BestCompanies id={props.session.id} byState />
            <IndustryList id={props.session.id} />
        </>
	);
}

const mapStateToProps = ({ session }) => ({
	session,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(OccupationsTab);