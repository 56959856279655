/** @format */

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import PageWrapper from '../global/PageWrapper';
import Pillar from '../pillars/Pillar';
import GoBack from '../global/GoBack';
import { setSkills } from '../../actions/skills/setSkills';
import AUTH_BASE_URL from '../../util/AUTH_BASE_URL';

const SkillWrapper = (props) => {
	const [info, setInfo] = React.useState();

	const params = useParams();
	const { pillarId } = params;

	useEffect(() => {
		if (!props.skills) {
			fetch(`${AUTH_BASE_URL}existing/skills/${pillarId}`)
				.then((response) => response.json())
				.then((data) => {
					console.log('DATA', data);
					props.setSkills(data);
					setInfo(data);
				});
		}
		return () => {
			props.setSkills(null);
		};
	}, []);

	//bring in
	return (
		<>
			{!props.skills ? (
				<PageWrapper pageType='pillar'>
					<section className='intro dark-bg'>
						<GoBack />
						<h1 style={{ marginTop: '20px' }}>Missing page</h1>
						<p>
							This prototype is still under development. Content will be placed
							here soon.
						</p>
					</section>
				</PageWrapper>
			) : (
				<PageWrapper pageType='pillar'>
					<Pillar
						type={props.type}
						data={props.skills}
					/>
				</PageWrapper>
			)}
		</>
	);
};
const mapStateToProps = ({ session, skills }) => ({
	session,
	skills,
});

const mapDispatchToProps = (dispatch) => ({
	setSkills: (skill) => dispatch(setSkills(skill)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SkillWrapper);
