import {SET_PATHWAYS} from "../../actions/pathways/setPathways";

export default (state = null, {type, pathways = null }) => {
	Object.freeze(state);
	switch (type) {
		case SET_PATHWAYS:
			return pathways;
		default:
			return state;
	}
};
