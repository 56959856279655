import {SET_RESUME} from "../../actions/pathways/setResume";

export default (state = null, {type, resume = null }) => {
	Object.freeze(state);
	switch (type) {
		case SET_RESUME:
			return resume;
		default:
			return state;
	}
};
