import React from 'react';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";



const LineChart = props => {
    // const [data, setData] = useState(props.data)
//   static demoUrl = 'https://codesandbox.io/s/simple-area-chart-4ujxw';

  
    return (
        
            <ResponsiveContainer width="100%" height={250}>
            <AreaChart
                width={500}
                height={400}
                data={props.data}
                margin={{
                top: 10,
                right: 10,
                left: 0,
                bottom: 0,
                }}
            >
                <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="var(--color-plum)" stopOpacity={0.2}/>
                <stop  offset="100%" stopColor="var(--color-plum)" stopOpacity={0}/>
                </linearGradient>
            </defs>
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <XAxis dataKey="name"  interval="preserveStartEnd"  tickLine={false} axisLine={false} overflow={"allow"} dy={5}/>
                <YAxis  dataKey="uv" hide={true} domain={[0,4000]} /> 
                <Tooltip />
                <Area type="monotone" dataKey="uv" strokeWidth={4} strokeLinecap="round" stroke="var(--color-plum)" fill="url(#colorUv)" minTickGap={10} dot={{ fill: 'var(--color-plum', stroke:'none', fillOpacity:1, r: 8 }} />
            </AreaChart>
            </ResponsiveContainer>
   
    );
  
}
export default LineChart
