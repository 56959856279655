import React from "react";
import { Link } from "react-router-dom";

const PathwaysGrid = (props) => {

    const openSheet = (e) => {
        e.preventDefault()
        props.openSheet(e)
    }

    return (
        <div className="pathways-infographic">
            <div className="pathways-grid">
                <div className="container">
                    <div className="pathway ghost"><div className="pathway-inner"/></div>
                    <div className="pathway ghost"><div className="pathway-inner"/></div>
                    <div className="pathway"></div>
                    <div className="pathway out-of-reach"><div className="pathway-inner cloud"></div></div>
                    <div className="pathway recommended path-right">
                        <div className="pathway-inner">
                            <span className="icon">
                                <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.06877 8.05463C7.06877 6.67925 7.47662 5.33476 8.24074 4.19117C9.00486 3.04759 10.0909 2.15627 11.3616 1.62993C12.6323 1.1036 14.0305 0.965886 15.3795 1.23421C16.7284 1.50253 17.9675 2.16484 18.9401 3.13738C19.9126 4.10992 20.5749 5.34901 20.8432 6.69797C21.1116 8.04692 20.9739 9.44515 20.4475 10.7158C19.9212 11.9865 19.0299 13.0726 17.8863 13.8367C16.7427 14.6008 15.3982 15.0087 14.0228 15.0087H6.37337C5.08234 15.0087 3.84419 14.4958 2.93129 13.5829C2.0184 12.67 1.50554 11.4319 1.50554 10.1408C1.50554 8.84982 2.0184 7.61167 2.93129 6.69877C3.84419 5.78588 5.08234 5.27302 6.37337 5.27302C6.78064 5.27286 7.18641 5.32249 7.58164 5.42079" fill="white" fillOpacity="0.2"/>
                                <path d="M7.06877 8.05463C7.06877 6.67925 7.47662 5.33476 8.24074 4.19117C9.00486 3.04759 10.0909 2.15627 11.3616 1.62993C12.6323 1.1036 14.0305 0.965886 15.3795 1.23421C16.7284 1.50253 17.9675 2.16484 18.9401 3.13738C19.9126 4.10992 20.5749 5.34901 20.8432 6.69797C21.1116 8.04692 20.9739 9.44515 20.4475 10.7158C19.9212 11.9865 19.0299 13.0726 17.8863 13.8367C16.7427 14.6008 15.3982 15.0087 14.0228 15.0087H6.37337C5.08234 15.0087 3.84419 14.4958 2.93129 13.5829C2.0184 12.67 1.50554 11.4319 1.50554 10.1408C1.50554 8.84982 2.0184 7.61167 2.93129 6.69877C3.84419 5.78588 5.08234 5.27302 6.37337 5.27302C6.78064 5.27286 7.18641 5.32249 7.58164 5.42079" stroke="#989BA3" strokeWidth="1.39081" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </span>
                            <span>
                                Infrastructure Manager
                            </span>
                        </div>
                    </div>
                    <div className="pathway recommended">
                        <div className="pathway-inner">
                            <span className="icon">
                            <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.06877 8.05463C7.06877 6.67925 7.47662 5.33476 8.24074 4.19117C9.00486 3.04759 10.0909 2.15627 11.3616 1.62993C12.6323 1.1036 14.0305 0.965886 15.3795 1.23421C16.7284 1.50253 17.9675 2.16484 18.9401 3.13738C19.9126 4.10992 20.5749 5.34901 20.8432 6.69797C21.1116 8.04692 20.9739 9.44515 20.4475 10.7158C19.9212 11.9865 19.0299 13.0726 17.8863 13.8367C16.7427 14.6008 15.3982 15.0087 14.0228 15.0087H6.37337C5.08234 15.0087 3.84419 14.4958 2.93129 13.5829C2.0184 12.67 1.50554 11.4319 1.50554 10.1408C1.50554 8.84982 2.0184 7.61167 2.93129 6.69877C3.84419 5.78588 5.08234 5.27302 6.37337 5.27302C6.78064 5.27286 7.18641 5.32249 7.58164 5.42079" fill="white" fillOpacity="0.2"/>
                            <path d="M7.06877 8.05463C7.06877 6.67925 7.47662 5.33476 8.24074 4.19117C9.00486 3.04759 10.0909 2.15627 11.3616 1.62993C12.6323 1.1036 14.0305 0.965886 15.3795 1.23421C16.7284 1.50253 17.9675 2.16484 18.9401 3.13738C19.9126 4.10992 20.5749 5.34901 20.8432 6.69797C21.1116 8.04692 20.9739 9.44515 20.4475 10.7158C19.9212 11.9865 19.0299 13.0726 17.8863 13.8367C16.7427 14.6008 15.3982 15.0087 14.0228 15.0087H6.37337C5.08234 15.0087 3.84419 14.4958 2.93129 13.5829C2.0184 12.67 1.50554 11.4319 1.50554 10.1408C1.50554 8.84982 2.0184 7.61167 2.93129 6.69877C3.84419 5.78588 5.08234 5.27302 6.37337 5.27302C6.78064 5.27286 7.18641 5.32249 7.58164 5.42079" stroke="#989BA3" strokeWidth="1.39081" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            </span>
                            <span>
                                Infrastructure Engineer
                            </span>
                        </div>
                    </div>
                    <div className="pathway ghost"><div className="pathway-inner"/></div>
                    <div className="pathway out-of-reach"><div className="pathway-inner"></div></div>
                    <div className="pathway recommended path-right">
                        <div className="pathway-inner">
                            <span className="icon">
                            <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.06877 8.05463C7.06877 6.67925 7.47662 5.33476 8.24074 4.19117C9.00486 3.04759 10.0909 2.15627 11.3616 1.62993C12.6323 1.1036 14.0305 0.965886 15.3795 1.23421C16.7284 1.50253 17.9675 2.16484 18.9401 3.13738C19.9126 4.10992 20.5749 5.34901 20.8432 6.69797C21.1116 8.04692 20.9739 9.44515 20.4475 10.7158C19.9212 11.9865 19.0299 13.0726 17.8863 13.8367C16.7427 14.6008 15.3982 15.0087 14.0228 15.0087H6.37337C5.08234 15.0087 3.84419 14.4958 2.93129 13.5829C2.0184 12.67 1.50554 11.4319 1.50554 10.1408C1.50554 8.84982 2.0184 7.61167 2.93129 6.69877C3.84419 5.78588 5.08234 5.27302 6.37337 5.27302C6.78064 5.27286 7.18641 5.32249 7.58164 5.42079" fill="white" fillOpacity="0.2"/>
<path d="M7.06877 8.05463C7.06877 6.67925 7.47662 5.33476 8.24074 4.19117C9.00486 3.04759 10.0909 2.15627 11.3616 1.62993C12.6323 1.1036 14.0305 0.965886 15.3795 1.23421C16.7284 1.50253 17.9675 2.16484 18.9401 3.13738C19.9126 4.10992 20.5749 5.34901 20.8432 6.69797C21.1116 8.04692 20.9739 9.44515 20.4475 10.7158C19.9212 11.9865 19.0299 13.0726 17.8863 13.8367C16.7427 14.6008 15.3982 15.0087 14.0228 15.0087H6.37337C5.08234 15.0087 3.84419 14.4958 2.93129 13.5829C2.0184 12.67 1.50554 11.4319 1.50554 10.1408C1.50554 8.84982 2.0184 7.61167 2.93129 6.69877C3.84419 5.78588 5.08234 5.27302 6.37337 5.27302C6.78064 5.27286 7.18641 5.32249 7.58164 5.42079" stroke="#989BA3" strokeWidth="1.39081" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
                            </span>
                            <span>
                                Infrastructure Arthitect
                            </span>
                        </div>
                    </div>
                    <div className="pathway recommended path-top-right">
                        <div className="pathway-inner">
                            <span className="icon">
                            <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.06877 8.05463C7.06877 6.67925 7.47662 5.33476 8.24074 4.19117C9.00486 3.04759 10.0909 2.15627 11.3616 1.62993C12.6323 1.1036 14.0305 0.965886 15.3795 1.23421C16.7284 1.50253 17.9675 2.16484 18.9401 3.13738C19.9126 4.10992 20.5749 5.34901 20.8432 6.69797C21.1116 8.04692 20.9739 9.44515 20.4475 10.7158C19.9212 11.9865 19.0299 13.0726 17.8863 13.8367C16.7427 14.6008 15.3982 15.0087 14.0228 15.0087H6.37337C5.08234 15.0087 3.84419 14.4958 2.93129 13.5829C2.0184 12.67 1.50554 11.4319 1.50554 10.1408C1.50554 8.84982 2.0184 7.61167 2.93129 6.69877C3.84419 5.78588 5.08234 5.27302 6.37337 5.27302C6.78064 5.27286 7.18641 5.32249 7.58164 5.42079" fill="white" fillOpacity="0.2"/>
<path d="M7.06877 8.05463C7.06877 6.67925 7.47662 5.33476 8.24074 4.19117C9.00486 3.04759 10.0909 2.15627 11.3616 1.62993C12.6323 1.1036 14.0305 0.965886 15.3795 1.23421C16.7284 1.50253 17.9675 2.16484 18.9401 3.13738C19.9126 4.10992 20.5749 5.34901 20.8432 6.69797C21.1116 8.04692 20.9739 9.44515 20.4475 10.7158C19.9212 11.9865 19.0299 13.0726 17.8863 13.8367C16.7427 14.6008 15.3982 15.0087 14.0228 15.0087H6.37337C5.08234 15.0087 3.84419 14.4958 2.93129 13.5829C2.0184 12.67 1.50554 11.4319 1.50554 10.1408C1.50554 8.84982 2.0184 7.61167 2.93129 6.69877C3.84419 5.78588 5.08234 5.27302 6.37337 5.27302C6.78064 5.27286 7.18641 5.32249 7.58164 5.42079" stroke="#989BA3" strokeWidth="1.39081" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
                            </span>
                            <span>
                                Infrastructure Specialist
                            </span>
                        </div>
                    </div>
                    <div className="pathway"><div className="pathway-inner"></div></div>
                    <div className="pathway out-of-reach"><div className="pathway-inner headset"></div></div>
                    {/* Row3 */}
                    <div className="pathway out-of-reach"><div className="pathway-inner"></div></div>
                    <div className="pathway"><div className="pathway-inner"></div></div>

                    
                    <div className="pathway recommended closest path-top-right">
                       
                        <div className="pathway-inner">
                        <Link to={process.env.PUBLIC_URL+"/pathway/IT-CAA"} className="desktop-only">
                            <span>Cloud Administrator</span>
                                <span className="number fit">
                                    95%
                                </span>
                            </Link>
                            <a href={"IT-CAA"} type="pathway" className="mobile-only" onClick={(event) => openSheet(event)}>
                            <span>Cloud Administrator</span>
                            <span className="number fit">
                                95%
                            </span>
                            </a>
                        </div>
                    </div>

                    <div className="pathway"><div className="pathway-inner"></div></div>
                    <div className="pathway out-of-reach"><div className="pathway-inner headset"></div></div>
                    <div className="pathway out-of-reach"><div className="pathway-inner headset"></div></div>
                    
                    
                    
                    <div className="pathway accessible">
                        <div className="pathway-inner">
                            <span>DevOps Architect</span>
                            <span className="number fit">
                                87%
                            </span>
                        </div>
                    </div>
                    <div className="pathway current path-top-right">
                        <div className="pathway-inner">
                            <span className="chip-highlight">Current</span>
                            <span>IT Support Specialist</span>
                            <span>82%</span>
                        </div>
                    </div>
                    <div className="pathway accessible">
                        <div className="pathway-inner">
                            <span>Support Engineer</span>
                            <span className="number fit">
                                89%
                            </span>
                        </div>
                    </div>
                    <div className="pathway out-of-reach"><div className="pathway-inner headset"></div></div>
                    <div className="pathway out-of-reach"><div className="pathway-inner presentation"></div></div>
                    <div className="pathway out-of-reach"><div className="pathway-inner presentation"></div></div>
                    {/* Row5 */}
                    <div className="pathway"><div className="pathway-inner"></div></div>
                    <div className="pathway accessible">
                        <div className="pathway-inner">
                            <span>IT Specialist</span>
                            <span className="number fit">
                                85%
                            </span>
                        </div>
                    </div>
                    <div className="pathway accessible">
                        <div className="pathway-inner">
                            <span>Cyber Security Analyst</span>
                            <span className="number fit">
                                94%
                            </span>
                        </div>
                    </div>
                    <div className="pathway out-of-reach"><div className="pathway-inner presentation"></div></div>
                    <div className="pathway out-of-reach"><div className="pathway-inner presentation"></div></div>
                    <div className="pathway ghost"><div className="pathway-inner"/></div>
                
                </div>
            </div>
            <div className="pathways-legend">
                    <div className="pathway-type">
                        <span className="pathway-color current"/>
                        <span className="pathway-name">
                            Current
                        </span>
                    </div>
                    <div className="pathway-type">
                        <span className="pathway-color recommended"/>
                        <span className="pathway-name">
                            Recommended
                        </span>
                    </div>
                    <div className="pathway-type">
                        <span className="pathway-color accessible"/>
                        <span className="pathway-name">
                            Accessible choice
                        </span>
                    </div>
                    <div className="pathway-type">
                        <span className="pathway-color out-of-reach"/>
                        <span className="pathway-name">
                            Out of reach
                        </span>
                    </div>
            </div>
        </div>
       
    )
}
export default PathwaysGrid