/** @format */

import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import PageWrapper from '../global/PageWrapper';
import ListPageHeader from './ListPageHeader';
import InstructorsTable from './tables/InstructorsTable';
import StudentsTable from './tables/StudentsTable';
import LicensesTable from './tables/LicensesTable';
import ClassesTable from './tables/ClassesTable';

const ListPage = (props) => {
	let tableData = [
        {
            id: "5QT27IN-000",
            email: "dsampson@nationalable.org",
            phone: "+1 301 555-0123",
            firstName: "Darren",
            lastName: "Sampson",
            avatar: "https://assets.pearsonct.design/avatar/63d6e420807516d2739002ee/darren.jpg",
            gender: "male",
            city: "Springdale",
            region: "MD",
            country: "United States",
            atcId: "ATC-INST",
            occupationId: "EDU-TCH",
            isManager: false,
            managerId: "",
            organization: "Callusa High",
            orgLevel: "High School",
            department: "Math",
            jobTitle: "Math Teacher",
            description: "Darren is a high school math teacher at Calusa High with an extensive background in technology and education services strategy and execution.",
            credentials: [
              {
                "id": "AWS-CERT-SOL-ARC", "date": "Jul 14, 2021"
              },
              {
                "id":"AWS-CD-PRAC", "date":"Jul 20, 2019"
              },
              {
                "id":"AWS-INST", "date":"May 5 2022"
              }
            ],
            skills: [
              {"id": "SK-CLD-ARC", "verified": true, "inferred": true, "date": "Jul 14, 2021"},
              {
                "id": "SK-CLD-CMP", "verified": true, "inferred": true,  "date": "Jul 20, 2019"
              },
              {"id": "SK-VIRT-INST", "verified": true, "inferred": true, "date": "May 5 2022"},
              {"id": "SK-AWS", verrified: true, "inferred": true, "date": "Jun 11, 2017"}
            ],
            classes:[{"id": "AWS-CERT-SOL-ARC", "examEnrolled": false, "atcSiteId": ""}],
            enrollments: [ { "id": "AWS-CL-FD", "examEnrolled": false, "atcSiteId": ""}],
            followedBadges: "3",
            connectStatus: "Pending",
            pathAssurance: "Confident",
            lastActive: "",
            language: ["English", "Spanish", "Portugese"],
            availability: "date date"
          }
    ];

	return (
		<PageWrapper pageType={props.pageType}>
			<section>
				<ListPageHeader
					title={props.session.role === 'instructor' ? "My " + props.pageType : props.pageType}
					add={
						props.pageType === 'instructors'
							? { text: 'instructor' }
							: props.pageType === 'licenses'
							? { text: 'license' }
							: false
					}
				/>

				{tableData ? (
					<>
						{props.pageType === 'instructors' ? (
							<InstructorsTable data={tableData} />
						) : props.pageType === 'students' ? (
							<StudentsTable data={tableData} />
						) : props.pageType === 'licenses' ? (
							<LicensesTable data={tableData} />
						) : props.pageType === 'classes' ? (
							<ClassesTable data={tableData} />
						) : (
							<p>Table does not exist.</p>
						)}
					</>
				) : (
					<div className='skeleton card'></div>
				)}
			</section>
		</PageWrapper>
	);
};
const mapStateToProps = ({ session }) => ({
	session,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ListPage);
