import React, {useState} from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import {setScores} from "../../../actions/dashboard/setScores";
import {connect} from "react-redux";



const LineChartScoreD = props => {
    // const [data, setData] = useState(props.data)

    const sampleData = [
        {
            month: "SEP",
            score: 78
        },
        {
            month: "OCT",
            score: 79
        },
        {
            month: "NOV",
            score: 80
        },
        {
            month: "DEC",
            score: 82
        },
        {
            month: "JAN",
            score: 80
        },
        {
            month: "FEB",
            score: 76
        },
        {
            month: "MAR",
            score: 77
        },
        {
            month: "APR",
            score: 78
        },
        {
            month: "MAY",
            score: 79
        },
        {
            month: "JUN",
            score: 79
        },
        {
            month: "JUL",
            score: 80
        },
        {
            month: "AUG",
            score: props.data
        }
    ]

    return (

            <ResponsiveContainer width="100%" height={150}>
            <AreaChart
                width={500}
                height={400}
                data={sampleData}
                margin={{
                top: 10,
                right: 0,
                left: 0,
                bottom: 10,
                }}
            >
                <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#9800A5" stopOpacity={0.7}/>
                <stop  offset="100%" stopColor="#9800A5" stopOpacity={0}/>
                </linearGradient>
            </defs>
                <CartesianGrid vertical={false} stroke="#393F4A" strokeWidth={1} />
                <XAxis dataKey="month" tick={{fontSize: '16px',fill: 'var(--color-white)'}} tickMargin={10} axisLine={{stroke: "#393F4A"}} ticks={["OCT","JAN","APR","JUL"]} tickLine={false}  overflow={"allow"}/>
                <YAxis dataKey="score" tick={{fontSize: '16px',fill: 'var(--color-white)'}} width={40} axisLine={false} domain={[70,90]} tickLine={{stroke: "#393F4A"}} tickMargin={10} ticks={[70,80,90]} />
                {/* <Tooltip /> */}
                <Area  type="monotone" dataKey="score" strokeWidth={4} strokeLinecap="round" stroke="#9800A5" fill="url(#colorUv)" minTickGap={10} />
            </AreaChart>
            </ResponsiveContainer>

    );

}
const mapStateToProps = ({session,scores}) => ({
	session, scores
});

const mapDispatchToProps = dispatch => ({
	setScores: (data) => dispatch(setScores(data)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(LineChartScoreD);
