/** @format */

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import RecommendationsCard from '../global/cards/RecommendationsCard';
import { getPurchasedCourse } from '../../util/getPurchasedCourse';
import { setPurchasedCourse } from '../../actions/course-details/setPurchasedCourse';

function MyCourses(props) {
	if (props.purchasedCourse) {
		return (
			<section>
				<h2>My Courses</h2>
				<div className='card-carousel firstSlide'>
					<button
						className='icon-btn circle-btn prev-btn'
						aria-label='Previous'
						disabled=''
					>
						<svg
							focusable='false'
							className='icon-24'
							aria-hidden='true'
						>
							<use
								xmlnsXlink='http://www.w3.org/1999/xlink'
								xlinkHref='#chevron-left-24'
							/>
						</svg>
					</button>
					<div className='card-container'>
						<ul>
							<li style={{ width: 310 }}>
								<RecommendationsCard
									data={props.purchasedCourse.courseData}
									variant='my-course'
								/>
							</li>
						</ul>
					</div>
					<button
						className='icon-btn circle-btn next-btn'
						aria-label='Next'
					>
						<svg
							focusable='false'
							className='icon-24'
							aria-hidden='true'
						>
							<use
								xmlnsXlink='http://www.w3.org/1999/xlink'
								xlinkHref='#chevron-right-24'
							/>
						</svg>
					</button>
				</div>
			</section>
		);
	}
}

const mapStateToProps = ({ session, purchasedCourse }) => ({
	session,
	purchasedCourse,
});

const mapDispatchToProps = (dispatch) => ({
	setPurchasedCourse: (data) => dispatch(setPurchasedCourse(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyCourses);
