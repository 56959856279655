/** @format */
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import ManagerRecommendations from './ManagerRecommendations';
import CommunityActivity from './CommunityActivity';
import PopularPrograms from './PopularPrograms';
import ActivitySnapshot from './ActivitySnapshot';
import AdditionalSkills from './AdditionalSkills';
import MyCourses from './MyCourses';
import FitScore from './NewFit/fitScore';
import Toast from '../global/Toast';
import LineChartScore from '../global/infographics/LineChartScore';
import Card from '../global/cards/Card';

function EmployeeDashboard(props) {
	const location = useLocation();
	// get userId
	const [coursePurchased, setCoursePurchased] = useState(false);
	const graph = useRef();

	useEffect(() => {
		console.log(props.course)
		let newState = location.state ? location.state.coursePurchased : false;
		setCoursePurchased(newState);

		setTimeout(() => {
			setCoursePurchased(false);
		}, 5000);
	}, [location]);
	return (
		<>
			{coursePurchased === true && props.course && !props.course.hasOwnProperty("started") ? (
				<Toast
					message={'Course purchased'}
					type='alert'
					className='show position-top'
				/>
			) : null}
			<FitScore />

			<MyCourses />

			<ManagerRecommendations title={props.session.type === 'enterprise' ? "Your manager recommends" : "Recommended for you"} />

			{props.insights ? (
				<></>
			) : (
				<section>
					<div className='card dark'>
						<h2>Discover your Work Style</h2>
						<p>
							Take this short two-part assessment to learn how your personality
							relates to your fit within the future workplace.
						</p>
						<div className='card-footer'>
							<Link
								className='button-primary'
								to='/workstylequiz'
							>
								Begin
							</Link>
						</div>
					</div>
				</section>
			)}

			<CommunityActivity />
			<PopularPrograms />
			{props.courseComplete ? (
				<section>
					<h2>Future Fit Score</h2>
					<div className='chip-container'>
						<span className='chip-highlight plum'>+5 this week!</span>
					</div>
					<Card>
						<h3
							className='text-h1'
							style={{
								fontSize: '64px',
								fontWeight: '600',
								marginBottom: '24px',
							}}
						>
							87
						</h3>
						<div
							className='graph-container infographic'
							ref={graph}
							style={{ height: '150px' }}
						>
							<LineChartScore animate={true} />
						</div>
					</Card>
				</section>
			) : null}
			<AdditionalSkills />
			<ActivitySnapshot title={props.session.type === 'enterprise' ? "Quad Bank activity snapshot" : "Activity snapshot"} />

			<section>
				<h2>Let's get to know you</h2>
				<p>Unlock personalized learning and development opportunities.</p>
				<div className='update-container'>
					<div className='right label'>0/2 complete</div>
					<div className='accordion-container undefined'>
						<button
							className='accordion-btn'
							aria-expanded='false'
						>
							<span className='accordion-txt'>Update your profile</span>
							<span className='icon-24'>
								<svg
									width={16}
									height={9}
									viewBox='0 0 16 9'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M15.3124 1.63286L8.59759 8.06646C8.38665 8.24224 8.17571 8.31255 7.99993 8.31255C7.78899 8.31255 7.57805 8.24224 7.40227 8.10161L0.652273 1.63286C0.300711 1.31646 0.300711 0.753958 0.617117 0.437551C0.933523 0.085989 1.49602 0.085989 1.81243 0.402395L7.99993 6.30865L14.1523 0.402395C14.4687 0.085989 15.0312 0.085989 15.3476 0.437551C15.664 0.753958 15.664 1.31646 15.3124 1.63286Z'
										fill='#60646D'
									/>
								</svg>
							</span>
						</button>
						<div className='accordion-body'>
							<div>More info here</div>
						</div>
					</div>
					<div className='accordion-container undefined'>
						<button
							className='accordion-btn'
							aria-expanded='false'
						>
							<span className='accordion-txt'>
								Indicate your skill interests
							</span>
							<span className='icon-24'>
								<svg
									width={16}
									height={9}
									viewBox='0 0 16 9'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M15.3124 1.63286L8.59759 8.06646C8.38665 8.24224 8.17571 8.31255 7.99993 8.31255C7.78899 8.31255 7.57805 8.24224 7.40227 8.10161L0.652273 1.63286C0.300711 1.31646 0.300711 0.753958 0.617117 0.437551C0.933523 0.085989 1.49602 0.085989 1.81243 0.402395L7.99993 6.30865L14.1523 0.402395C14.4687 0.085989 15.0312 0.085989 15.3476 0.437551C15.664 0.753958 15.664 1.31646 15.3124 1.63286Z'
										fill='#60646D'
									/>
								</svg>
							</span>
						</button>
						<div className='accordion-body'>
							<div>More info here</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

const mapStateToProps = ({ session, insights, courseComplete, course }) => ({
	session,
	insights,
	courseComplete,
	course
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeDashboard);
