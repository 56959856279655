import React from "react";

const InsightBlock = (props) => {

    return (
        <div className={`insight-block ${props.type}`}>
            <div className="bar-group">
                {/* Map categories */}
                {props.data.categories.map((category,index) => {

                    return (
                       <div className="bar-container" key={props.type + index}>
                            <div className="bar" aria-label={category.title} style={{width: category.value + "%"}}></div>
                        </div> 
                    )
                })}

            </div>
            <div className="insight-title">
                <span>{props.title}</span>
            </div>
            

        </div>
    )
}
export default InsightBlock