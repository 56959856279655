import {SET_USER} from "../../actions/users/setUser";

export default (state = null, {type, user = null }) => {
	Object.freeze(state);
	switch (type) {
		case SET_USER:
			return user;
		default:
			return state;
	}
};
