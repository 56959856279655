import React from "react";

const ListPageHeader = (props) => {

    const capitalize = (word) => {
     const capped = word.charAt(0).toUpperCase()
  + word.slice(1)

        return capped
    }

    return (
        <div className='section-header'>
            <h1 style={{marginBottom:"20px"}}>{capitalize(props.title)}</h1>
            {props.add && <button className='button--primary' onClick={props.add.func ? () =>  props.add.func() : null}>Add {props.add.text}</button> }
    </div>
    )
}
export default ListPageHeader