import React, {useState} from "react";
import Icon from "../global/Icon";

const InsightsGroup = (props) => {
    const [open, setOpen] = useState(false);
    const data = props.data
    return (
        <div className={`insights-group ${props.type} ${open ? "expanded" : ""}`}>
            <button className="insights-title" aria-expanded={open} onClick={() => setOpen(!open)}>
                <span className="icon">
                    <Icon icon="expand" size="24"/>
                </span>
                <h3 className="text">
                    {data.title}:
                </h3>
            </button>
            <div className="insights-body">
                <p className="insights-definition">Definition: {data.description}</p>
                <div className="insights-bar-container">
                    <div className="bar-container">
                        <div className="bar" style={{width: data.value + "%"}}>
                            <span><strong>You</strong> {data.value} percentile</span>
                        </div>
                        <div className="fifty">
                            <span>50th percentile <span className="icon"><Icon icon="info" size="18"/></span></span>
                        </div>
                    </div>
                    <div className="labels">
                        <div className="minimum-label">
                            <strong>{data.minimum.title}</strong>
                            <span className="bar-description">
                                {data.minimum.details}
                            </span>
                        </div>
                        <div className="maximum-label">
                            <strong>{data.maximum.title}</strong>
                            <span className="bar-description">
                                {data.maximum.details}
                            </span>
                        </div>
                    </div>
                    
                    
                </div>
                <div className="insights-more-details">
                    <div className="insights-meaning">
                        <h4>What does your score mean?</h4>
                        {data.meaning.map((meaning,index) => {
                            return <p key={"para" + index} dangerouslySetInnerHTML={{__html: meaning.content}}/>
                        })}
                    </div>
                    <div className="insights-improve">
                        <h4>How can you grow your skills in this area?</h4>
                        {data.howToImprove.map((meaning,index) => {
                            return <p key={"paraM" + index} dangerouslySetInnerHTML={{__html: meaning.content}}/>
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default InsightsGroup