import React, {useEffect, useState } from "react";
import MatchingSkills from "./MatchingSkills";
import ManagerRecommendations from "../dashboard/ManagerRecommendations";
import { getBadges } from '../../util/getBadges';
import { setBadges } from '../../actions/badges/setBadges';
import { connect } from 'react-redux';
import Carousel from "../global/Carousel";
import LearningCard from "../global/cards/LearningCard";

const JobDetail = (props) => {
    const postedDate = new Date(props.data.posted_date)
    const dateString = postedDate.toLocaleDateString();
    let trendingLearning;

    useEffect(() => {
		if (!props.badges) {
			getBadges().then((data) => {
				console.log(data);
				props.setBadges(data);
			});
		}
	}, []);
    return (
        <div className="jobDetail">
            <div className="companyDetails">
                <span className="logo">{props.data.company ? <img src={props.data.company.photo.url} /> : <></>}</span>
                <span className="name"><span>{props.data.company_name}</span></span>
            </div>
            <h2>{props.data.job_title}</h2>
            <div className="meta">{props.data.location} • {props.data.employment_type} • Posted {dateString}</div>
            <div className="jobDescription">
                <div className="titleHolder">
                    <h3><strong>About the role</strong></h3>
                    <button className="button-primary button-small">Apply now</button>
                </div>
                <div dangerouslySetInnerHTML={{__html: props.data.job_description}} />
                <MatchingSkills jobdata={props.data} />
    
            </div>
            <section style={{marginTop:"40px"}}>
                        <div className="section-header" style={{marginBottom:"20px"}}>
                            <h3><strong>Recommended learning</strong></h3>
                        </div>
                    

                        {props.badges ? (
                            ((trendingLearning = [
                                props.badges[21],
                                props.badges[24],
                                props.badges[22],
                                props.badges[10],
                                props.badges[6],
                            ]),
                            (
                                <Carousel size='310'>
                                    {trendingLearning.map((badge) => {
                                        return (
                                            <LearningCard
                                                key={badge.id}
                                                data={badge}
                                                details={true}
                                            />
                                        );
                                    })}
                                </Carousel>
                            ))
                        ) : (
                            <Carousel size='310'>
                                <div className='skeleton card'></div>
                                <div className='skeleton card'></div>
                                <div className='skeleton card'></div>
                            </Carousel>
                        )}
                    </section>
        </div>
    )
}

const mapStateToProps = ({ session, badges }) => ({
	session,
	badges,
});

const mapDispatchToProps = (dispatch) => ({
	setBadges: (badges) => dispatch(setBadges(badges)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(JobDetail);
