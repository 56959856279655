/** @format */

import React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import PageWrapper from '../global/PageWrapper';
import GoBack from '../global/GoBack';
import Tabs from '../global/tabs/Tabs';
import TabPanel from '../global/tabs/TabPanel';
import InsightsGroup from './InsightsGroup';
import { useMediaQuery } from '@mui/material';
import InsightsAccordion from './InsightsAccordion';

function Insights(props) {
	const location = useLocation();
	const { from } = location.state ? location.state : '';

	const isDesktop = useMediaQuery('(min-width:1220px');


	const insightsData = [
		{
			title: 'Approaching work',
			type: 'work',
			id: 'insights1',
			categories: [
				{
					title: 'Persistence',
					description:
						'A tendency to persist and complete work tasks, even when faced with obstacles or difficulties, or when success is unlikely.',
					value: 41,
					minimum: {
						title: 'You may lack follow through',
						details:
							'Focuses energy on doable tasks; may lack follow-through in the face of difficulties, obstacles, or when success is unlikely.',
					},
					maximum: {
						title: "You fixate on one task until it's done",
						details:
							'Doggedly continues assigned work even in the face of difficulties and obstacles: may occasionally need help redirecting efforts or prioritizing.',
					},
					meaning: [
						{
							type: 'p',
							content:
								'Your score indicates that you can lose motivation when tasks are overly challenging and success seems unlikely.',
						},
						{
							type: 'p',
							content:
								'You have effective strategies for getting work done, switching between tasks or taking breaks when you feel stuck. However, the risk is that you may give up too easily.',
						},
						{
							type: 'p',
							content:
								'You know when you should take a break, which allows you to come back to a task with fresh eyes and avoid exhaustion.',
						},
					],
					howToImprove: [
						{
							type: 'p',
							content:
								'Persistence is about continuing to push through toward a goal. Review situations when you have not persisted and look for patterns. Wanted to avoid conflict? A little impulsive? Identify your triggers so you can prevent or address them.',
						},
					],
				},
				{
					title: 'Attention to detail',
					description:
						'Tends to focus on details, catch errors, and complete all tasks thoroughly and carefully.',
					value: 73,
					minimum: {
						title: 'You ignore "unnecessary" rules',
						details: 'More details here',
					},
					maximum: {
						title: 'You work by the book',
						details: 'More details here',
					},
					meaning: [
						{
							type: 'p',
							content: 'Your score indicates that you ...',
						},
					],
					howToImprove: [
						{
							type: 'p',
							content: 'More information here...',
						},
					],
				},
				{
					title: 'Rule following',
					description:
						'Adheres to rules and regulations, behaves honestly and ethically, and follows set procedures.',
					value: 65,
					minimum: {
						title: 'You ignore "unnecessary" rules',
						details: 'More details here',
					},
					maximum: {
						title: 'You work by the book',
						details: 'More details here',
					},
					meaning: [
						{
							type: 'p',
							content: 'Your score indicates that you ...',
						},
					],
					howToImprove: [
						{
							type: 'p',
							content: 'More information here...',
						},
					],
				},
				{
					title: 'Achievement/effort',
					description:
						'Strives for success and recognition, to get ahead, and be the best; sets difficult work goals and works hard.',
					value: 46,
					minimum: {
						title: 'You go with the flow',
						details: 'More details here',
					},
					maximum: {
						title: 'You strive for success',
						details: 'More details here',
					},
					meaning: [
						{
							type: 'p',
							content: 'Your score indicates that you ...',
						},
					],
					howToImprove: [
						{
							type: 'p',
							content: 'More information here...',
						},
					],
				},
				{
					title: 'Dependability',
					description:
						'Can be counted on to be on time, fulfill obligations, and meet deadlines.',
					value: 84,
					minimum: {
						title: 'You struggle with deadlines',
						details: 'More details here',
					},
					maximum: {
						title: 'You meet all deadlines',
						details: 'More details here',
					},
					meaning: [
						{
							type: 'p',
							content: 'Your score indicates that you ...',
						},
					],
					howToImprove: [
						{
							type: 'p',
							content: 'More information here...',
						},
					],
				},
				{
					title: 'Initiative',
					description:
						'Willing to take on and enjoys new or additional work responsibilities and challenges.',
					value: 77,
					minimum: {
						title: 'You may lack follow through',
						details: 'More details here',
					},
					maximum: {
						title: "You fixate on one task until it's done",
						details: 'More details here',
					},
					meaning: [
						{
							type: 'p',
							content: 'Your score indicates that you ...',
						},
					],
					howToImprove: [
						{
							type: 'p',
							content: 'More information here...',
						},
					],
				},
			],
		},
		{
			title: 'Relating to people',
			type: 'relate',
		},
		{
			title: 'Dealing with emotions',
			type: 'emotions',
		},
		{
			title: 'Thinking styles',
			type: 'thinking',
		},
	];

	return (
		<PageWrapper pageType={'insights'}>
			<>
				<section className='intro dark-bg'>
					<GoBack path={from === 'quiz' ? 'dashboard' : false} />
					<h1>Work Style Insights</h1>
					<p>
						Work Style Insights is an assessment that has been designed to be a
						reliable, fair, and valid measure of the work styles that contribute
						to and influence job success. The work styles measured can be
						thought of as the individual’s typical patterns of thinking, feeling
						and behaving.{' '}
					</p>
				</section>
				<section className='card work-insights'>
					<div className='section-header'>
						<h2>Overview</h2>
						<button className='button-link link-btn'>About your scores</button>
					</div>
					{isDesktop ? (
						<div className='section-body'>
							<Tabs>
								<TabPanel
									label='Approaching work'
									id='insightsTabOne'
								>
									{insightsData[0].categories.map((category, index) => {
										return (
											<InsightsGroup
												type={insightsData[0].type}
												data={category}
											/>
										);
									})}
								</TabPanel>
								<TabPanel
									label='Relating to people'
									id='insightsTabTwo'
								>
									Blocks blocks
								</TabPanel>
								<TabPanel
									label='Dealing with emotions'
									id='insightsTabThree'
								>
									Blocks blocks
								</TabPanel>
								<TabPanel
									label='Thinking styles'
									id='insightsTabFour'
								>
									Blocks blocks
								</TabPanel>
							</Tabs>
						</div>
					) : (
						<div className='section-body mobile'>
							<InsightsAccordion
								type={insightsData[0].type}
								title={insightsData[0].title}
							>
								{insightsData[0].categories.map((category, index) => {
									return (
										<InsightsGroup
											key={'group' + index}
											type={insightsData[0].type}
											data={category}
										/>
									);
								})}
							</InsightsAccordion>
							<InsightsAccordion
								type={insightsData[1].type}
								title={insightsData[1].title}
							>
								Block block block
							</InsightsAccordion>
							<InsightsAccordion
								type={insightsData[2].type}
								title={insightsData[2].title}
							>
								Block block block
							</InsightsAccordion>
							<InsightsAccordion
								type={insightsData[3].type}
								title={insightsData[3].title}
							>
								Block block block
							</InsightsAccordion>
						</div>
					)}
				</section>
			</>
		</PageWrapper>
	);
}

const mapStateToProps = ({ session }) => ({
	session,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Insights);
