/** @format */

import React from 'react';

function CommunityActivity(props) {
	return (
		<section className='dark-section'>
			<h2>Community activity</h2>
			<h3>Soft skills</h3>
			<ul className='chip-list overflow-scroll'>
				<li>
					<span className='chip-btn plum'>Leading with Empathy</span>
				</li>
				<li>
					<span className='chip-btn plum'>Presentation Skills</span>
				</li>
				<li>
					<span className='chip-btn plum'>Privacy</span>
				</li>
				<li>
					<span className='chip-btn plum'>Management</span>
				</li>
			</ul>
			<h3>Hard skills</h3>
			<ul className='chip-list overflow-scroll'>
				<li>
					<span className='chip-btn plum'>Data Science</span>
				</li>
				<li>
					<span className='chip-btn plum'>Artificial Intelligence</span>
				</li>
				<li>
					<span className='chip-btn plum'>Accessibility</span>
				</li>
				<li>
					<span className='chip-btn plum'>Data Analytics</span>
				</li>
			</ul>
		</section>
	);
}
export default CommunityActivity;
