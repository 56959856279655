import {signin as signInUtil} from '../../util/session/signIn';

export const RECEIVE_CURRENT_USER = 'RECEIVE_CURRENT_USER';

export const receiveCurrentUser = user => ({
  type: RECEIVE_CURRENT_USER,
  user
});

export const signin = user => async dispatch => {
  console.log('user from signin', user)
  if (user.password) {
    const response = await signInUtil(user);
	console.log(
		'response from signin',response
	)
    if (response.ok) {
      const userData = await response.json();
      return await dispatch(receiveCurrentUser(userData));
    } else {
      return response
    }
  } else {
    return await dispatch(receiveCurrentUser(user));
  }
};

