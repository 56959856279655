import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from "recharts";



const BadgesChart = props => {

    const data1 = [
        {
            time: "9a",
            badges: 2
        },
        {
            time: "10a",
            badges: 4
        },
        {
            time: "11a",
            badges:5
        },
        {
            time:"12p",
            badges: 3
        },
        {
            time: "1p",
            badges: 9
        },
        {
            time: "2p",
            badges: 14
        },
        {
            time: "3p",
            badges: 12
        },
        {
            time: "4p",
            badges:9
        },
        {
            time: "5p",
            badges: 6
        },
        {
            time: "6p",
            badges:8
        },
        {
            time: "7p",
            badges: 8
        },
        {
            time: "8p",
            badges: 7
        },
        {
            time: "9p",
            badges: 4
        }
    ]

  
    return (
        
            <ResponsiveContainer width="100%" height={220}>
            <BarChart barCategoryGap={"15%"}
                width={500}
                height={400}
                data={data1}
                margin={{
                top: 10,
                right: 10,
                left: 60,
                bottom: 0,
                }}
                padding={{left:40}}
            >
                <text x={12} y={14}
                    style={{ fontSize: 14, fill: 'var(--color-text-muted)' }}
                    width={200}
                    textAnchor='left'
                >Peak</text>
               
                <YAxis hide={true} tickLine={false} axisLine={false} type="number" domain={[0, 16]} ticks={[0,8,16]} /> 
                <CartesianGrid vertical={false} stroke="#DDE3EE" strokeWidth={1} />
                <XAxis dataKey="time"  interval="preserveStartEnd"  tickLine={false} axisLine={false} overflow={"allow"} ticks={["9a","12p","3p","6p","9p"]} dy={5}/>
                <Bar dataKey="badges" fill="var(--color-plum)" radius={[3, 3, 0, 0]} />
                
            </BarChart>
            </ResponsiveContainer>
   
    );
  
}
export default BadgesChart
