import React, { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "../global/Modal";
import Industry from "./Industry";

const IndustryList = (props) => {
    const sampleIndustries = {
        industries : [
            {
                name : "Architecture and Engineering",
                vanity_slug : "archEng"
            },
            {
                name : "Arts, Entertainment, Sports, and Media",
                vanity_slug : "artsEntSportMed"
            },
            {
                name : "Building and Grounds Maintenance",
                vanity_slug : "buildGrounds"
            },
            {
                name : "Business and Financial",
                vanity_slug : "bizFin"
            },
            {
                name : "Community and Social Services",
                vanity_slug : "commSoc"
            },
            {
                name : "Computer and Mathematical",
                vanity_slug : "compMath"
            },
            {
                name : "Construction and Extraction",
                vanity_slug : "constExt"
            },
            {
                name : "Education, Training, and Library",
                vanity_slug : "edTrainLib"
            },
            {
                name : "Executive Management",
                vanity_slug : "exec"
            },
            {
                name : "Farming, Fishing, and Forestry",
                vanity_slug : "farmFishForest"
            },
            {
                name : "Food Preparation and Restaurant",
                vanity_slug : "foodPrep"
            },
            {
                name : "Healthcare Practitioner and Technical",
                vanity_slug : "health"
            },
            {
                name : "Healthcare Support",
                vanity_slug : "healthSupport"
            },
            {
                name : "Installation, Maintenance and Repair",
                vanity_slug : "installMaintRep"
            },
            {
                name : "Legal",
                vanity_slug : "legal"
            },
            {
                name : "Life, Physical, and Social Science",
                vanity_slug : "lifePhysSoc"
            },
            {
                name : "Military",
                vanity_slug : "mil"
            },
            {
                name : "Office and Administrative",
                vanity_slug : "admin"
            },
            {
                name : "Personal Care and Attendants",
                vanity_slug : "personalCare"
            },
            {
                name : "Production and Manufacturing",
                vanity_slug : "prodMfg"
            },
            {
                name : "Protective Service",
                vanity_slug : "security"
            },
            {
                name : "Sales",
                vanity_slug : "sales"
            },
            {
                name : "Transportation",
                vanity_slug : "transport"
            },
        ]
    }

    const [sheetopen, setsheetopen] = useState(false)
    const [sheetid, setsheetid] = useState("id1")

    const openSheet = (e) => {
        e.preventDefault()
        const id = 
        setsheetopen(true)
    }

    return (
        <>
            <div className="industryList">
                <h2>Careers by industry</h2>
                <ul>
                    {
                        sampleIndustries.industries.map((industry,i) => {
                            return(
                                <li key={i+"industrykey"}>
                                    <Link className="desktop-only" to={`/industries/${industry.vanity_slug}`}>
                                        {industry.name}
                                    </Link>
                                    <a className="mobile-only" href={`/industries/${industry.vanity_slug}`} onClick={openSheet} industryid={industry.vanity_slug}>{industry.name}</a>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            <Modal
				open={sheetopen}
				setModalOpen={setsheetopen}
				targetid={sheetid}
				sheet={true}
            >
                <Industry />
            </Modal>
        </>
    )
}

export default IndustryList