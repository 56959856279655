import React, {useEffect, useState} from "react";
import Tabs from "../global/tabs/Tabs";
import TabPanel from "../global/tabs/TabPanel";
import EmployeeBadgesTable from "./tables/EmployeeBadgesTable";
import EmployeeSkillTable from "./tables/EmployeeSkillsTable";
import InstructorClassesTable from "./tables/InstructorClassesTable";
import Carousel from "../global/Carousel";
import Card from "../global/cards/Card";
import { getSkillTags } from '../../util/getSkillTags';
import { setSkillTags } from '../../actions/dashboard/setSkillTags';
import {setUsers} from "../../actions/users/setUsers";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUsers } from "../../util/getUsers";

const InstructorDetails = (props) => {
    const [otherInstructors, setOtherInstructors] = useState([]);

    const [data,setData] = useState(props.data)
    // const data = props.data;
    const skillsCount = 12;
    const instructorCount = 5;

    useEffect(() => {
		if (!props.skillTags) {
			getSkillTags().then((data) => {
				props.setSkillTags(data);
			});
		}
        if (!props.users) {
            getUsers().then((userD) => {
                props.setUsers(userD)
                setOtherInstructors(userD.filter(f => f.role === "instructor" && f.id !== props.data.id))

            })
        } else {
            setOtherInstructors(props.users.filter(f => f.id !== props.data.id))
        }
        setData(props.data)
	}, [props.data]);

  
    return (
        <>
        <section>
            <Tabs>
                <TabPanel label="Details" id="employeeTabOne">
                <div className="item-details">
                        <h3>Instructor information</h3>
                        <ul>
                        {data.jobTitle &&  <li>
                            <span className="icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 8C8 5.8125 9.78125 4 12 4C14.1875 4 16 5.8125 16 8C16 10.2188 14.1875 12 12 12C9.78125 12 8 10.2188 8 8ZM14.5 8C14.5 6.625 13.375 5.5 12 5.5C10.5938 5.5 9.5 6.625 9.5 8C9.5 9.40625 10.5938 10.5 12 10.5C13.375 10.5 14.5 9.40625 14.5 8ZM11.5312 15.25L10.5 13.5H13.5L12.4375 15.25L12.9375 17.0938L14.4688 14.0312C17 14.3125 19 16.4375 19 19.0625C19 19.5938 18.5625 20 18.0312 20H5.9375C5.40625 20 5 19.5938 5 19.0625C5 16.4375 6.96875 14.3125 9.5 14.0312L11.0312 17.0938L11.5312 15.25ZM15.2812 15.7812L13.9062 18.5H17.4375C17.25 17.2812 16.4062 16.25 15.2812 15.7812ZM6.53125 18.5H10.0625L8.6875 15.7812C7.5625 16.25 6.71875 17.2812 6.53125 18.5Z" fill="#1F897C"/>
                                </svg>
                            </span>
                            Occupation: {data.jobTitle}
                            </li> }
                            <li>
                            <span className="icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.5 4C15.3125 4 16 4.6875 16 5.5V8.5C16 9.34375 15.3125 10 14.5 10H12.75V11.25H21.25C21.6562 11.25 22 11.5938 22 12C22 12.4375 21.6562 12.75 21.25 12.75H17.75V14H19.5C20.3125 14 21 14.6875 21 15.5V18.5C21 19.3438 20.3125 20 19.5 20H14.5C13.6562 20 13 19.3438 13 18.5V15.5C13 14.6875 13.6562 14 14.5 14H16.25V12.75H7.75V14H9.5C10.3125 14 11 14.6875 11 15.5V18.5C11 19.3438 10.3125 20 9.5 20H4.5C3.65625 20 3 19.3438 3 18.5V15.5C3 14.6875 3.65625 14 4.5 14H6.25V12.75H2.75C2.3125 12.75 2 12.4375 2 12C2 11.5938 2.3125 11.25 2.75 11.25H11.25V10H9.5C8.65625 10 8 9.34375 8 8.5V5.5C8 4.6875 8.65625 4 9.5 4H14.5ZM14.5 5.5H9.5V8.5H14.5V5.5ZM4.5 18.5H9.5V15.5H4.5V18.5ZM19.5 15.5H14.5V18.5H19.5V15.5Z" fill="#1F897C"/>
                            </svg>
                            </span>
                            Employer:  <a>{data.organization}</a>
                            </li>
                            
                            <li>
                            <span className="icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.75 7.25C8.75 7 8.96875 6.75 9.25 6.75H10.75C11 6.75 11.25 7 11.25 7.25V8.75C11.25 9.03125 11 9.25 10.75 9.25H9.25C8.96875 9.25 8.75 9.03125 8.75 8.75V7.25ZM14.75 6.75C15 6.75 15.25 7 15.25 7.25V8.75C15.25 9.03125 15 9.25 14.75 9.25H13.25C12.9688 9.25 12.75 9.03125 12.75 8.75V7.25C12.75 7 12.9688 6.75 13.25 6.75H14.75ZM8.75 11.25C8.75 11 8.96875 10.75 9.25 10.75H10.75C11 10.75 11.25 11 11.25 11.25V12.75C11.25 13.0312 11 13.25 10.75 13.25H9.25C8.96875 13.25 8.75 13.0312 8.75 12.75V11.25ZM14.75 10.75C15 10.75 15.25 11 15.25 11.25V12.75C15.25 13.0312 15 13.25 14.75 13.25H13.25C12.9688 13.25 12.75 13.0312 12.75 12.75V11.25C12.75 11 12.9688 10.75 13.25 10.75H14.75ZM6 6C6 4.90625 6.875 4 8 4H16C17.0938 4 18 4.90625 18 6V18C18 19.125 17.0938 20 16 20H8C6.875 20 6 19.125 6 18V6ZM7.5 6V18C7.5 18.2812 7.71875 18.5 8 18.5H10.5V16.5C10.5 15.6875 11.1562 15 12 15C12.8125 15 13.5 15.6875 13.5 16.5V18.5H16C16.25 18.5 16.5 18.2812 16.5 18V6C16.5 5.75 16.25 5.5 16 5.5H8C7.71875 5.5 7.5 5.75 7.5 6Z" fill="#1F897C"/>
                                </svg>

                            </span>
                            Department: <a>{data.department ? data.department : ""}</a></li>
                            <li>
                            <span className="icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.5 10C14.5 11.4062 13.375 12.5 12 12.5C10.5938 12.5 9.5 11.4062 9.5 10C9.5 8.625 10.5938 7.5 12 7.5C13.375 7.5 14.5 8.625 14.5 10ZM12 9C11.4375 9 11 9.46875 11 10C11 10.5625 11.4375 11 12 11C12.5312 11 13 10.5625 13 10C13 9.46875 12.5312 9 12 9ZM18 10C18 12.75 14.3438 17.5938 12.7188 19.625C12.3438 20.0938 11.625 20.0938 11.25 19.625C9.625 17.5938 6 12.75 6 10C6 6.6875 8.65625 4 12 4C15.3125 4 18 6.6875 18 10ZM12 5.5C9.5 5.5 7.5 7.53125 7.5 10C7.5 10.4062 7.625 11 7.96875 11.8125C8.28125 12.5625 8.75 13.4375 9.28125 14.3125C10.1875 15.75 11.2188 17.125 12 18.125C12.75 17.125 13.7812 15.75 14.6875 14.3125C15.2188 13.4375 15.6875 12.5625 16 11.8125C16.3438 11 16.5 10.4062 16.5 10C16.5 7.53125 14.4688 5.5 12 5.5Z" fill="#1F897C"/>
                            </svg>

                            </span>
                            Location: {data.city}, {data.region}, {data.country}</li>
                        {data.email && <li>
                            <span className="icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 8C4 6.90625 4.875 6 6 6H18C19.0938 6 20 6.90625 20 8V16C20 17.125 19.0938 18 18 18H6C4.875 18 4 17.125 4 16V8ZM5.5 8V8.71875L10.875 13.125C11.5312 13.6562 12.4375 13.6562 13.0938 13.125L18.5 8.71875V8C18.5 7.75 18.25 7.5 18 7.5H6C5.71875 7.5 5.5 7.75 5.5 8ZM5.5 10.6562V16C5.5 16.2812 5.71875 16.5 6 16.5H18C18.25 16.5 18.5 16.2812 18.5 16V10.6562L14.0625 14.2812C12.8438 15.2812 11.125 15.2812 9.90625 14.2812L5.5 10.6562Z" fill="#1F897C"/>
                            </svg>

                            </span>
                            Email: <a href={"mailto:"+data.email}>{data.email}</a></li>}
                            <li>
                            <span className="icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.5 12C10.5938 12 11.5 11.125 11.5 10C11.5 8.90625 10.5938 8 9.5 8C8.375 8 7.5 8.90625 7.5 10C7.5 11.125 8.375 12 9.5 12ZM17.5 11.25H14.5C14.0625 11.25 13.75 11.5938 13.75 12C13.75 12.4375 14.0625 12.75 14.5 12.75H17.5C17.9062 12.75 18.25 12.4375 18.25 12C18.25 11.5938 17.9062 11.25 17.5 11.25ZM10.5 13H8.5C7.09375 13 6 14.125 6 15.5C6 15.7812 6.21875 16 6.5 16H12.5C12.75 16 13 15.7812 13 15.5C13 14.125 11.875 13 10.5 13ZM17.5 8.75H14.5C14.0625 8.75 13.75 9.09375 13.75 9.5C13.75 9.9375 14.0625 10.25 14.5 10.25H17.5C17.9062 10.25 18.25 9.9375 18.25 9.5C18.25 9.09375 17.9062 8.75 17.5 8.75ZM19 5H5C3.875 5 3 5.90625 3 7V17C3 18.125 3.875 19 5 19H19C20.0938 19 21 18.125 21 17V7C21 5.90625 20.0938 5 19 5ZM19.5 17C19.5 17.2812 19.25 17.5 19 17.5H5C4.71875 17.5 4.5 17.2812 4.5 17V7C4.5 6.75 4.71875 6.5 5 6.5H19C19.25 6.5 19.5 6.75 19.5 7V17Z" fill="#1F897C"/>
                            </svg>

                            </span>

                            Phone: {data.phone ? data.phone : "123-456-7890"}</li>
                        </ul> 
                        </div>
                        
                    </TabPanel>
                    <TabPanel label="Skills" id="employeeTabTwo">              
                        <EmployeeSkillTable data={data.skills}/>
                    </TabPanel>
                    <TabPanel label="Credentials" id="employeeTabThree">
                        <EmployeeBadgesTable data={data.badges}/>
                    </TabPanel>
                    <TabPanel label="Classes" id="employeeTabFour">
                        <InstructorClassesTable data={data.classes}/>
                    </TabPanel>
            </Tabs>
        </section>
        <section>
                <h2>Trending & emerging skills</h2>
                <ul className="chip-list">
                        {
                            props.skillTags && props.skillTags.map((skill,i) => {
                                if(i > skillsCount && i < 26){
                                    return (
                                        <li key={i}>
                                            <Link to={process.env.PUBLIC_URL+"/skill/"+skill.id} className="chip-btn">{skill.name}</Link>
                                        </li>
                                    )
                                }
                                else return <></>
                            })
                        }
                </ul>
                </section>
                {otherInstructors.length > 0 ? 
                <section>
                <div className="section-header">
                    <h2>Other instructors</h2>
                    <button className="button-link">View all</button>
                </div>
                <div className="section-body">
                    <Carousel size="248">
                    {otherInstructors.map(employee => {

                        return (
                            <Card modifier="employeeCard">
                            <Link to={process.env.PUBLIC_URL+"/employee/"+employee.id}>
                                <div className="avatar">
                                <img src={employee.avatar} alt=""/>
                                </div>
                                <span className="employee-name text-lg">
                                {employee.firstName} {employee.lastName}
                                </span>
                                <span className="job-title">
                                {employee.jobTitle}
                                </span>
                                <span className="job-org">
                                {employee.department}
                                </span>
                            </Link>
                            </Card>
                        )


                    })}

                    </Carousel>

                </div>
                </section> : <></> }
        </>
    )
}
const mapStateToProps = ({ session, skillTags, users }) => ({
	session,
	skillTags,
    users
});

const mapDispatchToProps = (dispatch) => ({
	setSkillTags: (skillTags) => dispatch(setSkillTags(skillTags)),
    setUsers: (data) => dispatch(setUsers(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(InstructorDetails);
