import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import {connect, useSelector} from "react-redux";
import PageWrapper from "../global/PageWrapper";
import Pillar from "../pillars/Pillar";
import Breadcrumbs from "../global/Breadcrumbs";
import { useLocation } from 'react-router-dom';
import {setOccupations} from "../../actions/occupations/setOccupations";
import AUTH_BASE_URL from "../../util/AUTH_BASE_URL";
const PillarWrapper = (props) => {
	const [info, setInfo] = React.useState();

	const params = useParams();
    const { pillarId } = params;


	useEffect(() => {
		console.log(props)
		if (props.type === "occupation" || props.type === "pathway") {
			if (!props.occupations) {
				fetch(`${AUTH_BASE_URL}existing/occupations/${pillarId}`).then(response => response.json()).then(data => {
					console.log('DATA', data)
					props.setOccupations(data);
					setInfo(data);
				})
			}
		}

    return () => {
      props.setOccupations(null)
    }
	},[])


	console.log('THE INFO',props.occupations)

  //bring in
    return (
        <>
          { !props.occupations ?
            <PageWrapper pageType="pillar">
              <section className="intro dark-bg">
                  <Breadcrumbs isBack="true"/>
                  <h1 style={{marginTop:"20px"}}>Missing page</h1>
                  <p>This prototype is still under development. Content will be placed here soon.</p>
              </section>
            </PageWrapper> :
            <PageWrapper pageType="pillar">
                <Pillar type={props.type} data={props.occupations}/>
            </PageWrapper>
          }
        </>
    )
}
const mapStateToProps = ({ session,occupations }) => ({
	session, occupations
});

const mapDispatchToProps = (dispatch) => ({
	setOccupations: (occupation) => dispatch(setOccupations(occupation))
});

export default connect(mapStateToProps, mapDispatchToProps)(PillarWrapper)

