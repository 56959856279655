/** @format */

import React from 'react';
import { connect } from 'react-redux';
import PageWrapper from '../global/PageWrapper';
import Tabs from '../global/tabs/Tabs';
import TabPanel from '../global/tabs/TabPanel';
import OccupationsTab from './OccupationsTab';
import OpeningsTab from './OpeningsTab';

function Careers(props) {
	return (
		<PageWrapper pageType='careers'>
			<section className='intro'>
				<h1>Careers</h1>
				<section className='light-bg'>
					<Tabs>
						<TabPanel
							label='Occupations'
							id='occupationsTab'
						>
							<OccupationsTab />
						</TabPanel>
						<TabPanel
							label='Openings'
							id='jobsTab'
						>
							<OpeningsTab />
						</TabPanel>
					</Tabs>
				</section>
			</section>
		</PageWrapper>
	);
}

const mapStateToProps = ({ session }) => ({
	session,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Careers);
