import {SET_COMPANY_INFO} from "../../actions/companies/setCompanyInfo";

export default (state = null, {type, company = null }) => {
	Object.freeze(state);
	switch (type) {
		case SET_COMPANY_INFO:
			return company;
		default:
			return state;
	}
};
