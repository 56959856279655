import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import Navigation from "../global/Navigation";
import Header from "../global/Header";
import Tabs from "../global/tabs/Tabs";
import TabPanel from "../global/tabs/TabPanel";
import PathwaysGrid from "./PathwaysGrid";
import FutureFitBreakdown from "./FutureFitBreakdown";
import PathwayList from "./PathwayList";
import Modal from "../global/Modal";
import Pillar from "../pillars/Pillar";
import PageWrapper from "../global/PageWrapper";
import AUTH_BASE_URL from "../../util/AUTH_BASE_URL";
import {setOccupations} from "../../actions/occupations/setOccupations";


function Pathways(props) {
    const [sheetopen, setsheetopen] = useState(false);
	const [sheetcontent, setsheetcontent] = useState(null);
	const [sheetid, setsheetid] = useState('id1');


    useEffect(() => {
		if (!props.occupations) {
            fetch(`${AUTH_BASE_URL}existing/occupations/IT-CAA`).then(response => response.json()).then(data => {
                // props.setOccupations(data);
                setsheetcontent(data);
            })
        }

    return () => {
    //   props.setOccupations(null)
    }
	},[])

    const openSheet = (e) => {
		e.preventDefault();
		setsheetopen(true);
	};

	return (
        <>
        <PageWrapper pageType="pathways">
            <section className="intro">
                <h1 style={{marginBottom: "24px"}}>Your Future Fit Score</h1>
                <FutureFitBreakdown/>
            </section>
            <section style={{marginTop:"20px"}}>
                <div className="section-header">
                   <h2>Your pathways</h2> 
                </div>
                <Tabs modifier="pill">
                <TabPanel label="Reskill" id="reskillTab">
                    <>
                    <PathwaysGrid openSheet={openSheet}/>
                    <PathwayList openSheet={openSheet}></PathwayList>
                    </>
                </TabPanel>
                <TabPanel label="Upskill" id="upSkillTab">
                    <PathwaysGrid openSheet={openSheet}/>
                    <PathwayList openSheet={openSheet}></PathwayList>
                </TabPanel>
            </Tabs>
                
                
            </section>
            


        </PageWrapper>
        {sheetcontent !== null ?
            <Modal
				open={sheetopen}
				setModalOpen={setsheetopen}
				targetid={sheetid}
				sheet={true}
			>
				<Pillar
				type='pathway'
				data={sheetcontent}
			/>
			</Modal> :<></> }
        </>
    )
}


const mapStateToProps = ({ session, occupations }) => ({
	session,
    occupations
});

const mapDispatchToProps = (dispatch) => ({
    setOccupations: (data) => dispatch(setOccupations(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Pathways);
