/** @format */

import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

const VideoComponent = ({ src, launchModal }) => {
	const videoRef = useRef(null);
	const [currentTime, setCurrentTime] = useState(0);
	const [duration, setDuration] = useState(0);
	const [isPlaying, setIsPlaying] = useState(false);
	const courseCompleted = useSelector((state) => state.courseCompleted);
	const dispatch = useDispatch();

	const playVideo = () => {
		// Bump timeline to a few seconds before the end
		if (
			isFinite(videoRef.current.duration && videoRef.current.currentTime < 100)
		) {
			videoRef.current.currentTime = videoRef.current.duration - 3.5;
		}

		if (videoRef.current.paused) {
			videoRef.current.play();
			setIsPlaying(true);
		} else {
			videoRef.current.pause();
			setIsPlaying(false);
		}
	};
	const handleVideoEnded = () => {
		setIsPlaying(false);

		// setCompleted(true)
		window.courseCompleted = true;
		launchModal();
	};
	const togglePlay = () => {
		if (isPlaying) {
			videoRef.current.pause();
		} else {
			videoRef.current.play();
		}
		setIsPlaying(!isPlaying);
	};

	useEffect(() => {
		const video = videoRef.current;
		video.addEventListener('timeupdate', handleTimeUpdate);
		video.addEventListener('loadedmetadata', handleLoadedMetadata);

		return () => {
			video.removeEventListener('timeupdate', handleTimeUpdate);
			video.removeEventListener('loadedmetadata', handleLoadedMetadata);
		};
	}, [videoRef]);

	const handleTimeUpdate = (event) => {
		setCurrentTime(event.target.currentTime);
	};

	const handleLoadedMetadata = (event) => {
		setDuration(event.target.duration);
	};

	const handleTimelineClick = (event) => {
		videoRef.current.currentTime = event.target.value;
	};

	return (
		<div style={{ position: 'relative' }}>
			<video
				ref={videoRef}
				poster={
					process.env.PUBLIC_URL + '/images/channels/aws-cloud-practitioner.png'
				}
				onClick={playVideo}
				onEnded={handleVideoEnded}
				playsInline
			>
				<source
					src={src}
					type='video/mp4'
				/>
			</video>

			<div className='timeline'>
				{/* Using `value` instead of `defaultValue` will prevent user input */}
				<input
					type='range'
					id='timeline'
					name='timeline'
					min='0'
					defaultValue={currentTime}
					max={duration}
					onClick={handleTimelineClick}
				/>
				<label
					htmlFor='timeline'
					className='sr-only'
				>
					Time
				</label>
			</div>

			<button
				className={isPlaying ? 'play-btn hidden' : 'play-btn'}
				onClick={playVideo}
			>
				<img
					src={process.env.PUBLIC_URL + '/images/channels/play-button.svg'}
					alt=''
				/>
			</button>
		</div>
	);
};

export default VideoComponent;
