/** @format */

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCourseDetails } from '../../../util/getCourseDetails';
import { setCourseInfo } from '../../../actions/course-details/setCourseInfo';
import Card from './Card';

const CourseCard = (props) => {
	useEffect(() => {
		getCourseDetails(props.courseId).then((data) => {
			props.setCourseInfo(data);
		});
	}, []);

	if (props.course) {
		return (
			<Card modifier='learning'>
				<Link
					className='learning-link'
					to={`/courses/${props.course.id}`}
				>
					<h3 className='course-title'>{props.course.name}</h3>
					<p className='course-instructor'>{props.course.instructor}</p>
					<p className='course-details'>March 2023 - June 2023</p>
				</Link>
			</Card>
		);
	}
};
const mapStateToProps = ({ session, course }) => ({
	session,
	course,
});

const mapDispatchToProps = (dispatch) => ({
	setCourseInfo: (data) => dispatch(setCourseInfo(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseCard);
