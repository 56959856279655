import React, {useEffect, useRef, useState} from "react";
import TabList from "./TabList";
import TabItem from "./TabItem";

const Tabs = (props) => {
    const [selectedTab, setSelectedTab] = useState(props.selected ? props.selected : 0);
    const slider = useRef();
    const tabList = useRef();

    const positionSlider = () => {
        let tabListBox = tabList.current.getBoundingClientRect(),
            tabListBoxLeft = tabListBox.left;

    let activeTabBox = tabList.current.querySelector("[aria-selected=true]").getBoundingClientRect(),
        tabLeft = activeTabBox.left,
        tabWidth = activeTabBox.width;


        slider.current.style.left = tabLeft - tabListBoxLeft + 'px';
        slider.current.style.width = tabWidth + 'px';
    }
    useEffect(() => {
        positionSlider()
        
    },[selectedTab])


    // keyboard functionality
    const onKeyDown = (e) => {
        const tabLength = props.children.length,
            tabButtons = tabList.current.querySelectorAll("button");
        let activeElement = document.activeElement,
            activeIndex = [].indexOf.call(tabButtons,activeElement);

            switch (e.key) {
                case "Left":
                case "ArrowLeft":
                    if (activeIndex === 0) {
                        tabButtons[tabLength-1].focus()
                        setSelectedTab(tabLength-1)     
                    } else {
                        tabButtons[activeIndex -1].focus()
                        setSelectedTab(activeIndex -1)
                    }
                    
                    break;
                case "Right":
                case "ArrowRight":
                    if (activeIndex === tabLength-1) {
                        tabButtons[0].focus()   
                        setSelectedTab(0)  
                    } else {
                        tabButtons[activeIndex+1].focus()
                        setSelectedTab(activeIndex+1)
                    }
                    break;
                default:
                    return;

            }
            // e.preventDefault();
            //arrow left

        //arrow right
    }

    return (
        <div className={`tab-container ${props.modifier ? props.modifier : ""}`}>
            <div className="tab-list-container" ref={tabList} onKeyDown={onKeyDown}>
                <TabList>
                    {props.children.length > 1 ? props.children.map((child, index) => {
                        return (
                            <TabItem key={"tab-" + index}
                                id={child.props.id + "-tab"} panelId={child.props.id} 
                                activeTab={selectedTab === index}
                                select={() => setSelectedTab(index)}
                                
                                >
                                {child.props.label}
                            </TabItem>
                        )
                    }) : 
                    <TabItem key={"tab-0"}
                                id={props.children.id + "-tab"} panelId={props.children.id} 
                                activeTab={selectedTab === 0}
                                select={() => setSelectedTab(0)}
                                >
                                Employees
                            </TabItem>
                    }
                </TabList>
                <div className="tab-slider" ref={slider}></div>
            </div>
            <div className="tab-content">
                {props.children.length > 1 ? props.children.map((child, index) => {
                    if (index !== selectedTab) return undefined;
                    return child.props.children;
                 }) : props.children}
            </div>
        </div>
    )

}
export default Tabs