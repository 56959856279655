/** @format */

import React, { useEffect } from 'react';
import Breadcrumbs from '../global/Breadcrumbs';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import SalaryChart from '../global/infographics/SalaryChart';
import JobsListing from './JobsListing';
import JobListing from './JobListing';
import { getJobs } from '../../util/getJobs';
import { setJobs } from '../../actions/companies/setJobs';
import { connect } from 'react-redux';

const Industry = (props) => {
	useEffect(() => {
		if (!props.jobs) {
			getJobs().then((data) => {
				props.setJobs(data);
			});
		}
	});

	const Accordion = styled(MuiAccordion)(({ theme }) => ({
		border: `none`,
		'&:before': {
			display: 'none',
		},
		boxShadow: 'none',
		borderBottom: '1px solid #ccc',
	}));

	const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
		'& .MuiAccordionSummary-content': {
			margin: theme.spacing(0),
		},
		paddingBottom: '10px',
		marginBottom: '10px',
	}));

	const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
		padding: theme.spacing(1),
	}));

	return (
		<div className={`pillar-page industry`}>
			<section className={'intro dark-bg'}>
				<Breadcrumbs isBack='true' />

				<div className='section-row'>
					<div className='page-info'>
						<div>
							<h1 className='page-title'>Architecture and Engineering</h1>
							<p className='page-desc'>
								The architecture and engineering industry sector involves
								designing and constructing physical structures, such as
								buildings and infrastructure. It includes subfields such as
								civil engineering, mechanical engineering, architecture, and
								interior design, and offers employment opportunities in large
								firms, government agencies, and construction companies. A
								relevant degree, professional licensing, and certifications, as
								well as skills such as creativity, critical thinking, and
								collaboration, are typically required for employment in this
								sector.
							</p>
						</div>
					</div>
				</div>
			</section>
			<section>
				<div className='card'>
					<h2>Fast facts</h2>
					<div className='twoCols'>
						<div>
							<SalaryChart />
						</div>
						<div className='details'>
							<div className='section'>
								<p>
									<b>Growth potential</b>
								</p>
								<p>4% within next 10 years</p>
							</div>
							<div className='section'>
								<p>
									<b>Degree typically needed</b>
								</p>
								<p>Associates or Bachelors</p>
							</div>
							<div className='section'>
								<p>
									<b>Typical employment</b>
								</p>
								<p>Full-time, 40 hours a week</p>
							</div>
						</div>
					</div>
				</div>
				<div className='card'>
					<h2>Common majors</h2>
					<ul className='majors'>
						<li>Architecture</li>
						<li>Engineering</li>
						<li>Construction Management</li>
						<li>Computer Science</li>
						<li>Physics</li>
					</ul>
				</div>
			</section>
			<section>
				<div className='card'>
					<h2>Popular roles and outlook</h2>
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='panel1a-content'
							id='panel1a-header'
						>
							<p>Civil Engineers</p>
						</AccordionSummary>
						<AccordionDetails>
							<p>
								Perform engineering duties in planning, designing, and
								overseeing construction and maintenance of building structures
								and facilities, such as roads, railroads, airports, bridges,
								harbors, channels, dams, irrigation projects, pipelines, power
								plants, and water and sewage systems.
							</p>
							<div className='details'>
								<div className='section'>
									<p>
										<b>Salary range</b>
									</p>
									<p>$88,050</p>
									<p>$60,550 - $133,320</p>
								</div>
								<div className='section'>
									<p>
										<b>Avg annual demand</b>
									</p>
									<p> 24,200 </p>
								</div>
								<div className='section'>
									<p>
										<b>Advanced degree?</b>
									</p>
									<p>Unlikely</p>
								</div>
								<div className='section'>
									<p>
										<b>Common Skills Needed</b>
									</p>
									<ul className='commonSkills'>
										<li>Critical Thinking</li>
										<li>Reading Comprehension</li>
										<li>Active Listening</li>
										<li>Mathematics</li>
										<li>Speaking</li>
										<li>Writing</li>
										<li>Monitoring</li>
										<li>Complex Problem Solving</li>
										<li>Judgment and Decision Making</li>
										<li>Coordination</li>
									</ul>
								</div>
							</div>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='panel2a-content'
							id='panel2a-header'
						>
							<p>Industrial Engineers</p>
						</AccordionSummary>
						<AccordionDetails>
							<p>
								Design, develop, test, and evaluate integrated systems for
								managing industrial production processes, including human work
								factors, quality control, inventory control, logistics and
								material flow, cost analysis, and production coordination.
							</p>
							<div className='details'>
								<div className='section'>
									<p>
										<b>Salary range</b>
									</p>
									<p>$95,300</p>
									<p>$60,850 - $129,620</p>
								</div>
								<div className='section'>
									<p>
										<b>Avg annual demand</b>
									</p>
									<p> 22,400 </p>
								</div>
								<div className='section'>
									<p>
										<b>Advanced degree?</b>
									</p>
									<p>Unlikely</p>
								</div>
								<div className='section'>
									<p>
										<b>Common Skills Needed</b>
									</p>
									<ul className='commonSkills'>
										<li>Critical Thinking</li>
										<li>Reading Comprehension</li>
										<li>Active Listening</li>
										<li>Mathematics</li>
										<li>Speaking</li>
										<li>Writing</li>
										<li>Monitoring</li>
										<li>Complex Problem Solving</li>
										<li>Judgment and Decision Making</li>
										<li>Coordination</li>
									</ul>
								</div>
							</div>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='panel3a-content'
							id='panel3a-header'
						>
							<p>Mechanical Engineers</p>
						</AccordionSummary>
						<AccordionDetails>
							<p>
								Perform engineering duties in planning and designing tools,
								engines, machines, and other mechanically functioning equipment.
								Oversee installation, operation, maintenance, and repair of
								equipment such as centralized heat, gas, water, and steam
								systems.
							</p>
							<div className='details'>
								<div className='section'>
									<p>
										<b>Salary range</b>
									</p>
									<p>$88,050</p>
									<p>$60,550 - $133,320</p>
								</div>
								<div className='section'>
									<p>
										<b>Avg annual demand</b>
									</p>
									<p> 24,200 </p>
								</div>
								<div className='section'>
									<p>
										<b>Advanced degree?</b>
									</p>
									<p>Unlikely</p>
								</div>
								<div className='section'>
									<p>
										<b>Common Skills Needed</b>
									</p>
									<ul className='commonSkills'>
										<li>Critical Thinking</li>
										<li>Reading Comprehension</li>
										<li>Active Listening</li>
										<li>Mathematics</li>
										<li>Speaking</li>
										<li>Writing</li>
										<li>Monitoring</li>
										<li>Complex Problem Solving</li>
										<li>Judgment and Decision Making</li>
										<li>Coordination</li>
									</ul>
								</div>
							</div>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='panel4a-content'
							id='panel4a-header'
						>
							<p>Electrical Engineers</p>
						</AccordionSummary>
						<AccordionDetails>
							<p>
								Research, design, develop, test, or supervise the manufacturing
								and installation of electrical equipment, components, or systems
								for commercial, industrial, military, or scientific use.
							</p>
							<div className='details'>
								<div className='section'>
									<p>
										<b>Salary range</b>
									</p>
									<p>$88,050</p>
									<p>$60,550 - $133,320</p>
								</div>
								<div className='section'>
									<p>
										<b>Avg annual demand</b>
									</p>
									<p> 24,200 </p>
								</div>
								<div className='section'>
									<p>
										<b>Advanced degree?</b>
									</p>
									<p>Unlikely</p>
								</div>
								<div className='section'>
									<p>
										<b>Common Skills Needed</b>
									</p>
									<ul className='commonSkills'>
										<li>Critical Thinking</li>
										<li>Reading Comprehension</li>
										<li>Active Listening</li>
										<li>Mathematics</li>
										<li>Speaking</li>
										<li>Writing</li>
										<li>Monitoring</li>
										<li>Complex Problem Solving</li>
										<li>Judgment and Decision Making</li>
										<li>Coordination</li>
									</ul>
								</div>
							</div>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='panel5a-content'
							id='panel5a-header'
						>
							<p>Architectural Drafters</p>
						</AccordionSummary>
						<AccordionDetails>
							<p>
								Prepare detailed drawings of architectural and structural
								features of buildings or drawings and topographical relief maps
								used in civil engineering projects, such as highways, bridges,
								and public works. Use knowledge of building materials,
								engineering practices, and mathematics to complete drawings.
							</p>
							<div className='details'>
								<div className='section'>
									<p>
										<b>Salary range</b>
									</p>
									<p>$88,050</p>
									<p>$60,550 - $133,320</p>
								</div>
								<div className='section'>
									<p>
										<b>Avg annual demand</b>
									</p>
									<p> 24,200 </p>
								</div>
								<div className='section'>
									<p>
										<b>Advanced degree?</b>
									</p>
									<p>Unlikely</p>
								</div>
								<div className='section'>
									<p>
										<b>Common Skills Needed</b>
									</p>
									<ul className='commonSkills'>
										<li>Critical Thinking</li>
										<li>Reading Comprehension</li>
										<li>Active Listening</li>
										<li>Mathematics</li>
										<li>Speaking</li>
										<li>Writing</li>
										<li>Monitoring</li>
										<li>Complex Problem Solving</li>
										<li>Judgment and Decision Making</li>
										<li>Coordination</li>
									</ul>
								</div>
							</div>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='panel6a-content'
							id='panel6a-header'
						>
							<p>Electrical Engineering Technicians</p>
						</AccordionSummary>
						<AccordionDetails>
							<p>
								Apply electrical and electronic theory and related knowledge,
								usually under the direction of engineering staff, to design,
								build, repair, adjust, and modify electrical components,
								circuitry, controls, and machinery for subsequent evaluation and
								use by engineering staff in making engineering design decisions.
							</p>
							<div className='details'>
								<div className='section'>
									<p>
										<b>Salary range</b>
									</p>
									<p>$88,050</p>
									<p>$60,550 - $133,320</p>
								</div>
								<div className='section'>
									<p>
										<b>Avg annual demand</b>
									</p>
									<p> 24,200 </p>
								</div>
								<div className='section'>
									<p>
										<b>Advanced degree?</b>
									</p>
									<p>Unlikely</p>
								</div>
								<div className='section'>
									<p>
										<b>Common Skills Needed</b>
									</p>
									<ul className='commonSkills'>
										<li>Critical Thinking</li>
										<li>Reading Comprehension</li>
										<li>Active Listening</li>
										<li>Mathematics</li>
										<li>Speaking</li>
										<li>Writing</li>
										<li>Monitoring</li>
										<li>Complex Problem Solving</li>
										<li>Judgment and Decision Making</li>
										<li>Coordination</li>
									</ul>
								</div>
							</div>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='panel7a-content'
							id='panel7a-header'
						>
							<p>Architects</p>
						</AccordionSummary>
						<AccordionDetails>
							<p>
								Plan and design structures, such as private residences, office
								buildings, theaters, factories, and other structural property.
							</p>
							<div className='details'>
								<div className='section'>
									<p>
										<b>Salary range</b>
									</p>
									<p>$88,050</p>
									<p>$60,550 - $133,320</p>
								</div>
								<div className='section'>
									<p>
										<b>Avg annual demand</b>
									</p>
									<p> 24,200 </p>
								</div>
								<div className='section'>
									<p>
										<b>Advanced degree?</b>
									</p>
									<p>Unlikely</p>
								</div>
								<div className='section'>
									<p>
										<b>Common Skills Needed</b>
									</p>
									<ul className='commonSkills'>
										<li>Critical Thinking</li>
										<li>Reading Comprehension</li>
										<li>Active Listening</li>
										<li>Mathematics</li>
										<li>Speaking</li>
										<li>Writing</li>
										<li>Monitoring</li>
										<li>Complex Problem Solving</li>
										<li>Judgment and Decision Making</li>
										<li>Coordination</li>
									</ul>
								</div>
							</div>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='panel8a-content'
							id='panel8a-header'
						>
							<p>Electronics Engineers</p>
						</AccordionSummary>
						<AccordionDetails>
							<p>
								Research, design, develop, or test electronic components and
								systems for commercial, industrial, military, or scientific use
								employing knowledge of electronic theory and materials
								properties. Design electronic circuits and components for use in
								fields such as telecommunications, aerospace guidance and
								propulsion control, acoustics, or instruments and controls.
							</p>
							<div className='details'>
								<div className='section'>
									<p>
										<b>Salary range</b>
									</p>
									<p>$88,050</p>
									<p>$60,550 - $133,320</p>
								</div>
								<div className='section'>
									<p>
										<b>Avg annual demand</b>
									</p>
									<p> 24,200 </p>
								</div>
								<div className='section'>
									<p>
										<b>Advanced degree?</b>
									</p>
									<p>Unlikely</p>
								</div>
								<div className='section'>
									<p>
										<b>Common Skills Needed</b>
									</p>
									<ul className='commonSkills'>
										<li>Critical Thinking</li>
										<li>Reading Comprehension</li>
										<li>Active Listening</li>
										<li>Mathematics</li>
										<li>Speaking</li>
										<li>Writing</li>
										<li>Monitoring</li>
										<li>Complex Problem Solving</li>
										<li>Judgment and Decision Making</li>
										<li>Coordination</li>
									</ul>
								</div>
							</div>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='panel9a-content'
							id='panel9a-header'
						>
							<p>Surveying Technicians</p>
						</AccordionSummary>
						<AccordionDetails>
							<p>
								Perform surveying and mapping duties, usually under the
								direction of an engineer, surveyor, cartographer, or
								photogrammetrist, to obtain data used for construction,
								mapmaking, boundary location, mining, or other purposes. May
								calculate mapmaking information and create maps from source
								data, such as surveying notes, aerial photography, satellite
								data, or other maps to show topographical features, political
								boundaries, and other features. May verify accuracy and
								completeness of maps.
							</p>
							<div className='details'>
								<div className='section'>
									<p>
										<b>Salary range</b>
									</p>
									<p>$88,050</p>
									<p>$60,550 - $133,320</p>
								</div>
								<div className='section'>
									<p>
										<b>Avg annual demand</b>
									</p>
									<p> 24,200 </p>
								</div>
								<div className='section'>
									<p>
										<b>Advanced degree?</b>
									</p>
									<p>Unlikely</p>
								</div>
								<div className='section'>
									<p>
										<b>Common Skills Needed</b>
									</p>
									<ul className='commonSkills'>
										<li>Critical Thinking</li>
										<li>Reading Comprehension</li>
										<li>Active Listening</li>
										<li>Mathematics</li>
										<li>Speaking</li>
										<li>Writing</li>
										<li>Monitoring</li>
										<li>Complex Problem Solving</li>
										<li>Judgment and Decision Making</li>
										<li>Coordination</li>
									</ul>
								</div>
							</div>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='panel10a-content'
							id='panel10a-header'
						>
							<p>Computer Hardware Engineers</p>
						</AccordionSummary>
						<AccordionDetails>
							<p>
								Research, design, develop, or test computer or computer-related
								equipment for commercial, industrial, military, or scientific
								use. May supervise the manufacturing and installation of
								computer or computer-related equipment and components
							</p>
							<div className='details'>
								<div className='section'>
									<p>
										<b>Salary range</b>
									</p>
									<p>$88,050</p>
									<p>$60,550 - $133,320</p>
								</div>
								<div className='section'>
									<p>
										<b>Avg annual demand</b>
									</p>
									<p> 24,200 </p>
								</div>
								<div className='section'>
									<p>
										<b>Advanced degree?</b>
									</p>
									<p>Unlikely</p>
								</div>
								<div className='section'>
									<p>
										<b>Common Skills Needed</b>
									</p>
									<ul className='commonSkills'>
										<li>Critical Thinking</li>
										<li>Reading Comprehension</li>
										<li>Active Listening</li>
										<li>Mathematics</li>
										<li>Speaking</li>
										<li>Writing</li>
										<li>Monitoring</li>
										<li>Complex Problem Solving</li>
										<li>Judgment and Decision Making</li>
										<li>Coordination</li>
									</ul>
								</div>
							</div>
						</AccordionDetails>
					</Accordion>
				</div>
			</section>
			<section>
				<h2>New jobs in this category</h2>
				<JobsListing>
					{!props.jobs ? (
						<div>loading...</div>
					) : (
						props.jobs.map((job, i) => {
							if (i < 4) {
								return (
									<JobListing
										key={i + '-job'}
										data={job}
									/>
								);
							}
						})
					)}
				</JobsListing>
			</section>
		</div>
	);
};

const mapStateToProps = ({ session, jobs }) => ({
	session,
	jobs,
});

const mapDispatchToProps = (dispatch) => ({
	setJobs: (data) => dispatch(setJobs(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Industry);
