import {connect, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {useState, useEffect} from "react";
import Icon from "../global/Icon";
import PageWrapper from "../global/PageWrapper";
import Tabs from "../global/tabs/Tabs";
import TabPanel from "../global/tabs/TabPanel";
import courseVideo from "../../assets/videos/accp_01_02_01_00.mp4";
import VideoComponent from "../global/video/VideoComponent";
import Modal from "../global/Modal";
import Breadcrumbs from "../global/Breadcrumbs";

import bookMarkOn from '../../assets/images/channels/bookmark-on-16.svg'
import bookMarkOff from '../../assets/images/channels/bookmark-off-16.svg'
import {setCourseComplete} from "../../actions/channels/setCourseComplete";
import { setScores } from "../../actions/dashboard/setScores";
import { setExamScheduled } from "../../actions/channels/setExamScheduled";

function ChannelsVideo(props) {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const [videoDone, setVideoDone] = useState(false)
  const [modalopen, setmodalopen] = useState(false);
  
  const handleBack = (event) => {
    event.preventDefault();
    navigate('/channels');
  };
  const handleSchedule = (event) => {
    event.preventDefault();
    props.setExamScheduled(true)
    handleReturn(event)
  };

  useEffect(()=> {
	  if (props.courseComplete && !props.examScheduled) {
		  navigate('/dashboard');
	  } else if (props.courseComplete && props.examScheduled) {
      navigate('/exam/AWS-CL-FD');
    }
  }, [props.courseComplete])

  const Bookmark = ({ toggled, onClick }) => {
    const [isToggled, toggle] = useState(toggled)
    const callback = () => {
      toggle(!isToggled)
      onClick(!isToggled)
    }
    return (
      <button className="bookmark" aria-pressed={isToggled} onClick={callback}>
        <img
          className="bookmark-off"
          src={bookMarkOff}
          alt="Not bookmarked"
        />
        <img
          className="bookmark-on"
          src={
            bookMarkOn
          }
          alt="Bookmarked"
        />
        Bookmark
      </button>
    )
  }

function handleReturn () {
    // set the additional score here
    if(props.scores){
      const newScore = { ...props.scores }
      newScore[0].score += 5
      newScore[0].education += 3
      newScore[0].ability += 2
      props.setScores(newScore)

    }
    props.setCourseComplete(true)
}
  const endVideo = () => {
    setVideoDone(true)
    setTimeout(() => {setmodalopen(true)},250)
    // TODO: add a credential, add to future fit -- how do we pass to dashboard?

    // setmodalopen(true)
  }
  return (
    <>
      <PageWrapper pageType="channels">
        <section
          className="intro video-page"

        >
          {/* <h1>Good {timeString} {auth.userdata.firstName}!</h1> */}
          {/* <h2>Employee summary</h2> */}

          {/* <div className="breadcrumb-container">
            <button
              className=""
              onClick={(event) => {
                event.stopPropagation();
                handleBack(event);
              }}
            >
              <i className="fa fa-arrow-left" aria-hidden="true"></i>Back
            </button>
          </div> */}
          <Breadcrumbs isBack="true"/>
          {/* <h1
            style={{ color: "white", textAlign: "center", marginTop: "50px" }}
          >
            AWS Academy Cloud Foundations (CLF-C01)
          </h1>
          <p style={{ color: "white" }}>Introduction</p> */}
        </section>

        <section className="channels">
          <div className="video-player">
            <div className="video-container">
              {/* <img
                className="video-img"
                src={process.env.PUBLIC_URL + "/images/channels/vid1.png"}
                alt="2.1: Foundational Networking Security Technologies"
              /> */}
              {/* <VideoPlayer
              src={courseVideo}
              /> */}
              <VideoComponent launchModal={endVideo} src={courseVideo} />
            </div>
            <div className="video-details">
              <h4 className="video-title">Introduction</h4>
              <p className="video-author">By Richard A. Jones</p>
              {/* <p className="video-author-avatar">mJ</p> */}
              <img
                className="video-author-avatar"
                src="https://cite-media.pearson.com/legacy_paths/8ff53178-bd43-421c-b54d-09783a780a35/RichardJones_aug2020.jpg"
              />
              <ul className="video-meta">
                <li>15 views</li>
                <li>5 upvotes</li>
                <li>16 comments</li>
              </ul>
            </div>
          </div>

          <div className="video-buttons-group">
            <div className="vote-group">
              <button className="vote-up">
                <Icon icon="vote-up" size="16" />
                <span className="count">473</span>
                <span className="sr-only">Vote up</span>
              </button>
              <button className="vote-down">
                <Icon icon="vote-down" size="16" />
                <span className="sr-only">Vote down</span>
              </button>
            </div>

            <div className={`complete-indicator ${videoDone ? "completed" : ""}`}>
              <img
                className="video-not-complete"
                src={
                  process.env.PUBLIC_URL +
                  "/images/channels/complete-off-16.svg"
                }
                alt="Video not completely watched"
              />
              <img
                className="video-complete"
                src={
                  process.env.PUBLIC_URL + "/images/channels/complete-on-16.svg"
                }
                alt="Video completely watched"
              />
              Complete
            </div>

            <Bookmark
              toggled={false}
            />
          </div>

          <div className="comments-abbreviated">
            <h2>Comments 473</h2>
            <div className="summary">
              <p className="author-avatar">mJ</p>
              <p>
                Isn't that t-value for a 95% CI? The question asks for a value
                from an…
              </p>
            </div>
          </div>
        </section>

        <section className="channels">
          <div className="section-body">
            <Tabs modifier="channels">
              <TabPanel label="Related Videos &nbsp;8" id="relatedVideos">
                <ul className="related-videos">
                  <li>
                    <div className="poster">
                      <img
                        className="video-img"
                        src={
                          process.env.PUBLIC_URL + "/images/channels/vid1.png"
                        }
                        alt="2.1: Foundational Networking Security Technologies"
                      />
                      <img
                        className="play-btn"
                        src={
                          process.env.PUBLIC_URL +
                          "/images/channels/play-button.svg"
                        }
                        alt=""
                      />
                    </div>
                    <div className="video-details">
                      <p className="video-title">
                        2.1: Foundational Networking Security Technologies
                      </p>
                      <ul className="video-meta">
                        <li>15 views</li>
                        <li>5 upvotes</li>
                        <li>16 comments</li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div className="poster">
                      <img
                        className="video-img"
                        src={
                          process.env.PUBLIC_URL + "/images/channels/vid2.png"
                        }
                        alt="Basic terminology and concepts of Anatomy and Physiology"
                      />
                      <img
                        className="play-btn"
                        src={
                          process.env.PUBLIC_URL +
                          "/images/channels/play-button.svg"
                        }
                        alt=""
                      />
                    </div>
                    <div className="video-details">
                      <p className="video-title">
                        Basic terminology and concepts of Anatomy and Physiology
                      </p>
                      <ul className="video-meta">
                        <li>15 views</li>
                        <li>5 upvotes</li>
                        <li>16 comments</li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div className="poster">
                      <img
                        className="video-img"
                        src={
                          process.env.PUBLIC_URL + "/images/channels/vid1.png"
                        }
                        alt="Lab - Section 2.2"
                      />
                      <img
                        className="play-btn"
                        src={
                          process.env.PUBLIC_URL +
                          "/images/channels/play-button.svg"
                        }
                        alt=""
                      />
                    </div>
                    <div className="video-details">
                      <p className="video-title">Lab - Section 2.2</p>
                      <ul className="video-meta">
                        <li>15 views</li>
                        <li>5 upvotes</li>
                        <li>16 comments</li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div className="poster">
                      <img
                        className="video-img"
                        src={
                          process.env.PUBLIC_URL + "/images/channels/vid2.png"
                        }
                        alt="Basic terminology and concepts of Anatomy and Physiology"
                      />
                      <img
                        className="play-btn"
                        src={
                          process.env.PUBLIC_URL +
                          "/images/channels/play-button.svg"
                        }
                        alt=""
                      />
                    </div>
                    <div className="video-details">
                      <p className="video-title">
                        Basic terminology and concepts of Anatomy and Physiology
                      </p>
                      <ul className="video-meta">
                        <li>15 views</li>
                        <li>5 upvotes</li>
                        <li>16 comments</li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </TabPanel>
              <TabPanel label="Related Practice &nbsp;2" id="relatedPractice">
                <p>Panel 2</p>
              </TabPanel>
            </Tabs>
          </div>
        </section>
      </PageWrapper>
      <Modal open={modalopen} setModalOpen={setmodalopen} targetid={"root"}>
        <div className="congratulate-modal" style={{textAlign:"center"}}>
            <h1 className="text-h3">Congratulations on completing AWS Academy Cloud Foundations course!</h1>
                <div className="badge">
                  <img src="https://images.credly.com/size/340x340/images/73e4a58b-a8ef-41a3-a7db-9183dd269882/image.png" alt="badge"/>
                </div>
                <p>You are now ready to take the official certification exam to earn your credential.</p>
                <div className="button-row">
                  <button onClick={handleReturn} className="link-btn">Return to dashboard</button>
                  <button onClick={handleSchedule} className="button button-primary">Schedule an exam</button>
                </div>
				

        </div>

            </Modal>
    </>
  );
}

const mapStateToProps = ({session, scores, courseComplete, examScheduled}) => ({
	scores,
  courseComplete,
  examScheduled,
});

const mapDispatchToProps = dispatch => ({
	setScores: (data) => dispatch(setScores(data)),
	setCourseComplete: (data) => dispatch(setCourseComplete(data)),
  setExamScheduled: (data) => dispatch(setExamScheduled(data)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ChannelsVideo);
