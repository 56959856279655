import React, {useEffect, useRef, useState} from "react";
import TabList from "../tabs/TabList";
import TabItem from "../tabs/TabItem";

const ChartSwitcher = (props) => {
    const [showChart, setShowChart] = useState(props.visible);
    const [selectedTab, setSelectedTab] = useState(props.selected);
    const slider = useRef();
    const tabList = useRef();


    // keyboard functionality
    const onKeyDown = (e) => {
        const tabLength = props.children.length,
            tabButtons = tabList.current.querySelectorAll("button");
        let activeElement = document.activeElement,
            activeIndex = [].indexOf.call(tabButtons,activeElement);

            switch (e.key) {
                case "Left":
                case "ArrowLeft":
                    if (activeIndex === 0) {
                        tabButtons[tabLength-1].focus()
                        setSelectedTab(tabLength-1)     
                    } else {
                        tabButtons[activeIndex -1].focus()
                        setSelectedTab(activeIndex -1)
                    }
                    
                    break;
                case "Right":
                case "ArrowRight":
                    if (activeIndex === tabLength-1) {
                        tabButtons[0].focus()   
                        setSelectedTab(0)  
                    } else {
                        tabButtons[activeIndex+1].focus()
                        setSelectedTab(activeIndex+1)
                    }
                    break;
                default:
                    return;

            }
            // e.preventDefault();
            //arrow left

        //arrow right
    }

    return (
        <div className="tab-container chart-switcher" >
            <div className="tab-content" style={{minHeight:"405px"}}>
                {props.visible && props.children.map((child, index) => {
                    if (index !== selectedTab) return undefined;
                    return child.props.children;
                 })}
            </div>
            <div className="tab-list-container" ref={tabList} onKeyDown={onKeyDown}>
                <TabList>
                    {props.children.map((child, index) => {
                        return (
                            <TabItem key={"tab-" + index}
                                id={child.props.id + "-tab"} panelId={child.props.id} 
                                activeTab={selectedTab === index}
                                select={() => setSelectedTab(index)}
                                >
                                <span dangerouslySetInnerHTML={{__html: child.props.label}}/>
                            </TabItem>
                        )
                    })}
                </TabList>
            </div>
            
        </div>
    )

}
export default ChartSwitcher