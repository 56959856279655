import React, {useState} from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";



const DonutChart = props => {
    const [data, setData] = useState(props.data);

    const colors = ["#1F897C","#309CBD", "#655591","#C55554","#E7EBEC"]

  
    return (
        
            <ResponsiveContainer width="100%" height={300}>
                <PieChart width={400} height={400}>
                <text
                    x='50%'
                    y='46%'
                    dy={+12}
                    style={{ fontSize: 40, fontWeight: 'bold', fill: 'var(--color-black)' }}
                    width={200}
                    textAnchor='middle'
                >
                    85%
                </text>
                <text
                    x='50%'
                    y='58%'
                    style={{ fontSize: 18, fill: 'var(--color-black)' }}
                    width={200}
                    
                    textAnchor='middle'
                >
                    Participation
                </text>
                    <Pie data={data} dataKey="value" cx="50%" cy="50%" innerRadius={80} outerRadius={120} fill="#82ca9d" startAngle={90} endAngle={-360} paddingAngle={1}>
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
   
    );
  
}
export default DonutChart
