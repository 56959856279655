import {SET_LICENSES} from "../../actions/licenses/setLicenses";

export default (state = null, {type, licenses = null }) => {
	Object.freeze(state);
	switch (type) {
		case SET_LICENSES:
			return licenses;
		default:
			return state;
	}
};
