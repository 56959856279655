import {SET_JOB_TAGS} from "../../actions/dashboard/setJobTags";

export default (state = null, {type, jobTags = null }) => {
	Object.freeze(state);
	switch (type) {
		case SET_JOB_TAGS:
			return jobTags;
		default:
			return state;
	}
};
