import React, {useRef, useEffect, useState} from 'react';
import {setScores} from "../../../actions/dashboard/setScores";
import ProgressRing from "./../../global/ProgressRing";
import {getFutureFitScore} from "../../../util/getFutureFitScore";
import {setCourseComplete} from "../../../actions/channels/setCourseComplete";
import LineChartScoreD from './../../global/infographics/LineChartScoreDark';
import {connect} from "react-redux";
import ReactCanvasConfetti from 'react-canvas-confetti';
import { Link, useNavigate } from 'react-router-dom';
import { setExamScheduled } from '../../../actions/channels/setExamScheduled';

function FitScore(props) {
	const [showConfetti, setShowConfetti] = useState(false);
	const cardContainer = useRef();
	const navigate = useNavigate()

	useEffect(()=> {
		if(!props.scores){
			getFutureFitScore(props.session.userId).then(data => {
				props.setScores(data)
			})	
		}
	},[])

	useEffect(()=> {
		if (props.courseComplete) {
			setTimeout(() => {
				setShowConfetti(true)
			},500)
		}
	},[])

	useEffect(() => {
		// This function will run when the component unmounts
		return () => {
			props.setCourseComplete(false);
		};
	}, []);

	const schedule = (e) => {
		e.preventDefault()
		navigate('/exam/AWS-CL-FD')
	}

	if (props.courseComplete) {
		return (

			<section className="intro">
				<h1>Welcome, {props.session.firstName}</h1>
				<div className='card-container' ref={cardContainer}>
					<ReactCanvasConfetti className='confetti' fire={showConfetti}/>
					<section className='top-card card--visible'>
						<div className='score-container'>
							<ProgressRing id="oldScore" size="120"  stroke="12" value={props.scores ? props.scores[0].score : 0} ariaLabel={props.scores ? props.scores[0].score : 0} dark={true} />
							<span className='chip-highlight plum'>+5</span>
						</div>

						<h2 style={{margin:0}}>Congratulations!</h2>
						<p style={{margin:0}}>You just earned five more points toward your Future Fit Score!</p>
						<div className="badge">
							<div className="image">
								<img src={'https://images.credly.com/size/340x340/images/73e4a58b-a8ef-41a3-a7db-9183dd269882/image.png'} />
							</div>
							<button className="button button-primary">Share</button>
						</div>
					</section>
				</div>
				{
					!props.examScheduled ?
					<section className='card' style={{textAlign:"center"}}>
						<h3><strong>Gain your credential</strong></h3>
						<p>You are now ready to take the official certification exam for AWS Academy Cloud Foundations to earn your credential.</p>
						<button className="button button-primary" onClick={schedule}>Sign up for an exam</button>
					</section> :
					<section className="card" style={{textAlign:"center"}}>
						<h3>AWS Exam Scheduled</h3>
						<p>You have scheduled an exam at your local test center. Be sure to study up and get plenty of rest before your exam.</p>
						<button className="button button-primary">Review materials</button>
					</section>
				}
				{/* <button onClick={() => setCourseCompleted(true)}>Fake complete course</button> */}
			</section>
		)
	} else {
		return (
			<section className="intro">
				<h1>Welcome, {props.session.firstName}</h1>
				<div className='card-container' ref={cardContainer}>
					<ReactCanvasConfetti className='confetti' fire={false}/>
					<section className='top-card card--visible'>
						<Link to={process.env.PUBLIC_URL+"/pathways"} className="unstyled-link">
							<div className='score-container'>
								{/* <FitScore value={"673"} play={true} dark={true}/> */}
								<ProgressRing id="oldScore" size="120"  stroke="12" value={props.scores ? props.scores[0].score : 0} ariaLabel={props.scores ? props.scores[0].score : 0} dark={true} />
							</div>
							<h2 className="progress-label text-lg">Future Fit Score</h2>
							<div className="graph-container infographic">
								<LineChartScoreD data={props.scores ? props.scores[0].score : 0}/>
							</div>
						</Link>
					</section>
				</div>
				{
					!props.examScheduled ?
					<></> :
					<section className="card" style={{textAlign:"center"}}>
						<h3>AWS Exam Scheduled</h3>
						<p>You have scheduled an exam at your local test center. Be sure to study up and get plenty of rest before your exam.</p>
						<button className="button button-primary">Review materials</button>
					</section>
				}
			</section>
		)
	}

}

const mapStateToProps = ({session,scores, courseComplete, examScheduled}) => ({
	session, scores, courseComplete, examScheduled,
});

const mapDispatchToProps = dispatch => ({
	setScores: (data) => dispatch(setScores(data)),
	setCourseComplete: (data) => dispatch(setCourseComplete(data)),
	setExamScheduled: (data) => dispatch(setExamScheduled(data)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(FitScore);
