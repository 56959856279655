/** @format */

import React from 'react-redux';
import { connect } from 'react-redux';

const Notifications = (props) => {
	const notifications = [
		{
			title: 'New Job Opportunity',
			description:
				'Sales Executive Advertising • 52,000 • Lecanto, FL • Hybrid',
			date: '1/14/23',
			icon: 'credly.png',
			new: true,
		},
		{
			title: 'Credential Expired',
			description:
				'‘AWS Certified Cloud Practitioner’ has expired. Tap here to view.',
			date: '1/7/23',
			icon: 'alert.png',
			new: false,
		},
		{
			title: 'Credential Recommendation • Bill Gates',
			description:
				'‘Python for Data Science’ has been recommended by your manager.',
			date: '12/29/22',
			icon: 'microsoft.png',
			new: false,
		},
		{
			title: 'Complete your profile!',
			description:
				'Do you have a minute? Fill out your Career Preferences for us!',
			date: '12/19/22',
			icon: 'credly.png',
			new: false,
		},
		{
			title: 'New Credential',
			description:
				'‘Adobe Creative Educator L...’ has been issued to you. Tap here to view.',
			date: '12/14/22',
			icon: 'credential.png',
			new: false,
		},
	];

	return (
		<div className={'pageWrapper notifications'}>
			<main>
				<section className='intro dark-bg'>
					<h1 className='text-h3'>Notifications</h1>
				</section>
				<section>
					{notifications.map((notification, index) => {
						return (
							<article
								className='notification'
								key={'notif' + index}
							>
								<div className='icon'>
									<img
										src={
											process.env.PUBLIC_URL +
											'/images/notifications/' +
											notification.icon
										}
										alt=''
									/>
								</div>
								<div className='notification-info'>
									<h2>{notification.title}</h2>
									<p>{notification.description}</p>
									<p className='date'>{notification.date}</p>
								</div>
								<div className='notification-status'>
									{notification.new && <span className='status new' />}
								</div>
							</article>
						);
					})}
				</section>
			</main>
		</div>
	);
};

const mapStateToProps = ({ session }) => ({
	session,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
