/** @format */

import { SET_ALL_COURSES } from '../../actions/course-details/setAllCourses';

export default (state = null, { type, allCourses = null }) => {
	Object.freeze(state);
	switch (type) {
		case SET_ALL_COURSES:
			return allCourses;
		default:
			return state;
	}
};
