import React, {useEffect, useRef, useState} from "react";
import {useNavigate}  from "react-router-dom";
import {signin} from "../../actions/session/signIn";
import {connect} from "react-redux";
import Toast from "../global/Toast";
import Footer from "../global/Footer";
import SpinnerButton from "../global/SpinnerButton";
import AUTH_BASE_URL from "../../util/AUTH_BASE_URL";

// MSAL Login
import {useIsAuthenticated} from "@azure/msal-react";
// import {useFetchUser} from "../global/hooks/useFetchUser";

import Logo from "../../assets/icons/Logo_reverse.svg";
const SignIn = (props) => {
  const passWordInput = useRef(null)

  const [showToastMessage, setShowToast] = useState(null);
  const [loadingState, setLoadingState] = useState(false);
	const [username, setUsername] = useState("")
	const [password, setPassword] = useState("")
  const [user, setUser] = useState(null)

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    const user = {
      username,
      password,
    };

    props.signin(user).then(async (response) => {
		  console.log(response.type)
      if (response.type !== "RECEIVE_CURRENT_USER") {
        let data;
        if (response.json) {
          data = await response.json();
        } else {
          return;
        }
        if (!data.user) {
          setLoadingState(false);
          setShowToast(data.message);
          setTimeout(() => {
            setShowToast(null);
          }, 3000);
        }
      }
    });
  };

  const handleCloseToast = () => {
    setShowToast(null);
  };

  const handleBlur = async (e) => {
    e.preventDefault()
    // fetch the uers info and then set the enterprise state plus logo
    const data = await fetch(`${AUTH_BASE_URL}auth/user/email/${username}`)
    const u = await data.json()
    setUser(u)
  }

  useEffect(() => {
    console.log(passWordInput)
    if(user && passWordInput.current){
      passWordInput.current.focus()
    }
  }, [user, passWordInput])

  return (
    <div className="auth-page sign-in">
      {showToastMessage != null ? (
        <Toast
          closeToast={handleCloseToast}
          message={showToastMessage}
          type="alert"
        />
      ) : null}
		<div className="login">
			<div className="logo">
				<img src={Logo}/>
			</div>
			<form >
				{
          user && user.user.type == "enterprise" && user.company ?
          <img src={user.company.photo.url} /> :
          <></>
        }
				<div className="form-input">
					<label htmlFor="username">Email address:</label>
					<input id="username" type="text" className="gr-input" 
               onChange={e => setUsername(e.target.value)}
						   value={username} autoComplete="off" autoCorrect="off" autoCapitalize="off"
						   spellCheck="false" autoFocus />
				</div>

        {
          user ? 
          <>
            <div className="form-input">
              <label htmlFor="password">Password:</label>
              <input id="password" type="password" className="gr-input"
                  onChange={e => setPassword(e.target.value)} value={password} ref={passWordInput} />
            </div>
            <br />
            <button onClick={handleSubmit} type="submit" className="button-primary">Login</button>
          </> :
          <>
            <br />
            <button onClick={handleBlur} type="submit" className="button-primary">Continue</button>
          </>
        }

			</form>
			<Footer />
		</div>
    </div>
  );
};

const mapStateToProps = ({ session }) => ({
  session,
});

const mapDispatchToProps = (dispatch) => ({
  signin: (user) => dispatch(signin(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
