import {SET_RECOMMENDATIONS} from "../../actions/dashboard/setRecomendations";

export default (state = null, {type, recommendations = null }) => {
	Object.freeze(state);
	switch (type) {
		case SET_RECOMMENDATIONS:
			return recommendations;
		default:
			return state;
	}
};
