import {SET_PROFILE} from "../../actions/pathways/setProfile";

export default (state = null, {type, profile = null }) => {
	Object.freeze(state);
	switch (type) {
		case SET_PROFILE:
			return profile;
		default:
			return state;
	}
};
