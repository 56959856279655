/** @format */

import React, { useEffect, useState, useRef } from 'react';
import Icon from './Icon';

const Carousel = (props) => {
	const [shift, setShift] = useState(0);
	const [styles, setStyles] = useState({});
	const [disableRight, setDisableRight] = useState(false);

	const list = useRef();

	useEffect(() => {
		function handleResize() {
			if (window.innerWidth < 1025) {
				setShift(0);
				setStyles({});
				setDisableRight(false);
			}
		}
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	});

	const translate = (dir) => {
		const listBox = list.current.getBoundingClientRect(),
			listParent = list.current.parentNode.offsetWidth,
			translateBy = +props.size + 10,
			maxShift = listBox.width - listParent;
		let translateAmmount;

		if (dir === 'right') {
			setShift(shift + 1);
			if (translateBy * (shift + 1) > maxShift) {
				translateAmmount = '-' + maxShift;
				setDisableRight(true);
			} else {
				translateAmmount = '-' + translateBy * (shift + 1);
			}
		} else {
			setShift(shift - 1);
			translateAmmount = '-' + translateBy * (shift - 1);
			setDisableRight(false);
		}
		setStyles({ transform: 'translateX(' + translateAmmount + 'px)' });
	};

	return (
		<div
			className={`card-carousel ${
				shift === 0 ? 'firstSlide' : disableRight ? 'lastSlide' : ''
			}  ${props.modifier ? props.modifier : ''}`}
		>
			<button
				className='icon-btn circle-btn prev-btn'
				onClick={() => translate('left')}
				aria-label='Previous'
				disabled={shift === 0}
			>
				<Icon
					icon='chevron-left'
					size='24'
				/>
			</button>
			<div className={`card-container`}>
				<ul
					style={styles}
					ref={list}
				>
					{props.children.map((child, index) => {
						return (
							<li
								key={'card' + index}
								style={{ width: `${props.size}px` }}
							>
								{child}
							</li>
						);
					})}
				</ul>
			</div>
			<button
				className='icon-btn circle-btn next-btn'
				onClick={() => translate('right')}
				aria-label='Next'
				disabled={disableRight}
			>
				<Icon
					icon='chevron-right'
					size='24'
				/>
			</button>
		</div>
	);
};
export default Carousel;
