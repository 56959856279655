/** @format */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import PageWrapper from '../global/PageWrapper';
import { getCourseDetails } from '../../util/getCourseDetails';
import { setCourseInfo } from '../../actions/course-details/setCourseInfo';
import GoBack from '../global/GoBack';
import CourseAccordion from './CourseAccordion';
import CourseCountdown from '../coursedetails/CourseCountdown';

function CourseDetails(props) {
	const params = useParams();
	const { id } = params;
	const { courseId } = 'AWS-CL-FD';
	const [purchased, setPurchased] = useState(false);

	useEffect(() => {
		if (!props.course) {
			getCourseDetails('AWS-CL-FD').then((data) => {
				props.setCourseInfo(data);
			});
		} else {
			let courseData = { ...props.course };
			//props.course exists
			//check for started

			if (courseData.started >= 0) {
				courseData.started = courseData.started + 1;
			} else {
				courseData.started = 0;
			}
			console.log(courseData.started);
			props.setCourseInfo(courseData);
		}
	}, [courseId]);

	const jumpToFuture = () => {
		let courseData = props.course;

		courseData.started = true;

		props.setCourseInfo(courseData);
	};

	if (props.course) {
		return (
			<>
				<PageWrapper
					pageType='channel'
					activePage='coursedetails'
					hideNav
				>
					<div className='pillar-page learning'>
						<section
							className='intro dark-gradient-bg'
							style={{ textAlign: 'center' }}
						>
							<GoBack />
							<div className='section-row'>
								<div className='page-info'>
									<div
										className='badge'
										style={{ margin: 0 }}
									>
										<img
											src={props.course.image}
											alt=''
										/>
									</div>
									<div>
										<h1 className='page-title'>{props.course.name}</h1>
										<p className=''>
											Issued by:{' '}
											<Link
												className=''
												to={'/issuer/' + props.course.slug}
											>
												{props.course.issuer}
											</Link>
										</p>
									</div>
								</div>
							</div>
							<div style={{ width: '100%' }}>
								{props.course.started && props.course.started > 1 ? (
									<Link
										className='button button-primary'
										to={`/channels/video`}
									>
										Continue learning
									</Link>
								) : props.course.started ? (
									<Link
										className='button button-primary'
										to={`/channels/video`}
									>
										Start learning
									</Link>
								) : (
									<CourseCountdown
										total='72'
										units='hours'
										remaining='42'
									/>
								)}
							</div>
						</section>
						{props.course.started ? (
							<section>
								<CourseAccordion title='AWS Cloud Foundations'>
									<ul>
										<li>
											<a>Introduction</a>
										</li>
									</ul>
								</CourseAccordion>
								<CourseAccordion title='Lesson 1 Manage identity and access'>
									<ul>
										<li>
											<a>Lesson 1 introduction</a>
										</li>
										<li>
											<a>Skill 1.1</a>
										</li>
										<li>
											<a>Skill 1.2</a>
										</li>
										<li>
											<a>Summary</a>
										</li>
										<li>
											<a>Case study exercise</a>
										</li>
										<li>
											<a>Quiz</a>
										</li>
									</ul>
								</CourseAccordion>
								<CourseAccordion title='Lesson 2 Implement platform protection'>
									<ul>
										<li>
											<a>Lesson 2 introduction</a>
										</li>
										<li>
											<a>Skill 2.1</a>
										</li>
										<li>
											<a>Skill 2.2</a>
										</li>
										<li>
											<a>Summary</a>
										</li>
										<li>
											<a>Case study exercise</a>
										</li>
										<li>
											<a>Quiz</a>
										</li>
									</ul>
								</CourseAccordion>
								<CourseAccordion title='Lesson 3 Manage security operations'>
									<ul>
										<li>
											<a>Lesson 3 introduction</a>
										</li>
										<li>
											<a>Skill 3.1</a>
										</li>
										<li>
											<a>Skill 3.2</a>
										</li>
										<li>
											<a>Summary</a>
										</li>
										<li>
											<a>Case study exercise</a>
										</li>
										<li>
											<a>Quiz</a>
										</li>
									</ul>
								</CourseAccordion>
								<CourseAccordion title='Lesson 4 Secure data and applications'>
									<ul>
										<li>
											<a>Lesson 4 introduction</a>
										</li>
										<li>
											<a>Skill 4.1</a>
										</li>
										<li>
											<a>Skill 4.2</a>
										</li>
										<li>
											<a>Summary</a>
										</li>
										<li>
											<a>Case study exercise</a>
										</li>
										<li>
											<a>Quiz</a>
										</li>
									</ul>
								</CourseAccordion>
								<CourseAccordion title='Practice Exam'>
									<ul>
										<li>
											<a>Take a practice exam</a>
										</li>
									</ul>
								</CourseAccordion>
								<CourseAccordion title='Get Certified!'>
									<ul>
										<li>
											<a>Scheduling and Information (2 questions)</a>
										</li>
										<li>
											<a>Certification</a>
										</li>
									</ul>
								</CourseAccordion>
							</section>
						) : (
							<section className='overview'>
								<h2>Overview</h2>
								<p>{props.course.description}</p>
								<h3>Earning criteria</h3>
								<p>{props.course.earningCriteria}</p>
							</section>
						)}
					</div>
				</PageWrapper>
			</>
		);
	} else {
		return (
			<div className='authorized'>
				<div className='pageWrapper employee pillar'>
					<div className='skeleton course-details'>
						<br />
						<div className='img'></div>
						<h2></h2>

						<div className='table-row'></div>
						<div className='table-row'></div>
						<div className='table-row'></div>
						<div className='table-row'></div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ session, course, purchasedCourse }) => ({
	session,
	course,
	purchasedCourse,
});

const mapDispatchToProps = (dispatch) => ({
	setCourseInfo: (data) => dispatch(setCourseInfo(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetails);
