/** @format */

import React from 'react';
import { Link } from 'react-router-dom';

function RecommendationsCard(props) {
	// TODO: get the issuer data in here and render the link right

	return (
		<div className={`card learning ${props.variant}`}>
			<Link
				className='learning-link'
				to={props.variant === 'my-course' ? `/channels/${props.data.id}` : `/courses/${props.data.id}`}
			>
				<span className='program-img'>
					<img
						src={props.data.image}
						alt={props.data.name}
					/>
				</span>
				<h3 className='card-title'>{props.data.name}</h3>
			</Link>

			<p className='card-text'>
				<Link
					className='card-link'
					to={`/issuer/${props.data.slug}`}
				>
					{props.data.issuer}
				</Link>
			</p>
			<ul className='feature-list'>
				<li className='card-text'>
					<span className='circle-icon'>
						<svg
							focusable='false'
							className='icon-24'
							aria-hidden='true'
						>
							<use
								xmlnsXlink='http://www.w3.org/1999/xlink'
								xlinkHref='#dollar-sign-24'
							/>
						</svg>
					</span>
					{props.variant === 'my-course' ? props.data.option : props.data.cost}
				</li>
				<li className='card-text'>
					<span className='circle-icon'>
						<svg
							focusable='false'
							className='icon-24'
							aria-hidden='true'
						>
							<use
								xmlnsXlink='http://www.w3.org/1999/xlink'
								xlinkHref='#layer-group-24'
							/>
						</svg>
					</span>
					{props.data.level}
				</li>
				<li className='card-text'>
					<span className='circle-icon'>
						<svg
							focusable='false'
							className='icon-24'
							aria-hidden='true'
						>
							<use
								xmlnsXlink='http://www.w3.org/1999/xlink'
								xlinkHref='#timer-24'
							/>
						</svg>
					</span>
					{props.data.time} {props.data.timeUnit}
				</li>
				<li className='card-text'>
					<span className='circle-icon'>
						<svg
							focusable='false'
							className='icon-24'
							aria-hidden='true'
						>
							<use
								xmlnsXlink='http://www.w3.org/1999/xlink'
								xlinkHref='#award-24'
							/>
						</svg>
					</span>
					{props.data.type}
				</li>
			</ul>
		</div>
	);
}
export default RecommendationsCard;
