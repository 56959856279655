import React, {useEffect, useState} from "react";
import Icon from "../global/Icon";

const JobFilter = (props) => {
    const [filters, setFilter] = useState(props.filters);

    useEffect(() => {
        setFilter(props.filters)
        return () => {
            setFilter({})
        }
    },[props.filters])

    const addFilter = (key,value) => {

        let filterCopy = {...filters};
        let existingFilters = filterCopy[key];

        // console.log(filterCopy)

        //if key value array includes value, then remove it
        if (existingFilters.includes(value)) {
            // console.log("remove filter from list")
            existingFilters = existingFilters.filter(e => e !== value);
        }
        //else add it
        else {
            existingFilters.push(value)
        }
        filterCopy[key] = existingFilters
        setFilter(filterCopy)
        
    }

    return (
        <div className="filters">
            <h2>Job preferences</h2>
            {props.type === 'enterprise' &&
            <div className="filter-group">
                <fieldset>
                    <legend>Post type</legend>
                    <div className="options">
                        <div className="gr-checkbox">
                            <input type={"checkbox"} value={"internal"} id="postView1" defaultChecked={props.filters.view.includes("internal")} onChange={() => addFilter("view","internal")}/>
                            <label htmlFor="postView1">Internal posts</label>
                            <span>
                                <Icon icon="multi-correct" size="24"/>
                            </span>
                        </div>
                        <div className="gr-checkbox">
                            <input type={"checkbox"} value={"external"} id="postView2" defaultChecked={props.filters.view.includes("external")} onChange={() => addFilter("view","external")}/>
                            <label htmlFor="postView2">External posts</label>
                            <span>
                             <Icon icon="multi-correct" size="24"/>
                            </span>
                        </div>
                    </div>
                </fieldset>
            </div> }
            <div className="filter-group">
                <fieldset>
                    <legend>Job type</legend>
                    <div className="options">
                        <div className="gr-checkbox">
                            <input type={"checkbox"} value={"paidI"} id="type1"/>
                            <label htmlFor="type1">Paid internship</label>
                            <span>
                                <Icon icon="multi-correct" size="24"/>
                            </span>
                        </div>
                        <div className="gr-checkbox">
                            <input type={"checkbox"} value={"unpaidI"} id="type2"/>
                            <label htmlFor="type2">Unpaid internship</label>
                            <span>
                                <Icon icon="multi-correct" size="24"/>
                            </span>
                        </div>
                        <div className="gr-checkbox">
                            <input type={"checkbox"} value={"contract"} id="type3"/>
                            <label htmlFor="type3">Contract</label>
                            <span>
                                <Icon icon="multi-correct" size="24"/>
                            </span>
                        </div>
                        <div className="gr-checkbox">
                            <input type={"checkbox"} value={"partTime"} id="type4"/>
                            <label htmlFor="type4">Part time</label>
                            <span>
                                <Icon icon="multi-correct" size="24"/>
                            </span>
                        </div>
                        <div className="gr-checkbox">
                            <input type={"checkbox"} value={"fullTime"} id="type5" defaultChecked={true}/>
                            <label htmlFor="type5">Full time</label>
                            <span>
                                <Icon icon="multi-correct" size="24"/>
                            </span>
                        </div>
                        
                    </div>
                </fieldset>
            </div>
            <div className="filter-group">
                <fieldset>
                    <legend>Location</legend>
                    <div className='search search-input'>
							<input
								type={'text'}
								name='Search bar'
								aria-label='Search'
								placeholder='Search locations'
								className='gr-input'
							/>
							<span className='search-icon'>
								<Icon
									icon='search'
									size='24'
								/>
							</span>
						</div>
                    </fieldset>
            </div>
            <div className="filter-group">
                <fieldset>
                    <legend>Roles</legend>
                    <div className='search search-input'>
							<input
								type={'text'}
								name='Search bar'
								aria-label='Search'
								placeholder='Search all credentials'
								className='gr-input'
							/>
							<span className='search-icon'>
								<Icon
									icon='search'
									size='24'
								/>
							</span>
						</div>
                </fieldset>
            </div>
            <div className="button-row">
            <button className='button-primary button--small' onClick={() => {props.apply(filters);props.close()}}>Apply preferences</button>
            </div>

        </div>
    )
}
export default JobFilter