/** @format */

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setInsights } from '../../actions/pathways/setInsights';

function WorkStyleQuiz(props) {
	const [step, setStep] = useState(0);

	const submit = () => {
		// console.log(props.insights)

		props.setInsights(true);
	};

	return (
		<div className='work-style-quiz'>
			<header>
				<div className='logo'>
					<Link to='/dashboard'>
						<img
							src='https://pearsonct.design/apps/pearson-q-mk0/static/media/Logo_reverse.c733973243d80896d510cb67873e9853.svg'
							alt='Pearson'
						/>
					</Link>
				</div>
				<Link
					to={'/dashboard'}
					className='button-tertiary button-small'
					style={{ padding: '0.25rem' }}
				>
					Save and exit
				</Link>
			</header>

			<main>
				<div className='assessment-header'>
					{step === 1 || step === 2 ? (
						<h1 className='question-counter text-lg'>Question {step} of 10</h1>
					) : (
						<></>
					)}
				</div>

				{step === 0 ? (
					<section className='assessment step'>
						<h1>Learn about your working style!</h1>
						<p className='text-h3'>
							Welcome to Work Style Insights! This short assessment is designed
							to measure youe patterns of thinking, feeling, and behaving in the
							workplace.
						</p>
						<p className='text-h3'>
							There are no right or wrong results; just a better understanding
							of how to leverage your strengths, understand opportunities for
							improvement, and choose the right roles for you.
						</p>
						<div className='button-footer'>
							<button
								className='button-primary'
								onClick={() => setStep(1)}
							>
								Get started
							</button>
						</div>
					</section>
				) : step === 1 ? (
					<section
						className='assessment step'
						key='step1'
					>
						<fieldset>
							<legend className='text-h1'>
								Being on time to work is a major key to success.
							</legend>
							<div className='radio-options'>
								<div className='radio-option box'>
									<input
										type='radio'
										name='onTime'
										value='12'
										id='onTime1'
									/>
									<label htmlFor='onTime1'>Strongly Agree</label>
									<span>
										<svg
											viewBox='0 0 18 18'
											width='18'
											height='18'
										>
											<circle
												r='6'
												cx='9'
												cy='9'
											/>
										</svg>
									</span>
								</div>
								<div className='radio-option box'>
									<input
										type='radio'
										name='onTime'
										value='22'
										id='onTime2'
									/>
									<label htmlFor='onTime2'>Agree</label>
									<span>
										<svg
											viewBox='0 0 18 18'
											width='18'
											height='18'
										>
											<circle
												r='6'
												cx='9'
												cy='9'
											/>
										</svg>
									</span>
								</div>
								<div className='radio-option box'>
									<input
										type='radio'
										name='onTime'
										value='32'
										id='onTime3'
									/>
									<label htmlFor='onTime3'>Disagree</label>
									<span>
										<svg
											viewBox='0 0 18 18'
											width='18'
											height='18'
										>
											<circle
												r='6'
												cx='9'
												cy='9'
											/>
										</svg>
									</span>
								</div>
								<div className='radio-option box'>
									<input
										type='radio'
										name='onTime'
										value='42'
										id='onTime4'
									/>
									<label htmlFor='onTime4'>Strongly Disagree</label>
									<span>
										<svg
											viewBox='0 0 18 18'
											width='18'
											height='18'
										>
											<circle
												r='6'
												cx='9'
												cy='9'
											/>
										</svg>
									</span>
								</div>
							</div>
						</fieldset>
						<div className='button-footer'>
							{/* <button className="button-tertiary" onClick={() => setStep(0)}>Previous</button> */}
							<button
								className='button-primary'
								onClick={() => setStep(2)}
							>
								Next
							</button>
						</div>
					</section>
				) : step === 2 ? (
					<section
						className='assessment step'
						key='step2'
					>
						<fieldset>
							<legend className='text-h1'>
								I suppose some people may consider me to be a bit of an
								intellectual.
							</legend>
							<div className='radio-options'>
								<div className='radio-option box'>
									<input
										type='radio'
										name='workStyle2'
										value='1'
										id='intell1'
									/>
									<label htmlFor='intell1'>Strongly Agree</label>
									<span>
										<svg
											viewBox='0 0 18 18'
											width='18'
											height='18'
										>
											<circle
												r='6'
												cx='9'
												cy='9'
											/>
										</svg>
									</span>
								</div>
								<div className='radio-option box'>
									<input
										type='radio'
										name='workStyle2'
										value='2'
										id='intell2'
									/>
									<label htmlFor='intell2'>Agree</label>
									<span>
										<svg
											viewBox='0 0 18 18'
											width='18'
											height='18'
										>
											<circle
												r='6'
												cx='9'
												cy='9'
											/>
										</svg>
									</span>
								</div>
								<div className='radio-option box'>
									<input
										type='radio'
										name='workStyle2'
										value='3'
										id='intell3'
									/>
									<label htmlFor='intell3'>Disagree</label>
									<span>
										<svg
											viewBox='0 0 18 18'
											width='18'
											height='18'
										>
											<circle
												r='6'
												cx='9'
												cy='9'
											/>
										</svg>
									</span>
								</div>
								<div className='radio-option box'>
									<input
										type='radio'
										name='workStyle2'
										value='4'
										id='intell4'
									/>
									<label htmlFor='intell4'>Strongly Disagree</label>
									<span>
										<svg
											viewBox='0 0 18 18'
											width='18'
											height='18'
										>
											<circle
												r='6'
												cx='9'
												cy='9'
											/>
										</svg>
									</span>
								</div>
							</div>
						</fieldset>
						<div className='button-footer'>
							<button
								className='button-tertiary'
								onClick={() => setStep(1)}
							>
								Previous
							</button>
							<button
								className='button-primary'
								onClick={() => {
									setStep(3);
									submit();
								}}
							>
								Next
							</button>
						</div>
					</section>
				) : (
					<section className='assessment complete'>
						<h1>You've completed the Work Style Insights assessment!</h1>
						<p className='text-h3'>
							View your results to learn more about your strengths and
							opportunities for improvement. You can access these anytime
							through your Profile.
						</p>
						<div className='button-footer'>
							<Link
								to={'/dashboard'}
								className='button-tertiary'
							>
								Go to dashboard
							</Link>
							<Link
								to={'/insights'}
								state={{ from: 'quiz' }}
								className='button-primary'
							>
								View results
							</Link>
						</div>
					</section>
				)}
			</main>
		</div>
	);
}
const mapStateToProps = ({ session, insights }) => ({
	session,
	insights,
});

const mapDispatchToProps = (dispatch) => ({
	setInsights: (data) => dispatch(setInsights(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkStyleQuiz);
