import React from "react";
import { useSelector } from "react-redux";

const ClassDetails = (props) => {
    
    // const users = useSelector((state) => state.users);

    // function getInstructorName(id) {
    //     return users.filter(f => f.id === id)
    // }
    
        if (props.data) {
            console.log(props.data)
            return (
        <div className="class-details item-details">
            <div className="item-info">
                <h3>Class information</h3>
                <ul>
                    <li>
                        <strong>Purchased seats:</strong> {props.data.purchasedSeats ? props.data.purchasedSeats : 65}
                    </li>
                    <li>
                        <strong>CCX Name:</strong> {props.data.ccxName ? props.data.ccxName : "CCX name"}
                    </li>
                    <li>
                        <strong>Instructor: </strong> 
                        <a>{props.data.instructorId && props.data.instructorId.length > 0 ? props.data.instructorId
                        // (getInstructorName(props.data.instructorId)[0].firstName + " " + getInstructorName(props.data.instructorId)[0].lastName) 
                        : ""}</a>
                    </li>
                    <li>
                        <strong>Total enrolled:</strong> {props.data.enrolled ? props.data.enrolled : "00"}
                    </li>
                    <li>
                        <strong>CCX ID:</strong> {props.data.ccxId ? props.data.ccxId : "CCX ID"}
                    </li>
                    {/* <li>
                        <strong>URL:</strong> <em>insert url and ability to copy?</em>
                    </li> */}
                </ul>
            </div>
            {/* <div>
                Insert enrolled/students table?
            </div> */}

        </div> ) } else {
            return (<div className="skeleton"></div>)
            
        }
    
}
export default ClassDetails