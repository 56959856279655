/** @format */

import Card from '../global/cards/Card';
import reportImg from '../../assets/images/report_illustration.png';

const ClassSummary = () => {
	return (
		<Card modifier='c1asssummary'>
			<h2 style={{ color: '#020917' }}>
				Weekly report for your class is ready to View
			</h2>
			<p>
				Gain a summary of meaningful insights from your workforce's learning
				data
			</p>
			<button className='button-primary'>View</button>

			<img
				src={reportImg}
				alt=''
				style={{ position: 'absolute', right: '0', bottom: '0' }}
			/>
		</Card>
	);
};
export default ClassSummary;
