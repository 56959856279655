import React, { useState } from "react";
import LineChart from "./LineChart";

const data = [
    {
        name: 'NOV',
        uv: 2780
    },
    {
        name: 'DEC',
        uv: 2190
    },
    {
        name: 'JAN',
        uv: 3000
      },
      {
        name: 'FEB',
        uv: 3000
      },
      {
        name: 'MAR',
        uv: 2000
      },
      {
        name: 'APR',
        uv: 2780
      },
      {
        name: 'MAY',
        uv: 1890
      },
      {
        name: 'JUN',
        uv: 2390
      },
      {
        name: 'JUL',
        uv: 3490
      },
      {
        name: 'AUG',
        uv: 4000
      },
      {
        name: 'SEPT',
        uv: 3000
      },
      {
        name: 'OCT',
        uv: 2000
      },
      {
        name: 'NOV',
        uv: 2780
      },
      {
        name: 'DEC',
        uv: 1890
      },
      {
        name: 'JAN',
        uv: 3000
      },
      {
        name: 'FEB',
        uv: 3000
      },
      {
        name: 'MAR',
        uv: 2000
      },
      {
        name: 'APR',
        uv: 2780
      },
      {
        name: 'MAY',
        uv: 1890
      },
      {
        name: 'JUN',
        uv: 2390
      },
      {
        name: 'JUL',
        uv: 3490
      },
      {
        name: 'AUG',
        uv: 4000
      },
      {
        name: 'SEP',
        uv: 3000
      },
      {
        name: 'OCT',
        uv: 2000
      },
      {
        name: 'NOV',
        uv: 1780
      },
      {
        name: 'DEC',
        uv: 1890
      },
      {
        name: 'JAN',
        uv: 2300
      },
      {
        name: 'FEB',
        uv: 3000
      },
      {
        name: 'MAR',
        uv: 2800
      },
      {
        name: 'APR',
        uv: 2380
      },
      {
        name: 'MAY',
        uv: 1890
      },
      {
        name: 'JUN',
        uv: 2090
      },
      {
        name: 'JUL',
        uv: 2490
      },
      {
        name: 'AUG',
        uv: 2000
      },
      {
        name: 'SEP',
        uv: 2300
      },
      {
        name: 'OCT',
        uv: 2550
      }
    ];

const ChartTimeSwitcher = (props) => {
    const [timeFrame, setTimeFrame] = useState(12);
    const [chartData, setChartData] = useState(data.slice(-12));
 


    const formatData = (time) => {
        let originaData = [...data];
        let filteredData;
        switch(time) {
            case 1:
                filteredData = originaData.slice(-2);
                break;
            case 6:
                filteredData = originaData.slice(-6);
                break;
            case 12:
                filteredData = originaData.slice(-12);
                break;
            case 24:
                filteredData = originaData;
                break;
            default:
                filteredData = originaData;
        }
        setTimeFrame(time)
        setChartData(filteredData)
        
    }

    return (
        <div className='chart-container'>
        <div className='chart-header'>
                <h3 className='chart-label'>
                    <span className='chart-title'>{props.title}</span>
                    <span className='chart-now'>{props.lastValue}</span>
                </h3>
                <div className='chart-time'>
                    <fieldset>
                        <legend style={{visibility:"hidden"}}>Timeframe</legend>
                        <div className='radio-row'>
                            <label className='timeframe'><input type="radio" name="chartId" defaultChecked={timeFrame === 1} onChange={() => formatData(1)}/><span>1M</span></label>
                            <label className='timeframe'><input type="radio" name="chartId" defaultChecked={timeFrame === 6} onChange={() => formatData(6)}/><span>6M</span></label>
                            <label className='timeframe'><input type="radio" name="chartId" defaultChecked={timeFrame === 12} onChange={() => formatData(12)}/><span>1Y</span></label>
                            <label className='timeframe'><input type="radio" name="chartId" defaultChecked={timeFrame === 24} onChange={() => formatData(24)}/><span>2Y</span></label>
                        </div>
                    </fieldset>
                </div>
            </div>
            <LineChart data={chartData} time={12}/>
        </div>
    )
}
export default ChartTimeSwitcher