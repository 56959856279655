/** @format */

import React from 'react';
import { connect } from 'react-redux';
import PageWrapper from '../global/PageWrapper';

const Communities = (props) => {
	const communities = [
		{
			id: '34567',
			company: 'disney',
			name: 'Tips for applying',
			description: 'Lorem ipsum',
			memberCount: 432,
			postCount: 1176,
			image:
				'https://i0.wp.com/ooa.hunter.cuny.edu/wp-content/uploads/2022/01/dn.jpeg?fit=700%2C700&ssl=1&w=640',
		},
	];
	return (
		<PageWrapper pageType='communities'>
			<section className='intro'>
				<h1>{props.session.firstName}'s Communities</h1>
				<ul className='communityList'>
					{communities.map((community, i) => {
						return (
							<li
								key={`${i}-post`}
								className='card community'
							>
								<div className='title'>
									<div className='imageWrapper'>
										<img src={community.image} />
									</div>
									{community.name}
								</div>
								<div className='meta'>
									{community.memberCount}k members
									<br />
									{community.postCount} posts
								</div>
							</li>
						);
					})}
				</ul>
			</section>
		</PageWrapper>
	);
};

const mapStateToProps = ({ session }) => ({
	session,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Communities);
