import React from "react";

const JobsListing = (props) => {
    return (
        <ul className="jobsListing">
            { props.children }
        </ul>
    )
}

export default JobsListing