/** @format */

import React from 'react';
import Tag from '../global/Tag';

const ProfileInterestList = (props) => {
	if (props.data.length > 0) {
		return (
			<section className='employee-interests'>
				<h2>Interests</h2>
				<ul className='chip-list'>
					{props.data.map((interest, index) => {
						return (
							<li key={'interest' + index}>
								<Tag title={interest} />
							</li>
						);
					})}
				</ul>
			</section>
		);
	}
};
export default ProfileInterestList;
