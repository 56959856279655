import {SET_CREDENTIALS} from "../../actions/pathways/setCredentials";

export default (state = null, {type, credentials = null }) => {
	Object.freeze(state);
	switch (type) {
		case SET_CREDENTIALS:
			return credentials;
		default:
			return state;
	}
};
