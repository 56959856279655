/** @format */

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import GoBack from '../global/GoBack';
import { getCourseDetails } from '../../util/getCourseDetails';
import { setCourseInfo } from '../../actions/course-details/setCourseInfo';

function CourseDetailsEnroll(props) {
	const params = useParams();
	const { courseId } = params;

	useEffect(() => {
		if (!props.course) {
			getCourseDetails(courseId).then((data) => {
				props.setCourseInfo(data);
			});
		}

		return () => {
			//props.setCourseInfo(null)
		}
	}, [courseId]);

	if (props.course) {
		return (
			<div className={'authorized'}>
				<div className='pageWrapper course'>
					
					<main>
						<section style={{paddingTop:"32px"}}>
						<GoBack />
							<h2>{props.course.name}</h2>
							<p className='course-summary'>{props.course.benefits}</p>
							<nav className='course-nav'>
								<button className='button-primary'>Self-Paced</button>
								<Link
									to={`/courses/${courseId}/locations`}
									className='button-primary'
								>
									Instructor-Led
								</Link>
							</nav>

							<h3>Overview</h3>

							<p>{props.course.description}</p>

							<h4>Earning Criteria</h4>
							<p>{props.course.earningCriteria}</p>
						</section>

						<section>
							<div className='card-list'>
								<div className='card snapshot'>
									<span className='label'>Cost</span>
									<span className='number'>${props.course.cost}</span>
								</div>
								<div className='card snapshot'>
									<span
										className='label'
										style={{ whiteSpace: 'nowrap' }}
									>
										Est. Time
									</span>
									<span className='number'>
										{props.course.time}
										<small>{props.course.timeUnit}</small>
									</span>
								</div>
								<div className='card snapshot'>
									<span className='label'>Skills</span>
									<span className='number'>
										{props.course.skillNames.length}
									</span>
								</div>
								<div className='card snapshot'>
									<span className='label'>Employees</span>
									<span className='number'>{props.course.totalEmployees}</span>
								</div>
							</div>
						</section>
					</main>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ session, course }) => ({
	session,
	course,
});

const mapDispatchToProps = (dispatch) => ({
	setCourseInfo: (data) => dispatch(setCourseInfo(data)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CourseDetailsEnroll);
