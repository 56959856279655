import React from "react";

const JobLink = (props) => {
    return (
        <div className={`jobLink ${props.type}`}>
            <p><a href="#">{props.title}</a></p>
            <p className="description">{props.content}</p>
        </div>
    )
}

export default JobLink