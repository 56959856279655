import {SET_COURSE_INFO} from "../../actions/course-details/setCourseInfo";

export default (state = null, {type, course = null }) => {
	Object.freeze(state);
	switch (type) {
		case SET_COURSE_INFO:
			return course;
		default:
			return state;
	}
};
