import React from "react";
import ProgressRing from "../global/ProgressRing";
import Tag from "../global/Tag";

const SkillCard = (props) => {

    const renderIcon = () => {

        if (props.data.title && props.data.title.toLowerCase() === "career readiness") {
            return "career"
        } else if (props.data.title && props.data.title.toLowerCase() === "critical thinking") {
            return "brain"
        } else if (props.data.title && props.data.title.toLowerCase() === "communication") {
            return "chat"
        } else {
            return "team"
        }
    }

    return (
        <div className="card">
            <h3>{props.data.title}</h3>
            <div className="card-row">
                <ProgressRing size="64" stroke="8" value={props.data.progress} icon={renderIcon()}/>
                <ul className="chip-list">
                    {props.data.skills.map((skill, index) => {
                        return <li key={renderIcon() + index}><Tag title={skill}></Tag></li>
                    })}
                    
                </ul>
            </div>
           
        </div>
    )
}
export default SkillCard