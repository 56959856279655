import React, {useEffect, useState} from 'react'
import AUTH_BASE_URL from '../../util/AUTH_BASE_URL'
import { Link } from 'react-router-dom'

function PopularPrograms(props) {
	const [issuers, setissuers] = useState([])
	const popIssuers = issuers.length > 0 ? [issuers[3], issuers[4], issuers[6], issuers[11], issuers[15]] : []

	useEffect(() => {
		if(issuers.length === 0){
			fetch(`${AUTH_BASE_URL}existing/issuer/`)
			.then(response => response.json())
			.then(data => {

				setissuers(data);
			})
		}
	})

	return (
		<section className="popular-programs">
			<h2>Popular programs</h2>
			<div className="card-carousel firstSlide  ">
				<button
					className="icon-btn circle-btn prev-btn"
					aria-label="Previous"
					disabled=""
				>
					<svg focusable="false" className="icon-24" aria-hidden="true">
						<use
							xmlnsXlink="http://www.w3.org/1999/xlink"
							xlinkHref="#chevron-left-24"
						/>
					</svg>
				</button>
				<div className="card-container">
					<ul>
						{
							popIssuers.length > 0 ?
							popIssuers.map((issuer, i) => {
								return (
									<li style={{ width: 220 }} key={i+"-key"}>
										<div className="card program">
											<Link to={"/issuer/"+issuer.data.id}>
												<span className="program-img">
													<img
														src={issuer.data.photo.url}
														alt={issuer.data.id}
													/>
												</span>
											</Link>
											<span className="card-title">{issuer.data.name}</span>
											<span className="card-text">
												<strong>{issuer.data.badge_ids.length} </strong>Badges
											</span>
										</div>
									</li>
								)
							}) :
							<li>loading...</li>
						}
					</ul>
				</div>
				<button className="icon-btn circle-btn next-btn" aria-label="Next">
					<svg focusable="false" className="icon-24" aria-hidden="true">
						<use
							xmlnsXlink="http://www.w3.org/1999/xlink"
							xlinkHref="#chevron-right-24"
						/>
					</svg>
				</button>
			</div>
		</section>

	)
}
export default PopularPrograms
