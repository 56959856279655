/** @format */

import React, { useEffect } from 'react';
import Icon from '../global/Icon';
import FitScoreDonut from './FitScoreDonut';
import { getFutureFitScore } from '../../util/getFutureFitScore';
import { setScores } from '../../actions/dashboard/setScores';
import { connect } from 'react-redux';
import FitScoreDonutD3 from './FitScoreDonutD3';

const FutureFitBreakdown = (props) => {
	useEffect(() => {
		if (!props.scores) {
			getFutureFitScore(props.session.userId).then((data) => {
				props.setScores(data);
			});
		}
	}, []);

	if (props.scores) {
		return (
			<div className='card intro'>
				<FitScoreDonut data={props.scores[0]} />
				<div className='fit-score-legend'>
					<table className='legend-table'>
						<caption>Score breakdown</caption>
						<tbody>
							<tr>
								<th scope='row'>
									<span className='circle color-exp'></span>Experience
								</th>
								<td>{props.scores[0].experience}</td>
							</tr>
							<tr>
								<th scope='row'>
									<span className='circle color-edu'></span>Education
								</th>
								<td>{props.scores[0].education}</td>
							</tr>
							<tr>
								<th scope='row'>
									<span className='circle color-abil'></span>Ability
								</th>
								<td>{props.scores[0].ability}</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div className='visibility-marker'>
					<span>
						<Icon
							icon='visible'
							size='24'
						/>
						{/* #60646D */}
					</span>
					<span className='text-body'>Only visible to you</span>
				</div>
			</div>
		);
	} else {
		return (
			<div className='card intro'>
				<div className='skeleton donut'></div>
				<div className='skeleton fit-score-legend'>
					<div className='skeleton table-row'></div>
					<div className='skeleton table-row'></div>
					<div className='skeleton table-row'></div>
					<div className='skeleton table-row'></div>
				</div>
			</div>
		);
	}
};
const mapStateToProps = ({ session, scores }) => ({
	session,
	scores,
});

const mapDispatchToProps = (dispatch) => ({
	setScores: (data) => dispatch(setScores(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FutureFitBreakdown);
