import {SET_SKILL_TAGS} from "../../actions/dashboard/setSkillTags";

export default (state = null, {type, skillTags = null }) => {
	Object.freeze(state);
	switch (type) {
		case SET_SKILL_TAGS:
			return skillTags;
		default:
			return state;
	}
};
