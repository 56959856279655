/** @format */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAllCourses } from '../../util/getAllCourses';
import { setAllCourses } from '../../actions/course-details/setAllCourses';

function RelatedLearning(props) {
	const [currentCourse, setCurrentCourse] = useState(null);
	useEffect(() => {
		if (!props.allCourses) {
			getAllCourses().then((data) => {
				let currCourse = data.filter((a) => a.name === props.name);
				setCurrentCourse(...currCourse);
				props.setAllCourses(data);
			});
		}
	}, []);

	if (currentCourse !== null) {
		return (
			<div className='go-to-learning'>
				<div className='badge'>
					<img
						src={currentCourse.image}
						alt=''
					/>
				</div>
				<div className='learning-info'>
					{/*<Link
						to={`/courses/${currentCourse.id}`}
						className='learning-info-title'
                    >*/}
					<h4 className='text-body'>
						{currentCourse.name}
						<span className='icon-24 trending-up'>
							<svg
								focusable='false'
								className='icon-24'
								aria-hidden='true'
							>
								<use
									xmlnsXlink='http://www.w3.org/1999/xlink'
									xlinkHref='#trending-up-24'
								></use>
							</svg>
						</span>
					</h4>
					{/*</Link>*/}
					<p className='card-text'>
						<Link
							className='card-link'
							to={`/issuer/${currentCourse.slug}`}
						>
							Amazon Web Services Training and Certification
						</Link>
					</p>
				</div>
				<button
					className='icon-btn'
					aria-label='Open {title}'
				>
					<svg
						focusable='false'
						className='icon-24'
						aria-hidden='true'
					>
						<use
							xmlnsXlink='http://www.w3.org/1999/xlink'
							xlinkHref='#chevron-right-24'
						></use>
					</svg>
				</button>
			</div>
		);
	}
}

const mapStateToProps = ({ session, allCourses }) => ({
	session,
	allCourses,
});

const mapDispatchToProps = (dispatch) => ({
	setAllCourses: (data) => dispatch(setAllCourses(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RelatedLearning);
