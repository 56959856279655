/** @format */

import React, { useEffect } from 'react';
import PageWrapper from './PageWrapper';
import { connect, useSelector } from 'react-redux';
import Card from './cards/Card';
import { Link } from 'react-router-dom';
import { setBadges } from '../../actions/badges/setBadges';
import AUTH_BASE_URL from '../../util/AUTH_BASE_URL';
const Badges = (props) => {
	const myBadges = props.badges ? props.badges : [];

	useEffect(() => {
		fetch(`${AUTH_BASE_URL}badges`)
			.then((response) => response.json())
			.then((data) => {
				props.setBadges(data);
			});
	}, []);

	return (
		<PageWrapper activePage='badges'>
			<section className='intro dark-bg'>
				<h1 style={{ marginTop: '20px' }}>My Credentials</h1>
			</section>
			<section>
				{myBadges.map((badge) => {
					return (
						<Card
							modifier='learning'
							key={badge.id}
						>
							<div className='badge-image--center'>
								<Link
									className='learning-link'
									to={process.env.PUBLIC_URL + '/courses/' + badge.id}
								>
									<span className='program-img'>
										<img
											src={badge.image}
											alt={badge.name}
										/>
									</span>
								</Link>

								<div className='badge-labels--center'>
									<h3 className='card-title'>{badge.name}</h3>
									{badge.slug ? (
										<p className='card-text'>
											{' '}
											<Link
												to={
													process.env.PUBLIC_URL +
													/issuer/ +
													badge.slug.toLowerCase().replace(/\s/g, '-')
												}
												className='card-link'
											>
												{badge.issuer}
											</Link>
										</p>
									) : (
										<p className='card-text'>
											<a className='card-link'>{badge.issuer}</a>
										</p>
									)}
								</div>
							</div>
						</Card>
					);
				})}
			</section>
		</PageWrapper>
	);
};
const mapStateToProps = ({ session, badges }) => ({
	session,
	badges,
});

const mapDispatchToProps = (dispatch) => ({
	setBadges: (badge) => dispatch(setBadges(badge)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Badges);
