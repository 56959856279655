/** @format */

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { getCourseDetails } from '../../util/getCourseDetails';
import { setCourseInfo } from '../../actions/course-details/setCourseInfo';

import { setCourseLocations } from '../../actions/course-details/setCourseLocations';
import locations from '../../util/courseLocations.json';
import GoBack from '../global/GoBack';
import Icon from '../global/Icon';

function CourseLocations(props) {
	const params = useParams();
	const { courseId } = params;

	useEffect(() => {
		if (!props.courseLocations) {
			props.setCourseLocations(locations);
		}
		if (!props.course) {
			getCourseDetails(courseId).then((data) => {
				props.setCourseInfo(data);
			});
		}
	}, [courseId]);

	if (props.courseLocations && props.course) {
		return (
			<div className={'authorized'}>
				<div className='pageWrapper course'>
					<main>
						

						<section style={{paddingTop:"32px"}}>
						<GoBack />
							<h2>{props.course.name}</h2>

							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between',
									margin: '0 0 20px 0'
								}}
							>
								<div>
									<h3 style={{ margin: '20px 0 0 0' }}>Instructor-Led</h3>
									<span
										style={{
											fontSize: '16px',
											lineHeight: '1.25',
											textAlign: 'right',
											letterSpacing: '0.24px',
										}}
									>
										{props.courseLocations.length} available
									</span>
								</div>
								<div>
									<button className='button-unstyled'>
										<Icon
											icon='filter-alternative'
											size='18'
										/>
									</button>
								</div>
							</div>

							<ul className='locations-list'>
								{props.courseLocations.map((location, i) => {
									return (
										<li
											key={location.id}
											className='card'
										>
											<Link to={`/courses/${courseId}/${location.id}/checkout`}>
												<h4>{location.name}</h4>
												<div className='location-address'>
													{location.address}
												</div>

												<div className='location-info'>
													<div className='location-instuctor'>
														<span className='icon-wrap'>
															<Icon
																icon='people'
																size='24'
															/>
														</span>
														{location.instructor}
													</div>
													<div className='location-hours'>
														<span className='icon-wrap'>
															<Icon
																icon='generic'
																size='18'
															/>
														</span>
														{location.hours}
													</div>
													<div className='location-type'>
														<span className='icon-wrap'>
															<Icon
																icon='generic'
																size='18'
															/>
														</span>
														{location.type}
													</div>
												</div>
											</Link>
										</li>
									);
								})}
							</ul>

							<div className='alternate-options'>
								<p className='sub-text'>
									Not finding options that work? Explore more with Self-Paced.
								</p>

								<button className='button-primary'>Self-Paced</button>
							</div>
						</section>
					</main>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ session, courseLocations, course }) => ({
	session,
	courseLocations,
	course,
});

const mapDispatchToProps = (dispatch) => ({
	setCourseLocations: (data) => dispatch(setCourseLocations(locations)),
	setCourseInfo: (data) => dispatch(setCourseInfo(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseLocations);
