import React from "react";

const TabItem = (props) => {
    const classes = props.activeTab ? "tab-item active" : "tab-item"
    return (
        <li className={classes} role="presentation">
            <button id={props.id}
                        className="tab-action"
                        role="tab"
                        tabIndex={props.activeTab ? 0 : -1}
                        aria-selected={props.activeTab}
                        aria-controls={props.panelId}
                        onClick={() => props.select()}>
                    {props.children}
            </button>
        </li>
    )
}
export default TabItem