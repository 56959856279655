import React from "react";
import Icon from "../global/Icon";
const JobListing = (props) => {
    const postingDate = new Date(props.data.posted_date)
    const dateString = postingDate.toLocaleDateString()
    return (
        <li className={props.active ? "active jobListing card" : "jobListing card" }>
            {props.saveIcon && 
            <button
            className='icon-btn bookmark'
            aria-pressed={props.saved ? 'true':'false'}
           
        >
            <Icon
                icon={props.saved ? 'bookmark-on' : 'bookmark-off'}
                size='24'
            />
        </button>
            }
            <div jobid={props.data._id} onClick={props.clickHandler}>
                <span className="status">{props.data.employment_type}</span>
                <a href={`/companies/${ props.data.company ? props.data.company.vanity_slug : props.data.company_name.toLowerCase().replace(/\s/g, "") }`}>
                    <span className="company">
                        { props.data.company ? 
                            <span className="logo"><img src={props.data.company.photo.url} /></span> : 
                            <></>
                        }
                        
                    </span>
                </a>
                <span className="title">{props.data.job_title}</span>
                <span className="location">{props.location}</span>
                
                <span className="meta">
                <a href={`/companies/${ props.data.company ? props.data.company.vanity_slug : props.data.company_name.toLowerCase().replace(/\s/g, "") }`}><span className="name">{ props.data.company_name }</span></a>
                    <span className="posted">Posted {dateString}</span>
                    { props.data.skills ? <span className="skills">{props.data.skillMatches} of {props.data.skills.length} skills match</span> : <></>}
                </span>
            </div>
        </li>
    )
}

export default JobListing