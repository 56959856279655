/** @format */

import React from 'react';

const ProfileCredentials = (props) => {
	if (props.data.length > 0) {
		return (
			<section className='employee-badges'>
				<h2>Credentials</h2>
				<ul className='badge-list'>
					{props.data.map((item, index) => {
						return (
							<li key={'credential' + index}>
								<div className='badge-item'>
									<span className='badge'>
										<img
											src={item.image}
											alt='badge'
										/>
									</span>
									<span className='text-lg badge-title'>{item.name}</span>
								</div>
							</li>
						);
					})}
				</ul>
			</section>
		);
	}
};
export default ProfileCredentials;
