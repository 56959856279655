/** @format */

import React, { useEffect } from 'react';
import Icon from '../global/Icon';
import { Link } from 'react-router-dom';
import { getCompanies } from '../../util/getCompanies';
import { setCompanies } from '../../actions/companies/setCompanies';
import { connect } from 'react-redux';

const BestCompanies = (props) => {
	useEffect(() => {
		if (!props.companies) {
			getCompanies().then((data) => {
				props.setCompanies(data);
			});
		}
	});

	const sampleByState = {
		states: [
			{
				state: 'California',
				companies: [],
			},
			{
				state: 'Florida',
				companies: [],
			},
			{
				state: 'Georgia',
				companies: [],
			},
			{
				state: 'Illinois',
				companies: [],
			},
			{
				state: 'Michigan',
				companies: [],
			},
			{
				state: 'New Jersey',
				companies: [],
			},
			{
				state: 'New York',
				companies: [],
			},
			{
				state: 'North Carolina',
				companies: [],
			},
			{
				state: 'Ohio',
				companies: [],
			},
			{
				state: 'Pennsylvania',
				companies: [],
			},
			{
				state: 'Texas',
				companies: [],
			},
			{
				state: 'Virginia',
				companies: [],
			},
		],
	};

	return (
		<>
			{props.byState ? (
				<div className='bestCompanies byState'>
					<h2>Best companies to work for in states</h2>
					<ul className='states'>
						{sampleByState.states.map((state, i) => {
							return (
								<li key={i + 'statekey'}>
									<Link to={`/companies/state/${state.state.toLowerCase()}`}>
										<Icon
											icon={state.state.toLowerCase().replace(/\s/g, '')}
											size='24'
										/>
										{state.state}
									</Link>
								</li>
							);
						})}
					</ul>
				</div>
			) : (
				<div className='bestCompanies'>
					<h2>Best companies to work for</h2>
					<ul className='companies'>
						{props.companies ? (
							props.companies.map((company, i) => {
								return (
									<li key={i + 'companykey'}>
										<Link to={`/companies/${company.vanity_slug}`}>
											<div className='logo'>
												<img
													src={company.photo.url}
													alt=''
												/>
											</div>
											<div className='companyDetails'>
												<b>{company.name}</b>
												<br />
												{company.city}, {company.state}
											</div>
										</Link>
									</li>
								);
							})
						) : (
							<li>loading companies...</li>
						)}
					</ul>
					<div className='showMore'>
						<a
							href='!#'
							onClick={(e) => e.preventDefault}
						>
							Show more
						</a>
					</div>
				</div>
			)}
		</>
	);
};

const mapStateToProps = ({ session, companies }) => ({
	session,
	companies,
});

const mapDispatchToProps = (dispatch) => ({
	setCompanies: (data) => dispatch(setCompanies(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BestCompanies);
