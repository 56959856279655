/** @format */

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ProfileCourseList from './ProfileCourseList';
import ProfileResume from './ProfileResume';
import ProfileCredentials from './ProfileCredentials';
import ProfileInterestList from './ProfileInterestList';
import ProfileSkills from './ProfileSkills';
import WorkStyleInsights from './WorkStyleInsights';
import ProfileCard from './ProfileCard';
import PageWrapper from '../global/PageWrapper';
import MyCourses from '../dashboard/MyCourses';
import Footer from '../global/Footer';

function Profile(props) {
	console.log({ ...props.session });

	let softSkills = [
		{
			title: 'Career Readiness',
			skills: ['Curiosity', 'Planning', 'Self-Development'],
			progress: 60,
		},
		{
			title: 'Critical Thinking',
			skills: ['Analyze', 'Thinking', 'Deductive Reasoning'],
			progress: 40,
		},
		{
			title: 'Communication',
			skills: ['Persuasion', 'Writing', 'Administration'],
			progress: 70,
		},
		{
			title: 'Teamwork',
			skills: ['Collaboration', 'Shared Responsibilities'],
			progress: 77,
		},
	];

	return (
		<PageWrapper pageType='profile'>
			<>
				<section className='intro'>
					<ProfileCard data={props.session} />
				</section>
				<section className='about-me'>
					<h2>My story</h2>
					<p>{props.session.description}</p>
				</section>

				{/* If insights exist, render insights */}
				{props.insights ? (
					<WorkStyleInsights />
				) : (
					<section className='work-style-insights'>
						<div className='card dark'>
							<h2>Discover your Work Style</h2>
							<p style={{ textAlign: 'left' }}>
								Take this short two-part assessment to learn how your
								personality relates to your fit within the future workplace.
							</p>
							<div className='card-footer'>
								<Link
									className='button-primary'
									to='/workstylequiz'
								>
									Begin
								</Link>
							</div>
						</div>
					</section>
				)}

				{/* <MyCourses/> */}
				{/* connect to courses call if courses in props.session dont update on enrollment  */}
				<ProfileCourseList data={props.session.courses} />

				{/* changed user data to softSkills for this one */}
				<ProfileSkills data={softSkills} />

				<ProfileCredentials data={props.session.credentials} />

				<ProfileResume data={props.session.experience} />
				<ProfileInterestList data={props.session.interests} />
			</>

			{props.session.role !== 'student' && <Footer />}
		</PageWrapper>
	);
}

const mapStateToProps = ({ session, insights }) => ({
	session,
	insights,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
