import React from "react";

const Footer = (props) => {
    const size = props.size
    return (
        <footer>
            <ul className="footer-links">
                <li><a href="#">Request Demo</a></li>
                <li><a href="#">About Credly</a></li>
                <li><a href="#">Terms</a></li>
                <li><a href="#">Privacy</a></li>
                <li><a href="#">Developers</a></li>
                <li><a href="#">Support</a></li>
            </ul>
        </footer>
    )
}
export default Footer