/** @format */

import React, { useEffect, useState } from 'react';
import { getSkillTags } from '../../util/getSkillTags';
import { setSkillTags } from '../../actions/dashboard/setSkillTags';
import { connect } from 'react-redux';
import Tag from '../global/Tag';
import Modal from '../global/Modal';
import Pillar from '../pillars/Pillar';
import AUTH_BASE_URL from '../../util/AUTH_BASE_URL';

function AdditionalSkills(props) {
	const [occupations, setoccupations] = useState(null);
	const [sheetopen, setsheetopen] = useState(false);
	const [sheetcontent, setsheetcontent] = useState('content...');
	const [sheetid, setsheetid] = useState('id1');

	const mySkills = props.skillTags
		? [
				props.skillTags[23],
				props.skillTags[25],
				props.skillTags[32],
				props.skillTags[2],
				props.skillTags[3],
				props.skillTags[16],
		  ]
		: [];
	const myOccupations = occupations
		? [
				occupations[3],
				occupations[4],
				occupations[5],
				occupations[6],
				occupations[7],
				occupations[8],
				occupations[9],
		  ]
		: [];

	useEffect(() => {
		if (!props.skillTags) {
			getSkillTags().then((data) => {

				props.setSkillTags(data);
			});
		}
	});

	useEffect(() => {
		if (!occupations) {
			fetch(`${AUTH_BASE_URL}existing/occupations/`)
				.then((response) => response.json())
				.then((data) => {
										setoccupations(data);
				});
		}
	});

	const handleSkill = (e) => {
		e.preventDefault();
		const id = e.target.getAttribute('href');
		var skill;
		props.skillTags.forEach((sk) => {
			if (id === sk.id) skill = sk;
		});
		setsheetcontent(
			<Pillar
				type='skill'
				data={skill}
			/>
		);
		setsheetopen(true);
	};

	const handleOccupation = (e) => {
		e.preventDefault();
		const id = e.target.getAttribute('href');
		var skill;
		occupations.forEach((sk) => {
			if (id === sk.id) skill = sk;
		});
		setsheetcontent(
			<Pillar
				type='occupation'
				data={skill}
			/>
		);
		setsheetopen(true);
	};

	return (
		<>
			<section>
				<h2>Emerging skills</h2>
				<ul className='chip-list'>
					{mySkills.length > 0 ? (
						mySkills.map((skill, i) => {
							return (
								<li key={i + 'key'}>
									<Tag
										link
										title={skill.name}
										id={skill.id}
										type='skill'
										clickHandler={handleSkill}
									/>
								</li>
							);
						})
					) : (
						<li>loading skills</li>
					)}
				</ul>
			</section>
			<section>
				<h2>Similar job titles</h2>
				<ul className='chip-list'>
					{myOccupations.length > 0 ? (
						myOccupations.map((occupation, i) => {
							return (
								<li key={i + 'key'}>
									<Tag
										link
										title={occupation.jobTitle}
										id={occupation.id}
										type='occupation'
										clickHandler={handleOccupation}
									/>
								</li>
							);
						})
					) : (
						<li>loading...</li>
					)}
				</ul>
			</section>
			<Modal
				open={sheetopen}
				setModalOpen={setsheetopen}
				targetid={sheetid}
				sheet={true}
			>
				{sheetcontent}
			</Modal>
		</>
	);
}

const mapStateToProps = ({ session, skillTags }) => ({
	session,
	skillTags,
});

const mapDispatchToProps = (dispatch) => ({
	setSkillTags: (skillTags) => dispatch(setSkillTags(skillTags)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalSkills);
