/** @format */

import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../global/Icon';

function Header(props) {
	return (
		<header>
			<div className='company-name' />
			<div className='reverse-logo'>
				<Link to='/dashboard'>
					<img
						src='https://pearsonct.design/apps/pearson-q-mk0/static/media/Logo_reverse.c733973243d80896d510cb67873e9853.svg'
						alt='Pearson'
					/>
				</Link>
			</div>
			{props.variant === 'checkout' ? (
				<div className='checkout'>
					Secure Checkout
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='12'
						height='14'
						viewBox='0 0 12 14'
						fill='none'
					>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M2.55455 5V4C2.55455 1.79086 4.14591 0 6.10895 0C8.07199 0 9.66335 1.79086 9.66335 4V5H10.1077C10.8438 5 11.4406 5.67157 11.4406 6.5V12.5C11.4406 13.3284 10.8438 14 10.1077 14L2.11025 14C1.3741 14 0.777344 13.3284 0.777344 12.5L0.777344 6.5C0.777344 5.67157 1.3741 5 2.11025 5H2.55455ZM4.1096 5H8.1083V4C8.1083 2.75736 7.21316 1.75 6.10895 1.75C5.00474 1.75 4.1096 2.75736 4.1096 4L4.1096 5ZM5.66465 8C5.41927 8 5.22035 8.22386 5.22035 8.5L5.22035 10.5C5.22035 10.7761 5.41927 11 5.66465 11L6.55325 11C6.79863 11 6.99755 10.7761 6.99755 10.5L6.99755 8.5C6.99755 8.22386 6.79863 8 6.55325 8L5.66465 8Z'
						/>
					</svg>
				</div>
			) : (
				<div className='actions'>
					<div>
						<a
							href='/apps/pearson-q-mk0/search'
							className='icon-btn mobile-only'
							aria-label='search'
							id='searchModal'
						>
							<svg
								width={25}
								height={26}
								viewBox='0 0 25 26'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									fillRule='evenodd'
									clipRule='evenodd'
									d='M18.6561 17.631L23.6426 22.6175C24.0637 23.0386 24.0637 23.7214 23.6426 24.1425C23.2215 24.5636 22.5387 24.5636 22.1176 24.1425L17.1311 19.156C15.4356 20.5182 13.2818 21.3333 10.9376 21.3333C5.47226 21.3333 1.04175 16.9028 1.04175 11.4375C1.04175 5.97219 5.47226 1.54167 10.9376 1.54167C16.4029 1.54167 20.8334 5.97219 20.8334 11.4375C20.8334 13.7817 20.0183 15.9355 18.6561 17.631ZM10.9376 19.1458C15.1948 19.1458 18.6459 15.6947 18.6459 11.4375C18.6459 7.18031 15.1948 3.72917 10.9376 3.72917C6.68039 3.72917 3.22925 7.18031 3.22925 11.4375C3.22925 15.6947 6.68039 19.1458 10.9376 19.1458Z'
									fill='currentColor'
								/>
							</svg>
						</a>
						<a
							className='icon-btn desktop-only'
							href='/apps/pearson-q-mk0/search'
						>
							<svg
								width={25}
								height={26}
								viewBox='0 0 25 26'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									fillRule='evenodd'
									clipRule='evenodd'
									d='M18.6561 17.631L23.6426 22.6175C24.0637 23.0386 24.0637 23.7214 23.6426 24.1425C23.2215 24.5636 22.5387 24.5636 22.1176 24.1425L17.1311 19.156C15.4356 20.5182 13.2818 21.3333 10.9376 21.3333C5.47226 21.3333 1.04175 16.9028 1.04175 11.4375C1.04175 5.97219 5.47226 1.54167 10.9376 1.54167C16.4029 1.54167 20.8334 5.97219 20.8334 11.4375C20.8334 13.7817 20.0183 15.9355 18.6561 17.631ZM10.9376 19.1458C15.1948 19.1458 18.6459 15.6947 18.6459 11.4375C18.6459 7.18031 15.1948 3.72917 10.9376 3.72917C6.68039 3.72917 3.22925 7.18031 3.22925 11.4375C3.22925 15.6947 6.68039 19.1458 10.9376 19.1458Z'
									fill='currentColor'
								/>
							</svg>
						</a>
					</div>
					<div className='notification-icon'>
						<span className='status new' />
						<a
							className='icon-btn'
							aria-label='notifications'
							href='/apps/pearson-q-mk0/notifications'
						>
							<svg
								focusable='false'
								className='icon-24'
								aria-hidden='true'
							>
								<use
									xmlnsXlink='http://www.w3.org/1999/xlink'
									xlinkHref='#notification-24'
								/>
							</svg>
						</a>
					</div>
				</div>
			)}
		</header>
	);
}
export default Header;
