/** @format */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Icon from '../../global/Icon';
import Tippy from '@tippyjs/react';
import { getUsers } from '../../../util/getUsers';
import { setUsers } from '../../../actions/users/setUsers';

const StudentsTable = (props) => {
	const [sorted, setSorted] = useState({ column: 0, dir: 'descending' });
	const [tableData, setTableData] = useState([])

	const navigate = useNavigate();

	useEffect(() => {
		let studentData;
		if (!props.users) {
			getUsers().then((data) => {
				// console.log(props.session)
                // console.log(data.filter(f => f.role === "student" && f.courses.length > 0 && f.courses[0].instructor === 'Darren Sampson'))
                props.setUsers(data);
				studentData = props.session.role === "instructor" ? data.filter(f => f.role === "student" && f.courses.length > 0 && f.courses[0].instructor === 'Darren Sampson') : data.filter(f => f.role === "student")
			});
		} else {
			studentData = props.session.role === "instructor" ? props.users.filter(f => f.role === "student" && f.courses.length > 0 && f.courses[0].instructor === 'Darren Sampson')   : props.users.filter(f => f.role === "student")
		}

		setTableData(studentData)
        return () => {
            if(props.users){
                props.setUsers(null)
            }
			setTableData([])

        }
	}, [props]);

	const sortColumn = (index) => {
		//need to bring in data and then transform it still
		if (sorted.column === index) {
			if (sorted.dir === 'descending') {
				setSorted({ ...sorted, dir: 'ascending' });
			} else {
				setSorted({ ...sorted, dir: 'descending' });
			}
		} else {
			setSorted({ column: index, dir: 'descending' });
		}
	};
	
	function getRandomInt(min, max) {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
	}
	const centers = [
		'American Job Center of North Aurora',
		'Greater Omahan American Job Center',
		'Davenport Branch Office',
		'Rockford Branch Office',
		'Skokie Branch Office at OCC',
		'Near West American Job Center',
	];

	
	return (
		<>
			<div className='table-actions'>
				{props.session.role === 'owner' ? (
					<div
						className='form-input'
						style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
					>
						<label>View</label>
						<div className='gr-select-container'>
							<select
								className='gr-select'
								defaultValue={0}
							>
								<option value='0'>All centers</option>
								<option value='1'>Center 1</option>
								<option value='2'>Center 2</option>
								<option value='3'>Center 3</option>
							</select>
							<Icon
								icon='drop-down'
								size='18'
							/>
						</div>
					</div>
				) : (
					<span></span>
				)}
				<div className='filter'>
					<button className='button-unstyled'>
						<Icon
							icon='filter-alternative'
							size='18'
						/>
						Filter
					</button>
				</div>
			</div>

			<div className='table-container'>
				<table className='sortable-table' aria-label='Students'>	
					<thead>
						<tr>
							<th
								scope='col'
								aria-sort={sorted.column === 0 ? sorted.dir : 'none'}
							>
								<button
									className='sort-btn button-unstyled'
									onClick={() => sortColumn(0)}
								>
									Student
									<span className='icon'>
										{sorted.column === 0 ? (
											<Icon
												icon={sorted.dir}
												size='18'
											/>
										) : (
											<Icon
												icon='sortable'
												size='18'
											/>
										)}
									</span>
								</button>
							</th>
							<th
								scope='col'
								aria-sort={sorted.column === 1 ? sorted.dir : 'none'}
							>
								<button
									className='button-unstyled sort-btn'
									onClick={() => sortColumn(1)}
								>
									Center
									<span className='icon'>
										{sorted.column === 1 ? (
											<Icon
												icon={sorted.dir}
												size='18'
											/>
										) : (
											<Icon
												icon='sortable'
												size='18'
											/>
										)}
									</span>
								</button>
							</th>
							<th
								scope='col'
								aria-sort={sorted.column === 2 ? sorted.dir : 'none'}
							>
								<button
									className='button-unstyled sort-btn'
									onClick={() => sortColumn(2)}
								>
									Credentials
									<span className='icon'>
										{sorted.column === 2 ? (
											<Icon
												icon={sorted.dir}
												size='18'
											/>
										) : (
											<Icon
												icon='sortable'
												size='18'
											/>
										)}
									</span>
								</button>
							</th>
							<th
								scope='col'
								aria-sort={sorted.column === 3 ? sorted.dir : 'none'}
							>
								<button
									className='button-unstyled sort-btn'
									onClick={() => sortColumn(3)}
								>
									Classes
									<span className='icon'>
										{sorted.column === 3 ? (
											<Icon
												icon={sorted.dir}
												size='18'
											/>
										) : (
											<Icon
												icon='sortable'
												size='18'
											/>
										)}
									</span>
								</button>
							</th>
							<th
								scope='col'
								aria-sort={sorted.column === 4 ? sorted.dir : 'none'}
							>
								<button
									className='button-unstyled sort-btn'
									onClick={() => sortColumn(4)}
								>
									Instructor
									<span className='icon'>
										{sorted.column === 4 ? (
											<Icon
												icon={sorted.dir}
												size='18'
											/>
										) : (
											<Icon
												icon='sortable'
												size='18'
											/>
										)}
									</span>
								</button>
							</th>
							<th
								scope='col'
								aria-sort={sorted.column === 5 ? sorted.dir : 'none'}
							>
								<button
									className='button-unstyled sort-btn'
									onClick={() => sortColumn(5)}
								>
									Exam enrollment
									<span className='icon'>
										{sorted.column === 5 ? (
											<Icon
												icon={sorted.dir}
												size='18'
											/>
										) : (
											<Icon
												icon='sortable'
												size='18'
											/>
										)}
									</span>
								</button>
							</th>
						</tr>
					</thead>
					<tbody>
						{tableData ? tableData.map((item, index) => {
							const { firstName, lastName, city, state } = item;
							
							if (firstName.length > 0)
								return (
									<tr
										key={`student-${index}`}
										onClick={() => {
											navigate(`${process.env.PUBLIC_URL}/student/${item.id}`, {
												replace: false,
											});
											window.scrollTo(0, 0);
										}}
									>
										<th scope='row'>
											<div className='employee-data'>
												{item.avatar ? (
													<span className='avatar'>
														<img
															src={item.avatar}
															alt='avatar'
															style={{ borderRadius: '50%' }}
														/>
													</span>
												) : (
													<></>
												)}
												<div className='employee-info'>
													{/* <Link to={`${process.env.PUBLIC_URL}/employee/${item.id}`}><span className="employee-name"> {firstName} {lastName}</span></Link> */}
													<span className='employee-name'>
														{' '}
														{firstName} {lastName}
													</span>
													<span className='item-location'>
														{city}, {state}
													</span>
												</div>
											</div>
										</th>
										<td>
											<span className='item-location'>
												{centers[getRandomInt(0, 5)]}
											</span>
										</td>
										<td>

											{item.badges && item.badges.length > 0 ? (
												<ul className='credential-list'>
													{' '}
													{item.badges.map((credential, ci) => {
														return (
															<li key={'badge' + index + '-' + ci}>
																<Tippy content={credential.name}>
																	<span className='badge'>
																		<img
																			src={credential.image}
																			alt={credential.name}
																		/>
																	</span>
																</Tippy>
															</li>
														)
															
													})}
												</ul>
											) : (
												''
											)}
										</td>
										<td>
											{item.courses && item.courses.length > 0
                                            ? item.courses[0].name
												// ? getCredential(item.enrollments[0].id)[0].name
												: 'Microsoft Windows CertPREP Course'}
										</td>
										<td>
											{item.courses.length > 0 
												? item.courses[0].instructor
												: ''}

                                            
										</td>

										<td>
											{item.courses.length > 0
												? item.courses[0].examEnrolled
													? 'yes'
													: 'no'
												: 'no'}
										</td>
										{/* <td>
                                     {item.enrollments && item.enrollments.length > 0 ? <ul className="credential-list"> {item.enrollments.map((course, ci) => {const credential = getCredential(course.classId); return (<li key={"badge"+index+"-"+ci}><Tippy content={credential[0].name}><span className="badge"><img src={credential[0].image} alt={credential[0].name}/></span></Tippy></li>)})}</ul> : <></>}
                                    </td>
                                    <td>
                                       {item.enrollments && item.enrollments.length > 0 ? <ul className="skills-list"> {item.enrollments.map((course, si) => {const skill = getSkill(course.skillId);console.log(skill); if (skill.length > 0) return (<li key={"skill"+index+"-"+si}>{skill[0].name}</li>)})}</ul> : <></>}
                                    </td> */}
									</tr>
								);
							else return null;
						}) : <tr><td colSpan={6}>Loading...</td></tr>}
					</tbody>
				</table>
			</div>
		</>
	);
};
const mapStateToProps = ({ session,users }) => ({
	session,
	users
});

const mapDispatchToProps = (dispatch) => ({
	setUsers: (data) => dispatch(setUsers(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentsTable);
