import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import {Provider} from "react-redux";
import configureStore from "./store/store";
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {checkLoggedIn} from "./util/session/checkLoggedIn";

const root = ReactDOM.createRoot(document.getElementById('root'));
let preloadedState = {};
const store = configureStore(preloadedState);

const renderApp = (preloadedState) => {
	const store = configureStore(preloadedState);
	root.render(
		<Provider
			store={store}
		>
			<BrowserRouter forceRefresh={true}>
				<App />
			</BrowserRouter>
		</Provider>
	);
};
(async () => renderApp(await checkLoggedIn()))();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
