import React from "react";
import Card from "../global/cards/Card";

const DetailPageHeader = (props) => {
    const data = props.data
    return (
        <Card modifier="user-info">
            {data.avatar && data.avatar.length > 0 ? 
                <div className="avatar-container">
                    <div className="avatar">
                        <img src={data.avatar} alt="avatar"/>
                    </div>
                    <span className="chip-label"><i className="fa-regular fa-circle-check"></i> Connected</span>
                </div>
                : <></>
            }
                <div className="user-details">

                    <h1 className="user-name">{data.name ? data.name : data.firstName + " " + data.lastName}</h1>
                    
                    {data.jobTitle && <p className="user-occupation text-body">{data.jobTitle}</p> }
                    <p className="user-description text-lg">{data.description ? data.description : "Description text goes here"}</p>

                </div>
                
            </Card>
    )
}
export default DetailPageHeader