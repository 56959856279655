/** @format */

import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import {
	faLinkedinIn,
	faFacebookF,
	faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Breadcrumbs from '../global/Breadcrumbs';
import { Link } from 'react-router-dom';
import PageWrapper from '../global/PageWrapper';

import { useDataFetch } from '../../util/hooks/useDataFetch';
import truncateString from '../../util/truncateString';
import calculateMargin from '../../util/calculateMargin';
import { useParams, useLocation } from 'react-router-dom';
import AUTH_BASE_URL from '../../util/AUTH_BASE_URL';

const charLimit = 13;
const transparentImages = [
	'Quickstart',
	'Amazon Web Services Training and Certification',
	'Meta',
];
const containerClass = 'badge-container';
const childClass = 'badge-body';

const IssuerDetail = (props) => {
	const [issuer, setIssuer] = useState();
	const [badges, setbadges] = useState([]);
	const [badgeHeaderPadding, setBadgeHeaderPadding] = useState(0);
	const [isResized, setIsResized] = useState(false);
	const containerRef = useRef(null);
	const itemRef = useRef(null);
	const params = useParams();
	const { issuerId } = params;
	const [container, setContainer] = useState(null);
	const [items, setItems] = useState(null);

	useEffect(() => {
		fetch(`${AUTH_BASE_URL}existing/issuer/${issuerId}`)
			.then((response) => response.json())
			.then((data) => {
				setIssuer(data);
			});
	}, []);

	useEffect(() => {
		// get badges from badge ID's
		if (issuer && badges.length === 0) {
			getBadges().then((newBadgess) => {
				setbadges(newBadgess);
			});
		}
	});

	// get badges with an async function
	// fetch each one
	const getBadges = async () => {
		var newBadges = [];
		// get badges from badge ID's
		if (issuer) {
			for (let i = 0; i < issuer.data.badge_ids.length; i++) {
				let badgeId = issuer.data.badge_ids[i];
				let response = await fetch(`${AUTH_BASE_URL}badges/${badgeId}`);
				let newBadge = await response.json();
				newBadges.push(newBadge);
			}
		}

		return newBadges;
	};

	useEffect(() => {
		const margin = calculateMargin(containerClass, childClass);
		setBadgeHeaderPadding(margin);
	}, []);

	useEffect(() => {
		var delayInMilliseconds = 900; //1 second
		/**
		 * badge-container  is not available on DOM until about a second
		 * possibly due to skeleton animaion
		 */
		setTimeout(function () {
			if (containerRef.current) {
				// numOfElementsPerRow = calculateNumElementsInRow(container, childElements);
				setContainer(containerRef.current);
				setItems(itemRef.current);
			}
		}, delayInMilliseconds);
	}, [containerRef.current]);

	// Set Padding for the badge-header
	useEffect(() => {}, [badgeHeaderPadding]);

	// Watch for changes in the layout
	useEffect(() => {
		const observer = new ResizeObserver((entries) => {
			for (let entry of entries) {
				if (containerRef.current && entry.target === containerRef.current) {
					setIsResized(true);
					// When size changes get calculate the badgeHeader padding
					setBadgeHeaderPadding(calculateMargin(containerClass, childClass));
				}
			}
		});

		if (container) {
			observer.observe(container);
		}

		return () => {
			observer.disconnect();
		};
	}, [container]);

	return (
		<>
			{!issuer ? (
				<PageWrapper activePage='dashboard' hideNav>
					<section className='intro dark-bg'>
						<Breadcrumbs isBack='true' />
						<h1 style={{ marginTop: '20px' }}>Missing page</h1>
						<p>
							This prototype is still under development. Content will be placed
							here soon.
						</p>
					</section>
				</PageWrapper>
			) : (
				<PageWrapper activePage='pillar' hideNav>
					<div className='pillar-page learning'>
						<section className='issuer-detail mobile-body'>
							<Breadcrumbs isBack='true' />
							<div className='issuer-wrapper'>
								<div
									className={
										transparentImages.includes(issuer.data.name)
											? 'left-container transparent-img'
											: 'left-container'
									}
								>
									<img
										className='slug-logo'
										src={issuer.data.photo_url}
										alt={issuer.name}
									/>
								</div>
								<div className='right-container'>
									<header>{/* <!-- header content here --> */}</header>
									<h1 className='ac-heading--badge-name-hero page-title'>
										{issuer.data.name}
									</h1>
									<div className='bio page-desc'>{issuer.data.bio}</div>
									<div className='links'>
										{issuer.data && issuer.data.linked_in_url ? (
											<div className='link'>
												<a
													href={issuer.data.linked_in_url}
													target='_blank'
													rel='noopener noreferrer'
												>
													<FontAwesomeIcon icon={faLinkedinIn} />
												</a>
											</div>
										) : (
											<></>
										)}
										{issuer.data && issuer.data.facebook_url ? (
											<div className='link'>
												{' '}
												<a
													href={issuer.data.facebook_url}
													target='_blank'
													rel='noopener noreferrer'
												>
													<FontAwesomeIcon icon={faFacebookF} />
												</a>
											</div>
										) : (
											<></>
										)}
										{issuer.data && issuer.data.twitter_url ? (
											<div className='link'>
												<a
													href={issuer.data.twitter_url}
													target='_blank'
													rel='noopener noreferrer'
												>
													<FontAwesomeIcon icon={faTwitter} />
												</a>
											</div>
										) : (
											<></>
										)}
										{issuer.data && issuer.data.website_url ? (
											<div className='link'>
												<a
													href={issuer.data.website_url}
													target='_blank'
													rel='noopener noreferrer'
												>
													<FontAwesomeIcon icon={faLink} />
												</a>
											</div>
										) : (
											<></>
										)}
									</div>
								</div>
							</div>
						</section>
					</div>
					<section className='badges-section'>
						<div className='badge-wrapper'>
							<ul
								className='badge-container'
								ref={containerRef}
							>
								<div
									className='badge-header'
									style={{ paddingLeft: badgeHeaderPadding }}
								>
									<span className='ac-heading--badge-name-hero-subtitle page-title'>
										Credentials
									</span>
								</div>

								{badges?.map((badge, idx) => {
									return (
										<li
											ref={itemRef}
											className='badge-body'
											key={badge.id}
										>
											<Link
												to={process.env.PUBLIC_URL + '/courses/' + badge.id}
											>
												<img
													width='120px'
													className='badge'
													src={badge.image}
													alt={badge.name}
												/>
											</Link>
											<span className='badge-name'>
												{truncateString(badge.name, charLimit)}
											</span>
										</li>
									);
								})}
							</ul>
						</div>
					</section>
				</PageWrapper>
			)}
		</>
	);
};

export default IssuerDetail;
