import React, {useEffect, useState} from "react";
import PageWrapper from "../global/PageWrapper";
import Breadcrumbs from "../global/Breadcrumbs";
import DetailPageHeader from "./DetailPageHeader";
import { useParams } from "react-router-dom";
import { connect } from 'react-redux';
import { getAllCourses } from "../../util/getAllCourses";
import {getUserInfo} from "../../util/getUserInfo";
import {setUser} from "../../actions/users/setUser";
import { setLicenses } from "../../actions/licenses/setLicenses";
import { setAllCourses } from "../../actions/course-details/setAllCourses";
import InstructorDetails from "./InstructorDetails";
import LicenseDetails from "./tables/LicenseDetails";
import ClassDetails from "./tables/ClassDetails";
import StudentDetails from "./StudentDetails";
import { getLicenses } from "../../util/getLicenses";

const DetailPage = (props) => {
    const [pageData, setPageData] = useState([]);
    const params = useParams();
	const { id } = params;
    // let pageData;

    // console.log(props, id)

    useEffect(() => {
        let newData;
        if (props.pageType === "licenses") {
            if (!props.licenses) {
                getLicenses(id).then((data) => {
                    props.setLicenses(data)
                    newData = data.filter((a) => a.id === id)[0];
                });
                
            } else {
               newData = props.licenses.filter((a) => a.id === id)[0]; 
            }
            

            // pageData = props.licenseDetail.filter(f => f.id === id)[0]
        } else if (props.pageType === "classes") {
            if (!props.allCourses) {
                getAllCourses().then((data) => {
                    // console.log(data)
                    props.setAllCourses(data);
                });
            }
            newData = props.allCourses.filter(f => f.id === id)[0]
            // setPageData(classData)
        } else  {
            //either inst or student
            //always override 
            
            if (!props.user) {
                getUserInfo(id).then((data) => {
                    // console.log(data)
                    props.setUser(data);
                    newData = data.user
                });
            } else {
                console.log("not")
                newData = props.user.user
            }
            // pageData = []
            
        } 
	    setPageData(newData)
        // console.log(pageData)

        return () => {
            if(props.user){
                props.setUser(null)
            }
			setPageData([])

        }
	}, [id, props]);
    
    return (
        <PageWrapper pageType={props.pageType}>
            <div className={`${props.pageType.toLowerCase()}-detail detail-page`}>
                {pageData ? <>
                <section className="intro">
                    <Breadcrumbs backTarget={"/"+props.pageType.toLowerCase()} label={props.pageType} pageName={pageData.name ? pageData.name + " details" : pageData.firstName + " " + pageData.lastName}></Breadcrumbs>  
                    <DetailPageHeader data={pageData} />
                </section>
                {props.pageType === "licenses" ? 
                <section>
                    <LicenseDetails data={pageData}/> 
                    </section>
                : props.pageType === "classes" ? 
                <section><ClassDetails data={pageData}/></section>
                : props.pageType === "instructors" ? 
                    <InstructorDetails data={pageData}/>
                : props.pageType === "students" ? 
                <StudentDetails data={pageData}/>
                : <></>}

            </> : <><div className="">
                    
                    <section className="card user-info" style={{minHeight:"80px"}}>

                    </section>
                    <section className="skeleton card"></section>
                </div></>}
            </div>
        </PageWrapper>
    )
}
const mapStateToProps = ({ session, licenses, allCourses, user }) => ({
	session,
	licenses,
    allCourses,
    user

});

const mapDispatchToProps = (dispatch) => ({
	setLicenses: (data) => dispatch(setLicenses(data)),
    setAllCourses: (courses) => dispatch(setAllCourses(courses)),
    setUser: (user) => dispatch(setUser(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailPage);
