/** @format */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getCompanyDetails } from '../../util/getCompanyDetails';
import { setCompanyInfo } from '../../actions/companies/setCompanyInfo';
import { getJobsByCompany } from '../../util/getJobsByCompany';
import { setJobsByCompany } from '../../actions/companies/setJobsByCompany';
import { Link, useParams } from 'react-router-dom';
import GoBack from '../global/GoBack';
import Avatar from '../global/Avatar';
import JobsListing from '../careers/JobsListing';
import JobListing from '../careers/JobListing';
import Modal from '../global/Modal';
import JobDetail from '../careers/JobDetail';
import Icon from '../global/Icon';

function Company(props) {
	const params = useParams();
	const { id } = params;

	const [activeJob, setActiveJob] = useState({});
	const [sheetopen, setsheetopen] = useState(false);
	const [sheetid, setsheetid] = useState('id2');

	const posts = [
		{
			id: '12345',
			company: 'disney',
			postedDate: '2023-03-23',
			title:
				'The Walt Disney Company to participate in the JP Morgan council on film',
			content:
				'Lorem ipsum dolor set amet. Lorem ipsum dolor set amet. Lorem ipsum dolor set amet. Lorem ipsum dolor set amet. Lorem ipsum dolor set amet.',
			preview: 'Lorem ipsum dolor set amet.',
			image:
				'https://lumiere-a.akamaihd.net/v1/images/6047825fea89c20001bc7f2a-image_c06b80fa.jpeg?region=0,0,1536,864',
			commentCount: 98,
			comments: [],
		},
		{
			id: '12346',
			company: 'disney',
			postedDate: '2023-03-21',
			title:
				'Disney in collaboration with Lucas Film and the philanthropic institute',
			content:
				'Lorem ipsum dolor set amet. Lorem ipsum dolor set amet. Lorem ipsum dolor set amet. Lorem ipsum dolor set amet. Lorem ipsum dolor set amet.',
			preview: 'Lorem ipsum dolor set amet.',
			image:
				'https://cdn.segmentnextimages.com/wp-content/uploads/2022/03/Jedi-Fallen-Order-Duel.jpg',
			commentCount: 98,
			comments: [],
		},
		{
			id: '12347',
			company: 'disney',
			postedDate: '2023-03-19',
			title: 'The next generation of storytellers',
			content:
				'Lorem ipsum dolor set amet. Lorem ipsum dolor set amet. Lorem ipsum dolor set amet. Lorem ipsum dolor set amet. Lorem ipsum dolor set amet.',
			preview: 'Lorem ipsum dolor set amet.',
			image:
				'https://images.creativemarket.com/0.1.0/ps/15090451/3485/2323/m1/fpnw/wm1/sstk_2145228761_9516428.jpg-.jpg?1&s=7231e87944f17ab958c40e9dd8a1b848',
			commentCount: 98,
			comments: [],
		},
	];

	const communities = [
		{
			id: '34567',
			company: 'disney',
			name: 'Tips for applying',
			description: 'Lorem ipsum',
			memberCount: 432,
			postCount: 1176,
			image:
				'https://i0.wp.com/ooa.hunter.cuny.edu/wp-content/uploads/2022/01/dn.jpeg?fit=700%2C700&ssl=1&w=640',
		},
	];

	useEffect(() => {
		if (!props.company) {
			getCompanyDetails(id).then((data) => {
				props.setCompanyInfo(data);
			});
		}

		return () => {
			props.setCompanyInfo(null);
		};
	}, [id]);

	useEffect(() => {
		if (!props.jobsByCompany && props.company) {
			getJobsByCompany(props.company.company.name).then((data) => {
				props.setJobsByCompany(data);
			});
		} else if (props.jobsByCompany && props.company) {
			props.setJobsByCompany(props.jobsByCompany);
		}

		return () => {
			props.setJobsByCompany(null);
		};
	}, [props.company]);

	const clickJob = (e) => {
		e.preventDefault();
		var element = e.target;
		var parent = element.parentElement;
		while (parent.tagName.toLowerCase() != 'div') {
			element = parent;
			parent = element.parentElement;
		}
		const id = parent.getAttribute('jobid');
		var active = {};
		props.jobsByCompany.forEach((job) => {
			if (id == job._id) {
				setActiveJob(job);
				setsheetid(id);
				setsheetopen(true);
			}
		});
	};

	return (
		<>
			{props.company ? (
				<div className={'authorized'}>
					<div className='pageWrapper course company'>
						<main>
							
							<section className='intro' style={{paddingTop: "32px"}}>
								<GoBack />
								<div className='logo'>
									<img src={props.company.company.photo.url} />
								</div>
								<div className='headerWithButton'>
									<h1>{props.company.company.name}</h1>
									<button className='button-primary button-small'>
										Follow
									</button>
								</div>
								<section className='description'>
									<p>{props.company.company.bio}</p>
									<div className='recruiter'>
										<Avatar
											data={props.company.recruiter}
											hideName
										/>
										<div className='name'>
											<div className='blurb'>
												Meet the {props.company.company.name} recruiter!
											</div>
											<div className='actualName'>
												<Link to={`/people/${props.company.recruiter.id}`}>
													{props.company.recruiter.firstName}{' '}
													{props.company.recruiter.lastName}
												</Link>
											</div>
										</div>
									</div>
								</section>
							</section>
							<section>
								<h2>Careers & internships</h2>
								{props.jobsByCompany ? (
									<JobsListing>
										{props.jobsByCompany.map((job, i) => {
											return (
												<JobListing
													key={`${i}-job`}
													data={job}
													clickHandler={clickJob}
												/>
											);
										})}
									</JobsListing>
								) : (
									<div>loading...</div>
								)}
							</section>
							<section>
								<h2>{props.company.company.name}'s Posts</h2>
								<ul className='postList'>
									{posts.map((post, i) => {
										return (
											<li
												key={`${i}-post`}
												className='card post'
											>
												<div className='title'>
													<div className='imageWrapper'>
														<img src={post.image} />
													</div>
													{post.title}
												</div>
												<div className='preview'>{post.preview}...</div>
												<div className='meta'>
													<Icon
														icon='chat'
														size='24'
													/>
													{post.commentCount}
												</div>
											</li>
										);
									})}
								</ul>
							</section>
							<section>
								<h2>Communities</h2>
								<ul className='communityList'>
									{communities.map((community, i) => {
										return (
											<li
												key={`${i}-post`}
												className='card community'
											>
												<div className='title'>
													<div className='imageWrapper'>
														<img src={community.image} />
													</div>
													{community.name}
												</div>
												<div className='meta'>
													{community.memberCount}k members
													<br />
													{community.postCount} posts
												</div>
											</li>
										);
									})}
								</ul>
							</section>
						</main>
					</div>
					<Modal
						open={sheetopen}
						setModalOpen={setsheetopen}
						targetid={sheetid}
						sheet={true}
					>
						<JobDetail data={activeJob} />
					</Modal>
				</div>
			) : (
				<div className={'authorized'}>
					<div className='pageWrapper course'>
						<main>
							<GoBack />
							<section className='intro'>
								<h1>loading company data</h1>
							</section>
						</main>
					</div>
				</div>
			)}
		</>
	);
}

const mapStateToProps = ({ session, company, jobsByCompany }) => ({
	session,
	company,
	jobsByCompany,
});

const mapDispatchToProps = (dispatch) => ({
	setCompanyInfo: (data) => dispatch(setCompanyInfo(data)),
	setJobsByCompany: (data) => dispatch(setJobsByCompany(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Company);
