import React from "react";
import PageWrapper from "./PageWrapper";
import Breadcrumbs from "./Breadcrumbs";



const Placeholder = (props) => {

    return (
        <PageWrapper  activePage={props.page} pageType={props.page}>
            <section className="intro dark-bg">
                {props.page.toLowerCase() !== "communities" ?
                <Breadcrumbs isBack="true"/> : <></> }
                <h1 style={{marginTop:"20px"}}>{props.page} page</h1>
                <p style={{color:'white',zIndex:1,position:"relative"}}>This prototype is still under development. Content will be placed here soon.</p>
            </section>
        </PageWrapper>
    )
}
export default Placeholder
