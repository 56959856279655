import {SET_JOBS_BY_COMPANY} from "../../actions/companies/setJobsByCompany";

export default (state = null, {type, jobsByCompany = null }) => {
	Object.freeze(state);
	switch (type) {
		case SET_JOBS_BY_COMPANY:
			return jobsByCompany;
		default:
			return state;
	}
};
