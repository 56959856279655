import React from "react";
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";

const CourseCountdown = (props) => {
    const data = [
        {
            "name": "avg",
            "value": +props.remaining,
            "fill":"url(#ringGradientPK)"
        },{
            "name":"total",
            "value": props.total - props.remaining,
            "fill":"transparent"
        }
      ]
      const dataBg = [
        {
            "name":"total",
            "value": 100,
            "fill":"var(--color-light-200)"
        }
      ]

    return (
        <div className="course-countdown">
            <p className="text-body" style={{textAlign:"center"}}>This course will start in</p>
            <div className="countdown-container">
            <ResponsiveContainer width="100%" height={180}>
                <PieChart width={295} height={180}>
                     <defs>
                        <linearGradient id="ringGradientPK" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="0%" stopColor="#C835A3" />
                        <stop offset="100%" stopColor="#8D1BA0" />
                        </linearGradient>
                     </defs>
                     <text x={"50%"} y={140}
                            style={{ fontSize: 72, fill: 'var(--color-white)' }}
                            textAnchor='middle'
                        >{props.total}</text>
                        <text x={"50%"} y={165}
                            style={{ fontSize: 18, fill: 'var(--color-white)' }}
                            textAnchor='middle'
                        >{props.units}</text>
                       
                        <Pie
                            data={dataBg}
                            //   cx={200}
                            cy={110}
                            startAngle={205}
                            endAngle={-25}
                            innerRadius={75}
                            outerRadius={105}
                            cornerRadius={20}
                            fill="#8884d8"
                            paddingAngle={-20}
                            dataKey="value"
                            stroke={"transparent"}
                            stroke-width={0}
                            >
                            {dataBg.map((entry, index) => (
                                <Cell key={`cell-${index}`} />
                            ))}
                        </Pie>
                        <Pie
                            data={data}
                            //   cx={200}
                            cy={110}
                            startAngle={205}
                            endAngle={-25}
                            innerRadius={75}
                            outerRadius={105}
                            cornerRadius={20}
                            fill="#8884d8"
                            paddingAngle={-20}
                            dataKey="value"
                            stroke={"transparent"}
                            stroke-width={0}
                            >
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} />
                            ))}
                        </Pie>

                </PieChart>
                </ResponsiveContainer>

            </div>
        </div>
    )
}
export default CourseCountdown