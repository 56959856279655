import React from "react";
import { Link } from "react-router-dom";
import InsightBlock from "./InsightBlock";

const WorkStyleInsights = (props) => {
    const insightsData = [
        {
            "title":"Approaching work",
            "type":"work",
            "categories": [
                {
                    "title":"Persistence",
                    "value":41
                },
                {
                    "title":"Attention to detail",
                    "value":73
                },
                {
                    "title":"Rule following",
                    "value":65
                },
                {
                    "title":"Achievement/effort",
                    "value":46
                },
                {
                    "title":"Dependability",
                    "value":84
                },
                {
                    "title":"Initiative",
                    "value":77
                }
            ]
        },
        {
            "title":"Relating to people",
            "type":"relate",
            "categories": [
                {
                    "title":"Cat1",
                    "value":65
                },
                {
                    "title":"Cat2",
                    "value":50
                },
                {
                    "title":"Cat3",
                    "value":76
                },
                {
                    "title":"Cat4",
                    "value":80
                }
            ]
        },
        {
            "title":"Dealing with emotions",
            "type":"emotions",
            "categories": [
                {
                    "title":"Cat1",
                    "value":30
                },
                {
                    "title":"Cat2",
                    "value":76
                },
                {
                    "title":"Cat3",
                    "value":80
                }
            ]
        },
        {
            "title":"Thinking styles",
            "type":"thinking",
            "categories": [
                {
                    "title":"Cat1",
                    "value":77
                },
                {
                    "title":"Cat2",
                    "value":60
                },
                {
                    "title":"Cat3",
                    "value":82
                }
            ]
        }
    ]

    return (
        <section className="work-style-insights">
            <div className="insights-header">
                <h2>Work style insights</h2>
                <Link to="/insights">View results</Link>
            </div>
            <div className="insights-body">
                <InsightBlock type={"work"} title="Approaching work" data={insightsData[0]}/>
                <InsightBlock type={"relate"} title="Relating to people" data={insightsData[1]}/>
                <InsightBlock type={"emotions"} title="Dealing with emotions" data={insightsData[2]} />
                <InsightBlock type={"thinking"} title="Thinking styles" data={insightsData[3]}/>

                <p>Your results are ready! Click to read your insights.</p>
                <Link className="button-primary" to="/insights">
                    View results
                </Link>
            </div>
        </section>
        
    )
}
export default WorkStyleInsights