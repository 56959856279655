import Card from "../global/cards/Card";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import Icon from "../global/Icon";
import Carousel from "../global/Carousel";
import PageWrapper from "../global/PageWrapper";
import Breadcrumbs from "../global/Breadcrumbs";
import { Link } from "react-router-dom";
import channelHeader from '../../assets/images/channels/channels-header.svg';

const channelsRoute = "/channels/video";
const prevCourseRoute =  "/courses/AWS-CL-FD";

function Channels() {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  const handleClick = () => {
        // alert("Clicked:", channelsRoute);
    navigate(channelsRoute);
  };

  const handleBack = (event) => {
    event.preventDefault();
        // alert("Clicked");
    navigate(prevCourseRoute);
  };
  return (
    <>
      <PageWrapper pageType="channels">
        <section
          className="intro"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}${channelHeader})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center"
          }}
        >
          {/* <div className="breadcrumb-container">
            <button
              className=""
              onClick={(event) => {
                event.stopPropagation();
                handleBack(event);
              }}
            >
              <i className="fa fa-arrow-left" aria-hidden="true"></i>Back
            </button>
          </div> */}
          <Breadcrumbs isBack="true"/>
          <h1
            style={{ color: "white", textAlign: "center", marginTop: "50px" }}
          >
            AWS Academy Cloud Foundations (CLF-C01)
          </h1>

          {/* <p style={{ color: "white" }}>Section to be added</p> */}
        </section>

        <section className="channels">
          <div className="section-body">
            <div className="topic-section">
              <h2>Introduction</h2>

              <h3 className="count">
                <strong>Video</strong> 42
              </h3>
              <Carousel size="304" modifier="dark-blue">
                <Card modifier="video-course">
                  <div className="poster" style={{ display: "relative" }}>
                    <img
                      className="video-img"
                      //   className="complete"
                      src={
                        process.env.PUBLIC_URL +
                        "/images/channels/aws-cloud-practitioner.png"
                      }
                      alt="AWS Certified Cloud Practitioner"
                      onClick={() => handleClick()}
                    />
                    <div className="centered">
                      AWS Certified Cloud Practitioner Training<br></br>
                    </div>
                    <div className="sub-heading"> Introduction</div>
                    <img
                      className="play-btn"
                      src={
                        process.env.PUBLIC_URL +
                        "/images/channels/play-button.svg"
                      }
                      alt="Play Button"
                      onClick={() => handleClick()}
                    />
                    {/* <img className="complete" src={process.env.PUBLIC_URL + "/images/channels/video-watched-28.svg"} alt="Video watched"/> */}
                    <progress value="0" max="1209"></progress>
                  </div>
                  <div className="video-details">
                    <h4 className="video-title">
                    AWS Academy Cloud Foundations (CLF-C01)
                    </h4>
                    <p className="video-author">By Richard A. Jones</p>
                    <img
                      className="video-author-avatar"
                      src="https://cite-media.pearson.com/legacy_paths/8ff53178-bd43-421c-b54d-09783a780a35/RichardJones_aug2020.jpg"
                    />
                    <ul className="video-meta">
                      <li>15 views</li>
                      <li>5 upvotes</li>
                      <li>16 comments</li>
                    </ul>
                  </div>
                </Card>
                <Card modifier="video-course">
                  <div className="poster">
                    <img
                      className="video-img"
                      src={process.env.PUBLIC_URL + "/images/channels/vid2.png"}
                      alt="Basic terminology and concepts of Anatomy and Physiology"
                    />
                    <img
                      className="play-btn"
                      src={
                        process.env.PUBLIC_URL +
                        "/images/channels/play-button.svg"
                      }
                      alt=""
                    />
                    <img
                      className="complete"
                      src={
                        process.env.PUBLIC_URL +
                        "/images/channels/video-watched-28.svg"
                      }
                      alt="Video watched"
                    />
                    <progress value="1209" max="1209"></progress>
                  </div>
                  <div className="video-details">
                    <h4 className="video-title">Lab - Section 2.2</h4>
                    <p className="video-author">By miss Jam</p>
                    <p className="video-author-avatar">mJ</p>
                    <ul className="video-meta">
                      <li>15 views</li>
                      <li>5 upvotes</li>
                      <li>16 comments</li>
                    </ul>
                  </div>
                </Card>
                <Card modifier="video-course">
                  <div className="poster">
                    <img
                      className="video-img"
                      src={process.env.PUBLIC_URL + "/images/channels/vid2.png"}
                      alt="Basic terminology and concepts of Anatomy and Physiology"
                    />
                    <img
                      className="play-btn"
                      src={
                        process.env.PUBLIC_URL +
                        "/images/channels/play-button.svg"
                      }
                      alt=""
                    />
                    {/* <img className="complete" src={process.env.PUBLIC_URL + "/images/channels/video-watched-28.svg"} alt="Video watched"/> */}
                    <progress value="0" max="1209"></progress>
                  </div>
                  <div className="video-details">
                    <h4 className="video-title">
                      Basic terminology and concepts of Anatomy and Physiology
                    </h4>
                    <p className="video-author">By miss Jam</p>
                    <p className="video-author-avatar">mJ</p>
                    <ul className="video-meta">
                      <li>15 views</li>
                      <li>5 upvotes</li>
                      <li>16 comments</li>
                    </ul>
                  </div>
                </Card>
                <Card modifier="video-course">
                  <div className="poster">
                    <img
                      className="video-img"
                      src={process.env.PUBLIC_URL + "/images/channels/vid1.png"}
                      alt="Lab - Section 2.2"
                    />
                    <img
                      className="play-btn"
                      src={
                        process.env.PUBLIC_URL +
                        "/images/channels/play-button.svg"
                      }
                      alt=""
                    />
                    {/* <img className="complete" src={process.env.PUBLIC_URL + "/images/channels/video-watched-28.svg"} alt="Video watched"/> */}
                    <progress value="0" max="1209"></progress>
                  </div>
                  <div className="video-details">
                    <h4 className="video-title">Lab - Section 2.2</h4>
                    <p className="video-author">By miss Jam</p>
                    <p className="video-author-avatar">mJ</p>
                    <ul className="video-meta">
                      <li>15 views</li>
                      <li>5 upvotes</li>
                      <li>16 comments</li>
                    </ul>
                  </div>
                </Card>
              </Carousel>

              <h3 className="count">
                <strong>Practice</strong> 42
              </h3>
              <Carousel size="304" modifier="dark-blue">
                <Card modifier="practice">
                  <p className="type">Multiple Choice</p>
                  <p className="question">
                    Which of the following shos increasing complexity levels?
                    LORME IPSUM DOLAR SIT AMET BOING BOING BOING XOOM POP BOING
                    ZIP
                  </p>
                  <div className="answer-indicator">
                    <img
                      className="complete"
                      src={
                        process.env.PUBLIC_URL +
                        "/images/channels/radio-incorrect-28.svg"
                      }
                      alt="Answer incorrect"
                    />
                  </div>
                  <ul className="practice-meta">
                    <li>15 views</li>
                    <li>5 upvotes</li>
                    <li>16 comments</li>
                    <li className="format">
                      <Icon icon="video" size="14" />
                    </li>
                  </ul>
                </Card>
                <Card modifier="practice">
                  <p className="type">Multiple Choice</p>
                  <p className="question">
                    Which of the following shos increasing complexity levels?
                  </p>
                  <div className="answer-indicator">
                    <img
                      className="complete"
                      src={
                        process.env.PUBLIC_URL +
                        "/images/channels/radio-correct-28.svg"
                      }
                      alt="Answer correct"
                    />
                  </div>
                  <ul className="practice-meta">
                    <li>15 views</li>
                    <li>5 upvotes</li>
                    <li>16 comments</li>
                  </ul>
                </Card>
                <Card modifier="practice">
                  <p className="type">Multiple Choice</p>
                  <p className="question">
                    Which of the following shos increasing complexity levels?
                  </p>
                  {/* <div className="answer-indicator"><img className="complete" src={process.env.PUBLIC_URL + "/images/channels/radio-correct-28.svg"} alt="Answer correct"/></div> */}
                  <ul className="practice-meta">
                    <li>15 views</li>
                    <li>5 upvotes</li>
                    <li>16 comments</li>
                  </ul>
                </Card>
                <Card modifier="practice">
                  <p className="type">Multiple Choice</p>
                  <p className="question">
                    Which of the following shos increasing complexity levels?
                  </p>
                  {/* <div className="answer-indicator"><img className="complete" src={process.env.PUBLIC_URL + "/images/channels/radio-correct-28.svg"} alt="Answer correct"/></div> */}
                  <ul className="practice-meta">
                    <li>15 views</li>
                    <li>5 upvotes</li>
                    <li>16 comments</li>
                  </ul>
                </Card>
              </Carousel>
            </div>
          </div>
        </section>
      </PageWrapper>
    </>
  );
}

export default Channels;
