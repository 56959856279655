import {SET_COURSE_LOCATIONS} from "../../actions/course-details/setCourseLocations";

export default (state = null, {type, courseLocations = null }) => {
	Object.freeze(state);
	switch (type) {
		case SET_COURSE_LOCATIONS:
			return courseLocations;
		default:
			return state;
	}
};
