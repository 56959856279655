/** @format */

import React from 'react';
import Icon from '../../global/Icon';
import { Link } from 'react-router-dom';

const SelectableTable = (props) => {
	console.log('Data in Table', props.data);
	let limitedList =
		props.data && props.data.length > 6 ? props.data.slice(0, 5) : props.data;

	return (
		<div className='table-with-actions'>
			<div className='table-actions'>
				<div className='select-all'>
					<div className='checkbox'>
						<input
							type='checkbox'
							name='select all'
							id='tableSelectAll'
						/>
					</div>
					<div className='dropdown-container'>
						<button
							className='icon-btn'
							aria-label='Select options'
							aria-expanded='false'
						>
							<Icon
								icon='drop-down'
								size='18'
							/>
						</button>
					</div>
				</div>
				<div className='table-options'>
					<div className='pagination'>
						<span className='page-number'>1 of 4</span>
						<button
							className='icon-btn'
							aria-label='Previous'
							disabled
						>
							<Icon
								icon='chevron-left'
								size='24'
							/>
						</button>
						<button
							className='icon-btn'
							aria-label='Next'
						>
							<Icon
								icon='chevron-right'
								size='24'
							/>
						</button>
					</div>
					<button
						className='icon-btn circle-btn'
						aria-label='More options'
					>
						<Icon
							icon='ellipsis'
							size='24'
						/>
					</button>
				</div>
			</div>

			<div className='table-container'>
				<table className='selectable-table'>
					<thead>
						<tr>
							{props.columns.map((column, index) => {
								if (index === 0)
									return (
										<th
											scope='col'
											colSpan={2}
											key={'header' + index}
										>
											{column}
										</th>
									);
								else
									return (
										<th
											scope='col'
											key={'header' + index}
										>
											{column}
										</th>
									);
							})}
							{props.options ? <td /> : <></>}
						</tr>
					</thead>
					<tbody>
						{limitedList ? (
							limitedList.map((entry, index) => {
								return (
									<tr key={'row' + index}>
										<td className='checkbox-cell'>
											<div className='checkbox'>
												<input
													type='checkbox'
													aria-labelledby={entry.id}
												/>
											</div>
										</td>
										<th scope='col'>
											<div className='employee-data'>
												{entry.avatar ? (
													<span className='avatar'>
														<img
															src={entry.avatar}
															alt=''
														/>
													</span>
												) : (
													<></>
												)}
												<Link
													to={process.env.PUBLIC_URL + '/employee/' + entry.id}
													className='employee-info'
												>
													<span
														className='text-body employee-name'
														id={entry.id}
													>
														{entry.firstName} {entry.lastName}
													</span>
													{entry.jobTitle ? (
														<span className='employee-description'>
															{entry.jobTitle} &middot; {entry.city},{' '}
															{entry.state}
														</span>
													) : (
														<></>
													)}
												</Link>
											</div>
										</th>
										<td>{entry.badges.length}</td>
										<td>{entry.skills.length > 0 ? entry.skills.length : 1}</td>
										<td>
											<span className='color-plum'>Connected</span>
										</td>
										<td>
											<span className='color-plum'>Confident</span>
										</td>
										<td>{entry.lastActive}</td>
										{/* {Object.keys(entry).map((prop) => {

                                        if (prop === "title") {
                                            return (

                                            )
                                        } else if (prop === "description" || prop === "avatar") return undefined
                                        else {
                                            return (

                                            )
                                        }
                                    })} */}
										{props.options ? (
											<td className='action-cell'>
												<button
													className='icon-btn circle-btn'
													aria-label='View options name name'
												>
													<Icon
														icon='ellipsis'
														size='24'
													/>
												</button>
											</td>
										) : (
											<></>
										)}
									</tr>
								);
							})
						) : (
							<></>
						)}
					</tbody>
				</table>
			</div>
		</div>
	);
};
export default SelectableTable;
