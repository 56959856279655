import {SET_BADGES} from "../../actions/badges/setBadges";

export default (state = null, {type, badges = null }) => {
	Object.freeze(state);
	switch (type) {
		case SET_BADGES:
			return badges;
		default:
			return state;
	}
};
