import React from "react";

const JobLinks = (props) => {
    return (
        <div className="jobLinks dark-bg">
            { props.children }
        </div>
    )
}

export default JobLinks