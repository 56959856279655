const calculateMargin = (containerClass, childClass) => {
  const container = document.querySelector(`.${containerClass}`);
  let numOfElementsPerRow = 0;
  const childElements = document.querySelectorAll(`.${childClass}`);
    if (container !== null) {
    numOfElementsPerRow = calculateNumElementsInRow(container, childElements);
  }
  return numOfElementsPerRow;
};
function calculateNumElementsInRow(container, childElements) {
  const containerWidth = container?.clientWidth;
  let rowWidth = 0;
  let numElements = 0;
  const numElementsInRow = [];
  let childWidth = 0;

  for (let i = 0; i < childElements.length; i++) {
    const childElement = childElements[i];
    childWidth = childElement.clientWidth;
    const childMargin = parseInt(getComputedStyle(childElement).marginRight);
        if (rowWidth + childWidth + childMargin * 2 <= containerWidth) {
      // Add the child element width and margin to the row width
      rowWidth += childWidth + childMargin * 2;
      numElements++;
    } else {
      // Add the number of elements in this row to the array
      numElementsInRow.push(numElements);

      // Reset the row width and number of elements for the next row
      rowWidth = childWidth + childMargin * 2;
      numElements = 1;
    }
  }

  // Add the number of elements in the last row to the array
  numElementsInRow.push(numElements);
  const rowElements = numElementsInRow[0];
  let offset = 0;
  switch (rowElements) {
    case 4:
      offset = -20;
      break;
    case 3:
      offset = -15;
      break;
    case 2:
      offset = -5;
      break;
    case 1:
      offset = 0;
      break;
    default:
  }
  const unusedSpaceInFront =
    ((containerWidth - rowElements * childWidth) / 2) + offset;
    const formattedSpace = unusedSpaceInFront.toString() + "px";
    return formattedSpace;
}
export default calculateMargin;
