import React, {useState} from "react";
import Icon from "../global/Icon";

const InsightsAccordion = (props) => {
    const [open,setOpen] = useState(false)

    return (
        <div className={`insights-accordion ${props.type}`}>
            <button className="tab-action" aria-expanded={open} onClick={() => setOpen(!open)}>
               <span className="icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className="vert" d="M3 12H21" stroke="var(--color-white)" strokeWidth="2" strokeLinecap="round"/>
                    <path className="horiz" d="M12 21L12 3" stroke="var(--color-white)" strokeWidth="2" strokeLinecap="round"/>
                </svg>
               </span>
                {props.title}    
            </button> 
            {open && 
                <div className="insights-accordion-body">
                    {props.children}
                </div>
            }
        </div>
    )
}
export default InsightsAccordion