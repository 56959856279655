import {SET_INSIGHTS} from "../../actions/pathways/setInsights";

export default (state = null, {type, insights = null }) => {
	Object.freeze(state);
	switch (type) {
		case SET_INSIGHTS:
			return insights;
		default:
			return state;
	}
};
