import { SET_LICENSE_DETAIL } from "../../actions/licenses/setLicenseDetail";

export default (state = null, {type, licenseDetail = null }) => {
	Object.freeze(state);
	switch (type) {
		case SET_LICENSE_DETAIL:
			return licenseDetail;
		default:
			return state;
	}
};
