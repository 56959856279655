/** @format */

import React from 'react';

const ProfileResume = (props) => {
	if (props.data.length > 0) {
		return (
			<section className='employee-experience'>
				<h2>Experience</h2>
				{props.data.map((item, index) => {
					return (
						<article
							className='experience-entry'
							key={'resume' + index}
						>
							<h3 className='experience-title'>{item.title}</h3>
							<div className='company-logo'>
								<span className='company-icon'>
									<img
										src={process.env.PUBLIC_URL + '/images/resume/' + item.logo}
										alt={item.company}
									/>
								</span>
								<span className='company-name'>{item.company}</span>
							</div>
							<p className='experience-duration'>
								{item.start} - {item.end}
							</p>
							<p className='experience-desc'>{item.description}</p>
						</article>
					);
				})}
			</section>
		);
	}
};
export default ProfileResume;
