import {SET_SNAPSHOT} from "../../actions/dashboard/setSnapshots";

export default (state = null, {type, snapshots = null }) => {
	Object.freeze(state);
	switch (type) {
		case SET_SNAPSHOT:
			return snapshots;
		default:
			return state;
	}
};
