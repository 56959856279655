import {SET_COURSE_COMPLETE} from "../../actions/channels/setCourseComplete";

export default (state = null, {type, courseComplete = null }) => {
	Object.freeze(state);
	switch (type) {
		case SET_COURSE_COMPLETE:
			return courseComplete;
		default:
			return state;
	}
};
