/** @format */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { getCourseDetails } from '../../util/getCourseDetails';
import { setCourseInfo } from '../../actions/course-details/setCourseInfo';

import { setCourseLocations } from '../../actions/course-details/setCourseLocations';
import { setExamScheduled } from '../../actions/channels/setExamScheduled';
import locations from '../../util/courseLocations.json';
import GoBack from '../global/GoBack';
import Icon from '../global/Icon';
import { CalendarPicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';

import { styled } from '@mui/material/styles';

import Tabs from '../global/tabs/Tabs';
import TabPanel from '../global/tabs/TabPanel';

const LocalCalendarPicker = styled(CalendarPicker)(({ theme }) => ({
	'& .Mui-selected' : {
		background: "linear-gradient(135deg, #0CC0AD 0%, #2E839E 50.57%, #29657A 100%)"
	}
}));

function ExamSignUp(props) {
	const params = useParams();
	const { courseId } = params;
	const navigate = useNavigate()

	const handleNav = (e) => {
		e.preventDefault()
		props.setExamScheduled(true)
		navigate("/")
	}

	useEffect(() => {
		if (!props.courseLocations) {
			props.setCourseLocations(locations);
		}
		if (!props.course) {
			getCourseDetails(courseId).then((data) => {
				props.setCourseInfo(data);
			});
		}
	}, [courseId]);

	// TODO: set this as friday of whatever week it is
	const [date, setDate] = useState(dayjs('2023-04-28'));

	if (props.courseLocations && props.course) {
		return (
			<div className={'authorized'}>
				<div className='pageWrapper course'>
					<main>
						

						<section style={{paddingTop:"32px"}}>
						<GoBack />
							<h2 style={{ marginBottom: "40px" }}>{props.course.name}</h2>
							<h3>Schedule your exam</h3>

							<div className="card calendarWrapper" style={{ margin: "15px 0 25px", padding : "10px 0" }}>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<LocalCalendarPicker date={date} onChange={(newDate) => setDate(newDate)} />
								</LocalizationProvider>
							</div>

							<div>
								<Tabs modifier="pill">
									<TabPanel label="At Center" id="reskillTab">
										<div>
											<div className='activity-list-search' style={{ padding: "0" }}>
												<div className='search search-input'>
													<input
														type={'text'}
														name='Search bar'
														aria-label='Search'
														placeholder='Find your test center'
														className='gr-input'
													/>
													<span className='search-icon'>
														<Icon
															icon='search'
															size='18'
														/>
													</span>
												</div>
												<div className='filter'>
													<button className='button-unstyled'>
														<Icon
															icon='filter-alternative'
															size='18'
														/>
														Filter
													</button>
												</div>
											</div>
										</div>
										<ul className='locations-list'>
											{props.courseLocations.map((location, i) => {
												const activeCard = i == 2 ? "card active" : "card"
												const activeStyles = i == 2 ? {outline : "2px solid #0CC0AD", outlineOffset : "2px"} : {}
												return (
													<li
														key={location.id}
														className={activeCard}
														style={activeStyles}
													>
														<Link to={`/courses/${courseId}/${location.id}/checkout`}>
															<h4>{location.name}</h4>
															<div className='location-address'>
																{location.address}
															</div>

															<div className='location-info'>
																<div className='location-instuctor'>
																	<span className='icon-wrap'>
																		<Icon
																			icon='people'
																			size='24'
																		/>
																	</span>
																	{location.instructor}
																</div>
																<div className='location-hours'>
																	<span className='icon-wrap'>
																		<Icon
																			icon='generic'
																			size='18'
																		/>
																	</span>
																	{location.hours}
																</div>
																<div className='location-type'>
																	<span className='icon-wrap'>
																		<Icon
																			icon='generic'
																			size='18'
																		/>
																	</span>
																	{location.type}
																</div>
															</div>
														</Link>
													</li>
												);
											})}
										</ul>
										<button onClick={handleNav} className='button-primary btn-centered'>Confirm</button>
									</TabPanel>
									<TabPanel label="At Online" id="reskillTab">
										Not active right now
									</TabPanel>
								</Tabs>

							</div>
						</section>
					</main>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ session, courseLocations, course, examScheduled }) => ({
	session,
	courseLocations,
	course,
	examScheduled,
});

const mapDispatchToProps = (dispatch) => ({
	setCourseLocations: (data) => dispatch(setCourseLocations(locations)),
	setCourseInfo: (data) => dispatch(setCourseInfo(data)),
	setExamScheduled: (data) => dispatch(setExamScheduled(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExamSignUp);
