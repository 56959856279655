/** @format */

import React from 'react';
import { Link } from 'react-router-dom';
import Card from './Card';
import Icon from '../Icon';

const LearningCard = (props) => {
	let badge = props.data;

	return (
		<Card modifier='learning'>
			<Link
				className='learning-link'
				to={process.env.PUBLIC_URL + '/courses/' + badge.id}
			>
				<span className='program-img'>
					<img
						src={badge.image}
						alt={badge.name}
					/>
				</span>
				<h3 className='card-title'>{badge.name}</h3>
			</Link>
			{badge.slug ? (
				<p className='card-text'>
					<Link
						to={
							process.env.PUBLIC_URL +
							/issuer/ +
							badge.slug.toLowerCase().replace(/\s/g, '-')
						}
						className='card-link'
					>
						{badge.issuer}
					</Link>
				</p>
			) : (
				<p className='card-text'>
					<a className='card-link'>{badge.issuer}</a>
				</p>
			)}
			<ul className='feature-list'>
				<li className='card-text'>
					<span className='circle-icon'>
						<Icon
							icon='dollar-sign'
							size='24'
						/>
					</span>
					${badge.cost}
				</li>
				<li className='card-text'>
					<span className='circle-icon'>
						<Icon
							icon='layer-group'
							size='24'
						/>
					</span>
					{badge.level}
				</li>
				<li className='card-text'>
					<span className='circle-icon'>
						<Icon
							icon='timer'
							size='24'
						/>
					</span>
					{badge.time} {badge.timeUnit}
				</li>
				<li className='card-text'>
					<span className='circle-icon'>
						<Icon
							icon='award'
							size='24'
						/>
					</span>
					{badge.type}
				</li>
			</ul>
			{props.details ? (
				<Link
					className='button-tertiary'
					to={process.env.PUBLIC_URL + '/courses/' + badge.id}
					id={badge.id}
				>
					Details
				</Link>
			) : (
				<></>
			)}
		</Card>
	);
};
export default LearningCard;
