import React from "react";
import SkillCard from "./SkillCard";

const ProfileSkills = (props) => {

    return (
        <section className="employee-skills dark-gradient-bg">
            <h2>Skills</h2>
            <div className="overflow-scroll">
                <div className="card-row">
                    {props.data.map((group,index) => {

                        return <SkillCard data={group} key={"group"+index}/>
                    })}
                </div>
                
            </div>

        </section>
    )
}
export default ProfileSkills