import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Icon from "../../global/Icon";

const InstructorClassesTable = (props) => {
    const [sorted, setSorted] = useState({column:0,dir:"descending"});

    const navigate = useNavigate();

    const sortColumn = (index) => {
        //need to bring in data and then transform it still
        if (sorted.column === index) {
            if (sorted.dir === "descending") {
                setSorted({...sorted, dir:"ascending"})
            } else {
                setSorted({...sorted, dir:"descending"})
            }
        } else {
            setSorted({column:index, dir:"descending"})
        }
    }
    
    function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
      }

    return (
        <div className="table-container">
                <table className="sortable-table" aria-label="Classes">
                    <thead>
                        <tr>
                            <th scope="col" aria-sort={sorted.column === 0 ? sorted.dir : "none"}>
                                <button className="sort-btn button-unstyled" onClick={() => sortColumn(0)}>
                                    Class 
                                    <span className="icon">
                                        {sorted.column === 0 ? <Icon icon={sorted.dir} size="18"/> : <Icon icon="sortable" size="18"/>}
                                    </span>
                                </button>
                            </th>
                            <th scope="col" aria-sort={sorted.column === 1 ? sorted.dir : "none"}>
                                <button className="button-unstyled sort-btn" onClick={() => sortColumn(1)}>
                                    Purchased seats 
                                    <span className="icon">
                                        {sorted.column === 1 ? <Icon icon={sorted.dir} size="18"/> : <Icon icon="sortable" size="18"/>}
                                    </span>
                                </button>
                            </th>
                            <th scope="col" aria-sort={sorted.column === 2 ? sorted.dir : "none"}>
                                <button className="button-unstyled sort-btn" onClick={() => sortColumn(2)}>
                                   CCX ID 
                                    <span className="icon">
                                        {sorted.column === 2 ? <Icon icon={sorted.dir} size="18"/> : <Icon icon="sortable" size="18"/>}
                                    </span>
                                </button>
                            </th>
                            <th scope="col" aria-sort={sorted.column === 3 ? sorted.dir : "none"}>
                                <button className="button-unstyled sort-btn" onClick={() => sortColumn(3)}>
                                    CCX Name
                                    <span className="icon">
                                        {sorted.column === 3 ? <Icon icon={sorted.dir} size="18"/> : <Icon icon="sortable" size="18"/>}
                                    </span>
                                </button>
                            </th>
                            
                            <th scope="col" aria-sort={sorted.column === 5 ? sorted.dir : "none"}>
                                <button className="button-unstyled sort-btn" onClick={() => sortColumn(5)}>
                                    Students enrolled
                                    <span className="icon">
                                        {sorted.column === 5 ? <Icon icon={sorted.dir} size="18"/> : <Icon icon="sortable" size="18"/>}
                                    </span>
                                </button>
                            </th>
                            <th scope="col" aria-sort={sorted.column === 6 ? sorted.dir : "none"}>
                                <button className="button-unstyled sort-btn" onClick={() => sortColumn(6)}>
                                    Actions
                                    <span className="icon">
                                        {sorted.column === 6 ? <Icon icon={sorted.dir} size="18"/> : <Icon icon="sortable" size="18"/>}
                                    </span>
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.data ? <>
                        {props.data.map((item, index) => {
                            
                            if (item.name.length > 0) return (
                                <tr key={`license-${index}`} >
                                     <th scope="row">
                                        <div className="employee-data">
                                            
                                            <div className="employee-info">
                                                <span className="employee-name"> {item.name}</span>                                                
                                            </div>
                                        </div>              
                                    </th>
                                    <td>
                                     {item.purchasedSeats ? item.purchasedSeats : "65"}
                                    </td>
                                    <td>
                                       {item.ccxId ? item.ccxId : "ccx-v1:VUE+ 9780136721390+2023+ccx@..."}
                                    </td>
                                    <td>
                                        {item.ccxName ? item.ccxName : "Cohort " + getRandomInt(1,9) + "/1 " + item.id + " 101"}
                                    </td>
                                
                                     <td>
                                        {item.enrolled ? item.enrolled : getRandomInt(1,65)}
                                    </td>
                                    <td>
                                        <button className="link-btn">
                                            Copy url
                                        </button>
                                    </td>
                                   
                                </tr>)
                            else return null
                            
                        })} 
                    </> : <tr><td colSpan={6}>No classes</td></tr>}
                    </tbody>
                </table>
        </div>
    )
}
export default InstructorClassesTable