/** @format */

import React from 'react';
import Card from '../global/cards/Card';
import PageWrapper from '../global/PageWrapper';
import Icon from '../global/Icon';
import Footer from '../global/Footer';
import RadialChart from '../global/infographics/RadialChart';

const Surveys = (props) => {
	const netImpact = [
		{
			name: 'total',
			value: 20,
			fill: '#eee',
		},
		{
			name: 'net impact',
			value: 13,
			fill: '#6D0176',
		},
	];

	const average = [
		{
			name: 'total',
			value: 10,
			fill: '#eee',
		},
		{
			name: 'average',
			value: 7.72,
			fill: '#6D0176',
		},
	];

	const top2 = [
		{
			name: 'total',
			value: 100,
			fill: '#eee',
		},
		{
			name: 'top 2 % box',
			value: 39,
			fill: '#6D0176',
		},
	];

	return (
		<PageWrapper pageType='surveys'>
			<div className='surveys-page'>
				<div className='activity-list-search'>
					<div className='search search-input'>
						<input
							type={'text'}
							name='Search bar'
							aria-label='Search'
							placeholder='Search workforce'
							className='gr-input'
						/>
						<span className='search-icon'>
							<Icon
								icon='search'
								size='18'
							/>
						</span>
					</div>
					<div className='filter'>
						<button className='button-unstyled'>
							<Icon
								icon='filter-alternative'
								size='18'
							/>
							Filter
						</button>
					</div>
				</div>
				<section className='dark-bg'>
					<div
						className='analytics-actions section-row'
						style={{ display: 'flex' }}
					>
						<div style={{ flex: 1 }} />
						<button className='button-link'>
							<Icon
								icon='export'
								size='24'
							/>{' '}
							Export
						</button>
					</div>
					<div className='card-list'>
						<Card modifier='overview'>
							<button className='icon-btn info-btn'>
								<span className='info-icon'>
									<Icon
										icon='info'
										size='18'
									/>
								</span>
							</button>
							<span className='label'>Net impact</span>
							<RadialChart
								data={netImpact}
								label='13'
								impact='18'
							/>
						</Card>
						<Card modifier='overview'>
							<button className='icon-btn info-btn'>
								<span className='info-icon'>
									<Icon
										icon='info'
										size='18'
									/>
								</span>
							</button>
							<span className='label'>Average (0-10)</span>
							<RadialChart
								data={average}
								label='7.72'
								impact='8.37'
							/>
						</Card>
						<Card modifier='overview'>
							<button className='icon-btn info-btn'>
								<span className='info-icon'>
									<Icon
										icon='info'
										size='18'
									/>
								</span>
							</button>
							<span className='label'>% top 2 box</span>
							<RadialChart
								data={top2}
								label='39'
								impact='68'
							/>
						</Card>
					</div>
				</section>
				<section>
					<div className='section-header'>
						<h2>Program health</h2>
						<button className='button-link with-icon'>
							<Icon
								icon='date'
								size='24'
							/>
							1 Oct 2022 - 31 Oct 2022
						</button>
					</div>
					<div className='section-body card'>
						<h3>
							Answer summary{' '}
							<span className='label'>
								(Color coding relative to overall scores )
							</span>
						</h3>
						<div className='labeled'>
							<div className='label'>Limited to</div>
							<div className='value'>
								Acme Learning Experience Assessment - Instructor Led
							</div>
						</div>
						<div className='labeled'>
							<div className='label'>Experience attributes</div>
							<div className='value'>
								Name: Introduction to Leadership, 10.25.18, End Date: Oct 25,
								2018, Course: Introduction to Leadership, Modality: Classroom,
								Location: Chicago, Instructor: Aaron Overbrook
							</div>
						</div>
						<div className='data-table'>
							<table>
								<thead>
									<tr>
										<th>Total number of responses</th>
										<th>N</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>22</td>
										<td>21</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div className='section-body'>
						<div className='side-nav card'>
							<ul className='v-card'>
								<li>
									<a
										href='#'
										className='active'
									>
										Summary
									</a>
								</li>
								<li>
									<a href='#'>Question Detail</a>
								</li>
								<li>
									<a href='#'>Crosstab</a>
								</li>
								<li>
									<a href='#'>Qualitative Analysis</a>
								</li>
								<li>
									<a href='#'>Experiences</a>
								</li>
								<li>
									<a href='#'>Individuals</a>
								</li>
								<li>
									<a href='#'>Respondents</a>
								</li>
								<li>
									<a href='#'>Comments</a>
								</li>
							</ul>
						</div>
						<div className='right-pane'>
							<div className='two-column'>
								<div className='col-1 card'>
									<h3>Top results</h3>
									<b>Top Question</b>
									<br />
									How likely is it that you would recommend this experience to a
									friend or colleague?
									<br />
									<br />
									<b>Top Respondent Group</b>
									<br />
									Function - Finance
									<br />
								</div>
								<div className='col-2 card'>
									<h3>Bottom results</h3>
									<b>Bottom Question</b>
									<br />
									How much will this experience contribute to business results?
									<br />
									<br />
									<b>Bottom Respondent Group</b>
									<br />
									Function - No Response
									<br />
								</div>
							</div>
							<div className='one-column card'>
								<h3>Question category scores</h3>
								<table>
									<thead>
										<tr>
											<th>CATEGORY</th>
											<th>NET IMPACT</th>
											<th>AVG. (0-10)</th>
											<th>% TOP 2 BOX</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>Overall Assessment</td>
											<td>13</td>
											<td>7.72</td>
											<td>39</td>
										</tr>
										<tr>
											<td>Overall Experience</td>
											<td>53</td>
											<td>8.37</td>
											<td>68</td>
										</tr>
										<tr>
											<td>Instructor</td>
											<td>43</td>
											<td>8.50</td>
											<td>50</td>
										</tr>
										<tr>
											<td>Environment</td>
											<td>15</td>
											<td>7.62</td>
											<td>31</td>
										</tr>
										<tr>
											<td>Content</td>
											<td>8</td>
											<td>7.62</td>
											<td>31</td>
										</tr>
										<tr>
											<td>Knowledge Gain</td>
											<td>0</td>
											<td>7.58</td>
											<td>25</td>
										</tr>
										<tr>
											<td>Job Impact</td>
											<td>-13</td>
											<td>7.47</td>
											<td>33</td>
										</tr>
										<tr>
											<td>Business Outcomes</td>
											<td>-29</td>
											<td>6.64</td>
											<td>21</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className='one-column card'>
								<h3>Comment summary</h3>
								<div
									words='answerSummary.words'
									id='cloud_overall'
									style={{
										width: '100%',
										height: '250px',
										position: 'relative',
										overflowX: 'hidden',
									}}
									auto-resize='true'
									fontSize='{from:0.09, to:0.04}'
									steps='7'
									className='jqcloud'
								>
									<span
										id='cloud_overall_word_0'
										className='jqcloud-word w7'
										style={{
											fontSize: '82px',
											position: 'absolute',
											left: '363.625px',
											top: '77.5px',
										}}
									>
										great
									</span>
									<span
										id='cloud_overall_word_1'
										className='jqcloud-word w5'
										style={{
											fontSize: '67px',
											position: 'absolute',
											left: '559.847px',
											top: '79.4285px',
										}}
									>
										very
									</span>
									<span
										id='cloud_overall_word_2'
										className='jqcloud-word w3'
										style={{
											fontSize: '52px',
											position: 'absolute',
											left: '228.416px',
											top: '72.0879px',
										}}
									>
										good
									</span>
									<span
										id='cloud_overall_word_3'
										className='jqcloud-word w3'
										style={{
											fontSize: '52px',
											position: 'absolute',
											left: '379.428px',
											top: '176.972px',
										}}
									>
										training
									</span>
									<span
										id='cloud_overall_word_4'
										className='jqcloud-word w2'
										style={{
											fontSize: '44px',
											position: 'absolute',
											left: '144.894px',
											top: '147.078px',
										}}
									>
										employee
									</span>
									<span
										id='cloud_overall_word_5'
										className='jqcloud-word w2'
										style={{
											fontSize: '44px',
											position: 'absolute',
											left: '727.396px',
											top: '84.0923px',
										}}
									>
										some
									</span>
									<span
										id='cloud_overall_word_6'
										className='jqcloud-word w1'
										style={{
											fontSize: '37px',
											position: 'absolute',
											left: '419.085px',
											top: '31.5465px',
										}}
									>
										better
									</span>
									<span
										id='cloud_overall_word_7'
										className='jqcloud-word w1'
										style={{
											fontSize: '37px',
											position: 'absolute',
											left: '192.808px',
											top: '27.634px',
										}}
									>
										concepts
									</span>
									<span
										id='cloud_overall_word_8'
										className='jqcloud-word w1'
										style={{
											fontSize: '37px',
											position: 'absolute',
											left: '30.3805px',
											top: '84.1558px',
										}}
									>
										content
									</span>
									<span
										id='cloud_overall_word_9'
										className='jqcloud-word w1'
										style={{
											fontSize: '37px',
											position: 'absolute',
											left: '556.057px',
											top: '161.572px',
										}}
									>
										could
									</span>
									<span
										id='cloud_overall_word_10'
										className='jqcloud-word w1'
										style={{
											fontSize: '37px',
											position: 'absolute',
											left: '623.611px',
											top: '18.8571px',
										}}
									>
										helpful
									</span>
									<span
										id='cloud_overall_word_11'
										className='jqcloud-word w1'
										style={{
											fontSize: '37px',
											position: 'absolute',
											left: '725.512px',
											top: '142.687px',
										}}
									>
										instructor
									</span>
									<span
										id='cloud_overall_word_12'
										className='jqcloud-word w1'
										style={{
											fontSize: '37px',
											position: 'absolute',
											left: '362.814px',
											top: '27.8721px',
										}}
									>
										job
									</span>
									<span
										id='cloud_overall_word_13'
										className='jqcloud-word w1'
										style={{
											fontSize: '37px',
											position: 'absolute',
											left: '22.5933px',
											top: '22.9084px',
										}}
									>
										learned
									</span>
									<span
										id='cloud_overall_word_14'
										className='jqcloud-word w1'
										style={{
											fontSize: '37px',
											position: 'absolute',
											left: '35.2848px',
											top: '145.315px',
										}}
									>
										new
									</span>
									<span
										id='cloud_overall_word_15'
										className='jqcloud-word w1'
										style={{
											fontSize: '37px',
											position: 'absolute',
											left: '162.953px',
											top: '94.6418px',
										}}
									>
										not
									</span>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
			<Footer />
		</PageWrapper>
	);
};
export default Surveys;
