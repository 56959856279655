import {SET_COMPANIES} from "../../actions/companies/setCompanies";

export default (state = null, {type, companies = null }) => {
	Object.freeze(state);
	switch (type) {
		case SET_COMPANIES:
			return companies;
		default:
			return state;
	}
};
