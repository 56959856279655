import {FIT_SCORE} from "../../actions/dashboard/setScores";

export default (state = null, {type, scores = null }) => {
	Object.freeze(state);
	switch (type) {
		case FIT_SCORE:
			return scores;
		default:
			return state;
	}
};
