/** @format */

import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import SignIn from '../../components/auth/SignIn';
export const AuthRoute = (path, Component, loggedIn, data) => {
		return (
		<Route
			exact
			path={path}
			element={loggedIn ? <Navigate to='/dashboard' /> : <SignIn />}
		/>
	);
};
