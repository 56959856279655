/** @format */

import React from 'react';
import { useSelector } from 'react-redux';
import AvatarList from './AvatarList';
import Icon from '../../global/Icon';
import { Link } from 'react-router-dom';

const ActivityList = (props) => {
	const badgestate = useSelector((state) => state.badges);

	const badgeList = () => {
		var badgelist = [];
		var badges = [];

		if (props.badges && props.badges.length > 0) {
			props.badges.forEach((badge) => {
				badgelist.push(badge);
			});
		} else {
			badgelist.push(badgestate[0]);
		}

		var avatars = [];
		avatars.push(
			<>
				<AvatarList
					users={[
						{
							user: 'Jonathan Doe',
							avatar: 'images/avatars/cherise_dodson.png',
						},
						{
							user: 'Jonathan Doe',
							avatar: 'images/avatars/inaaya_schmidt.png',
						},
						{ user: 'Jonathan Doe', avatar: 'images/avatars/nick_wallace.png' },
						{ user: 'Jonathan Doe', avatar: 'images/avatars/nick_wallace.png' },
					]}
				/>{' '}
				Gillian Reed + 3 others earned:
			</>
		);
		avatars.push(
			<>
				<AvatarList
					users={[
						{ user: 'Jonathan Doe', avatar: 'images/avatars/nick_wallace.png' },
					]}
				/>{' '}
				Nick Wallace earned:
			</>
		);
		avatars.push(
			<>
				<AvatarList
					users={[
						{
							user: 'Jonathan Doe',
							avatar: 'images/avatars/inaaya_schmidt.png',
						},
						{
							user: 'Jonathan Doe',
							avatar: 'images/avatars/cherise_dodson.png',
						},
					]}
				/>{' '}
				George Sullivan + 1 other earned:
			</>
		);
		avatars.push(avatars[0]);
		avatars.push(avatars[1]);
		avatars.push(avatars[2]);

		badgelist.forEach((badge, i) => {
			badges.push(
				<li
					className='activity-item'
					key={badge.id}
				>
					<div className='activity-desc'>
						<div className='activity-img'>
							<Link to={process.env.PUBLIC_URL + '/courses/' + badge.id}>
								<img
									src={badge.image}
									alt={badge.name}
								/>
							</Link>
						</div>
						<div className='activity-info'>
							<div className='users-label'>{avatars[i]}</div>
							<h3>
								<Link to={process.env.PUBLIC_URL + '/courses/' + badge.id}>
									{badge.name}
								</Link>
							</h3>
							{badge.slug ? (
								<p className=''>
									Issued by{' '}
									<Link
										to={
											process.env.PUBLIC_URL +
											/issuer/ +
											badge.slug.toLowerCase().replace(/\s/g, '-')
										}
										className=''
									>
										{badge.issuer}
									</Link>
								</p>
							) : (
								<p>
									Issued by <a>{badge.issuer}</a>
								</p>
							)}
						</div>
					</div>
					<button
						className='button--primary'
						onClick={props.congratsHandler}
						id={badge.id}
					>
						Congratulate
					</button>
				</li>
			);
		});

		return badges;
	};

	return (
		<div
			className={
				props.search
					? 'activity-list-container search-open'
					: 'activity-list-container'
			}
		>
			{props.search ? (
				<div className='activity-list-search'>
					<div className='search search-input'>
						<input
							type={'text'}
							name='Search bar'
							aria-label='Search'
							placeholder='Search activity'
							className='gr-input'
						/>
						<span className='search-icon'>
							<Icon
								icon='search'
								size='18'
							/>
						</span>
					</div>
					<div className='filter'>
						<button className='button-unstyled'>
							<Icon
								icon='filter-alternative'
								size='18'
							/>
							Filter
						</button>
					</div>
				</div>
			) : (
				<></>
			)}
			<ul className='activity-list'>{badgeList()}</ul>
		</div>
	);
};
export default ActivityList;
