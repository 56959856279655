import React from 'react';


const HorizontalMeter = props => {
    const width = 100 * props.data.value / props.data.total
  
    return (
        <div className='progress-bar-container'>
            <div className='progress-bar-labels'>
                <span className='bar-title'>{props.data.name}</span>
                <span className='bar-value'>{props.data.value.toLocaleString("en-US")}</span>
            </div>
            <div className='bar-container' role="img" aria-label={`${width}%`}>
                <div className='bar' style={{width:`${width}%`, backgroundColor: `${props.fill}`}} />
            </div>
        </div>
    );
  
}
export default HorizontalMeter
