/** @format */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../global/Header';
import GoBack from '../global/GoBack';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Icon from '../global/Icon';
import { getCourseDetails } from '../../util/getCourseDetails';
import { setCourseInfo } from '../../actions/course-details/setCourseInfo';
import { setCourseLocations } from '../../actions/course-details/setCourseLocations';
import locations from '../../util/courseLocations.json';
import { setPurchasedCourse } from '../../actions/course-details/setPurchasedCourse';

import { styled } from '@mui/material/styles';

const Accordion = styled(MuiAccordion)(({ theme }) => ({
	border: `none`,
	'&:before': {
		display: 'none',
	},
}));

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
	'& .MuiAccordionSummary-content': {
		margin: theme.spacing(0),
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(1),
}));

function CourseCheckout(props) {
	const navigate = useNavigate();
	const [expanded, setExpanded] = React.useState('panel2');

	const params = useParams();
	const { courseId, locationId } = params;
	const [selectedCourseLocation, setSelectedCourseLocation] = useState([]);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	// when the purchased button is clicked, set the purchased course to props.purchasedCourse
	function handlePurchaseClick() {
		let data = {};
		const path = window.location.pathname;
		const pathParts = path.split('/');
		const locPart = pathParts[3];

		data.courseId = props.course.id;
		data.location = locPart;
		data.courseData = props.course;

		props.setPurchasedCourse(data);
		navigate('/dashboard', {
			state: {
				coursePurchased: true,
			},
		});
	}

	useEffect(() => {
		if (!props.courseLocations) {
			props.setCourseLocations(locations);
		}
		if (!props.course) {
			getCourseDetails(courseId).then((data) => {
				props.setCourseInfo(data);
			});
		}

		var selCourseLocation = locations.filter((a) => a.id === locationId);
		setSelectedCourseLocation(...selCourseLocation);
	}, [courseId, locationId]);

	//
	if (props.course && props.courseLocations) {
		return (
			<div className={'authorized'}>
				<div className='pageWrapper checkout'>
					<Header variant='checkout' />
					<main>

						<section>
							<GoBack />
							<div className='card'>
								<h3>Payment</h3>
								<p>
									Please pay using a credit card, PayPal or Apple Pay, or
									employer credit
								</p>
								<Accordion
									expanded={expanded === 'panel1'}
									onChange={handleChange('panel1')}
									elevation={0}
									disableGutters
									square
								>
									<AccordionSummary
										disableGutters
										elevation={0}
										expandIcon={
											<Icon
												icon='expand'
												size='24'
											/>
										}
										aria-controls='panel1bh-content'
										id='panel1bh-header'
									>
										<h4>Checkout with credit card</h4>
									</AccordionSummary>
									<AccordionDetails>
										<div className='form-input'>
											<label htmlFor='card'>Card number</label>
											<input className='gr-input' type="text" id="card"/>
										</div>
									</AccordionDetails>
								</Accordion>
								{props.session.type === 'enterprise' &&
								<Accordion
									expanded={expanded === 'panel2'}
									onChange={handleChange('panel2')}
									elevation={0}
								>
									<AccordionSummary
										expandIcon={
											<Icon
												icon='expand'
												size='24'
											/>
										}
										aria-controls='panel2bh-content'
										id='panel2bh-header'
									>
										<h4>Checkout with employer credit</h4>
									</AccordionSummary>
									<AccordionDetails>
										<div className='employer-credit'>
											<span>
												<p>$5,000 credit from Quad Bank</p>
												<p className='sub-text'>expires 12/32/2023</p>
											</span>
										</div>
									</AccordionDetails>
								</Accordion> }
							</div>
						</section>
						<section>
							<div className='card'>
								<ul className='cart'>
									<li>
										<div className='item-name'>{props.course.name}</div>
										<div className='item-cost'>${props.course.cost}</div>
									</li>
									<li style={{ alignItems: 'center', gap: '20px' }}>
										<img
											src={props.course.image}
											alt=''
										/>
										<div className='course-specifics'>
											<span>Instructor-Led</span>
											<span>{selectedCourseLocation.name}</span>
											<span>{selectedCourseLocation.type}</span>
										</div>
									</li>
									<li className='promo-code'>
										<span>Promo code</span>
										<Icon
											icon='expand'
											size='24'
										/>
									</li>
									<li className='cart-extras'>
										<span>Subscription term</span>
										<span>unlimited access</span>

										<span>Estimated tax</span>
										<span>$0.00</span>

										<span>
											<strong>Due today</strong>
										</span>
										<span>
											{props.session.type === 'enterprise' ? <strong>$0.00</strong> : <strong>$1,995</strong> }
										</span>
									</li>
								</ul>
							</div>
						</section>
						<div className='page-action'>
							<button
								className='button-primary'
								onClick={handlePurchaseClick}
							>
								Purchase
							</button>
						</div>
					</main>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({
	session,
	course,
	courseLocations,
	purchasedCourse,
}) => ({
	session,
	course,
	courseLocations,
	purchasedCourse,
});

const mapDispatchToProps = (dispatch) => ({
	setCourseInfo: (data) => dispatch(setCourseInfo(data)),
	setCourseLocations: (data) => dispatch(setCourseLocations(data)),
	setPurchasedCourse: (data) => dispatch(setPurchasedCourse(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseCheckout);
