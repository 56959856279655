/** @format */

import React, { useRef, useEffect, useState } from 'react';
import Tabs from '../global/tabs/Tabs';
import TabPanel from '../global/tabs/TabPanel';
import Breadcrumbs from '../global/Breadcrumbs';
import Icon from '../global/Icon';
import Card from '../global/cards/Card';
import { Link } from 'react-router-dom';
import { setBadges } from '../../actions/badges/setBadges';
import AUTH_BASE_URL from '../../util/AUTH_BASE_URL';
import { connect } from 'react-redux';

const SearchPage = (props) => {
	const [savedBadges, setSavedBadges] = useState([]);

	const toast = useRef();
	const header = useRef();

	const someBadges = props.badges
		? [
				props.badges[2],
				props.badges[5],
				props.badges[6],
				props.badges[9],
				props.badges[11],
		  ]
		: [];
	const courseraBadges = props.badges
		? [props.badges[0], props.badges[1], props.badges[3]]
		: [];

	useEffect(() => {
		fetch(`${AUTH_BASE_URL}badges/all`)
			.then((response) => response.json())
			.then((data) => {
				props.setBadges(data);
			});
	}, []);

	useEffect(() => {
		//create svved badges initial state
		if (props.badges) setSavedBadges([props.badges[7], props.badges[8]]);
	}, [props.badges]);

	useEffect(() => {
		const modal = document.querySelector('.modal-window');
		modal.addEventListener('scroll', onScroll);

		return () => {
			modal.removeEventListener('scroll', onScroll);
		};
	});

	function onScroll() {
		const modal = document.querySelector('.modal-window');
		if (modal.scrollTop > 60) {
			console.log('shrink header');
			header.current.classList.add('sticky');
		} else {
			console.log('expand header');
			header.current.classList.remove('sticky');
		}
	}

	const addToBookmarks = (badge) => {
		setSavedBadges([...savedBadges, badge]);

		const toaster = toast.current;

		setTimeout(() => {
			toaster.classList.add('show');
			setTimeout(() => {
				toaster.classList.replace('show', 'animate-out');
			}, 5000);
			setTimeout(() => {
				toaster.classList.remove('animate-out');
			}, 5500);
		}, 200);
	};
	const removeFromBookmarks = (badge) => {
		let existingSaved = [...savedBadges];

		const position = existingSaved.findIndex((p) => p.id === badge.id);

		existingSaved.splice(position, 1);
		setSavedBadges(existingSaved);
	};

	const compareToSaved = (badge) => {
		// console.log(savedBadges)
		const position = savedBadges.findIndex((p) => p.id === badge.id);
		if (position >= 0) {
			return true;
		} else {
			return false;
		}
	};

	const bookmarkIt = (boo, badge) => {
		if (boo) {
			removeFromBookmarks(badge);
		} else {
			addToBookmarks(badge);
		}
	};

	return (
		<div className='pageWrapper search-page'>
			<main>
				<section
					className='intro dark-bg'
					ref={header}
				>
					<Breadcrumbs isBack='true' />
					<div className='scroll-hide'>
						<h1>Level-up your career</h1>
						<p className='tagline'>
							Explore new skills and credentials just for you
						</p>
					</div>
					<div className='search-actions'>
						<div className='search search-input'>
							<input
								type={'text'}
								name='Search bar'
								aria-label='Search'
								placeholder='Search all credentials'
								className='gr-input'
							/>
							<span className='search-icon'>
								<Icon
									icon='search'
									size='24'
								/>
							</span>
						</div>
						<div className='filter'>
							<button className='icon-btn'>
								<Icon
									icon='filter-alternative'
									size='18'
								/>
							</button>
						</div>
					</div>
				</section>
				<section className='search-contents'>
					<Tabs>
						<TabPanel
							label='Recommended'
							id='tabOnePanel'
						>
							<section className='badge-group'>
								<div className='badge-group-header'>
									<h3>
										<span className='tagline'>Trending credentials for</span>
										<span className=''>IT Support Specialist</span>
									</h3>
									<button className='link-btn'>View more</button>
								</div>
								{someBadges.map((badge, index) => {
									if (badge) {
										return (
											<Card
												key={index}
												modifier='search-result'
											>
												<Link
													className='learning-link'
													to={process.env.PUBLIC_URL + '/courses/' + badge.id}
												>
													<span className='program-img'>
														<img
															src={badge.image}
															alt={badge.name}
														/>
													</span>
													<div className='badge-info'>
														<h3 className='card-title'>{badge.name}</h3>
														<p className='card-text'>{badge.issuer}</p>
														<p className='card-features'>
															{badge.time} {badge.timeUnit} &bull; ${badge.cost}{' '}
															&bull; {badge.level} &bull; {badge.type}
														</p>
													</div>
												</Link>
												<button
													className='icon-btn bookmark'
													aria-pressed={compareToSaved(badge)}
													onClick={() =>
														bookmarkIt(compareToSaved(badge), badge)
													}
												>
													<Icon
														icon={
															compareToSaved(badge)
																? 'bookmark-on'
																: 'bookmark-off'
														}
														size='24'
													/>
												</button>
											</Card>
										);
									}
								})}
							</section>
							<section className='badge-group'>
								<div className='badge-group-header'>
									<h3>
										<span className='tagline'>Because you earned</span>
										<span className=''>Credentials from Coursera</span>
									</h3>
									<button className='link-btn'>View more</button>
								</div>
								{courseraBadges.map((badge, index) => {
									return (
										<Card
											modifier='search-result'
											key={'coursera' + index}
										>
											<Link
												className='learning-link'
												to={process.env.PUBLIC_URL + '/courses/' + badge.id}
											>
												<span className='program-img'>
													<img
														src={badge.image}
														alt={badge.name}
													/>
												</span>
												<div className='badge-info'>
													<h3 className='card-title'>{badge.name}</h3>
													<p className='card-text'>{badge.issuer}</p>
													<p className='card-features'>
														{badge.time} {badge.timeUnit} &bull;{' '}
														{badge.cost > 0 ? '$' + badge.cost : 'Free'} &bull;{' '}
														{badge.level} &bull; {badge.type}
													</p>
												</div>
											</Link>
											<button
												className='icon-btn bookmark'
												aria-pressed={compareToSaved(badge)}
												onClick={() => bookmarkIt(compareToSaved(badge), badge)}
											>
												<Icon
													icon={
														compareToSaved(badge)
															? 'bookmark-on'
															: 'bookmark-off'
													}
													size='24'
												/>
											</button>
										</Card>
									);
								})}
							</section>
						</TabPanel>
						<TabPanel
							label='Saved'
							id='tabTwoPanel'
						>
							<section className='badge-group'>
								{savedBadges.map((badge, index) => {
									if (badge && badge.id) {
										return (
											<Card
												modifier='search-result'
												key={'saved' + index}
											>
												<Link
													className='learning-link'
													to={'/courses/' + badge.id}
												>
													<span className='program-img'>
														<img
															src={badge.image}
															alt={badge.name}
														/>
													</span>
													<div className='badge-info'>
														<h3 className='card-title'>{badge.name}</h3>
														<p className='card-text'>{badge.issuer}</p>
														<p className='card-features'>
															{badge.time} {badge.timeUnit} &bull; ${badge.cost}{' '}
															&bull; {badge.level} &bull; {badge.type}
														</p>
													</div>
												</Link>
												<button
													className='icon-btn bookmark'
													aria-pressed={compareToSaved(badge)}
													onClick={() =>
														bookmarkIt(compareToSaved(badge), badge)
													}
												>
													<Icon
														icon={
															compareToSaved(badge)
																? 'bookmark-on'
																: 'bookmark-off'
														}
														size='24'
													/>
												</button>
											</Card>
										);
									}
								})}
							</section>
						</TabPanel>
					</Tabs>
				</section>

				<div
					className='alert toast'
					ref={toast}
				>
					<span className='color-plum icon'>
						<Icon
							icon='bookmark-on'
							size='24'
						/>
					</span>
					<p className='font-lg'>Badge saved!</p>
				</div>
			</main>
		</div>
	);
};

const mapStateToProps = ({ session, badges }) => ({
	session,
	badges,
});

const mapDispatchToProps = (dispatch) => ({
	setBadges: (badge) => dispatch(setBadges(badge)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage);
