/** @format */

export const SET_ALL_COURSES = 'SET_ALL_COURSES';

export const setAllCourses = (data) => {
	return {
		type: SET_ALL_COURSES,
		allCourses: data,
	};
};
