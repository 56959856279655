import {SET_SKILLS} from "../../actions/skills/setSkills";

export default (state = null, {type, skills = null }) => {
	Object.freeze(state);
	switch (type) {
		case SET_SKILLS:
			return skills;
		default:
			return state;
	}
};
