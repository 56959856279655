/** @format */

import React from 'react';

const AvatarList = (props) => {
	return (
		<div className='avatar-list'>
			{props.users.map((user, index) => {
				return (
					<span
						className='avatar'
						key={'user' + index}
						style={{
							backgroundImage:
								'url(' + process.env.PUBLIC_URL + '/' + user.avatar + ')',
						}}
					/>
				);
			})}
		</div>
	);
};
export default AvatarList;
