import {SET_JOBS} from "../../actions/companies/setJobs";

export default (state = null, {type, jobs = null }) => {
	Object.freeze(state);
	switch (type) {
		case SET_JOBS:
			return jobs;
		default:
			return state;
	}
};
