/** @format */

import Logo from '../../assets/icons/Logo.svg';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const InstructorNavigation = (props) => {
	const [open, setOpen] = useState(false);
	var page = props.activePage ? props.activePage.toLowerCase() : 'test';
	if (page == 'profile') {
		page = 'account';
	}
	if (page == 'pillar') {
		page = 'dashboard';
	}

	const links = [
		{
			id: '0',
			label: 'Dashboard',
			icon: (
				<svg
					className='icon-24'
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path d='M5 6.5C5 5.6875 5.65625 5 6.5 5H9.5C10.3125 5 11 5.6875 11 6.5V11.5C11 12.3438 10.3125 13 9.5 13H6.5C5.65625 13 5 12.3438 5 11.5V6.5ZM6.5 11.5H9.5V6.5H6.5V11.5ZM13 12.5C13 11.6875 13.6562 11 14.5 11H17.5C18.3125 11 19 11.6875 19 12.5V17.5C19 18.3438 18.3125 19 17.5 19H14.5C13.6562 19 13 18.3438 13 17.5V12.5ZM14.5 17.5H17.5V12.5H14.5V17.5ZM9.5 14C10.3125 14 11 14.6875 11 15.5V17.5C11 18.3438 10.3125 19 9.5 19H6.5C5.65625 19 5 18.3438 5 17.5V15.5C5 14.6875 5.65625 14 6.5 14H9.5ZM9.5 15.5H6.5V17.5H9.5V15.5ZM13 6.5C13 5.6875 13.6562 5 14.5 5H17.5C18.3125 5 19 5.6875 19 6.5V8.5C19 9.34375 18.3125 10 17.5 10H14.5C13.6562 10 13 9.34375 13 8.5V6.5ZM14.5 8.5H17.5V6.5H14.5V8.5Z' />
				</svg>
			),
			href: `${process.env.PUBLIC_URL}/`,
		},
		{
			id: '1',
			label: 'Students',
			icon: (
				<svg
					className='icon-24'
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path d='M13.9688 14C15.7812 14 17.2188 12.5625 17.2188 10.75C17.2188 8.96875 15.75 7.5 13.9688 7.5C12.1875 7.5 10.75 8.96875 10.75 10.75C10.7188 12.5625 12.1875 14 13.9688 14ZM13.9688 9C14.9375 9 15.7188 9.8125 15.7188 10.75C15.7188 11.7188 14.9375 12.5 13.9688 12.5C13.0312 12.5 12.2188 11.7188 12.2188 10.75C12.2188 9.8125 13.0312 9 13.9688 9ZM20 9C21.375 9 22.5 7.90625 22.5 6.5C22.5 5.125 21.375 4 20 4C18.5938 4 17.5 5.125 17.5 6.5C17.5 7.90625 18.5938 9 20 9ZM15.5312 15H12.4375C9.96875 15 8 16.875 8 19.1875C8 19.6562 8.375 20 8.875 20H19.0938C19.5938 20 20 19.6562 20 19.1875C20 16.875 18 15 15.5312 15ZM9.5625 18.5C9.875 17.375 11.0312 16.5 12.4062 16.5H15.5312C16.9062 16.5 18.0625 17.375 18.4062 18.5H9.5625ZM21.2188 10H19.3125C18.9062 10 18.5312 10.125 18.1875 10.2812C18.1875 10.4375 18.2188 10.5938 18.2188 10.75C18.2188 11.8125 17.8438 12.7812 17.1875 13.5H23.4375C23.75 13.5 24 13.25 24 12.9375C24 11.3125 22.75 10 21.2188 10ZM9.71875 10.75C9.71875 10.5938 9.75 10.4375 9.78125 10.2812C9.4375 10.0938 9.0625 10 8.65625 10H6.75C5.21875 10 4 11.3125 4 12.9375C4 13.25 4.21875 13.5 4.53125 13.5H10.7812C10.125 12.7812 9.71875 11.8125 9.71875 10.75ZM8 9C9.375 9 10.5 7.90625 10.5 6.5C10.5 5.125 9.375 4 8 4C6.59375 4 5.5 5.125 5.5 6.5C5.5 7.90625 6.59375 9 8 9Z' />
				</svg>
			),
			href: `${process.env.PUBLIC_URL}/students`,
			roles: ['centerowner', 'manager'],
		},
		{
			id: '3',
			label: 'Classes',
			icon: (
				<svg
					className='icon-24'
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path d="M21 3H3c-1.1 0-2 .9-2 2v3h2V5h18v14h-7v2h7c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM1 18v3h3c0-1.66-1.34-3-3-3zm0-4v2c2.76 0 5 2.24 5 5h2c0-3.87-3.13-7-7-7zm0-4v2c4.97 0 9 4.03 9 9h2c0-6.08-4.93-11-11-11zm10 1.09v2L14.5 15l3.5-1.91v-2L14.5 13 11 11.09zM14.5 6 9 9l5.5 3L20 9l-5.5-3z"></path>
				</svg>
			),
			href: `${process.env.PUBLIC_URL}/classes`,
			roles: ['owner', 'instructor'],
		},
		{
			id: '4',
			label: 'Analytics',
			icon: (
				<svg
					className='icon-24'
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path d='M5.5 16.75C5.5 17.1875 5.8125 17.5 6.25 17.5H19.25C19.6562 17.5 20 17.8438 20 18.25C20 18.6875 19.6562 19 19.25 19H6.25C5 19 4 18 4 16.75V5.75C4 5.34375 4.3125 5 4.75 5C5.15625 5 5.5 5.34375 5.5 5.75V16.75ZM14.5 13.5312C14.2188 13.8438 13.75 13.8438 13.4688 13.5312L10.9688 11.0625L8.5 13.5312C8.21875 13.8438 7.75 13.8438 7.46875 13.5312C7.15625 13.25 7.15625 12.7812 7.46875 12.4688L10.4688 9.46875C10.5938 9.34375 10.7812 9.25 10.9688 9.25C11.1875 9.25 11.375 9.34375 11.5 9.46875L14 11.9688L17.7188 8.21875C18 7.9375 18.4688 7.9375 18.75 8.21875C19.0625 8.53125 19.0625 9 18.75 9.28125L14.5 13.5312Z' />
				</svg>
			),
			href: `${process.env.PUBLIC_URL}/analytics`,
			roles: ['owner', 'manager'],
		},

		{
			id: '5',
			label: 'Surveys',
			icon: (
				<svg
					className='icon-24'
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path d='M9.28125 7.74514C9 7.43264 8.53125 7.43264 8.21875 7.71389L4.21875 11.4639C4.0625 11.5889 4 11.8076 4 11.9951C4 12.2139 4.0625 12.4014 4.21875 12.5576L8.21875 16.3076C8.375 16.4326 8.5625 16.4951 8.71875 16.4951C8.90625 16.4951 9.09375 16.4326 9.25 16.2764C9.53125 15.9639 9.53125 15.4951 9.21875 15.2139L5.84375 11.9951L9.25 8.80764C9.5625 8.52639 9.5625 8.05764 9.28125 7.74514ZM16.1875 4.02639C15.7812 3.93264 15.375 4.15139 15.25 4.55764L11.25 19.0576C11.1562 19.4639 11.375 19.8701 11.7812 19.9951C11.8438 19.9951 11.9062 19.9951 12 19.9951C12.3125 19.9951 12.625 19.8076 12.7188 19.4639L16.7188 4.96389C16.8125 4.55764 16.5938 4.15139 16.1875 4.02639ZM23.75 11.4639L19.75 7.71389C19.4375 7.43264 18.9688 7.43264 18.6875 7.74514C18.4062 8.05764 18.4062 8.52639 18.7188 8.80764L22.125 11.9951L18.7188 15.2139C18.4062 15.4951 18.4062 15.9639 18.6875 16.2764C18.8438 16.4326 19.0312 16.4951 19.25 16.4951C19.4062 16.4951 19.5938 16.4326 19.75 16.3076L23.75 12.5576C23.9062 12.4014 24 12.2139 24 11.9951C24 11.8076 23.9062 11.5889 23.75 11.4639Z' />
				</svg>
			),
			href: `${process.env.PUBLIC_URL}/surveys`,
		},
		{
			id: '7',
			label: 'Support',
			icon: (
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='16'
					height='16'
					viewBox='0 0 16 16'
					fill='none'
				>
					<path
						d='M10.2408 10.6094H5.7335C3.89072 10.6094 2.42148 12.1035 2.42148 13.9463C2.42148 14.3198 2.69541 14.5938 3.06894 14.5938H12.9054C13.2789 14.5938 13.5777 14.3198 13.5777 13.9463C13.5777 12.1035 12.0836 10.6094 10.2408 10.6094ZM3.6666 13.3984C3.91562 12.502 4.7374 11.8047 5.7335 11.8047H10.2408C11.2369 11.8047 12.0587 12.502 12.3077 13.3984H3.6666ZM7.99961 4.63281C9.09531 4.63281 9.9918 5.5293 9.9918 6.625C9.9918 7.12305 9.79258 7.54639 9.51865 7.89502C9.61826 7.99463 9.69297 8.11914 9.76768 8.21875H9.9918C10.4151 8.21875 10.7887 7.99463 10.9879 7.6709C11.1124 7.34717 11.1871 6.99854 11.1871 6.625C11.1871 4.88184 9.74277 3.4375 7.99961 3.4375C6.23154 3.4375 4.81211 4.88184 4.81211 6.625C4.81211 7.646 5.28525 8.54248 6.00742 9.11523C6.00742 9.09033 6.00742 9.06543 6.00742 9.01562C6.00742 8.59229 6.18174 8.19385 6.45566 7.89502C6.18174 7.54639 6.00742 7.12305 6.00742 6.625C6.00742 5.5293 6.879 4.63281 7.99961 4.63281ZM3.6168 7.42188C3.81602 7.42188 4.01523 7.24756 4.01523 7.02344V6.625C4.01523 4.43359 5.7833 2.64062 7.99961 2.64062C10.191 2.64062 11.984 4.43359 11.984 6.625V7.02344C11.984 8.14404 11.0875 9.01562 9.9918 9.01562H9.19492C9.19492 8.59229 8.82139 8.21875 8.39805 8.21875H7.60117C7.15293 8.21875 6.8043 8.59229 6.8043 9.01562C6.8043 9.46387 7.15293 9.8125 7.60117 9.8125H9.9918C11.5108 9.8125 12.756 8.56738 12.7809 7.02344V6.625C12.7809 4.01025 10.6144 1.84375 7.99961 1.84375C5.35996 1.84375 3.21836 4.01025 3.21836 6.625V7.02344C3.21836 7.24756 3.39268 7.42188 3.6168 7.42188Z'
						fill='#989BA3'
					/>
				</svg>
			),
			href: `${process.env.PUBLIC_URL}/support`,
		},
	];

	return (
		<header className='vertical-nav'>
			<div className='logo'>
				<Link to={process.env.PUBLIC_URL + '/'}>
					<img
						src={Logo}
						alt='Pearson'
					/>
				</Link>
			</div>
			<button
				className={
					open ? 'button-unstyled hamburger fixed' : 'button-unstyled hamburger'
				}
				aria-expanded={open}
				onClick={() => setOpen(!open)}
			>
				<i className='fa fa-bars'></i>
			</button>
			<nav className={open ? 'nav-menu' : 'nav-menu hidden'}>
				<ul className='nav-links'>
					{links.map((link, i) => {
						return (
							<li key={link.id}>
								<Link
									to={link.href}
									className={page == link.label.toLowerCase() ? 'active' : ''}
									aria-current={page == link.label.toLowerCase() ? 'page' : ''}
								>
									<span className='icon'>{link.icon}</span>
									<span className='nav-text'>{link.label}</span>
								</Link>
							</li>
						);
					})}
				</ul>
			</nav>
		</header>
	);
};

export default InstructorNavigation;
