/** @format */

import React from 'react';
import Icon from '../global/Icon';

const ProfileCard = (props) => {
	console.log(props.data);
	return (
		<div className='card user-info'>
			<div className='avatar'>
				<img
					src={props.data.avatar}
					alt='avatar'
				/>
			</div>
			<h1 className='user-name'>
				{props.data.firstName} {props.data.lastName}
			</h1>
			<p className='user-location'>
				<span className='icon'>
					<Icon
						icon='location'
						size='24'
					/>
				</span>
				{props.data.city}, {props.data.state}
			</p>
			<p className='user-occupation text-h3'>{props.data.jobRole ? props.data.jobRole : props.data.jobTitle}</p>
			<p className='user-occupation text-body' style={{margin:"8px 0 0 0"}}>{props.data.organization && props.data.organization.length > 0 ? props.data.organization : props.data.type === 'enterprise' ? "Quad Bank" : props.data.role==='owner' ? "National Able Network" : "Independent Professional"}</p>
		</div>
	);
};
export default ProfileCard;
