/** @format */

import { connect } from 'react-redux';
import PageWrapper from '../global/PageWrapper';
import OwnerDashboard from './OwnerDashboard';
import EmployeeDashboard from './EmployeeDashboard';
import InstructorDashboard from './InstructorDashboard';

function Dashboard(props) {
	console.log(props.session.role);
	return (
		<>
			<PageWrapper pageType='dashboard'>
				{props.session.role === 'owner' ? (
					//Gabriella
					<OwnerDashboard />
				) : props.session.role === 'instructor' ? (
					//Darren
					<InstructorDashboard />
				) : (
					//Jeremy
					<EmployeeDashboard />
				)}
			</PageWrapper>
		</>
	);
}

const mapStateToProps = ({ session }) => ({
	session,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
