import React from "react";
import { connect } from "react-redux";
import Tabs from "../global/tabs/Tabs";
import TabPanel from "../global/tabs/TabPanel";
import JobPanel from "./JobPanel";

const OpeningsTab = (props) => {
    return (
        <>
            <Tabs modifier="pill">
                <TabPanel label="Recommended" id="recommendedTab">
                    <JobPanel type="recommended" userid={props.session.userId} />
                </TabPanel>
                <TabPanel label="Saved" id="savedTab">
                    <JobPanel type="saved" userid={props.session.userId} />
                </TabPanel>
            </Tabs>
        </>
    )
}

const mapStateToProps = ({ session }) => ({
	session,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(OpeningsTab);