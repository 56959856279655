import React from "react";
import OrdersTable from "../tables/OrdersTable";

const LicenseDetails = (props) => {

    return (
        <div className="license-details item-details">
            <div className="item-info">
                <h3>License information</h3>
                <ul>
                    <li>
                        <strong>Purchased seats:</strong> {props.data.purchasedSeats}
                    </li>
                    <li>
                        <strong>Course access duration:</strong> {props.data.duration} {props.data.durationUnit}
                    </li>
                    <li>
                        <strong>Status:</strong> {props.data.status}
                    </li>
                    <li>
                        <strong>Numer of orders:</strong> {props.data.orders ? props.data.orders.length : 5}
                    </li>
                </ul>
            </div>
            {props.data.orders ? 
            <OrdersTable data={props.data.orders}/>
            : <></>}

        </div>
    )
}
export default LicenseDetails