/** @format */

import React, { useEffect, useState } from 'react';
import { getSkillTags } from '../../util/getSkillTags';
import { setSkillTags } from '../../actions/dashboard/setSkillTags';
import { getBadges } from '../../util/getBadges';
import { setBadges } from '../../actions/badges/setBadges';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Carousel from '../global/Carousel';
import LearningCard from '../global/cards/LearningCard';
import StudentSummary from './StudentSummary';
import ActivityList from './ActivityList/ActivityList';
import Footer from '../global/Footer';
import Modal from '../global/Modal';

function OwnerDashboard(props) {
	let badgeSubset, trendingLearning;
	const skillsCount = 12;
	const now = new Date();
	const hours = now.getHours();
	const timeString =
		hours > 0 && hours < 12
			? 'morning'
			: hours > 12 && hours < 18
			? 'afternoon'
			: 'evening';

	useEffect(() => {
		if (!props.skillTagss) {
			getSkillTags().then((data) => {
				props.setSkillTags(data);
			});
		}
		if (!props.badges) {
			getBadges().then((data) => {
				props.setBadges(data);
			});
		}
	}, []);

	const [modalopen, setmodalopen] = useState(false);
	const [modalcontent, setmodalcontent] = useState('Content');
	const [modalid, setmodalid] = useState('id');

	const openModal = (e) => {
		e.preventDefault();
		const id = e.target.getAttribute('id');
		const badge = props.badges.find((b) => {
			if (b.id == id) return b;
		});
		const isCongrats = e.target.textContent == 'Congratulate' ? true : false;

		// the idea here is that you can get this content from wherever, and populate during render
		var ModalContent = (
			<>
				<div className='recommend innerContent'>
					<div className='col-1'>
						<img src={badge.image} />
					</div>
					<div className='col2'>
						<h2>{badge.name}</h2>
						{badge.slug ? (
							<p className='card-text'>
								Issued by:{' '}
								<Link
									to={
										process.env.PUBLIC_URL +
										/issuer/ +
										badge.slug.toLowerCase().replace(/\s/g, '-')
									}
									className='card-link'
								>
									{badge.issuer}
								</Link>
							</p>
						) : (
							<p>
								Issued by: <a>{badge.issuer}</a>
							</p>
						)}
						<div>
							<button
								className='button button-primary'
								onClick={closeModal}
							>
								{isCongrats ? 'Congratulate' : 'Recommend'}
							</button>
						</div>
					</div>
				</div>
				<div className='buttonBar'>
					<div className='form-input'>
						<label htmlFor='testInput'>Add a custom message</label>
						<input
							type='text'
							id='testInput'
							className='gr-input'
							placeholder='Add your custom message here'
						/>
					</div>
				</div>
			</>
		);

		setmodalid(e.target.getAttribute('id'));
		setmodalcontent(ModalContent);
		setmodalopen(true);
	};
	const closeModal = (e) => {
		e.preventDefault();
		setmodalopen(false);
	};

	return (
		<>
			<section className='intro'>
				<h1>
					Good {timeString}, {props.session.firstName}!
				</h1>
				<h2>
					Student summary <span className='chip-highlight teal'>This week</span>
				</h2>
				<StudentSummary />
			</section>
			<section>
				<h2>
					Student learning activity
					<Link
						to='#'
						className='right-shift'
					>
						View all
					</Link>
				</h2>
				{props.badges ? (
					((badgeSubset = [
						props.badges[4],
						props.badges[0],
						props.badges[9],
						props.badges[12],
						props.badges[15],
						props.badges[29],
					]),
					(
						<ActivityList
							search={false}
							badges={badgeSubset}
							congratsHandler={openModal}
						/>
					))
				) : (
					<div className='skeleton'>
						<div className='table-row'></div>
						<div className='table-row'></div>
						<div className='table-row'></div>
						<div className='table-row'></div>
					</div>
				)}
			</section>

			<section>
				<h2>Trending learning at your center</h2>
				{props.badges ? (
					((trendingLearning = [
						props.badges[21],
						props.badges[16],
						props.badges[19],
						props.badges[8],
						props.badges[3],
						props.badges[25],
					]),
					(
						<Carousel size='310'>
							{trendingLearning.map((badge) => {
								return (
									<LearningCard
										key={badge.id}
										data={badge}
										details={true}
									/>
								);
							})}
						</Carousel>
					))
				) : (
					<Carousel size='310'>
						<div className='skeleton card'></div>
						<div className='skeleton card'></div>
						<div className='skeleton card'></div>
					</Carousel>
				)}
			</section>

			<section>
				<h2>
					Trending certifications at your center
					<Link
						to='#'
						className='right-shift'
					>
						View all
					</Link>
				</h2>
				<ul className='chip-list'>
					{props.skillTags &&
						props.skillTags.map((skill, i) => {
							if (i < skillsCount) {
								return (
									<li>
										<Link
											to={process.env.PUBLIC_URL + '/skill/' + skill.id}
											className='chip-btn'
										>
											{skill.name}
										</Link>
									</li>
								);
							}
						})}
				</ul>
			</section>

			<Footer />

			<Modal
				open={modalopen}
				setModalOpen={setmodalopen}
				targetid={modalid}
			>
				{modalcontent}
			</Modal>
		</>
	);
}

const mapStateToProps = ({ session, skillTags, badges }) => ({
	session,
	skillTags,
	badges,
});

const mapDispatchToProps = (dispatch) => ({
	setSkillTags: (skillTags) => dispatch(setSkillTags(skillTags)),
	setBadges: (badges) => dispatch(setBadges(badges)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OwnerDashboard);
