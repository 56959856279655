// breadcrumbs
import { Link, useNavigate } from 'react-router-dom';

const Breadcrumbs = (props) => {
    const label = props.label ? props.label : "Back"
    const target = props.backTarget ? process.env.PUBLIC_URL+props.backTarget : `${process.env.PUBLIC_URL}/`
    const icon = props.isBack ? <i className="fa fa-arrow-left" aria-hidden="true"></i> : ""
    const page = props.pageName ? <span className="pageName-wrapper"><i className="fa fa-chevron-right" aria-hidden="true"></i><span className="pageName">{props.pageName}</span></span> : "";

    const navigate = useNavigate();
	const goBack = () => {
		navigate(-1);
	}
     return (
        <div className="breadcrumb-container">
            {props.isBack ? 
                <button className='' onClick={() => navigate(-1)}>
                    { icon }
                    {label}
                </button>
                : 
                <>
                <Link to={target}>
                    { icon }
                    {label}
                </Link>
                { page }
            </>
        }
            
        </div>
    )
}

export default Breadcrumbs