/** @format */

import React, { useRef } from 'react';
import { connect } from 'react-redux';
import Card from '../global/cards/Card';
import PageWrapper from '../global/PageWrapper';
import DonutChart from '../global/infographics/DonutChart';
import SkillsAreaChart from '../global/infographics/SkillsAreaChart';
import BadgesChart from '../global/infographics/BarChart';
import HorizontalMeter from '../global/infographics/HorizontalMeter';
import Icon from '../global/Icon';
import Footer from '../global/Footer';
import useOnScreen from '../occupations/OnScreenHook';

const Analytics = (props) => {
	const graph1 = useRef();
	const graph2 = useRef();

	const graph1Visible = useOnScreen(graph1);
	const graph2Visible = useOnScreen(graph2);

	const pieData = [
		{
			name: 'Employee Learning badges',
			value: 32,
		},
		{
			name: 'Connected employees',
			value: 24,
		},
		{
			name: 'Mapped employees',
			value: 12,
		},
		{
			name: 'Total employees',
			value: 16,
		},
		{
			name: 'Not particpated',
			value: 16,
		},
	];
	const healthData = [
		{
			name: 'Employee Learning badges',
			value: 123456789,
		},
		{
			name: 'Connected employees',
			value: 2345678,
		},
		{
			name: 'Mapped employees',
			value: 34567,
		},
		{
			name: 'Total employees',
			value: 4567,
		},
	];

	return (
		<PageWrapper pageType='analytics'>
			<div className='analytics-page'>
				<section className='dark-bg'>
					<div className='analytics-actions section-row'>
						<div className='opacity0'>
							{props.session.role === 'owner' ? (
								<div className='form-input'>
									<label>Role</label>
									<div className='gr-select-container'>
										<select
											className='gr-select'
											defaultValue={1}
										>
											<option value='0'>All</option>
											<option value='1'>Technology</option>
											<option value='2'>Marketing</option>
											<option value='3'>Design</option>
										</select>
										<Icon
											icon='drop-down'
											size='18'
										/>
									</div>
								</div>
							) : (
								<h1>My team</h1>
							)}
						</div>
						<button className='button-link'>
							<Icon
								icon='export'
								size='24'
							/>{' '}
							Export
						</button>
					</div>
					<div className='card-list horizontal-scroll'>
						<Card modifier='overview'>
							<button className='icon-btn info-btn'>
								<Icon
									icon='chevron-right'
									size='24'
								/>
							</button>
							<span className='label'>Student engagement</span>
							<span className='number'>
								{props.session.role === 'owner' ? '32.3k' : '7'}
							</span>
							<span className='chip-highlight'>
								{props.session.role === 'owner' ? '+128' : '+1'}{' '}
								<span className='trending-up'>
									<Icon
										icon='trending-up'
										size='18'
									/>
								</span>
							</span>
							<div className='trending-line'>
								{/* <svg width="162" height="23" viewBox="0 0 162 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M 0,22.986474248962505 C 1.7999999999999998,22.81121027511182 5.4,22.486297290597207 9,22.11015437970907 C 12.600000000000001,21.73401146882093 14.4,21.464716904730558 18,21.10575969452182 C 21.6,20.74680248431308 23.4,20.6696922042719 27,20.31536832866538 C 30.6,19.961044453058857 32.4,19.55400526298725 36,19.33414031648921 C 39.6,19.114275369991176 41.4,19.454963056038427 45,19.216043596175197 C 48.6,18.977124136311968 50.4,18.6997203371024 54,18.139543017173068 C 57.6,17.579365697243738 59.4,16.996402516472937 63,16.415156996528545 C 66.6,15.83391147658415 68.4,15.670190635386056 72,15.2333154174511 C 75.6,14.796440199516145 77.4,14.20505301822853 81,14.230780906853763 C 84.6,14.256508795478998 86.4,14.93092581437732 90,15.361954860577276 C 93.6,15.792983906777232 95.4,16.795023725179103 99,16.38592613785355 C 102.6,15.976828550527996 104.4,13.461270395125531 108,13.316466923949514 C 111.6,13.171663452773497 113.4,16.336499223216 117,15.661908781973468 C 120.6,14.987318340730939 122.4,11.261778490869657 126,9.943514717736871 C 129.6,8.625250944604085 131.4,9.200391997687117 135,9.07058991630954 C 138.6,8.940787834931966 140.4,8.698962141439761 144,9.294504310848994 C 147.6,9.89004648025823 149.4,12.87354370681892 153,12.048300763355712 C 156.6,11.223057819892505 158.4,6.393530532732025 162,5.168289593532961 C 165.6,3.9430486543338974 169.2,5.771334772594909 171,5.922096067360396" fill="none" stroke="#6D0176" stroke-width="1.5px" />
                                        </svg> */}
								<svg
									width='162'
									height='23'
									viewBox='0 0 162 23'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M 0,22.953634881555555 C 1.44,22.867416434668943 6.12,22.570568709444228 9,22.41476958851423 C 11.879999999999999,22.25897046758423 15.12,22.32530622561432 18,21.979890375743057 C 20.88,21.634474525871795 24.12,20.592053716661436 27,20.25592052681883 C 29.88,19.919787336976224 33.12,19.863414477416303 36,19.87905793922677 C 38.88,19.894701401037235 42.12,20.437639476757962 45,20.353692163134248 C 47.88,20.269744849510534 51.12,19.815141686327994 54,19.354387229078565 C 56.88,18.893632771829136 60.12,17.66559239565024 63,17.473976805325314 C 65.88,17.28236121500039 69.12,18.560183458132055 72,18.15678978954778 C 74.88,17.753396120963505 78.12,15.192348354869317 81,14.952766376673583 C 83.88,14.713184398477848 87.12,16.458363177303717 90,16.65940242582444 C 92.88,16.86044167434516 96.12,16.53906663977765 99,16.209261679928105 C 101.88,15.87945672007856 105.12,15.2223111773069 108,14.59812142676478 C 110.88,13.973931676222664 114.12,13.038249563647398 117,12.308075739039873 C 119.88,11.577901914432351 123.12,9.926139511057505 126,10.034535022967763 C 128.88,10.14293053487802 132.12,13.378232220710878 135,12.985547688478984 C 137.88,12.592863156247093 141.12,7.569822287586233 144,7.580256696518436 C 146.88,7.590691105450639 150.12,12.546016190637038 153,13.050762744305253 C 155.88,13.555509297973467 159.12,11.183725365466257 162,10.73492265694478 C 164.88,10.286119948423305 169.56,10.324014110589829 171,10.245745816046028'
										fill='none'
										stroke='#6D0176'
										stroke-width='1.5px'
									/>
								</svg>
							</div>
							<button className='button-link'>View</button>
						</Card>
						<Card modifier='overview'>
							<button className='icon-btn info-btn'>
								<Icon
									icon='chevron-right'
									size='24'
								/>
							</button>
							<span className='label'>Time in the platform</span>
							<span className='number'>
								{props.session.role === 'owner' ? '8,218' : '4'}
							</span>
							<span className='chip-highlight'>
								{props.session.role === 'owner' ? '+32' : '+1'}{' '}
								<span className='trending-up'>
									<Icon
										icon='trending-up'
										size='18'
									/>
								</span>
							</span>
							<div className='trending-line'>
								<svg
									width='162'
									height='23'
									viewBox='0 0 162 23'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M 0,22.91735811030287 C 1.7999999999999998,22.82699710906049 5.4,22.6688200167793 9,22.465553104090958 C 12.6,22.26228619140262 14.4,22.107156984215866 18,21.901023546861172 C 21.6,21.694890109506478 23.4,21.723502522057984 27,21.43488591731749 C 30.6,21.146269312576997 32.4,20.70950690521561 36,20.45794052315871 C 39.6,20.20637414110182 41.4,20.795271147070284 45,20.177054007033018 C 48.6,19.55883686699575 50.4,17.813427647527 54,17.366854822972385 C 57.6,16.92028199841777 59.4,18.126473716670613 63,17.944189884259938 C 66.6,17.761906051849262 68.4,17.121695397929837 72,16.455435660919015 C 75.6,15.789175923908193 77.4,14.479161354257725 81,14.61289119920583 C 84.6,14.746621044153937 86.4,16.868356909890522 90,17.12408488565955 C 93.6,17.379812861428576 95.4,16.374833355978506 99,15.89153107805097 C 102.6,15.408228800123437 104.4,15.373718222093123 108,14.70757349602188 C 111.6,14.041428769950636 113.4,12.831401749218406 117,12.560807447694755 C 120.6,12.290213146171103 122.4,13.439178082567771 126,13.354601988403626 C 129.6,13.270025894239481 131.4,11.894706026628892 135,12.13792697687403 C 138.6,12.381147927119168 140.4,14.501587352206737 144,14.570706739629314 C 147.6,14.63982612705189 149.4,13.183495123917664 153,12.483523913986913 C 156.6,11.783552704056163 158.4,11.559831381490179 162,11.070850689975565 C 165.6,10.58186999846095 169.2,10.245066503126187 171,10.038620456413843'
										fill='none'
										stroke='#6D0176'
										stroke-width='1.5px'
									/>
								</svg>
							</div>
							<button className='button-link'>View</button>
						</Card>
						<Card modifier='overview'>
							<button className='icon-btn info-btn'>
								<Icon
									icon='chevron-right'
									size='24'
								/>
							</button>
							<span className='label'>Videos watched</span>
							<span className='number'>
								{props.session.role === 'owner' ? '4,781' : '132'}
							</span>
							<span className='chip-highlight'>
								{props.session.role === 'owner' ? '+426' : '+3'}{' '}
								<span className='trending-up'>
									<Icon
										icon='trending-up'
										size='18'
									/>
								</span>
							</span>
							<div className='trending-line'>
								<svg
									width='162'
									height='23'
									viewBox='0 0 162 23'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M 0,22.787926692953057 C 1.44,22.682978020679048 6.12,22.408321988751116 9,22.131997491240497 C 11.879999999999999,21.85567299372988 15.120000000000001,21.395230806491693 18,21.0608985835117 C 20.88,20.72656636053171 24.12,20.1735773861548 27,20.042421097615556 C 29.88,19.91126480907631 33.12,20.51987032985048 36,20.241171780141414 C 38.88,19.962473230432348 42.12,18.367866563429303 45,18.3005551619339 C 47.88,18.233243760438498 51.12,20.162496206160696 54,19.82047552079516 C 56.88,19.478454835429623 60.12,16.341104896908195 63,16.162925878399296 C 65.88,15.984746859890397 69.12,18.464051314020207 72,18.706856655114542 C 74.88,18.949661996208878 78.12,18.59278769203366 81,17.6804592602389 C 83.88,16.768130828444143 87.12,13.202320958799998 90,13.004803956397298 C 92.88,12.8072869539946 96.12,15.984067521327043 99,16.445977995222034 C 101.88,16.907888469117022 105.12,16.434285087467806 108,15.891744418240988 C 110.88,15.349203749014167 114.12,14.074892380937342 117,13.05509881255441 C 119.88,12.035305244171479 123.12,9.186479655847007 126,9.518034615847665 C 128.88,9.849589575848322 132.12,15.005986011409723 135,15.127317312558523 C 137.88,15.248648613707322 141.12,10.468953445712545 144,10.276355248027663 C 146.88,10.083757050342781 150.12,14.664177253679224 153,13.923578577028007 C 155.88,13.18297990037679 159.12,6.141803183859651 162,5.647613518957557 C 164.88,5.153423854055462 169.56,10.004928427000738 171,10.834893171389917'
										fill='none'
										stroke='#6D0176'
										stroke-width='1.5px'
									/>
								</svg>
							</div>
							<button className='button-link'>View</button>
						</Card>
						<Card modifier='overview'>
							<button className='icon-btn info-btn'>
								<Icon
									icon='chevron-right'
									size='24'
								/>
							</button>
							<span className='label'>Practice tests conducted</span>
							<span className='number'>873</span>
							<span className='chip-highlight'>
								+4{' '}
								<span className='trending-up'>
									<Icon
										icon='trending-up'
										size='18'
									/>
								</span>
							</span>
							<div className='trending-line'>
								<svg
									width='162'
									height='23'
									viewBox='0 0 162 23'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M 0,22.913725699656727 C 1.4400000000000002,22.786480522749596 6.119999999999999,22.299978872630543 9,22.11844334398716 C 11.88,21.936907815343776 15.12,21.883196632614414 18,21.77912864563558 C 20.88,21.675060658656747 24.12,21.776570805312776 27,21.46801842536946 C 29.88,21.159466045426147 33.12,20.169808765367847 36,19.850676270989858 C 38.88,19.53154377661187 42.12,19.794666965742135 45,19.47344033550703 C 47.88,19.152213705271922 51.12,18.192641131567168 54,17.843009832020442 C 56.88,17.493378532473713 60.12,17.108719858344674 63,17.28824471333998 C 65.88,17.46776956833529 69.12,18.875888130336573 72,18.965040175741116 C 74.88,19.05419222114566 78.12,18.160096335645317 81,17.845444997118364 C 83.88,17.53079365859141 87.12,17.07413674304445 90,16.998469309947666 C 92.88,16.922801876850883 96.12,18.003175327933302 99,17.37252354026348 C 101.88,16.741871752593656 105.12,13.564840093238214 108,13.05689563701128 C 110.88,12.548951180784346 114.12,14.684698038132897 117,14.197870688845144 C 119.88,13.711043339557392 123.12,10.951633735779172 126,10.014224703962833 C 128.88,9.076815672146495 132.12,8.384608009871574 135,8.339064239993032 C 137.88,8.29352047011449 141.12,9.147571918366001 144,9.72957614222195 C 146.88,10.3115803660779 150.12,12.267201282481924 153,11.976590639092716 C 155.88,11.685979995703509 159.12,7.989792747211977 162,7.913259621039405 C 164.88,7.836726494866833 169.56,10.924658763798186 171,11.498258600514145'
										fill='none'
										stroke='#6D0176'
										stroke-width='1.5px'
									/>
								</svg>
							</div>
							<button className='button-link'>View</button>
						</Card>
						<Card modifier='overview'>
							<button className='icon-btn info-btn'>
								<Icon
									icon='chevron-right'
									size='24'
								/>
							</button>
							<span className='label'>Labs conducted</span>
							<span className='number'>873</span>
							<span className='chip-highlight'>
								+4{' '}
								<span className='trending-up'>
									<Icon
										icon='trending-up'
										size='18'
									/>
								</span>
							</span>
							<div className='trending-line'>
								<svg
									width='162'
									height='23'
									viewBox='0 0 162 23'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M 0,22.913725699656727 C 1.4400000000000002,22.786480522749596 6.119999999999999,22.299978872630543 9,22.11844334398716 C 11.88,21.936907815343776 15.12,21.883196632614414 18,21.77912864563558 C 20.88,21.675060658656747 24.12,21.776570805312776 27,21.46801842536946 C 29.88,21.159466045426147 33.12,20.169808765367847 36,19.850676270989858 C 38.88,19.53154377661187 42.12,19.794666965742135 45,19.47344033550703 C 47.88,19.152213705271922 51.12,18.192641131567168 54,17.843009832020442 C 56.88,17.493378532473713 60.12,17.108719858344674 63,17.28824471333998 C 65.88,17.46776956833529 69.12,18.875888130336573 72,18.965040175741116 C 74.88,19.05419222114566 78.12,18.160096335645317 81,17.845444997118364 C 83.88,17.53079365859141 87.12,17.07413674304445 90,16.998469309947666 C 92.88,16.922801876850883 96.12,18.003175327933302 99,17.37252354026348 C 101.88,16.741871752593656 105.12,13.564840093238214 108,13.05689563701128 C 110.88,12.548951180784346 114.12,14.684698038132897 117,14.197870688845144 C 119.88,13.711043339557392 123.12,10.951633735779172 126,10.014224703962833 C 128.88,9.076815672146495 132.12,8.384608009871574 135,8.339064239993032 C 137.88,8.29352047011449 141.12,9.147571918366001 144,9.72957614222195 C 146.88,10.3115803660779 150.12,12.267201282481924 153,11.976590639092716 C 155.88,11.685979995703509 159.12,7.989792747211977 162,7.913259621039405 C 164.88,7.836726494866833 169.56,10.924658763798186 171,11.498258600514145'
										fill='none'
										stroke='#6D0176'
										stroke-width='1.5px'
									/>
								</svg>
							</div>
							<button className='button-link'>View</button>
						</Card>
					</div>
				</section>
				<section>
					<div className='section-header'>
						<h2>Program health</h2>
						<button className='button-link with-icon'>
							<Icon
								icon='date'
								size='24'
							/>
							1 Oct 2022 - 31 Oct 2022
						</button>
					</div>
					<div className='section-body'>
						<div className='card-row graphs-row'>
							<Card modifier='program-health'>
								<div className='infographic-row'>
									<p className='data-highlight'>
										<span className='number'>
											{props.session.role === 'owner' ? '3,585' : '6'}
										</span>
										<span>
											of {props.session.role === 'owner' ? '4,218' : '7'}{' '}
											students
										</span>
									</p>
									<div className='infographic'>
										<DonutChart data={pieData} />
									</div>
								</div>

								<HorizontalMeter
									data={{
										name: 'Student learning credentials',
										value: 123456789,
										total: 240000000,
									}}
									fill='#1F897C'
								/>
								<HorizontalMeter
									data={{
										name: 'Connected students',
										value: 23456789,
										total: 60000000,
									}}
									fill='#309CBD'
								/>
								<HorizontalMeter
									data={{
										name: 'Mapped students',
										value: 3456789,
										total: 18000000,
									}}
									fill='#655591'
								/>
								<HorizontalMeter
									data={{ name: 'Total students', value: 4567, total: 16000 }}
									fill='#C55554'
								/>
								{/* const colors = ["#1F897C","#309CBD", "#655591","#C55554","#E7EBEC"] */}
							</Card>
							<div className='card-column'>
								<Card>
									<h3
										className='text-lg'
										style={{
											position: 'absolute',
											left: '24px',
											top: '24px',
											maxWidth: '100px',
											fontWeight: 700,
										}}
									>
										Verified skills
									</h3>
									<div className='infographic'>
										<SkillsAreaChart />
									</div>
								</Card>
								<Card>
									<div className='infographic-row'>
										<div
											className='card-column'
											style={{ justifyContent: 'space-between' }}
										>
											<h3
												className='text-lg'
												style={{ fontWeight: 700 }}
											>
												Credentials earned
											</h3>
											<span style={{ color: 'var(--color-text-muted)' }}>
												13% MoM
											</span>
										</div>
										<div className='infographic'>
											<BadgesChart />
										</div>
									</div>
								</Card>
							</div>
						</div>
					</div>
				</section>
			</div>
			<Footer />
		</PageWrapper>
	);
};

const mapStateToProps = ({ session }) => ({
	session,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
