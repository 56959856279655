import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Icon from "../../global/Icon";

const EmployeeBadgesTable = (props) => {
    const navigate = useNavigate()
    const [sorted, setSorted] = useState({column:0,dir:"descending"});
    const sortColumn = (index) => {
        //need to bring in data and then transform it still
        if (sorted.column === index) {
            if (sorted.dir === "descending") {
                setSorted({...sorted, dir:"ascending"})
            } else {
                setSorted({...sorted, dir:"descending"})
            }
        } else {
            setSorted({column:index, dir:"descending"})
        }
    }
  

    return (
        <div className="table-container">
                <table className="sortable-table" aria-label="Credentials">
                    {/* <caption>Badges</caption> */}
                    <thead>
                        <tr>
                            <th scope="col" aria-sort={sorted.column === 0 ? sorted.dir : "none"}>
                                <button className="sort-btn button-unstyled" onClick={() => sortColumn(0)}>
                                Credential
                                    <span className="icon">
                                        {sorted.column === 0 ? <Icon icon={sorted.dir} size="18"/> : <Icon icon="sortable" size="18"/>}
                                    </span>
                                </button>
                            </th>
                            <th scope="col" aria-sort={sorted.column === 1 ? sorted.dir : "none"}>
                                <button className="button-unstyled sort-btn" onClick={() => sortColumn(1)}>
                                   Level
                                    <span className="icon">
                                        {sorted.column === 1 ? <Icon icon={sorted.dir} size="18"/> : <Icon icon="sortable" size="18"/>}
                                    </span>
                                </button>
                            </th>
                            <th scope="col" aria-sort={sorted.column === 2 ? sorted.dir : "none"}>
                                <button className="button-unstyled sort-btn" onClick={() => sortColumn(2)}>
                                    Type 
                                    <span className="icon">
                                        {sorted.column === 2 ? <Icon icon={sorted.dir} size="18"/> : <Icon icon="sortable" size="18"/>}
                                    </span>
                                </button>
                            </th>
                            <th scope="col" aria-sort={sorted.column === 3 ? sorted.dir : "none"}>
                                <button className="button-unstyled sort-btn" onClick={() => sortColumn(3)}>
                                    Date issued
                                    <span className="icon">
                                        {sorted.column === 3 ? <Icon icon={sorted.dir} size="18"/> : <Icon icon="sortable" size="18"/>}
                                    </span>
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.data && props.data.length > 0 ? props.data.map((item, index) => {  
                            // const itemData = mapIdtoBadge(item.classId)                          
                                return (
                                <tr key={`skills-${index}`} onClick={() => {navigate(`${process.env.PUBLIC_URL}/learning/${item.id}`,{replace:false});window.scrollTo(0,0)}}>
                                     <th scope="row">
                                        <div className="employee-data">
                                            {item.image && 
                                            <div className="badge">
                                                <img src={item.image} alt="badge"/>
                                            </div> }
                                            <div className="employee-info">
                                                <span className="employee-name"> {item.name}</span>
                                                <span className="employee-location">{item.issuer}</span>
                                            </div> 
                                        </div>
                                    </th>
                                    <td>
                                        {item.level ? item.level : "level"}
                                    </td>
                                    <td>
                                       {item.type ? item.type : "type"}
                                    </td>
                                    <td>
                                        {item.date}
                                    </td>
                                    
                                  
                                   
                                </tr>)
                            
                        }) : <tr>
                                <td colSpan={5}>No credentials </td>
                            </tr>}
                    
                    </tbody>
                </table>
        </div>
    )
}
export default EmployeeBadgesTable