import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer } from "recharts";



const SkillsAreaChart = props => {

    const data1 = [
        {
            year: 2012,
            soft: 1100,
            technical:1440
        },
        {
            year: 2013,
            soft: 1090,
            technical: 1640
        },
        {
            year: 2014,
            soft: 820,
            technical: 1500
        },
        {
            year:2015,
            soft: 930,
            technical: 1100
        },
        {
            year: 2016,
            soft: 980,
            technical: 1200
        },
        {
            year: 2017,
            soft: 1070,
            technical: 1450
        }
    ]
    const renderColorfulLegendText = (value, entry) => {
    
      
        return <div style={{display:"inline-block",color:"var(--color-text-muted)",fontWeight:"400",marginTop:"16px"}}>{value} skills</div>;
      };
   
  
    return (
        
            <ResponsiveContainer width="100%" height={220}>
            <AreaChart
                width={500}
                height={400}
                data={data1}
                margin={{
                top: 10,
                right: 10,
                left: 0,
                bottom: 0,
                }}
            >
                <defs>
                <linearGradient id="colorTG" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="var(--color-bright-teal)" stopOpacity={0.8}/>
                <stop  offset="100%" stopColor="var(--color-bright-teal)" stopOpacity={0}/>
                </linearGradient>
                <linearGradient id="colorGG" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="var(--color-dark-blue)" stopOpacity={0.8}/>
                <stop  offset="100%" stopColor="var(--color-dark-blue)" stopOpacity={0}/>
                </linearGradient>
            </defs>
                <Legend formatter={renderColorfulLegendText} align="left" verticalAlign="middle" height={72} width={130} iconType={"plainline"} wrapperStyle={{top:"150px",textTransform:"capitalize", color: "grey"}}/>
                <CartesianGrid vertical={false} stroke="#DDE3EE" strokeWidth={1} />
                <XAxis dataKey="year"  interval="preserveStartEnd"  tickLine={false} axisLine={false} overflow={"allow"} ticks={[2013,2014,2015,2016]} dy={5}/>
                <YAxis tickLine={false} axisLine={false} type="number" domain={[500, 2000]} ticks={[500,1000,1500,2000]} /> 
                {/* <Tooltip /> */}
                <Area type="monotone" dataKey="soft" strokeWidth={2} strokeLinecap="round" stroke="var(--color-dark-blue)" fill="url(#colorGG)" minTickGap={10}/>
                <Area type="monotone" dataKey="technical" strokeWidth={2} strokeLinecap="round" stroke="var(--color-bright-teal)" fill="url(#colorTG)" minTickGap={10}  />
            </AreaChart>
            </ResponsiveContainer>
   
    );
  
}
export default SkillsAreaChart
