import React, {useEffect, useState} from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";
import Icon from '../global/Icon';
import {connect} from "react-redux";

const FitScoreDonut = (props) => {
    const [load, setLoad] = useState(false);
	const scores = props.data;

    useEffect(() => {
        setTimeout(() => {
            setLoad(true)
        },500)
    })
    const data = [
        {
            "name": "avg",
            "value": 75,
            "avg": 70,
            "exp": scores.experience,
            "edu": scores.education,
            "abil": scores.ability,
            "fill":"url(#ringGradientFF)"
          }
      ]
      const stacked = [
        {
            name: "Score",
            exp: scores.experience,
            edu: scores.education,
            abil: scores.ability
        },
        {
          "name": "score",
          "value": scores.score,
          "avg": 70,
          "fill":"#0CC0AD"
        }
      ]
      const donut = [
        {
            "name":"experience",
            "value":scores.experience,
            "fill":"#9901A5"
        },
        {
            "name":"education",
            "value":scores.education,
            "fill":"#6D0176"
        },
        {
            "name":"ability",
            "value":scores.ability,
            "fill":"#57015E"
        },
        
        {
            "name":"total",
            "value": 100 - scores.score,
            "fill":"var(--color-light-200)"
        }
        
      ]
      const donut1 = [
        {
            "name":"experience",
            "value":scores.experience,
            "fill":"#9901A5",
            "stroke":"white"
        },
        {
            "name":"total",
            "value": 100 - scores.experience,
            "fill":"transparent",
            "stroke":"transparent"
        }
        
      ]
      const donut2 = [
        {
            "name":"education",
            "value":scores.experience + scores.education,
            "fill":"#6D0176",
            "stroke":"white"
        },
        {
            "name":"total",
            "value": 100 - scores.experience - scores.education,
            "fill":"transparent",
            "stroke":"transparent"
        }
        
      ]
      const donut3 = [
        {
            "name":"ability",
            "value":scores.experience + scores.education + scores.ability,
            "fill":"#57015E",
            "stroke":"white"
        },
        
        {
            "name":"total",
            "value": 100 - scores.experience - scores.education - scores.ability,
            "fill":"transparent",
            "stroke":"transparent"
        }
        
      ]
      const average = [
        {
            "name": "avg",
            "value": 75,
            "fill":"url(#ringGradientFF)",
            "stroke":"white"
        },{
            "name":"left",
            "value":25,
            "fill":"transparent",
            "stroke":"transparent"
        }
      ]
      const averageBg = [
        {
            "name":"total",
            "value":100,
            "fill":"var(--color-light-200)",
            "stroke":"transparent"
        }
      ]
      const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  

    return (
        <div className='infographic fit-score'>
            <div className='infographic-text'>
                {props.increase && <span className='chip-highlight'>+5 <Icon icon="trending-up" size="18"/></span>}
                {/* <span style={{fontSize:"14px"}}>Looking great</span> */}
                <span className='current-value'>
                    {props.data.score}
                </span>
                <span className='avg-value'>
                    avg 75
                </span>
            </div>
           <div style={{textAlign:"center"}}>
            {load ? 
            <ResponsiveContainer width="100%" height={230}>
                <PieChart width={295} height={230}>
                     <defs>
                        <linearGradient id="ringGradientFF" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="0%" stopColor="#2A677C" />
                        <stop offset="100%" stopColor="#0CC0AD" />
                        </linearGradient>
                     </defs>
                     <text x={"50%"} y={220}
                            style={{ fontSize: 18, fill: 'var(--color-black)', transform: "translateX(-95px)" }}
                            fontWeight="bold"
                            textAnchor='middle'
                        >0</text>
                        <text x={"50%"} y={220}
                            style={{ fontSize: 18, fill: 'var(--color-black)', transform: "translateX(90px)" }}
                            fontWeight="bold"
                            textAnchor='middle'
                        >100</text>
                     <Pie
                        data={averageBg}
                        //   cx={200}
                        cy={150}
                        startAngle={205}
                        endAngle={-25}
                        innerRadius={111}
                        outerRadius={148}
                        cornerRadius={20}
                        fill="#8884d8"
                        paddingAngle={-10}
                        dataKey="value"
                        strokeWidth={4}
                        stroke={"var(--color-white)"}
                        >
                        {data.map((entry, index) => {
                            <Cell key={`cell-${index}`} />
                        })}
                        {/* {renderLines()} */}
                        </Pie>
                        <Pie
                        data={donut3}
                        //   cx={200}
                        cy={150}
                        startAngle={205}
                        endAngle={-25}
                        innerRadius={111}
                        outerRadius={148}
                        cornerRadius={20}
                        fill="#8884d8"
                        paddingAngle={-10}
                        dataKey="value"
                        strokeWidth={4}
                        >
                        {data.map((entry, index) => {
                            <Cell key={`cell-${index}`} />
                        })}
                        {/* {renderLines()} */}
                        </Pie>
                        <Pie
                        data={donut2}
                        //   cx={200}
                        cy={150}
                        startAngle={205}
                        endAngle={-25}
                        innerRadius={111}
                        outerRadius={148}
                        cornerRadius={20}
                        fill="#8884d8"
                        paddingAngle={-10}
                        dataKey="value"
                        strokeWidth={4}
                        >
                        {data.map((entry, index) => {
                            <Cell key={`cell-${index}`} />
                        })}
                        {/* {renderLines()} */}
                        </Pie>
                        <Pie
                        data={donut1}
                        //   cx={200}
                        cy={150}
                        startAngle={205}
                        endAngle={-25}
                        innerRadius={111}
                        outerRadius={148}
                        cornerRadius={20}
                        fill="#8884d8"
                        paddingAngle={-10}
                        dataKey="value"
                        strokeWidth={4}
                        >
                        {data.map((entry, index) => {
                            <Cell key={`cell-${index}`} />
                        })}
                        {/* {renderLines()} */}
                        </Pie>


                        {/* average chart */}
                        <Pie
                        data={averageBg}
                        //   cx={200}
                        cy={150}
                        startAngle={205}
                        endAngle={-25}
                        innerRadius={72}
                        outerRadius={105}
                        cornerRadius={20}
                        fill="#8884d8"
                        paddingAngle={-20}
                        dataKey="value"

                        >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} />
                        ))}
                        </Pie>
                        <Pie
                        data={average}
                        //   cx={200}
                        cy={150}
                        startAngle={205}
                        endAngle={-25}
                        innerRadius={72}
                        outerRadius={105}
                        cornerRadius={20}
                        fill="#8884d8"
                        paddingAngle={-20}
                        dataKey="value"

                        >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} />
                        ))}
                        </Pie>

                </PieChart>
                </ResponsiveContainer>: <div className='skeleton' style={{height:"230px"}}></div>}
            </div>

        </div>
    )
}
const mapStateToProps = ({session,scores}) => ({
	session, scores
});

export default connect(
	mapStateToProps
)(FitScoreDonut);


