/** @format */

// modal
import React from 'react';
import { useState, useEffect } from 'react';

const delay = (delayInms) => {
	return new Promise((resolve) => setTimeout(resolve, delayInms));
};

const close = (e) => {
	// how to go from here??
};

const Modal = (props) => {
	const [inmodal, setinmodal] = useState(false);

	const closeHandler = async (e) => {
		e.preventDefault();
		setinmodal(false);
		let d = await delay(400);
		props.setModalOpen(false);
		document.getElementById(props.targetid).focus();
	};

	useEffect(() => {
		const callMe = async () => {
			let d = await delay(100);
			let body = document.body;
			if (props.open) {
				body.className = `modal-window-open ${
					props.overlay ? 'modal-overlay' : ''
				}`;
				let modal = props.overlay
					? document.querySelector('.modal-window .breadcrumb-container button')
					: document.querySelector('.modal-window .modal-close button');
				modal.focus();
			} else {
				body.className = '';
			}
			setinmodal(props.open);
		};
		callMe();
	}, [props.open]);

	return (
		<div
			className={`modal ${props.sheet ? 'sheet' : ''} ${
				props.overlay ? 'overlay' : ''
			} ${!props.open ? 'hidden' : 'open'}`}
		>
			{props.overlay ? (
				<></>
			) : (
				<div
					className='modal-backdrop'
					onClick={closeHandler}
				></div>
			)}
			<div className={inmodal ? 'modal-window in' : 'modal-window'}>
				{props.overlay ? (
					<div className='breadcrumb-container'>
						<button
							className=''
							onClick={closeHandler}
						>
							<i
								className='fa fa-arrow-left'
								aria-hidden='true'
							></i>
							Back
						</button>
					</div>
				) : (
					<div className='modal-close'>
						<button
							className='button-unstyled'
							onClick={closeHandler}
						>
							&times;
						</button>
					</div>
				)}
				<div className='modal-body'>{props.children}</div>
			</div>
		</div>
	);
};

export { close };

export default Modal;
