/** @format */

import React from 'react';
import {
	RadialBarChart,
	RadialBar,
	Legend,
	ResponsiveContainer,
} from 'recharts';

const RadialChart = (props) => {
	const data = props.data;

	const style = {
		top: '50%',
		right: 0,
		transform: 'translate(0, -50%)',
		lineHeight: '24px',
	};

	return (
		<div className='radialChart'>
			<ResponsiveContainer
				width={400}
				aspect={1}
			>
				<RadialBarChart
					cx={'50%'}
					cy={'50%'}
					innerRadius='95%'
					outerRadius='95%'
					startAngle={200}
					endAngle={-20}
					barSize={10}
					data={data}
				>
					<RadialBar
						minAngle={15}
						background
						clockWise
						dataKey='value'
					/>
				</RadialBarChart>
			</ResponsiveContainer>
			<div className='chartLabel'>{props.label}</div>
			<div className='impactLabel'>
				<div className='impactValue'>{props.impact}</div>
				<div className='label'>Impact rating</div>
			</div>
		</div>
	);
};

export default RadialChart;
