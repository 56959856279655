/** @format */

export const SET_USERS_WITH_SKILL = 'SET_USERS_WITH_SKILL';

export const setUsersWithSkill = (data) => {
	return {
		type: SET_USERS_WITH_SKILL,
		usersWithSkill: data,
	};
};
