import React, {useState} from "react";
import Icon from "../global/Icon";

const CourseAccordion = (props) => {
    const [open, setOpen] = useState(false)

    return (
        <div className="accordion-container">
            <button className="course-accordion accordion-btn" aria-expanded={open} onClick={() => setOpen(!open)}>
                <span className="title">{props.title}</span>
                <span className="icon">
                    <Icon icon={open ? "collapse" : "expand"} size="18"/>
                </span>
            </button>
            {open ? 
            
            <div className="accordion-body">
                    {props.children}
            </div>
            : <></> }
        </div>
    )
}
export default CourseAccordion